import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
// import { clearCancellationPolicyList, getCancellationPolicyList } from "../redux/actions/cancellation";
import Select from "react-select";
import { getHotelCountry } from "../../../redux/actions/getContracts";
import {
  clearCancellationPolicyList,
  deleteCancellationPolicy,
  getCancellationHotelFilter,
  getCancellationPolicyList,
  getCancellationPolicyListCities,
} from "../../../redux/actions/cancellation";
// import useCancellationPolicyList from "../../../customHooks/useCancellationPolicyList";
import { TbListDetails } from "react-icons/tb";
import DeletePopUp from "../../molecules/DeletePopUp/DeletePopUp";
import usePermissions from "../../../customHooks/usePermissions";
import { BiTransfer } from "react-icons/bi";
import { MdDeleteForever, MdOutlineDateRange, MdOutlineLocalOffer, MdOutlineSettings } from "react-icons/md";
import { GoPerson } from "react-icons/go";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { RxUpdate } from "react-icons/rx";
import NoPermission from "../NoPermission/NoPermission";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import ListFilters from "../../../utils/ListFilters";

export default function CancellationpolicyList() {
  // const [rows, columns] = useCancellationPolicyList();
  const token = cookie.load("access_token");
  const [deletePopup, setDeletePopup] = useState("");
  const [permissionID, setPermissionID] = useState("");
  const [cancellation, setCancellation] = useState(false);
  const cancellationPolicies = useSelector((state) => state.cancellationPolicyList);
  const [cancellationDataPolicy, setCancellationDataPolicy] = useState(cancellationPolicies);
  const [load, setLoad] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [flag, setFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hotelId, setHotelId] = useState();
  const [destination, setDestination] = useState([]);
  const [canellationData, setCancellationData] = useState([]);
  const city = cookie.load("CityContract");
  const Hotels = cookie.load("HotelsContract");
  const [columnVisibility, setColumnVisibility] = useState({});

  const cityArr = destination.map((des) => {
    return { label: des.name, value: des.id };
  });

  //////// Hotels ///////////
  let hotelsArr = hotels
    ? hotels?.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];
  const dispatch = useDispatch();
  const fetchData = () => {
    // setLoad(true);

    dispatch(
      getCancellationPolicyList(token, (result) => {
        if (result.status === 200) {
          setLoad(false);
          setCancellationData(result.data);
        }
      }),
    );
  };

  const fetchCities = () => {
    dispatch(
      getCancellationPolicyListCities(token, (result) => {
        if (result.status === 200) {
          setDestination(result.data);
        }
      }),
    );
  };
  useEffect(() => {
    dispatch(getCancellationPolicyListCities(token, "en"));

    const currentColumnVisibility = localStorage.getItem("contract-grid-visibility");
    if (currentColumnVisibility) {
      setColumnVisibility({ ...JSON.parse(currentColumnVisibility) });
    }

    return () => {
      setHotelId(null);
    };
  }, []);
  useEffect(() => {
    fetchData();
    fetchCities();
  }, []);
  const getFilterData = () => {
    dispatch(() => {
      getCancellationHotelFilter(Hotels?.value || hotelId, (result) => {
        if (result.status === 200) {
          setCancellationData(
            result?.data.map((policy) => {
              return {
                id: policy.id,
                hotel: policy.hotel.name,
                from: policy.cancellation_valid_from.split("T")[0],
                to: policy.cancellation_valid_to.split("T")[0],
                offerType: policy.offer_type?.name,
                cancellationBefore: policy.cancellation_before,
                charge: policy.cancellation_charge.map((item) => item.charge),
                num_days: policy.cancellation_charge.map((item) => item.num_days),
                price: policy.cancellation_charge.map((item) => item.price),
                creationDate: policy.created_at,
                createdBy: "",
              };
            }),
          );
        }
        setIsLoading(false);
      });
    });
  };

  useEffect(() => {
    getFilterData();
    setIsLoading(false);
  }, [hotelId, Hotels?.value]);
  const handleDelete = (permissionID) => {
    dispatch(
      deleteCancellationPolicy(token, permissionID, (result) => {
        if (result.status === 204) {
          fetchData();
        }
      }),
    );
    setDeletePopup(false);
  };

  const [permissions, permissionsFlag] = usePermissions([
    "cancellation.view_cancellationpolicy",
    "cancellation.change_cancellationpolicy",
    "cancellation.delete_cancellationpolicy",
  ]);
  const handleCityChange = (e) => {
    cookie.save("CityContract", e, { path: "/" });
    cookie.remove("HotelsContract", { path: "/" });
    setCancellationData(null);
    setFlag(true);
    dispatch(
      getHotelCountry(e.value, (result) => {
        if (result.status === 200) {
          setFlag(false);
          setHotels(result.data);
        }
      }),
    );
  };

  const handleHotelChange = (e) => {
    setIsLoading(true);
    setHotelId(e.value);
    cookie.save("HotelsContract", e, { path: "/" });
    dispatch(() => {
      getCancellationHotelFilter(e.value, (result) => {
        if (result.status === 200) {
          setCancellationData(
            result?.data.map((policy) => {
              return {
                id: policy.id,
                hotel: policy.hotel.name,
                from: policy.cancellation_valid_from.split("T")[0],
                to: policy.cancellation_valid_to.split("T")[0],
                offerType: policy.offer_type?.name,
                cancellationBefore: policy.cancellation_before,
                charge: policy.cancellation_charge.map((item) => item.charge),
                num_days: policy.cancellation_charge.map((item) => item.num_days),
                price: policy.cancellation_charge.map((item) => item.price),
                creationDate: policy.created_at,
                createdBy: "",
              };
            }),
          );
        }
      });
    });
  };

  useEffect(() => {
    if (Hotels) {
      setIsLoading(true);
      setHotelId(Hotels.value);
      dispatch(
        getCancellationHotelFilter(Hotels.value, (result) => {
          if (result.status === 200) {
            setCancellationData(
              result?.data.map((policy) => {
                return {
                  id: policy.id,
                  hotel: policy.hotel.name,
                  from: policy.cancellation_valid_from.split("T")[0],
                  to: policy.cancellation_valid_to.split("T")[0],
                  offerType: policy.offer_type?.name,
                  cancellationBefore: policy.cancellation_before,
                  charge: policy.cancellation_charge.map((item) => item.charge),
                  num_days: policy.cancellation_charge.map((item) => item.num_days),
                  price: policy.cancellation_charge.map((item) => item.price),
                  creationDate: policy.created_at,
                  createdBy: "",
                };
              }),
            );
          }
          setIsLoading(false);
        }),
      );
    }

    if (city) {
      setFlag(true);
      dispatch(
        getHotelCountry(city.value, (result) => {
          if (result.status === 200) {
            setFlag(false);
            setHotels(result.data);
          }
        }),
      );
    }
  }, [Hotels?.value, hotelId]);

  function handleDeletePopUp(params) {
    //
    setDeletePopup(`Permission for ${params?.hotel}`);
    setPermissionID(params.id);
  }

  useEffect(() => {
    dispatch(getCancellationPolicyList());

    return () => {
      dispatch(clearCancellationPolicyList());
    };
  }, []);
  useEffect(() => {
    if (cancellationPolicies) {
      const rows = cancellationPolicies?.map((policy) => {
        return {
          id: policy.id,
          hotel: policy.hotel.name,
          from: policy.cancellation_valid_from.split("T")[0],
          to: policy.cancellation_valid_to.split("T")[0],
          offerType: policy.offer_type?.name,
          cancellationBefore: policy.cancellation_before,
          charge: policy.cancellation_charge.map((item) => item.charge),
          num_days: policy.cancellation_charge.map((item) => item.num_days),
          price: policy.cancellation_charge.map((item) => item.price),
          creationDate: policy.created_at,
          createdBy: "",
        };
      });
    }
  }, [canellationData]);

  const columns = [
    {
      key: "id",
      title: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
      dataIndex: "id",
      render: (text, params) => {
        return <button className="w-full h-full  flex items-center justify-start gap-x-1 ml-5 ">{params.id}</button>;
      },
    },
    {
      key: "hotel",
      title: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          Hotel
        </div>
      ),
      dataIndex: "hotel",
      render: (text, params) => {
        return (
          <button className="w-full h-full  flex items-center justify-start gap-x-1 ml-5 ">
            {params.hotel.name || params.hotel}
          </button>
        );
      },
    },
    {
      key: "from",
      title: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <MdOutlineDateRange className="w-5 h-5  text-yellow-500" />
          FROM
        </div>
      ),
      dataIndex: "from",
    },
    {
      key: "to",
      title: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <MdOutlineDateRange className="w-5 h-5  text-yellow-500" />
          TO
        </div>
      ),
      dataIndex: "to",
    },
    {
      key: "offerType",
      title: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <MdOutlineLocalOffer className="w-5 h-5  text-yellow-500" />
          OFFER TYPE
        </div>
      ),
      dataIndex: "offerType",
      render: (text, params) => {
        return (
          <button className="w-full h-full  flex items-center justify-center gap-x-1  ">
            {params?.offerType?.name || params?.offerType}
          </button>
        );
      },
    },

    {
      key: "creationDate",
      title: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <MdOutlineDateRange className="w-5 h-5  text-yellow-500" />
          CREATION DATE
        </div>
      ),
      dataIndex: "creationDate",
      render: (text, params) => {
        return (
          <button className="w-full h-full  flex items-center justify-start gap-x-1 ml-10 ">
            {params.creationDate}
          </button>
        );
      },
    },
    {
      key: "createdBy",
      title: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <GoPerson className="w-5 h-5  text-yellow-500" />
          CREATED BY
        </div>
      ),
      dataIndex: "createdBy",
    },
    {
      key: "operations",
      title: "Operations",
      title: (
        <div className=" flex items-center gap-x-1 text-xs ml-5">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),
      dataIndex: "operations",
      editable: false,

      render: (text, params) => {
        return (
          <div key={params.id} className="flex items-center justify-center w-full gap-x-2">
            {permissions?.map((item) => {
              if (item.name === "cancellation.view_cancellationpolicy" && item.value === true) {
                return (
                  <Link
                    to={`/dashboard/cancellation-policy/viewList/${params.id}`}
                    className="flex items-center gap-x-1  bg-yellow-400 px-1.5 py-1 rounded-md hover:bg-yellow-500 active:scale-95 duration-300 transition-all"
                  >
                    <AiOutlineEye className="w-5 h-5 text-gray-700 " />
                  </Link>
                );
              } else if (item.name === "cancellation.change_cancellationpolicy" && item.value === true) {
                return (
                  <Link
                    to={`/dashboard/cancellation-policy/create-cancellation-policy/create/${params.id}/update`}
                    className="flex items-center gap-x-1 bg-green-700 px-1.5 py-1 rounded-md hover:bg-green-800 active:scale-95 duration-300 transition-all"
                  >
                    <RxUpdate className="w-5 h-5 text-white" />
                  </Link>
                );
              } else if (item.name === "cancellation.delete_cancellationpolicy" && item.value === true) {
                return (
                  <button className="flex items-center gap-x-1 bg-red-800 px-1.5 py-1 rounded-md hover:bg-red-700 active:scale-95 duration-300 transition-all">
                    <MdDeleteForever onClick={() => handleDeletePopUp(params)} className="w-5 h-5 text-white" />
                  </button>
                );
              }
            })}
          </div>
        );
      },
    },
  ];
  const [permissionsView, permissionsFlagView] = usePermissions(["cancellation.view_cancellationpolicy"]);

  return (
    <div className="formPage-wrap overflow-x-auto min-h-screen">
      {load ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loading />
          <TopBarProgress />
        </div>
      ) : (
        <div>
          <div className="w-full flex items-center justify-center ">
            <h2 className="header-h2 ">
              {" "}
              <TbListDetails className="w-5 h-5 text-yellow-600" /> Cancellation Policy List
            </h2>
          </div>

          {permissionsFlagView &&
            permissionsView?.map((item) => {
              if (item.name === "cancellation.view_cancellationpolicy" && item.value === true) {
                return (
                  <>
                    <div className="input-par form-wrap mb-6">
                      <div className="input-chil ">
                        <label htmlFor="hotel" className="w-full flex  text-slate-950 font-semibold md:text-xl text-md">
                          Choose City First
                        </label>
                        <Select
                          options={cityArr}
                          id="hotel"
                          placeholder="Select City"
                          className="text-gray-900 h-full font-semibold capitalize z-50"
                          isSearchable
                          onChange={(e) => {
                            handleCityChange(e);
                          }}
                          defaultValue={city}
                        />
                      </div>
                      <div className="input-chil">
                        <label htmlFor="hotel" className="w-full flex  text-slate-950 font-semibold md:text-xl text-md">
                          Choose Hotel
                        </label>
                        {flag ? (
                          <div className="w-full  flex items-center justify-center">
                            <ButtonLoading /> <TopBarProgress />{" "}
                          </div>
                        ) : (
                          <Select
                            options={hotelsArr}
                            id="contract"
                            className="text-gray-900 h-full font-semibold capitalize "
                            isSearchable
                            placeholder="Choose Hotel"
                            onChange={(e) => {
                              handleHotelChange(e);
                            }}
                            defaultValue={Hotels}
                          />
                        )}
                      </div>
                    </div>
                    {isLoading ? (
                      <div className="flex items-center justify-center w-full h-screen">
                        <Loading /> <TopBarProgress />
                      </div>
                    ) : (
                      hotelId &&
                      canellationData && (
                        <div className=" w-full overflow-x-auto ">
                          <ListFilters columns={columns} rows={canellationData} title={"Cancelattion Policy List"} />

                          {/* <DataGrid
                            // className="data-grid"
                            rows={canellationData}
                            columns={columns}
                            autoHeight
                            slots={{
                              Toolbar: GridToolbar,
                            }}
                            rowCount={canellationData.length}
                            initialState={{
                              ...canellationData?.initialState,
                              pagination: { paginationModel: { pageSize: 10 } },
                              sorting: {
                                sortModel: [{ key: "id", sort: "desc" }],
                              },
                            }}
                            className=""
                            pageSizeOptions={[5, 10, 25]}
                            onColumnVisibilityModelChange={(visibility) => {
                              setColumnVisibility(visibility);
                              localStorage.setItem("contract-grid-visibility", JSON.stringify(visibility));
                            }}
                          /> */}
                          {deletePopup && (
                            <DeletePopUp
                              name={deletePopup}
                              Func={() => handleDelete(permissionID)}
                              hideFunc={() => {
                                setDeletePopup(false);
                              }}
                            />
                          )}
                        </div>
                      )
                    )}
                  </>
                );
              } else {
                return (
                  <div className="w-full h-screen ">
                    <NoPermission />
                  </div>
                );
              }
            })}
        </div>
      )}
    </div>
  );
}
