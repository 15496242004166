import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdGTranslate } from "react-icons/md";
import { translateTextToArabic, translateTextToEnglish } from "../../../redux/actions/translationActions";
import { NotificationContainer, NotificationManager } from "react-notifications";

function ClientNameReservationForm({ setClientNameAr, setClientNameEn, numOfPerson, setSaveBtn }) {
  const [loopDataAr, setLoopDataAr] = useState();
  const [loopDataEn, setLoopDataEn] = useState();

  const [t] = useTranslation();

  useEffect(() => {
    if (numOfPerson) {
      setLoopDataAr(Array(numOfPerson).fill(""));
      setLoopDataEn(Array(numOfPerson).fill(""));
    }
  }, [numOfPerson]);

  const handleDataChangeAr = (index, value) => {
    setLoopDataAr((prevData) => {
      const newData = [...prevData];
      newData[index] = value;
      return newData;
    });
  };

  const handleDataChangeEn = (index, value) => {
    setLoopDataEn((prevData) => {
      const newData = [...prevData];
      newData[index] = value;
      return newData;
    });
  };

  let clientNameArrayAr = [];
  clientNameArrayAr?.push(loopDataAr);

  clientNameArrayAr?.map((item) => setClientNameAr(item));

  let clientNameArrayEn = [];
  clientNameArrayEn?.push(loopDataEn);

  clientNameArrayEn?.map((item) => setClientNameEn(item));

  async function handleTranslateClientNames(index) {
    let clientNameAr = loopDataAr[index];
    let clientNameEn = loopDataEn[index];

    if (clientNameAr.length >= 3 && clientNameEn.length === 0) {
      try {
        const clientNameEnTranslated = await translateTextToEnglish(clientNameAr);
        handleDataChangeEn(index, clientNameEnTranslated);
      } catch (e) {
        NotificationManager.error("Error when trying to translate, check your internet connection and try again.");
      }
    }

    if (clientNameEn.length >= 3 && clientNameAr.length === 0) {
      try {
        const clientNameArTranslated = await translateTextToArabic(clientNameEn);
        handleDataChangeAr(index, clientNameArTranslated);
      } catch (e) {
        NotificationManager.error("Error when trying to translate, check your internet connection and try again.");
      }
    }
  }

  return (
    <>
      {loopDataEn && loopDataAr && (
        <div className="flex flex-col md:flex-row justify-between items-center gap-y-4 gap-x-5 w-full   rounded-md xl:px-4">
          <div className="flex flex-col w-full  ">
            <ul className="space-y-2">
              {loopDataAr?.map((item, index) => (
                <div className="flex gap-2">
                  <li key={index} className="w-full gap-2 ">
                    <div className="flex flex-col  w-full text-sm">
                      <label className="text-sm text-end font-body" dir={`${t("dir_reverse")}`}>
                        {" "}
                        <span className="text-yellow-600">*</span>
                        {t("clientNameAr")}
                      </label>
                      <input
                        dir={`${t("dir")}`}
                        className="w-full h-6 text-right  border-gray-200  border-opacity-100 border-b-2"
                        type="text"
                        value={item}
                        lang="ar"
                        required
                        onChange={(event) => {
                          handleDataChangeAr(index, event.target.value);
                          setSaveBtn(false);
                        }}
                      />
                    </div>
                  </li>

                  <button
                    type="button"
                    onClick={() => {
                      handleTranslateClientNames(index);
                    }}
                  >
                    <MdGTranslate size={36} fill="#002244" />
                  </button>
                </div>
              ))}
            </ul>
          </div>

          <div className="flex flex-col w-full  overflow-y-auto">
            <ul className="space-y-2">
              {loopDataEn?.map((item, index) => (
                <li key={index} className="w-full">
                  <div className="flex flex-col items-start  w-full text-sm ">
                    <label className="font-body" dir={`${t("dir")}`}>
                      {t("clientName")}
                      <span className="text-yellow-600">*</span>
                    </label>
                    <input
                      //  placeholder="Enter your name"
                      className="w-full h-6 border-gray-200 border-opacity-100 border-b-2  "
                      type="text"
                      required
                      dir={`${t("dir")}`}
                      value={item}
                      onChange={(event) => {
                        handleDataChangeEn(index, event.target.value);
                        setSaveBtn(false);
                      }}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <NotificationContainer />
        </div>
      )}
    </>
  );
}

export default ClientNameReservationForm;
