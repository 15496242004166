import React, { useEffect, useState } from "react";
import { FcEmptyFilter } from "react-icons/fc";
import { MdOutlineArrowDropDown, MdOutlineNextPlan } from "react-icons/md";
import { VscFilter } from "react-icons/vsc";
import Select from "react-select";
import { IoCalendarOutline } from "react-icons/io5";
import { GoPerson } from "react-icons/go";
import { useDispatch } from "react-redux";
import { getAllAssistAction } from "../../../redux/actions/meetAssistAction";
import { useNavigate } from "react-router";

const FilterAirportService = ({
  flag,
  setFlag,
  setLoad,
  paramsFilter,
  setParamsFilter,
  lastParams,
  setCurrentPage,
}) => {
  const [members, setMembers] = useState([]);
  const [choosenMember, setChoosenMember] = useState(null);
  const [choosenMemberId, setChoosenMemberId] = useState(null);
  const [checkedToday, setCheckedToday] = useState(false);
  const [checkedTomorrow, setCheckedTomorrow] = useState(false);
  const [checkedNext, setCheckedNext] = useState(false);
  const [specificDay, setSpecificDay] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(
      getAllAssistAction(signal, (result) => {
        if (result.status === 200) {
          setMembers(result.data);
        }
      }),
    );

    return () => {
      controller.abort();
    };
  }, [dispatch]);

  const repArr = members.map((item) => ({
    label: item.representative_name,
    value: item.id,
  }));

  const handleChange = (value, type) => {
    navigate(`/dashboard/meet-&-assist-service/${lastParams}/1`);
    setCurrentPage(1);

    const paramName = value.split("=")[0];

    let updatedFilters = [...paramsFilter];

    if (type === "date") {
      updatedFilters = updatedFilters.filter((param) => !param.startsWith("&specific_date"));
    } else if (paramName === "&representative_name" && type === "All") {
      updatedFilters = updatedFilters.filter((param) => !param.startsWith("&representative_name"));
    }

    const filterIndex = updatedFilters.findIndex((item) => item.split("=")[0] === paramName);

    if (filterIndex > -1) {
      updatedFilters[filterIndex] = value;
    } else {
      updatedFilters.push(value);
    }

    setParamsFilter(updatedFilters);
    setLoad(false);
  };

  return (
    <div className="relative w-full md:w-fit">
      <button
        onClick={() => setFlag(!flag)}
        className="w-full outline-none focus:outline-none justify-between px-3 py-1 rounded-md flex items-center md:w-32 bg-gray-100 hover:bg-gray-200 active:scale-90 transition-all duration-150"
      >
        <span className="flex items-center gap-x-1 font-semibold text-[#001a35]">
          <VscFilter className="w-3 h-3 md:w-4 md:h-4 text-yellow-600" />
          Filter
        </span>
        <MdOutlineArrowDropDown className="w-5 h-5" />
      </button>
      {flag && (
        <div className="absolute z-50 flex flex-col gap-y-3 bg-white border -left-28 sm:left-4 md:left-0 rounded-md p-3 w-[250px] text-sm font-semibold">
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-x-1">
              <IoCalendarOutline className="w-5 h-5 text-yellow-600" />
              <p>ARRIVAL TODAY</p>
            </div>
            <input
              type="checkbox"
              name="ARRIVAL_TODAY"
              value="&arrival_today=true"
              checked={checkedToday}
              onChange={(e) => {
                const checked = e.target.checked;
                if (checked) {
                  handleChange(e.target.value);
                } else {
                  handleChange("&arrival_today=", "All");
                }
                setCheckedToday(checked);
              }}
            />
          </div>
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-x-1">
              <IoCalendarOutline className="w-5 h-5 text-yellow-600" />
              <p>ARRIVAL TOMORROW</p>
            </div>
            <input
              type="checkbox"
              name="ARRIVAL_TOMORROW"
              value="&arrival_tomorrow=true"
              checked={checkedTomorrow}
              onChange={(e) => {
                const checked = e.target.checked;
                if (checked) {
                  handleChange(e.target.value);
                } else {
                  handleChange("&arrival_tomorrow=", "All");
                }
                setCheckedTomorrow(checked);
              }}
            />
          </div>
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-x-1">
              <MdOutlineNextPlan className="w-5 h-5 text-yellow-600" />
              <p>NEXT DAYS</p>
            </div>
            <input
              type="checkbox"
              name="NEXT_DAYS"
              value="&days_next=true"
              checked={checkedNext}
              onChange={(e) => {
                const checked = e.target.checked;
                if (checked) {
                  handleChange(e.target.value);
                } else {
                  handleChange("&days_next=", "All");
                }
                setCheckedNext(checked);
              }}
            />
          </div>
          <div className="flex w-full justify-between items-center">
            <p className="uppercase flex items-center gap-1">
              <IoCalendarOutline className="w-5 h-5 text-yellow-600" />
              Specific Day
            </p>
            <input
              className="border-2 rounded-md floating-input text-center"
              type="date"
              value={specificDay}
              onChange={(e) => {
                const date = e.target.value;
                if (date) {
                  handleChange(`&specific_date=${date}`, "date");
                } else {
                  handleChange("&specific_date=", "date");
                }
                setSpecificDay(date);
              }}
            />
          </div>
          <div className="w-full flex flex-col gap-y-1">
            <div className="flex items-center gap-x-1">
              <GoPerson className="w-5 h-5 text-yellow-600" />
              <p>REP NAME</p>
            </div>
            <Select
              className="w-full"
              placeholder="REP NAME"
              isClearable
              value={choosenMember ? { label: choosenMember, value: choosenMemberId } : null}
              options={repArr}
              isSearchable
              onChange={(e) => {
                if (e) {
                  setChoosenMemberId(e.value);
                  setChoosenMember(e.label);
                  handleChange(`&representative_name=${e.value}`);
                } else {
                  handleChange(`&representative_name=`, "All");
                  setChoosenMemberId(null);
                  setChoosenMember(null);
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterAirportService;
