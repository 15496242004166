import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import "./MultiReservation.css";
import { TiTick } from "react-icons/ti";
import { MdOutlineDone, MdVerified } from "react-icons/md";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import MultiBookingForm from "../../organisms/MultiReservationForm/MultiReservationForm";
import { useNavigate } from "react-router-dom";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/solid";
import Navbar from "../../organisms/Navbar/Navbar";
import { GiNightSleep, GiPerson } from "react-icons/gi";
import {
  clearDisplayMultiReservationData,
  postMultiReservationData,
  savedButtonAction,
} from "../../../redux/actions/MultiReservationAction";
import cookie from "react-cookies";
import { useTranslation } from "react-i18next";
import { clearBookingOrder } from "../../../redux/actions/bookingOrderAction";
import { clearDisplayReservationData, reservationRoomAction } from "../../../redux/actions/reservationAction";
import ButtonLoading from "../../organisms/ButtonLoading/ButtonLoading";
import { getSelectedCustomeByCode, updateCustomerLink } from "../../../redux/actions/linkAction";
import Loading from "../../organisms/Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { toast } from "react-toastify";
import { finishResrvation } from "../../../redux/actions/finishResrvation";

const MultiBooking = () => {
  const [hotels, setHotel] = useState("hotels");
  const [navBookingOrder, setNavBookingOrder] = useState(true);
  const [saveBtnStatus, setSaveBtnStatus] = useState(false);
  const [saveBtnStatusBack, setSaveBtnStatusBack] = useState(true);
  const [saveBtn, setSaveBtn] = useState(false);
  const [guestStatus, setGuestStatus] = useState(false);
  const [CodeID, setCodeID] = useState();

  const [t, i18n] = useTranslation();
  const localeCode = t("localeCode");
  const { cardData } = useSelector((state) => state.cardReducer);
  const { cardTotalAmount } = useSelector((state) => state.cardReducer);
  const [currentStep, setCurrentStep] = useState(0);
  const [complete, setComplete] = useState(false);
  const [flag, setFlag] = useState(false);
  const [saveScale, setSaveScale] = useState(false);
  const [lengthRoom, setlengthRoom] = useState([]);
  const [nextStepArray, setnextStepArray] = useState([]);
  const [steps, setsteps] = useState([]);
  const [CardTotalNum, setCardTotalNum] = useState();
  const [valuesArray, setValuesArray] = useState([]);
  const [value, setValue] = useState([0, 0]);

  const splideRef = React.useRef(null);
  const navigate = useNavigate();

  const { selectedDataFromForm } = useSelector((state) => state.SelectedSearchReducer); // to send this with data multi booking page
  const { multiReservationData } = useSelector((state) => state.multiReservationReducer); // this data of multi forms
  const { displayMultiReservationData } = useSelector((state) => state.multiReservationReducer); // i use this data for display only because easy to display compare with multiReservationData
  const { displayReservationData } = useSelector((state) => state.reservationReducer); // this data for signal book now only
  const { saveBtnFlag } = useSelector((state) => state.multiReservationReducer);

  const dispatch = useDispatch();
  const token = cookie.load("access_token");

  useEffect(() => {
    cardData.length === 0 && navigate("/"); // if cardData Empty navigate to hotels pages

    setCurrentStep(0);
    setComplete(false);
    setSaveBtn(false);
    setSaveScale(false);
    setCardTotalNum(cardTotalAmount);

    if (splideRef.current) {
      splideRef.current.go(0); // Navigate to the first slide (index 0)
    }

    const steps = [];
    cardData.map((item) => {
      for (let x = 0; x < item.quantity; x++) {
        steps.push(item.selectedroom);
      }
    });
    setsteps(steps);

    let nextStepArray = [];
    for (let x = 0; x < currentStep; x++) {
      nextStepArray.push(currentStep);
    }
    setnextStepArray(nextStepArray);

    const lengthRoom = multiReservationData.map((item) => {
      return item.hotel.rooms.length + 1;
    });
    setlengthRoom(lengthRoom);

    if (cookie.load("code")) {
      dispatch(
        getSelectedCustomeByCode(cookie.load("code"), (result) => {
          setCodeID(result.data.id);
        }),
      );
    }
  }, [cardData]);

  const goToNextStep = () => {
    setSaveScale(true);
    if (!saveBtn && multiReservationData.length === 0) {
      toast.warning(`${t("pleaseSave")}`);
    }
    if (multiReservationData.length > 0 || lengthRoom - 1 > currentStep) {
      if (saveBtn) {
        nextStepArray.push();
        if (currentStep === steps.length - 1) {
          setComplete(true);
        } else {
          setCurrentStep((prev) => prev + 1);
        }
        if (splideRef.current) {
          splideRef.current.go(">");
        }
        setSaveBtn(false);
      } else {
        toast.warning(`${t("pleaseSave")}`);
      }
    }

    dispatch(savedButtonAction(saveBtnStatus));
  };

  const goToFinishStep = async () => {
    if (CardTotalNum > 1) {
      if (saveBtn) {
        setFlag(true);
        dispatch(
          postMultiReservationData(
            token,
            multiReservationData,
            (result) => {
              if (result.bookings.length > 0) {
                setFlag(false);
                if (currentStep === steps.length - 1) {
                  setComplete(true);
                } else {
                  setCurrentStep((prev) => prev + 1);
                }
                if (splideRef.current) {
                  splideRef.current.go(">");
                }
                // if guest who is book room  we will expire your link and change status to booked and logout
                // this link can't using any time
                if (CodeID) {
                  setGuestStatus(true);
                  dispatch(
                    updateCustomerLink(CodeID, { link_status: "booked" }, (result) => {
                      if (result.status === 200) {
                        setGuestStatus(false);
                        setComplete(true);
                        dispatch(finishResrvation(true));

                        let countdown = 60;
                        // Display initial toast with countdown
                        const toastId = toast.info(`Link will expired in ${countdown} seconds...`, {
                          autoClose: false,
                        });
                        const interval = setInterval(() => {
                          countdown -= 1;
                          // Update toast with new countdown value
                          toast.update(toastId, { render: `Link will expired in ${countdown} seconds...` });
                          if (countdown === 0) {
                            clearInterval(interval);

                            cookie.remove("access_token", { path: "/" });
                            cookie.remove("refresh_token", { path: "/" });
                            cookie.remove("currency", { path: "/" });
                            cookie.remove("language", { path: "/" });
                            cookie.remove("code", { path: "/" });
                            toast.update(toastId, {
                              render: "Booking done and Link expired",
                              type: toast.TYPE.SUCCESS,
                              isLoading: false,
                              autoClose: 5000,
                            });

                            dispatch(clearBookingOrder());
                            dispatch(clearDisplayReservationData());
                            dispatch(clearDisplayMultiReservationData());
                            // Update toast to success

                            // Navigate to login page
                            navigate("/login");
                          }
                        }, 1000);
                      } else {
                        setGuestStatus(false);
                      }
                    }),
                  );
                }
              }
            },
            (error) => {
              setFlag(false);
              toast.error(Object.values(error?.response?.data)[0] || "Failed to post data");
              setCurrentStep(0);
              setComplete(false);
              setSaveBtn(false);
              setSaveScale(false);
              setCardTotalNum(cardTotalAmount);
            },
          ),
        );
      } else if (!saveBtn) {
        toast.warning(`${t("pleaseSave")}`);
      }
    } else {
      if (saveBtn) {
        setFlag(true);

        dispatch(
          reservationRoomAction(
            token,
            displayReservationData,
            (result) => {
              if (result.status === 201) {
                setFlag(false);

                if (currentStep === steps.length - 1) {
                  setComplete(true);
                } else {
                  setCurrentStep((prev) => prev + 1);
                }
                if (splideRef.current) {
                  splideRef.current.go(">");
                }

                // if guest who is book room  we will expire your link and change status to booked and logout
                // this link can't using any time
                if (CodeID) {
                  setGuestStatus(true);

                  dispatch(
                    updateCustomerLink(CodeID, { link_status: "booked" }, (result) => {
                      if (result.status === 200) {
                        setGuestStatus(false);
                        setComplete(true);
                        dispatch(finishResrvation(true));

                        let countdown = 60;
                        // Display initial toast with countdown
                        const toastId = toast.info(`Link will expired in ${countdown} seconds...`, {
                          autoClose: false,
                        });
                        const interval = setInterval(() => {
                          countdown -= 1;
                          // Update toast with new countdown value
                          toast.update(
                            toastId,
                            { render: `Link will expired in ${countdown} seconds...` },
                            {
                              autoClose: false,
                            },
                          );
                          if (countdown === 0) {
                            clearInterval(interval);

                            cookie.remove("access_token", { path: "/" });
                            cookie.remove("refresh_token", { path: "/" });
                            cookie.remove("currency", { path: "/" });
                            cookie.remove("language", { path: "/" });
                            cookie.remove("code", { path: "/" });
                            // toast.success("booking done and Link will expired");
                            // Update toast to success
                            toast.update(toastId, {
                              render: "Booking done and Link expired",
                              type: toast.TYPE.SUCCESS,
                              isLoading: false,
                              autoClose: 5000,
                            });

                            dispatch(clearBookingOrder());
                            dispatch(clearDisplayReservationData());
                            dispatch(clearDisplayMultiReservationData());

                            // Navigate to login page
                            navigate("/login");
                          }
                        }, 1000);
                      } else {
                        setGuestStatus(false);
                      }
                    }),
                  );
                }
              }
            },
            (error) => {
              setFlag(false);
              // // Converting the object to a JSON string
              // var jsonString = JSON.stringify();

              // // Parsing the JSON string back to an object
              // var parsedObj = JSON.parse(jsonString);

              toast.error(Object.values(error?.response?.data)[0] || "Failed to post data");
              setCurrentStep(0);
              setComplete(false);
              setSaveBtn(false);
              setSaveScale(false);
              setCardTotalNum(cardTotalAmount);
            },
          ),
        );
      } else if (!saveBtn) {
        toast.warning(`${t("pleaseSave")}`);
      }
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    } else {
      navigate("/");
    }
    if (splideRef.current) {
      splideRef.current.go("<");
    } else {
      navigate("/");
    }
    dispatch(savedButtonAction(saveBtnStatusBack));
  };

  const splideOptions = {
    rewind: false,
    arrows: false,
    keyboard: false,
    pagination: false,
    drag: false, // Disable drag/swipe gestures
    direction: t("dir") === "en" ? "ltr" : "rtl",
    breakpoints: {
      1200: { perPage: 1 },
      991: { perPage: 1 },
      768: { perPage: 1 },
      500: { perPage: 1 },
      425: { perPage: 1 },
    },
  };

  const splideOptionsMultiForms = {
    perPage: 1,
    perMove: 1,
    arrows: true,
    keyboard: "global",
    rewind: false,
    pagination: false,
    direction: t("dir") === "en" ? "ltr" : "rtl",
    breakpoints: {
      1200: { perPage: 1 },
      991: { perPage: 1 },
      768: { perPage: 1 },
      500: { perPage: 1 },
      425: { perPage: 1 },
      280: { perPage: 1 },
    },
  };

  if (!CodeID && cookie.load("code")) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Loading /> <TopBarProgress />
      </div>
    );
  }

  if (flag) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Loading /> <TopBarProgress />
      </div>
    );
  }

  return (
    <div className="w-full">
      <Navbar setValuesArray={setValuesArray} setValue={setValue} hotels={hotels} navBookingOrder={navBookingOrder} />
      {lengthRoom && flag && CardTotalNum && guestStatus && steps && multiReservationData && cardData ? (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      ) : (
        <div className=" w-full  flex flex-col   items-center justify-center pt-7 " dir={t("dir")}>
          <div className="flex justify-center items-center  w-full lg:w-[700px] text-sm  " dir="ltr">
            {steps?.map((step, i) => (
              <button
                key={i}
                className={`step-item  ${i < currentStep || currentStep === i ? "active" : ""} ${i < currentStep || complete} `}
                // onClick={() => {setCurrentStep(i); }}
                onClick={() => {
                  if (i < currentStep) {
                    goToPreviousStep();
                  }
                  if (i > currentStep) {
                    goToNextStep(); // Go to the clicked step's index
                  }
                }}
              >
                <div className={`${i < currentStep || complete ? "bg-lime-600" : ""} step bg-[#002244]  `}>
                  {i < currentStep || complete ? <TiTick size={24} /> : new Intl.NumberFormat(localeCode).format(i + 1)}
                </div>
                <p className="text-black text-xs">{step}</p>
              </button>
            ))}
          </div>
          {complete === false ? (
            <div className="  flex flex-col items-center   text-center w-full  text-black  ">
              <div className="w-full ">
                <Splide options={splideOptions} ref={splideRef}>
                  {cardData.flatMap((roomData, index) =>
                    Array.from({ length: roomData.quantity }, (_, x) => (
                      <SplideSlide key={roomData.uniqueId + x} dir={t("dir")}>
                        <MultiBookingForm
                          roomData={roomData}
                          currentStep={currentStep}
                          saveScale={saveScale}
                          setSaveBtn={setSaveBtn}
                          saveBtn={saveBtn}
                          key={roomData.uniqueId + x}
                        />
                      </SplideSlide>
                    )),
                  )}
                </Splide>
              </div>

              {/* Render the current step's form */}
              <div className="flex items-center  gap-x-10 mt-10" dir="ltr">
                {!flag ? (
                  <button
                    className="flex items-center gap-x-2 bg-white border-gray-300 border rounded-lg md:px-8 py-1 px-5 text-sm md:text-[18px] text-black cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all "
                    onClick={goToPreviousStep}
                    disabled={currentStep === 0}
                  >
                    <ChevronDoubleLeftIcon className="w-5 h-5  text-black" /> {t("back")}
                  </button>
                ) : (
                  ""
                )}

                {/* <MdVerified className='w-24 h-24 text-lion ' /> */}
                {currentStep === steps.length - 1 ? (
                  !flag ? (
                    <button
                      className="flex items-center gap-x-2 md:px-8 py-1 px-5 bg-[#002244] rounded-lg text-white font-serif text-sm md:text-[18px] cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all "
                      onClick={() => goToFinishStep()}
                      disabled={flag}
                    >
                      {t("finish")} <ChevronDoubleLeftIcon className="w-5 h-5 rotate-180 text-white" />
                    </button>
                  ) : (
                    <ButtonLoading />
                  )
                ) : (
                  <button
                    className="flex items-center gap-x-2 md:px-8 py-1 px-5   bg-[#002244] rounded-lg text-white font-serif text-sm md:text-[18px] cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all "
                    onClick={goToNextStep}
                  >
                    {t("next")} <ChevronDoubleLeftIcon className="w-5 h-5 rotate-180 text-white" />
                  </button>
                )}
              </div>
            </div>
          ) : (
            complete === true && (
              <div className="flex flex-col lg:flex-row items-center justify-center gap-x-16 gap-y-10 text-center lg:container  ">
                <div className="lg:w-[40%] ">
                  <Splide options={splideOptionsMultiForms}>
                    {CardTotalNum > 1 ? (
                      displayMultiReservationData?.bookings?.map((item, index) => (
                        <SplideSlide key={index}>
                          <div className="md:px-5 mx-auto ">
                            <div className="w-full h-full flex flex-col items-start text-start px-6  text-black">
                              <div className="flex items-center justify-between w-full  ">
                                <p className="flex text-xs md:text-2xl font-serif font-semibold text-[#002244]  ">
                                  {item.room_name}
                                </p>
                                <p className="flex items-center gap-x-1 text-md md:text-xl lg:text-3xl  font-semibold  text-[#002244] ">
                                  {new Intl.NumberFormat(localeCode).format(item.total_price)}
                                  {/* <span className="text-[20px] text-yellow-600">{item?.currency_type}</span> */}
                                  <span className=" text-[20px]  text-yellow-600">{t(item?.currency_type)}</span>
                                </p>
                              </div>
                              <img src="/images/2-lg.png" className="w-full lg:h-[300px] rounded-md mb-2 mt-3" />
                              <p className="flex items-center text-sm md:text-[20px] font-serif font-semibold">
                                {item.hotel}
                              </p>
                              <div className="flex justify-between w-full mt-2">
                                <div className=" grid items-center gap-y-3    ">
                                  <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold ">
                                    <MdOutlineDone className="w-5 h-5 text-yellow-600 font-bold  " />
                                    {t("checkIn")}: {item.from_date}{" "}
                                  </p>
                                  <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold ">
                                    <MdOutlineDone className="w-5 h-5 text-yellow-600 font-bold  " />
                                    {t("checkOut")}: {item.to_date}{" "}
                                  </p>
                                  <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold ">
                                    <GiNightSleep className="w-5 h-5 text-yellow-600 " />
                                    {t("nights")}: {new Intl.NumberFormat(localeCode).format(item.nights)}{" "}
                                  </p>
                                  <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold ">
                                    <GiPerson className="w-5 h-5 text-yellow-600 " />
                                    {t("persons")}: {new Intl.NumberFormat(localeCode).format(item.number_of_persons)}{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SplideSlide>
                      ))
                    ) : (
                      <div className="flex flex-col lg:flex-row  w-full ">
                        <div className="w-full  flex flex-col items-start text-start text-black">
                          <div className="flex items-center justify-between w-full ">
                            <p className="flex text-xs md:text-2xl font-serif font-semibold text-[#002244]  ">
                              {displayReservationData.bookings.room_name}
                            </p>
                            <p className="flex items-center gap-x-1 text-md md:text-xl lg:text-3xl  font-semibold text-[#002244]  ">
                              {new Intl.NumberFormat(localeCode).format(displayReservationData.bookings.total_price)}
                              {/* <span className="text-[20px] text-yellow-600">
                                {displayReservationData?.currency_type}
                              </span> */}
                              <span className="text-[20px] text-yellow-600">
                                {t(displayReservationData?.bookings?.currency_type)}
                              </span>
                            </p>
                          </div>

                          <p className="text-sm mt-3"></p>
                          <img src="/images/2-lg.png" className="w-full rounded-md lg:h-[300px] mb-2 mt-3" />
                          <p className="flex items-center text-sm md:text-[20px] font-serif font-semibold">
                            {displayReservationData.bookings.hotel}
                          </p>
                          <div className="flex justify-between w-full mt-2">
                            <div className=" grid items-center gap-y-3    ">
                              <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold ">
                                <MdOutlineDone className="w-5 h-5 text-yellow-600 font-bold  " />
                                {t("checkIn")}: {displayReservationData.bookings.from_date}{" "}
                              </p>
                              <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold ">
                                <MdOutlineDone className="w-5 h-5 text-yellow-600 font-bold  " />
                                {t("checkOut")}: {displayReservationData.bookings.to_date}{" "}
                              </p>
                              <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold ">
                                <GiNightSleep className="w-5 h-5 text-yellow-600 " />
                                {t("nights")}:{" "}
                                {new Intl.NumberFormat(localeCode).format(displayReservationData.bookings.nights)}{" "}
                              </p>
                              <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold ">
                                <GiPerson className="w-5 h-5 text-yellow-600  " />
                                {t("persons")}:{" "}
                                {new Intl.NumberFormat(localeCode).format(
                                  displayReservationData.bookings.number_of_persons,
                                )}{" "}
                              </p>
                              {/* <p className="flex items-center gap-x-2 font-semibold text-xs"><CgNametag className='w-5 h-5  text-lion' />{item.id}</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Splide>
                </div>

                <div className="flex flex-col gap-y-5 items-center justify-center  lg:w-[30%] ">
                  <div>
                    {CardTotalNum > 1 ? (
                      <div className="flex items-center gap-x-2 font-semibold text-sm border-yellow-700 border border-opacity-25 rounded-xl p-5">
                        {displayMultiReservationData.short_id}
                      </div>
                    ) : (
                      <div className="flex items-center gap-x-2 font-semibold text-sm border-yellow-700 border border-opacity-25 rounded-xl p-5">
                        {displayReservationData.short_id}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col  items-center justify-center ">
                    <MdVerified className="w-28 h-28 md:w-48 md:h-48 lg:w-60 lg:h-60 text-yellow-600 opacity-70" />
                    <p className="text-md md:text-2xl lg:text-3xl  font-semibold">{t("bookingIsSuccess")}</p>
                    <div className="flex items-center justify-between gap-x-1 text-3xl opacity-70 mt-2 w-full font-semibold ">
                      <p className="flex items-center justify-center w-full bg-[#002244] rounded-lg text-white p-1">
                        {CardTotalNum > 1
                          ? displayMultiReservationData?.bookings?.reduce(
                              (acc, curr) => acc + parseInt(curr.total_price),
                              0,
                            )
                          : new Intl.NumberFormat(localeCode).format(displayReservationData.bookings.total_price)}{" "}
                        <span className="text-[20px] ml-1 mt-2 bg-[#002244]">
                          {t(displayReservationData?.bookings?.currency_type)}
                        </span>
                      </p>
                    </div>
                  </div>
                  <button
                    className="bg-[#002244] px-8 py-3 rounded-lg text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                    onClick={() => {
                      navigate(`/${cookie.load("code")}`);
                      dispatch(clearBookingOrder());
                      dispatch(clearDisplayReservationData());
                      dispatch(clearDisplayMultiReservationData());
                    }}
                  >
                    {t("home")}
                  </button>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default MultiBooking;
