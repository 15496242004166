import React from "react";
import { MdDelete } from "react-icons/md";

function DeleteButton({ onDelete }) {
  return (
    <button
      onClick={onDelete}
      className="w-8 h-8 rounded-full   bg-red-800 text-white flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-red-800"
    >
      <MdDelete />
    </button>
  );
}

export default DeleteButton;
