import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

export const getHotelsByCity = (city, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`/airport-services/api/v1/get_hotels_by_city/?city_id=${city}`);
      callback(response);
    } catch (error) {}
  };
};

export const getAllAirline = (signal, callback, failureCallback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`/airport-services/api/v1/airline-names/`, {
        signal,
      });
      callback(response);
    } catch (error) {
      if (failureCallback) {
        failureCallback(error);
      }
    }
  };
};

export const addAirportAction = (data, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).post("/airport-services/api/v1/limousine-services/", data);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};
export const getAllMeetAssist = (status, page, page_size, filter, callback) => {
  return async (dispatch, getState) => {
    const filterParams = filter.length > 0 ? filter.map((item) => item).join("") : null;
    try {
      if (filterParams) {
        const response = await api_token(token).get(
          `/airport-services/api/v1/limousine-services/?status=${status}&page=${page}&page_size=${page_size}${filterParams}`,
        );
        callback(response);
      } else {
        const response = await api_token(token).get(
          `/airport-services/api/v1/limousine-services/?status=${status}&page=${page}&page_size=${page_size}`,
        );
        callback(response);
      }
    } catch (error) {}
  };
};

export const getSelectedAllAirportAction = (id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`/airport-services/api/v1/get-limousine-by-room/${id}/`);
      callback(response);
    } catch (error) {}
  };
};

export const getselectedAirportaction = (id, signal, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`/airport-services/api/v1/limousine-services/${id}/`, { signal });
      callback(response);
    } catch (error) {}
  };
};

export const updateAirportAction = (data, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).patch(`/airport-services/api/v1/limousine-services/${id}/`, data);
      callback(response);
    } catch (error) {}
  };
};

export const deleteAirportAction = (id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).post(`/airport-services/api/v1/limousine-services/${id}/soft-delete/`);
      callback(response);
    } catch (error) {}
  };
};

export const HarddeleteAirportAction = (id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).delete(`/airport-services/api/v1/limousine-services/${id}/hard-delete/`);
      callback(response);
    } catch (error) {}
  };
};

export const getIataCodeAction = (iata, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`/airport-services/api/v1/airport/${iata}/`);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};
