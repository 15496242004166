import { Button, Modal } from "flowbite-react";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import ChildrenAssistantRoomSelector from "./ChildrenAssistantRoomSelector";
import * as Yup from "yup";
import { findMissingNumberInArray } from "../../../utils/arrayUtils";
import { addChildrenPolicy } from "../../../redux/actions/contractValidity";
import ChildrenAssistantChildInputs from "./ChildrenAssistantChildInputs";

const modalTheme = {
  body: {
    base: "flex-1 p-6",
  },
  content: {
    inner: "relative flex flex-col rounded-lg bg-white shadow dark:bg-gray-700",
  },
};

const initialChildrenPolicy = {
  allRooms: false,
  rooms: [],
  children: [
    {
      minAge: "",
      maxAge: "",
      percentage: "",
      order: "",
      adultOrder: "",
    },
  ],
};

const validationSchema = Yup.object().shape({
  rooms: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required("Room ID is required"),
        name: Yup.string().required("Room name is required"),
        maxAdults: Yup.number().required("Max adults is required"),
        maxChildren: Yup.number().required("Max children is required"),
        maxOccupancy: Yup.number().required("Max occupancy is required"),
      }),
    )
    .min(1, "At least one room is required"),
  children: Yup.array().of(
    Yup.object().shape({
      order: Yup.number().required("Required"),
      minAge: Yup.number().required("Required"),
      maxAge: Yup.number().required("Required"),
      percentage: Yup.number().required("Required"),
      adultOrder: Yup.number().required("Required"),
    }),
  ),
});

function ChildrenPolicyAssistantTool({ hotelRooms }) {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const contractValidity = useSelector((state) => state.contractValidity);

  const contractSelectedRoomsIds = contractValidity?.map((validity) => Number(validity.room)).filter(Boolean);
  const selectedRooms = hotelRooms.filter((room) => contractSelectedRoomsIds.includes(room.id));

  function handleSubmit(values, helpers) {
    const allChildrenOrderArray = values.children.map((child) => child.order);
    console.log(allChildrenOrderArray, "allChildrenOrderArray");
    const missingNumber = findMissingNumberInArray(allChildrenOrderArray);

    if (missingNumber) {
      toast.error(`Please add child order ${missingNumber}`);
      return;
    }

    const policyAdded = dispatch(addChildrenPolicy(values.rooms, values.children));

    if (!policyAdded) {
      return;
    }

    setOpenModal(false);
    helpers.resetForm();
  }

  return (
    <>
      <Button
        color="success"
        onClick={() => {
          if (contractSelectedRoomsIds.length === 0) {
            toast.error("Please add rooms first");
            return;
          }
          setOpenModal(true);
        }}
      >
        Children Policy Assistance tool
      </Button>
      <Formik initialValues={initialChildrenPolicy} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ resetForm, submitForm }) => (
          <Form>
            <Modal
              theme={modalTheme}
              size="5xl"
              show={openModal}
              onClose={() => {
                setOpenModal(false);
                resetForm();
              }}
            >
              <Modal.Header>Children Policy Assistant</Modal.Header>
              <Modal.Body>
                <div className="space-y-6">
                  <ChildrenAssistantRoomSelector selectedRooms={selectedRooms} />
                  <ChildrenAssistantChildInputs />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button type={"submit"} onClick={submitForm}>
                  Add
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ChildrenPolicyAssistantTool;
