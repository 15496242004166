import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsBank } from "react-icons/bs";
import { RiCurrencyFill, RiKeyboardLine } from "react-icons/ri";
import { HiUserGroup } from "react-icons/hi2";
import { useDispatch } from "react-redux";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { GrAtm } from "react-icons/gr";
import { TbBrandCashapp } from "react-icons/tb";
import PosMAchineRelated from "./posMachineRelated/PosMAchineRelated";
import PosList from "./posMachineRelated/PosList";
import AddCBoxRelated from "./cashBoxRelation/AddCBoxRelated";
import CBoxListRelated from "./cashBoxRelation/CBoxListRelated";
import AddBankRelated from "./bankRelated/AddBankRelated";
import BankListRelated from "./bankRelated/BankListRelated";
import CollectorListRelated from "./collectorRelated/CollectorListRelated";
import AddCollectorRelated from "./collectorRelated/AddCollectorRelated";
import BankChequeListRelated from "./bankChequesRelated/BankChequeListRelated";
import AddBankChequeRelated from "./bankChequesRelated/AddBankChequeRelated";
// import { Link } from "react-router-dom";
const AccountingSetting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  const permissionData = {
    permission_codes: [
      "accounting.view_posmachinetransaction",
      "accounting.view_bank",
      "box.view_box",
      "room_booking.view_collector",
      "accounting.view_chequebook",
      "accounting.add_chequebook ",
      "accounting.add_posmachinetransaction",
      "accounting.add_bank",
      "box.add_box",
      "room_booking.add_collector",
    ],
  };
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div div className="formPage-wrap">
      {permissionFlag ? (
        <div className="w-full ">
          <div className="w-full flex justify-center   ">
            <h2 className="header-h2 ">
              {" "}
              <RiKeyboardLine className="w-5 h-5" />
              Accounting Settings
            </h2>
          </div>

          <div className=" form-wrap">
            {/* <div className="w-full grid grid-cols-5  mb-4  gap-y-5 my-5 bg-gray-50 rounded-md p-2 gap-4 max-sm:grid-cols-1"> */}
            <div className="w-full grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2   mb-4  gap-y-5 my-5   gap-4 grid-cols-1">
              <Link to="/dashboard/accounting-setting/posmachine" className="flex flex-col items-center gap-2 ">
                <div className="bg-[#082F49] hover:bg-[#082f49dc]   w-24 h-24  rounded-full flex-items-center justify-center duration-350 transition-transform hover:scale-[1.05] hover:shadow-lg shadow-md">
                  <GrAtm className="text-xl text-white w-full h-full p-4" />
                </div>
                <h3 className="text-black font-bold text-xl">POS Machine</h3>
              </Link>
              <Link to="/dashboard/accounting-setting/cBox" className="flex flex-col items-center gap-2 ">
                <div className="bg-[#450A0A] hover:bg-[#450a0adc]   w-24 h-24  rounded-full flex-items-center justify-center duration-350 transition-transform hover:scale-[1.05] hover:shadow-lg shadow-md">
                  <TbBrandCashapp className="text-xl text-white w-full h-full p-4" />
                </div>
                <h3 className="text-black font-bold text-xl"> Cash Box</h3>
              </Link>
              <Link to="/dashboard/accounting-setting/collectorRelated" className="flex flex-col items-center gap-2">
                <div className="bg-[#042F2E] hover:bg-[#042f2ee7]   w-24 h-24   rounded-full flex-items-center justify-center  duration-350 transition-transform hover:scale-[1.05]  hover:shadow-lg shadow-md">
                  <HiUserGroup className="text-xl text-white w-full h-full p-4" />
                </div>
                <h3 className="text-black font-bold text-xl">Collectors </h3>
              </Link>
              <Link to="/dashboard/accounting-setting/bankrelated" className="flex flex-col items-center gap-2 ">
                <div className="bg-[#1E3A8A] hover:bg-[#1e3b8ae1]    w-24 h-24   rounded-full flex-items-center justify-center duration-350 transition-transform hover:scale-[1.05]  hover:shadow-lg shadow-md">
                  <BsBank className="text-xl text-white w-full h-full p-4" />
                </div>{" "}
                <h3 className="text-black font-bold text-xl">Banks</h3>
              </Link>
              <Link to="/dashboard/accounting-setting/bankChequeRelated" className="flex flex-col items-center gap-2 ">
                <div className="bg-[#334155] hover:bg-[#334155e6]   w-24 h-24   rounded-full flex-items-center justify-center duration-350 transition-transform hover:scale-[1.05] hover:shadow-lg shadow-md">
                  <RiCurrencyFill className="text-xl text-white w-full h-full p-4" />
                </div>
                <h3 className="text-black font-bold text-xl">Bank Cheques</h3>
              </Link>
            </div>
            {/* </div> */}
            <div className="hidden">
              {permission.map((item) => {
                if (item.name === "accounting.add_posmachinetransaction" && item.value === true) {
                  return <PosMAchineRelated />;
                } else if (item.name === "accounting.add_bank" && item.value === true) {
                  return <AddBankRelated />;
                } else if (item.name === "box.add_box" && item.value === true) {
                  return <AddCBoxRelated />;
                } else if (item.name === "room_booking.add_collector" && item.value === true) {
                  return <AddCollectorRelated />;
                } else if (item.name === "accounting.view_posmachinetransaction" && item.value === true) {
                  return <PosList />;
                } else if (item.name === "accounting.view_bank" && item.value === true) {
                  return <BankListRelated />;
                } else if (item.name === "box.view_box" && item.value === true) {
                  return <CBoxListRelated />;
                } else if (item.name === "room_booking.view_collector" && item.value === true) {
                  return <CollectorListRelated />;
                } else if (item.name === "accounting.view_chequebook" && item.value === true) {
                  return <BankChequeListRelated />;
                } else if (item.name === "accounting.add_chequebook" && item.value === true) {
                  return <AddBankChequeRelated />;
                }
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default AccountingSetting;
