import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteSelectedHotelBank, hotelBankDetailsList } from "../../../redux/actions/hotelBankDetails";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { BsCardImage, BsCurrencyExchange } from "react-icons/bs";
import { BiTransfer } from "react-icons/bi";
import { GiBank } from "react-icons/gi";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import { RiHotelLine } from "react-icons/ri";
import { GrSwift } from "react-icons/gr";
import { MdOutlineCommentBank, MdOutlineManageAccounts } from "react-icons/md";
import { CgNotes } from "react-icons/cg";
import { AiFillDelete, AiOutlineEye } from "react-icons/ai";
import { useLocation, useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import lightToast from "light-toast";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";

const HotelBankDetailsList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [bankList, setBankList] = useState(null);
  const [isloading, setIsLoading] = useState(true);
  const [columnVisibility, setColumnVisibility] = useState({});

  useEffect(() => {
    dispatch(
      hotelBankDetailsList(params.page, params.sorting, (result) => {
        if (result.status === 200) {
          setIsLoading(false);
          setBankList(result.data);
        }
      }),
    );
  }, [params.page, location]);

  useEffect(() => {
    const currentColumnVisibility = localStorage.getItem("grid-visibility-hotel-bank-details-list");
    if (currentColumnVisibility) {
      setColumnVisibility({ ...JSON.parse(currentColumnVisibility), slug: false });
    }
  }, []);

  function handlePageChange(para) {
    setIsLoading(true);
    navigate(`/dashboard/add-hotel-info/hotel-bank-details-list/${para.page + 1}/${params.sorting}`);
  }

  function comparator([para]) {
    setIsLoading(true);
    if (para) {
      if (para.sort === "asc") {
        //    dispatch(sortBookingListAction(token,params.field))
        navigate(`/dashboard/add-hotel-info/hotel-bank-details-list/${params.page}/${para.field}`);
      } else {
        //  dispatch(sortBookingListAction(token,"-"+params.field))
        navigate(`/dashboard/add-hotel-info/hotel-bank-details-list/${params.page}/-${para.field}`);
      }
    } else {
      // dispatch(sortBookingListAction(token,params.field))
      navigate(-1);
    }
  }

  const handleDelete = (id) => {
    setIsLoading(true);
    dispatch(
      deleteSelectedHotelBank(id, (result) => {
        if (result.status === 204) {
          lightToast.success("This Hote Bank was completed Deleted");
          dispatch(
            hotelBankDetailsList(params.page, params.sorting, (result) => {
              if (result.status === 200) {
                setIsLoading(false);
                setBankList(result.data);
              }
            }),
          );
        }
      }),
    );
  };

  const rows =
    bankList &&
    bankList.results.map((item, index) => {
      return {
        account_number: item.account_number,
        bank_name: item.bank_name,
        currency: item.currency,
        document: item.document,
        hotelId: item.hotel.id,
        name: item.hotel.name,
        iban_number: item.iban_number,
        id: item.id,
        swift_code: item.swift_code,
        notes: item.notes,
      };
    });

  const columns = [
    {
      field: "id",
      headerName: (
        <div className="flex items-center gap-x-1 text-xs">
          <BiTransfer className="w-4 h-4 text-yellow-500" />
          ID{" "}
        </div>
      ),
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 ">{params.row.id}</div>;
      },
    },
    {
      field: "hotelId",
      headerName: (
        <div className="flex items-center gap-x-1 tetxt-xs">
          <BiTransfer className="w-4 h-4 text-yellow-500" />
          HOTEL ID
        </div>
      ),
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 mr-5">{params.row.hotelId}</div>;
      },
    },

    {
      field: "name",
      headerName: (
        <div className="flex items-center gap-x-1 text-xs">
          <RiHotelLine className="w-4 h-4 text-yellow-500" />
          HOTEL NAME{" "}
        </div>
      ),
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="w-full flex items-center justify-center gap-x-1">{params.row.name}</div>;
      },
    },

    {
      field: "account_number",
      headerName: (
        <div className="flex items-center gap-x-1 text-xs ">
          <MdOutlineManageAccounts className="w-4 h-4 text-yellow-500" />
          ACCOUNT NUMBER{" "}
        </div>
      ),

      flex: 1.5,
      editable: false,
      renderCell: (params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 mr-8">{params.row.account_number}</div>;
      },
    },

    {
      field: "bank_name",
      headerName: (
        <div className="flex items-center gap-x-1 text-xs">
          <GiBank className="w-4 h-4 text-yellow-500" />
          BANK NAME{" "}
        </div>
      ),
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="w-full flex items-center justify-center gap-x-1">{params.row.bank_name}</div>;
      },
    },

    {
      field: "currency",
      headerName: (
        <div className="flex items-center gap-x-1 text-xs">
          <BsCurrencyExchange className="w-4 h-4 text-yellow-500" />
          CURRENCY{" "}
        </div>
      ),
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 mr-5">{params.row.currency}</div>;
      },
    },

    {
      field: "iban_number",
      headerName: (
        <div className="flex items-center gap-x-1 text-xs">
          <MdOutlineCommentBank className="w-4 h-4 text-yellow-500" />
          IBAN NUMBER{" "}
        </div>
      ),
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 mr-5">{params.row.iban_number}</div>;
      },
    },

    {
      field: "swift_code",
      headerName: (
        <div className="flex items-center gap-x-1 text-xs">
          <GrSwift className="w-4 h-4 text-yellow-500" />
          SWIFT CODE{" "}
        </div>
      ),
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 mr-5">{params.row.swift_code}</div>;
      },
    },

    {
      field: "document",
      headerName: (
        <div className="flex items-center gap-x-2 text-xs">
          <BsCardImage className="w-4 h-4 text-yellow-500 mb-1" />
          DOCUMENT
        </div>
      ),

      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <div className="w-full flex items-center justify-center gap-x-1 mr-5">
            <img src={params.row.document} className="w-10 h-10" />
          </div>
        );
      },
    },

    {
      field: "notes",
      headerName: (
        <div className="flex items-center gap-x-1 text-xs">
          <CgNotes className="w-4 h-4 text-yellow-500" />
          NOTES{" "}
        </div>
      ),
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 mr-5">{params.row.notes}</div>;
      },
    },

    {
      field: "operations",
      filterable: false,
      sortable: false,
      flex: 1,
      headerName: "OPERATIONS",
      renderCell: (params) => {
        return (
          <div className="flex items-center justify-start gap-x-5">
            {permission.map((item) => {
              if (item.name === "hotels_management.view_hotelbankaccountnumber" && item.value === true) {
                return (
                  <Link
                    to={`/dashboard/add-hotel-info/hotel-bank-details-list/preview/${params.row.id}`}
                    className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                  >
                    <AiOutlineEye className="w-5 h-5 text-slate-900 " />
                  </Link>
                );
              } else if (item.name === "hotels_management.delete_hotelbankaccountnumber" && item.value === true) {
                return (
                  <button
                    onClick={() => {
                      setId(params.row.id);
                      setConfirm(true);
                    }}
                    className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                  >
                    <AiFillDelete className="w-5 h-5 text-red-800" />
                  </button>
                );
              }
            })}

            {/* <Link         onClick={()=>console.log("osspsss",params)} to={`/dashboard/all-transactions/1/id/update/${params.row.booked_room_id}/${params.row.transaction_type}/${params.row.keyId}`}
                                className='flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all'>
                                <FiEdit className='w-5 h-5 text-green-800 ' />
                  </Link> */}
          </div>
        );
      },
    },
  ];

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: [
      "hotels_management.view_hotelbankaccountnumber",
      "hotels_management.delete_hotelbankaccountnumber",
    ],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  return (
    <>
      {permissionFlag ? (
        permission.map((item) => {
          if (item.name === "hotels_management.view_hotelbankaccountnumber" && item.value === true) {
            return (
              <div className="w-full overflow-x-auto bg-white ">
                {confirm && (
                  <div className="relative z-50 ">
                    <div className="absolute flex flex-col items-center justify-center gap-y-2 w-[300px] h-36 bg-white border-gray-300 border top-96 left-[450px] bg-center rounded-md ">
                      <p className="text-2xl font-light">Are You Sure?</p>
                      <div className="w-full flex items-center justify-center gap-x-5 text-white">
                        <button
                          onClick={() => {
                            setConfirm(false);
                            handleDelete(id);
                          }}
                          className="bg-green-700 rounded-md px-3 py-1 hover:scale-105 active:scale-95 duration-300 transition-all"
                        >
                          Yes,I'm Sure
                        </button>
                        <button
                          onClick={() => setConfirm(false)}
                          className="bg-red-700 rounded-md px-3 py-1 hover:scale-105 active:scale-95 duration-300 transition-all"
                        >
                          No,take me back
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {isloading ? (
                  <>
                    <TopBarProgress />
                    <div className="w-full h-screen flex items-center justify-center">
                      <Loading />
                    </div>
                  </>
                ) : (
                  <Box sx={{ height: "100%", width: "100%", backgroundColor: "white" }}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      rowCount={bankList.count}
                      onSortModelChange={comparator}
                      columnVisibilityModel={columnVisibility}
                      onColumnVisibilityModelChange={(visibility) => {
                        setColumnVisibility(visibility);
                        localStorage.setItem("grid-visibility-hotel-bank-details-list", JSON.stringify(visibility));
                      }}
                      paginationModel={{ page: Number(params.page - 1), pageSize: 10 }}
                      onPaginationModelChange={handlePageChange}
                      paginationMode="server"
                    />
                  </Box>
                )}
              </div>
            );
          }
        })
      ) : (
        <div className="flex items-center justify-center w-full h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </>
  );
};

export default HotelBankDetailsList;
