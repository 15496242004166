import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyAction } from "../../../redux/actions/bookingListAction";
import cookie from "react-cookies";
import { RiRefund2Line } from "react-icons/ri";
import { VscHistory, VscRequestChanges } from "react-icons/vsc";
import { BsCollection, BsSend } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { LiaFileInvoiceDollarSolid, LiaFileInvoiceSolid } from "react-icons/lia";
import { GiMoneyStack } from "react-icons/gi";
import { PiHandshakeLight } from "react-icons/pi";
import { IoCarSportOutline } from "react-icons/io5";
import { MdCarRental } from "react-icons/md";
import { BiCut } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import { getBookingEmailAction } from "../../../redux/actions/bookingEmailAction";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { getPaymentPermissionAction } from "../../../redux/actions/permissionAction";
import BookingEmailModal from "./BookingEmailModal";
// import jwtDecode from "jwt-decode";

const Payments = () => {
  const params = useParams();
  const [showBookingEmailModal, setShowBookingEmailModal] = useState(false);
  const token = cookie.load("access_token");

  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrencyAction(token));
  }, []);

  /////permission/////////
  const permissionData = {
    permission_codes: {
      transaction: [
        "room_booking.view_banktransfer",
        "room_booking.add_banktransfer",
        "room_booking.change_banktransfer",
        "room_booking.delete_banktransfer",
        "room_booking.add_cashpaymenttransaction",
        "room_booking.view_cashpaymenttransaction",
        "room_booking.change_cashpaymenttransaction",
        "room_booking.delete_cashpaymenttransaction",
        "accounting.add_posmachinetransaction",
        "accounting.view_posmachinetransaction",
        "accounting.change_posmachinetransaction",
        "accounting.delete_posmachinetransaction",
        "room_booking.add_payment",
        "room_booking.view_payment",
        "room_booking.change_payment",
        "room_booking.delete_payment",
      ],
      collection: ["room_booking.add_collection", "room_booking.view_collection"],
      refund: ["room_booking.view_refund", "room_booking.change_refund"],
      hotelPayment: [
        "room_booking.add_hotelpaymentorder",
        "room_booking.change_hotelpaymentorder",
        "room_booking.view_hotelpaymentorder",
        "room_booking.delete_hotelpaymentorder",
      ],
      bookingRequest: [
        "room_booking.add_bookingrequest",
        "room_booking.change_bookingrequest",
        "room_booking.view_bookingrequest",
        "room_booking.delete_bookingrequest",
      ],
      meetAndAssist: [
        "airport_services.add_airportlimousineservice",
        "airport_services.change_airportlimousineservice",
        "airport_services.view_airportlimousineservice",
        "airport_services.delete_airportlimousineservice",
      ],
    },
  };

  // Get All Permissions
  const [permission, setPermission] = useState();
  const [permissionFlag, setPermissionFlag] = useState(false);

  useEffect(() => {
    dispatch(
      getPaymentPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setPermissionFlag(true);
          setPermission(result.data);
        }
      }),
    );
  }, []);

  //  const transactionPermission = permission?.some((item)=>{
  //     return item.value
  //   })

  return (
    <>
      {permissionFlag ? (
        <div className=" w-full  border-gray-300 border rounded-lg">
          <BookingEmailModal
            showBookingEmailModal={showBookingEmailModal}
            setShowBookingEmailModal={setShowBookingEmailModal}
            selectedSingleRoom={selectedSingleRoom}
          />

          <div className="grid grid-cols-4  gap-y-5 p-10">
            {permission.transaction.some((item) => item.value) && (
              <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
                <Link
                  to={"./transactions"}
                  // onClick={() => navigate("./transactions")}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-green-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <GiMoneyStack className="w-10 h-10  " />
                  </div>
                </Link>
                <p className="text-lg font-semibold">
                  Transactions {/* ( add cash amount -  add bank transfer - add visa payment ) */}{" "}
                </p>
              </div>
            )}

            {permission.collection.some((item) => item.value) && (
              <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4 border-solid">
                <Link
                  to={"./collection-request"}
                  // onClick={() => navigate("./collection-request")}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-red-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <BsCollection className="w-10 h-10" />
                  </div>
                </Link>
                <p className="text-lg font-semibold">Collection Request</p>
              </div>
            )}

            {permission.hotelPayment.some((item) => item.value) && selectedSingleRoom.payment_type === "cash" ? (
              <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
                <Link
                  to={"./payment-hotels"}
                  // onClick={() => navigate("./payment-hotels")}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-teal-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <BsSend className="w-10 h-10  " />
                  </div>
                </Link>
                <p className="text-lg font-semibold">Payment Hotels</p>
              </div>
            ) : (
              <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
                <button className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-900 text-white hover:scale-105 active:scale-95 duration-300 transition-all">
                  <div className="w-full flex justify-center items-center ">
                    <LiaFileInvoiceSolid className="w-10 h-10  " />
                  </div>
                </button>
                <p className="text-lg font-semibold">booking vouchers</p>
              </div>
            )}
            {permission.refund.some((item) => item.value) && (
              <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
                <Link
                  to={"./request-refund"}
                  // onClick={() => navigate("./request-refund")}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-[#7C2D12] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <RiRefund2Line className="w-10 h-10  " />
                  </div>
                </Link>
                <p className="text-lg font-semibold">Request a Refund</p>
              </div>
            )}

            <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
              <button
                onClick={() => {
                  setShowBookingEmailModal(!showBookingEmailModal);
                  dispatch(getBookingEmailAction(token, params.id));
                }}
                className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-[#334155] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                <div className="w-full flex justify-center items-center ">
                  <HiOutlineMail className="w-10 h-10  " />
                </div>
              </button>
              <p className="text-lg font-semibold">Booking Emails</p>
            </div>

            <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
              <button className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-teal-900 text-white hover:scale-105 active:scale-95 duration-300 transition-all">
                <div className="w-full flex justify-center items-center ">
                  <VscHistory className="w-10 h-10  " />
                </div>
              </button>
              <p className="text-lg font-semibold">booking history</p>
            </div>
            {permission.bookingRequest.some((item) => item.value) && (
              <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
                <Link
                  to={`./booking-requests`}
                  // onClick={() => navigate(`./booking-requests`)}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-[#0D9488] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <VscRequestChanges className="w-10 h-10  " />
                  </div>
                </Link>
                <p className="text-lg font-semibold">booking requests</p>
              </div>
            )}
            <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
              <button className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-[#4D7C0F] text-white hover:scale-105 active:scale-95 duration-300 transition-all">
                <div className="w-full flex justify-center items-center ">
                  <BiCut className="w-10 h-10" />
                </div>
              </button>
              <p className="text-lg font-semibold">Cut stay</p>
            </div>

            <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
              <Link
                // to={`./representative-serivce`}
                className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                <div className="w-full flex justify-center items-center ">
                  <PiHandshakeLight className="w-10 h-10" />
                </div>
              </Link>
              <p className="text-lg font-semibold">Service Representative</p>
            </div>
            {permission.meetAndAssist.some((item) => item.value) && (
              <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
                <Link
                  to={`./meet-service`}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-[#0C4A6E] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <IoCarSportOutline className="w-10 h-10" />
                  </div>
                </Link>
                <p className="text-lg font-semibold">Meet & Assist Service</p>
              </div>
            )}
            <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
              <button className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all">
                <div className="w-full flex justify-center items-center ">
                  <MdCarRental className="w-10 h-10" />
                </div>
              </button>
              <p className="text-lg font-semibold">Rent Cars</p>
            </div>

            <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
              <button className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all">
                <div className="w-full flex justify-center items-center ">
                  <LiaFileInvoiceDollarSolid className="w-10 h-10" />
                </div>
              </button>
              <p className="text-lg font-semibold">VAT Invoice</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </>
  );
};

export default Payments;
