import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import { GoPerson } from "react-icons/go";
import { BsCashCoin, BsCurrencyExchange } from "react-icons/bs";
import { LiaCalendar } from "react-icons/lia";
import { IoCashOutline } from "react-icons/io5";
import { BiTransfer } from "react-icons/bi";
import { MdOutlineAccessTime } from "react-icons/md";
import TopBarProgress from "react-topbar-progress-indicator";
import { getAllWalletAction } from "../../../redux/actions/walletAction";
import ListFilters from "../../../utils/ListFilters";

const WalletList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const token = cookie.load("access_token");
  const [load, setLoad] = useState(false);
  const [wallet, setWallet] = useState("");
  const [type, setType] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [transaction, setTransaction] = useState(null);
  useEffect(() => {
    setLoad(true);
    dispatch(
      getAllWalletAction(token, params.page, params.sorting, (result) => {
        if (result.status === 200) {
          setLoad(false);
          setWallet(result.data);
        }
      }),
    );
  }, [params.page, location]);

  const rows =
    wallet &&
    wallet.results.map((item, index) => {
      console.log(item);
      return {
        id: item.id,
        created_date: item.created_date.slice(0, 10),
        created_time: item.created_date.slice(11, 19),
        wallet_number: item.wallet_number,
        agent_name: item.agent_name,
        amount: item.amount,
        commission: item.commission,
        currency: item.currency,
        method_of_payment: item.method_of_payment,
        subtotal: item.subtotal,
        amount_used: item.amount_used,
        rest_of_payment: item?.rest_of_payment,
      };
    });

  const columns = [
    {
      key: "created_date",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          DATE
        </div>
      ),

      dataIndex: "created_date",
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => {
              navigate(`/dashboard/e-wallet/wallet-list/preview/${params.id}`);
            }}
            className=" h-full flex items-center justify-center gap-x-5 w-full "
          >
            {params.created_date}
          </button>
        );
      },
    },

    {
      key: "created_time",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <MdOutlineAccessTime className="w-5 h-5  text-yellow-500" />
          TIME
        </div>
      ),
      dataIndex: "created_time",
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => {
              navigate(`/dashboard/e-wallet/wallet-list/preview/${params.id}`);
            }}
            className=" h-full flex items-center justify-center gap-x-5 w-full "
          >
            {params.created_time}
          </button>
        );
      },
    },

    {
      key: "wallet_number",
      title: (
        <div className="flex items-center gap-x-1 text-[11px] ">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          WALLET NUMBER
        </div>
      ),
      dataIndex: "wallet_number",
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => {
              navigate(`/dashboard/e-wallet/wallet-list/preview/${params.id}`);
            }}
            className=" h-full flex items-center justify-center gap-x-5 w-full "
          >
            {params.wallet_number}
          </button>
        );
      },
    },
    {
      key: "agent_name",
      title: (
        <div className="flex items-center gap-x-1 text-[11px] ">
          <GoPerson className="w-5 h-5  text-yellow-500" />
          AGENT NAME
        </div>
      ),
      dataIndex: "agent_name",
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => {
              navigate(`/dashboard/e-wallet/wallet-list/preview/${params.id}`);
            }}
            className=" h-full flex items-center justify-center gap-x-5 w-full "
          >
            {params.agent_name}
          </button>
        );
      },
    },

    {
      key: "amount",
      title: (
        <div className="flex items-center gap-x-1 text-xs justify-center  w-full  text-[11px] ">
          <IoCashOutline className="w-5 h-5  text-yellow-500" />
          AMOUNT
        </div>
      ),
      dataIndex: "amount",
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => {
              navigate(`/dashboard/e-wallet/wallet-list/preview/${params.id}`);
            }}
            className=" h-full flex items-center justify-center gap-x-5 w-full mr-5 "
          >
            {params.amount}
          </button>
        );
      },
    },

    {
      key: "commission",
      title: (
        <div className="flex items-center gap-x-1 text-[11px] ">
          <IoCashOutline className="w-5 h-5  text-yellow-500" />
          COMMISSION
        </div>
      ),
      dataIndex: "commission",
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => {
              navigate(`/dashboard/e-wallet/wallet-list/preview/${params.id}`);
            }}
            className=" h-full flex items-center justify-center gap-x-5 w-full "
          >
            {params.commission}
          </button>
        );
      },
    },

    {
      key: "currency",
      title: (
        <div className="flex items-center gap-x-1 text-[11px] ">
          <BsCurrencyExchange className="w-5 h-5  text-yellow-500" />
          CURRENCY
        </div>
      ),

      dataIndex: "currency",
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => {
              navigate(`/dashboard/e-wallet/wallet-list/preview/${params.id}`);
            }}
            className=" h-full flex items-center justify-center gap-x-5 w-full "
          >
            {params.currency}
          </button>
        );
      },
    },

    {
      key: "method_of_payment",
      title: (
        <div className="flex items-center gap-x-1 text-[10px]">
          <BsCashCoin className="w-5 h-5  text-yellow-500" />
          METHOD OF PAYMENT
        </div>
      ),

      dataIndex: "method_of_payment",
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => {
              navigate(`/dashboard/e-wallet/wallet-list/preview/${params.id}`);
            }}
            className=" h-full flex items-center justify-center gap-x-5 w-full "
          >
            {params.method_of_payment}
          </button>
        );
      },
    },
    {
      key: "subtotal",
      title: (
        <div className="flex items-center gap-x-1 text-[11px] ">
          <IoCashOutline className="w-5 h-5  text-yellow-500" />
          SUBTOTAL
        </div>
      ),

      dataIndex: "subtotal",
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => {
              navigate(`/dashboard/e-wallet/wallet-list/preview/${params.id}`);
            }}
            className=" h-full flex items-center justify-center gap-x-5 w-full "
          >
            {params.subtotal}
          </button>
        );
      },
    },

    {
      key: "amount_used",
      title: (
        <div className="flex items-center gap-x-1 text-[11px] ">
          <IoCashOutline className="w-5 h-5  text-yellow-500" />
          AMOUNT USED
        </div>
      ),

      dataIndex: "amount_used",
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => {
              navigate(`/dashboard/e-wallet/wallet-list/preview/${params.id}`);
            }}
            className=" h-full flex items-center justify-center gap-x-5 w-full "
          >
            {params.amount_used}
          </button>
        );
      },
    },

    {
      key: "rest_of_payment",
      title: (
        <div className="flex items-center gap-x-1 text-[11px] ml-3">
          <IoCashOutline className="w-5 h-5  text-yellow-500" />
          R.O.P
        </div>
      ),
      dataIndex: "rest_of_payment",
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => {
              navigate(`/dashboard/e-wallet/wallet-list/preview/${params.id}`);
            }}
            className=" h-full flex items-center justify-center gap-x-5 w-full "
          >
            {params?.rest_of_payment}
          </button>
        );
      },
    },
  ];

  function comparator([para]) {
    setLoad(true);
    if (para) {
      if (para.sort === "asc") {
        //    dispatch(sortBookingListAction(token,params.key))
        navigate(`/dashboard/e-wallet/wallet-list/${params.page}/${para.key}`);
      } else {
        //  dispatch(sortBookingListAction(token,"-"+params.key))
        navigate(`/dashboard/e-wallet/wallet-list/${params.page}/-${para.key}`);
      }
    } else {
      // dispatch(sortBookingListAction(token,params.key))
      navigate(-1);
    }
  }

  useEffect(() => {
    const currentColumnVisibility = localStorage.getItem("grid-visibility-wallet");
    if (currentColumnVisibility) {
      setColumnVisibility({ ...JSON.parse(currentColumnVisibility), slug: false });
    }
  }, []);

  function handlePageChange(para) {
    setLoad(true);
    navigate(`/dashboard/e-wallet/wallet-list/${para.page + 1}/${params.sorting}`);
  }

  return (
    <div className="w-full formPage-wrap min-h-screen">
      {load ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loading />
          <TopBarProgress />
        </div>
      ) : (
        wallet && (
          <div className="w-full overflow-auto">
            <ListFilters columns={columns} rows={rows} title="Wallet List" />
          </div>
          // <Box sx={{ height: "90%", width: "100%", backgroundColor: "white" }}>
          //   <DataGrid
          //     rows={rows}
          //     columns={columns}
          //     rowCount={wallet.count}
          //     onSortModelChange={comparator}
          //     columnVisibilityModel={columnVisibility}
          //     onColumnVisibilityModelChange={(visibility) => {
          //       setColumnVisibility(visibility);
          //       localStorage.setItem("grid-visibility-wallet", JSON.stringify(visibility));
          //     }}
          //     paginationModel={{ page: Number(params.page - 1), pageSize: 10 }}
          //     onPaginationModelChange={handlePageChange}
          //     paginationMode="server"
          //   />
          // </Box>
        )
      )}
    </div>
  );
};

export default WalletList;
