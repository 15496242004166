import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMarkup, getAllMarkups } from "../../../redux/actions/markupControl";
import { useNavigate } from "react-router";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import cookie from "react-cookies";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import { BiCategory, BiTransfer } from "react-icons/bi";
import { MdAccessTime, MdOutlineDriveFileRenameOutline, MdOutlineLocationOn, MdOutlineSettings } from "react-icons/md";
import { GiMoneyStack, GiTakeMyMoney } from "react-icons/gi";
import { LiaCalendar } from "react-icons/lia";
import ListFilters from "../../../utils/ListFilters";

export default function MarkUpList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [markupId, setMarkupId] = useState(null);
  const markups = useSelector((state) => state.allMarkups);
  useEffect(() => {
    dispatch(
      getAllMarkups((result) => {
        if (result.status === 200) {
          setLoad(true);
        }
      }),
    );
  }, []);
  const handleDelete = (id) => {
    setLoad(false);
    dispatch(
      deleteMarkup(id, (result) => {
        if (result.status === 204) {
          dispatch(
            getAllMarkups((result) => {
              if (result.status === 200) {
                setLoad(true);
              }
            }),
          );
        }
      }),
    );
  };

  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["markup.change_markup", "markup.delete_markup", "markup.view_markup"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  const columns = [
    {
      key: "id",
      title: (
        <div className="flex items-center gap-x-1">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
      dataIndex: "id",

      render: (text, params) => {
        return <div className="  ">{params.id}</div>;
      },
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",

      title: (
        <div className="flex items-center gap-x-1 w-full">
          <MdOutlineDriveFileRenameOutline className="w-5 h-5  text-yellow-500" />
          Name
        </div>
      ),

      render: (text, params) => {
        return <div className="  ">{params.name}</div>;
      },
    },
    {
      key: "userCategory",
      title: (
        <div className="flex items-center gap-x-1">
          <BiCategory className="w-5 h-5  text-yellow-500" />
          User Category
        </div>
      ),
      dataIndex: "userCategory",

      render: (text, params) => {
        return <div className="  ">{params.userCategory}</div>;
      },
    },
    {
      key: "market",
      title: "Market",
      title: (
        <div className="flex items-center gap-x-1">
          <MdOutlineLocationOn className="w-5 h-5  text-yellow-500" />
          Market
        </div>
      ),
      dataIndex: "market",

      render: (text, params) => {
        return <div className="  ">{params.market}</div>;
      },
    },
    {
      key: "priceType",
      title: (
        <div className="flex items-center gap-x-1">
          <GiTakeMyMoney className="w-5 h-5  text-yellow-500" />
          Price Type
        </div>
      ),
      dataIndex: "priceType",

      render: (text, params) => {
        return <div className="  ">{params.priceType}</div>;
      },
    },

    {
      key: "price",
      title: (
        <div className="flex items-center gap-x-1">
          <GiMoneyStack className="w-5 h-5  text-yellow-500" />
          Price
        </div>
      ),
      dataIndex: "price",

      render: (text, params) => {
        return <div className="  ">{params.price}</div>;
      },
    },
    {
      key: "fromDate",
      title: "From Date",
      title: (
        <div className="flex items-center gap-x-1">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          From Date
        </div>
      ),
      dataIndex: "fromDate",

      render: (text, params) => {
        return <div className="  ">{params.fromDate}</div>;
      },
    },
    {
      key: "toDate",
      title: (
        <div className="flex items-center gap-x-1">
          <MdAccessTime className="w-5 h-5  text-yellow-500" />
          To Date
        </div>
      ),
      dataIndex: "toDate",

      render: (text, params) => {
        return <div className="ml-3  ">{params.toDate}</div>;
      },
    },
    {
      key: "actions",
      filterable: false,
      sortable: false,
      dataIndex: "actions",

      title: (
        <div className="flex items-center justify-center gap-x-1">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          Actions
        </div>
      ),
      render: (text, record) => {
        return (
          <div className="w-full flex justify-center items-center gap-x-1">
            {permission.map((item) => {
              // if (item.name === "markup.view_markup" && item.value === true) {
              //   return (
              //     <div className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all">
              //       <AiOutlineEye className="w-5 h-5 text-slate-900 " />
              //     </div>
              //   );P
              // }
              if (item.name === "markup.change_markup" && item.value === true) {
                return (
                  <Link
                    to={`/dashboard/markup-control/edit-markup/${record.id}`}
                    className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                  >
                    <FiEdit className="w-5 h-5 text-green-800 " />
                  </Link>
                );
              } else if (item.name === "markup.delete_markup" && item.value === true) {
                return (
                  <button
                    onClick={() => {
                      setToggle(true);
                      setMarkupId(record.id);
                    }}
                    className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                  >
                    <AiFillDelete className="w-5 h-5 text-red-800" />
                  </button>
                  // <svg
                  //   xmlns="http://www.w3.org/2000/svg"
                  //   viewBox="0 0 24 24"
                  //   fill="currentColor"
                  //   onClick={() => {
                  //     dispatch(deleteMarkup(params.id, () => dispatch(getAllMarkups())));
                  //   }}
                  // >
                  //   <title>trash-can-outline</title>
                  //   <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
                  // </svg>
                );
              }
            })}
          </div>
        );
      },
    },
  ];
  const rows = markups?.map((markup) => {
    return {
      id: markup.id,
      name: markup.name,
      userCategory: markup.user_category ? markup.user_category.name : "---",
      market: markup.market.name,
      priceType: markup.type_price,
      price: markup.price,
      fromDate: markup.from_date,
      toDate: markup.to_date,
    };
  });

  return (
    <div className="formPage-wrap w-full">
      <div
        className={` fixed ${
          toggle ? "top-0 left-0 right-0 bottom-0  " : ""
        }  blur-effect-theme   w-full flex items-center justify-center  opacity-100 z-[250] overflow-x-hidden `}
      >
        <div
          className={`bg-black/70 flex  flex-col justify-center items-center gap-y-5  py-5 max-w-md w-full absolute     ${
            toggle
              ? "opacity-100 visible   transition-all duration-500 transform border-gray-300 border rounded-lg"
              : "opacity-0 invisible -right-full translate-x-8 transition-all duration-500 transform "
          } `}
        >
          <p className="text-2xl text-white font-light">Are You Sure?</p>
          <div className="w-full flex items-center justify-center gap-x-5 text-white">
            <button
              onClick={() => {
                setToggle(false);
                handleDelete(markupId);
              }}
              className="bg-green-700 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              Yes,I'm Sure
            </button>
            <button
              onClick={() => setToggle(false)}
              className="bg-red-700 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              No,take me back
            </button>
          </div>
        </div>
      </div>

      {permissionFlag && load ? (
        permission.some((item) => item.value) ? (
          <div className="w-full  overflow-auto">
            <ListFilters columns={columns} rows={rows} title="Markup List" />
          </div>
        ) : (
          <div className="w-full h-screen">
            <NoPermission />
          </div>
        )
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
