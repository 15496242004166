import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import { CashBoxActionDelete, getAllCashBox } from "../../../redux/actions/addCashBoxAction";
import Box from "@mui/material/Box";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import { BsCurrencyExchange, BsFillSafeFill, BsSafe2 } from "react-icons/bs";
import { LiaCalendar } from "react-icons/lia";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { RxUpdate } from "react-icons/rx";
import { MdDeleteForever, MdOutlineLocationOn, MdOutlineSettings } from "react-icons/md";
import DeletePopUp from "../../molecules/DeletePopUp/DeletePopUp";
import { TbTransferVertical } from "react-icons/tb";
import { toast } from "react-toastify";
import ListFilters from "../../../utils/ListFilters";

const CashBoxList = () => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const [deletePopup, setDeletePopup] = useState("");
  const [permissionID, setPermissionID] = useState("");
  const [cashBox, seCashBox] = useState([]);
  const { cashBoxData } = useSelector((state) => state.addCashBoxReducer);

  const fetchData = () => {
    dispatch(
      getAllCashBox(token, (res) => {
        if (res.status === 200) {
          seCashBox(res?.data);
        } else {
          toast.error(res.data.data || `Error in Fetching data, error status: ${res.status} `);
        }
      }),
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  function handleDeletePopUp(params) {
    setDeletePopup(`Permission for ${params.name}`);
    setPermissionID(params.id);
  }

  const handleDelete = (permissionID) => {
    dispatch(
      CashBoxActionDelete(token, permissionID, (result) => {
        if (result.status === 204) {
          fetchData();
        }
      }),
    );
    setDeletePopup(false);
  };

  const rows =
    cashBox &&
    cashBox?.map((item, index) => {
      return {
        id: item.id,
        name: item.name,
        currency_type: item.currency_type,
        location: item.location,
        balance: item.balance,
        created_at: item.created_at.slice(0, 10),
        created_by: item.created_by,
      };
    });

  const columns = [
    {
      key: "id",
      title: (
        <div className="flex items-center gap-x-1">
          <TbTransferVertical className="w-4 h-4 text-yellow-500 mb-1" />
          ID
        </div>
      ),

      dataIndex: "id",
      editable: false,
      render: (text, params) => {
        return <div className="ml-2">{params.id}</div>;
      },
    },
    {
      key: "name",
      title: (
        <div className="flex items-center gap-x-1">
          <BsSafe2 className="w-4 h-4 text-yellow-500 mb-1" />
          CASH BOX NAME
        </div>
      ),

      dataIndex: "name",
      editable: false,
      render: (text, params) => {
        return <div className="ml-2">{params.name}</div>;
      },
    },

    {
      key: "currency_type",
      title: (
        <div className="flex items-center gap-x-1">
          <BsCurrencyExchange className="w-5 h-5 text-yellow-500" />
          CURRENCY
        </div>
      ),
      dataIndex: "currency_type",
      editable: false,
      render: (text, params) => {
        return <div className="ml-10">{params.currency_type}</div>;
      },
    },

    {
      key: "location",
      title: (
        <div className="flex items-center gap-x-1">
          <MdOutlineLocationOn className="w-5 h-5 text-yellow-500" />
          LOCATION
        </div>
      ),
      dataIndex: "location",
      editable: false,
      render: (text, params) => {
        return <div className="ml-10">{params.location}</div>;
      },
    },
    {
      key: "balance",
      title: (
        <div className="flex items-center gap-x-1">
          <BsFillSafeFill className="w-5 h-5 text-yellow-500" />
          Balance
        </div>
      ),
      dataIndex: "balance",
      editable: false,
      render: (text, params) => {
        return <div className="ml-10">{params.balance}</div>;
      },
    },

    {
      key: "created_at",
      title: (
        <div className="flex items-center gap-x-1">
          <LiaCalendar className="w-5 h-5 text-yellow-500" />
          CREATION DATE{" "}
        </div>
      ),
      dataIndex: "created_at",
      editable: false,
      render: (text, params) => {
        return <div className="ml-10">{params.created_at}</div>;
      },
    },
    // {
    //   key: "created_by",
    //   title: (
    //     <div className="flex items-center gap-x-1">
    //       <GoPerson className="w-5 h-5 text-yellow-500" />
    //       USER CREATED
    //     </div>
    //   ),

    //   flex: 1,
    //   editable: false,
    //   render: (text,params) => {
    //     return <div className="ml-10">{params.created_by}</div>;
    //   },
    // },
    {
      key: "operations",
      title: "Operations",
      title: (
        <div className=" flex items-center gap-x-1 text-xs ml-5">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),
      dataIndex: "operations",
      editable: false,

      render: (text, params) => {
        return (
          <div className="flex items-center justify-center w-full gap-x-2">
            <Link
              to={`/dashboard/account-list/cash_box_list/${params.id}`}
              className="flex items-center gap-x-1  bg-yellow-400 px-1.5 py-1 rounded-md hover:bg-yellow-500 active:scale-95 duration-300 transition-all"
            >
              <AiOutlineEye className="w-5 h-5 text-gray-700 " />
            </Link>

            <Link
              to={`/dashboard/account-list/cash_box_list/create/${params.id}/update`}
              className="flex items-center gap-x-1 bg-green-700 px-1.5 py-1 rounded-md hover:bg-green-800 active:scale-95 duration-300 transition-all"
            >
              <RxUpdate className="w-5 h-5 text-white" />
            </Link>

            <button className="flex items-center gap-x-1 bg-red-800 px-1.5 py-1 rounded-md hover:bg-red-700 active:scale-95 duration-300 transition-all">
              <MdDeleteForever onClick={() => handleDeletePopUp(params)} className="w-5 h-5 text-white" />
            </button>
          </div>
        );
      },
    },
  ];

  /////permission/////////
  const permissionData = {
    permission_codes: ["box.view_box"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result?.data);
        }
      }),
    );
  }, []);
  /////permission/////////
  return (
    <div className="w-full formPage-wrap min-h-screen ">
      {cashBoxData && permissionFlag ? (
        permission?.map((item) => {
          if (item.name === "box.view_box" && item.value === true) {
            return (
              <Box>
                <div className="w-full overflow-auto">
                  <ListFilters columns={columns} rows={rows} title="CashBox List" />
                </div>
                {/* <DataGrid
                  rows={rows}
                  columns={columns} 
                  // rowCount={ allTransactionsData.count}
                  // onSortModelChange={comparator}
                  // columnVisibilityModel={columnVisibility}
                  // onColumnVisibilityModelChange={(visibility) => {
                  //   setColumnVisibility(visibility);
                  //   localStorage.setItem("grid-visibility-transaction-list", JSON.stringify(visibility));
                  // }}
                  // paginationModel={{ page: Number(params.page - 1), pageSize: 10 }}
                  // onPaginationModelChange={handlePageChange}
                  // paginationMode="server"
                />*/}
                {deletePopup && (
                  <DeletePopUp
                    name={deletePopup}
                    Func={() => handleDelete(permissionID)}
                    hideFunc={() => {
                      setDeletePopup(false);
                    }}
                  />
                )}
              </Box>
            );
          } else {
            return (
              <div className="w-full h-screen ">
                <NoPermission />
              </div>
            );
          }
        })
      ) : (
        <>
          <TopBarProgress />
          <div className="w-full h-full flex items-center justify-center">
            <Loading />
          </div>
        </>
      )}
    </div>
  );
};

export default CashBoxList;
