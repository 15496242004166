import {
  FcAssistant,
  FcBusinessman,
  FcDam,
  FcDocument,
  FcHeadset,
  FcInfo,
  FcKindle,
  FcMoneyTransfer,
  FcOvertime,
  FcPlus,
  FcPodiumWithAudience,
  FcSalesPerformance,
  FcServices,
  FcSettings,
  FcSms,
  FcSteam,
  FcSupport,
} from "react-icons/fc";
import { IoMdAirplane } from "react-icons/io";
import { IoFastFoodSharp } from "react-icons/io5";

export const menus = [
  {
    icon: <FcServices size={30} />,
    text: "General Setting",
    children: ["Currency Rate", "Markup Control", "Whitelist Ip", "Email Settings", "Permissions", "Banner"],
  },
  {
    icon: <FcAssistant size={30} />,
    text: "CRM",
    children: ["Client Calls", "Agents Calls", "Call Center Calls", "Call Center WhatsApp"],
  },
  {
    icon: <FcSms size={30} />,
    text: "System Notifications",
    children: [
      "Booking Option Date",
      "Booking Pending Payments ",
      "Booking Pending Hotel Confirmation",
      "Same Day Bookings",
      "Bookings Due Out",
    ],
  },
  {
    icon: <FcHeadset size={30} />,
    text: "B2B Portal",
    children: ["Add Agent", "Agents List", "Agents Accounting", "Agent Reports"],
  },
  {
    icon: <FcBusinessman size={30} />,
    text: "B2C Portal",
    children: ["Add Client", "Client List", "Client Accounting", "Client Reports"],
  },
  {
    icon: <FcDam size={30} />,
    text: "Hotel Settings",
    children: [
      "Add Hotel",
      "Hotel List",
      "Preferred Hotels",
      "Add Room",
      "Room List",
      "Add Hotel Info",
      "Room Packages",
      "Upgrade Tool",
    ],
  },
  {
    icon: <FcSalesPerformance size={30} />,
    text: "Sales",
    children: ["Request List", "Client Follow Up", "Waiting List Clients", "Special Rates Requests"],
  },
  {
    icon: <FcDocument size={30} />,
    text: "Contract",
    children: [
      "Add Contract",
      "Import and Edit Contract",
      "Contract List",
      "Contract Approval",
      "Contract Archive",
      "Deleted Contract",
      "Price Test Tool",
      "Inventory",
      "Offers",
      "Cancellation Policy",
    ],
  },
  {
    icon: <FcSteam size={30} />,
    text: "Hotel Operations",
    children: ["Booking List", "Bookings Hub", "Cancelled Bookings", "Booking Archive"],
  },
  {
    icon: <FcInfo size={30} />,
    text: "Hotel Info",
    children: [
      "Hotel Main Info",
      "Hotel Bank Account",
      "Sales Team Contacts",
      "Reservations Contacts",
      "Credit Team Contacts",
      "Front Desk Contacts",
      "Accounting Contacts",
    ],
  },

  {
    icon: <FcMoneyTransfer size={30} />,
    text: "Accounting",
    children: [
      "Accounting Setting",
      "Bank Transfers",
      "Cash Boxes Statment",
      "Credit Hotels",
      "POS VISA",
      "E Wallet",
      "Refund Requests",
      "Collection Requests",
      "Hotel Payment Orders",
      "Expenses",
      "Other Income",
    ],
  },
  {
    icon: <FcKindle size={30} />,
    text: "Statistics and Reports",
    children: [
      "General Statistics",
      "Contracts Statistics",
      "Bookings Statistics",
      "Hotels Statistics",
      "Agents Profit Report",
      "Accounting Report",
      "Hotels Debits Report",
      "Uploading Team Reports",
      // "Create Link Reports",
      "Create Link Statistics",
      "Cancellation Report",
    ],
  },
  {
    icon: <FcPlus size={30} />,
    text: "Extras",
    children: ["Create Link", "Complain Request"],
  },
  {
    icon: <IoFastFoodSharp size={30} />,
    text: "Team Food Request",
    children: [],
  },
  {
    icon: <FcSupport size={30} />,
    text: "IT Department",
    children: ["Tickets Support"],
  },
  {
    icon: <FcOvertime size={30} />,
    text: "Assets Management",
    children: [],
  },
  {
    icon: <FcSettings size={30} />,
    text: "Airport Services",
    children: ["Airport Control", "Meet and Assist Service"],
  },
  {
    icon: <IoMdAirplane size={30} />,
    text: "Airline Tickets",
    children: [],
  },
  {
    icon: <FcPodiumWithAudience size={30} />,
    text: "Staff management",
    children: ["Departments Management", "Staff Users", "Team Workforce"],
  },
];

export const menuJsonState = {
  general_setting: false,
  currency_rate: false,
  markup_control: false,
  whitelist_ip: false,
  email_settings: false,
  permissions: false,
  crm: false,
  client_calls: false,
  agent_calls: false,
  call_center_calls: false,
  call_center_whatsapp: false,
  system_notifications: false,
  booking_option_date: false,
  booking_pending_payments_: false,
  booking_pending_hotel_confirmation: false,
  same_day_bookings: false,
  bookings_due_out: false,
  b2b_portal: false,
  add_agent: false,
  agents_list: false,
  agents_accounting: false,
  agent_reports: false,
  b2c_portal: false,
  add_client: false,
  client_list: false,
  client_accounting: false,
  client_reports: false,
  hotel_settings: false,
  add_hotel: false,
  hotel_list: false,
  room_list: false,
  add_room: false,
  add_hotel_info: false,
  sales: false,
  request_list: false,
  client_follow_up: false,
  waiting_list_clients: false,
  special_rates_requests: false,
  contract: false,
  add_contract: false,
  import_and_edit_contract: false,
  contract_list: false,
  contract_approval: false,
  contract_archive: false,
  price_test_tool: false,
  inventory: false,
  offers: false,
  cancellation_policy: false,
  hotel_operations: false,
  booking_list: false,
  bookings_hub: false,
  hotel_info: false,
  hotel_main_info: false,
  hotel_bank_account: false,
  sales_team_contacts: false,
  reservations_contacts: false,
  credit_team_contacts: false,
  front_desk_contacts: false,
  accounting_contacts: false,
  accounting: false,
  accounting_setting: false,
  bank_transfers: false,
  cash_boxes_statment: false,
  credit_hotels: false,
  pos_visa: false,
  e_wallet: false,
  refund_requests: false,
  collection_requests: false,
  hotel_payment_orders: false,
  expenses: false,
  other_income: false,
  statistics_and_reports: false,
  general_statistics: false,
  contracts_statistics: false,
  bookings_statistics: false,
  hotels_statistics: false,
  agents_profit_report: false,
  accounting_report: false,
  hotels_debits_report: false,
  uploading_team_reports: false,
  extras: false,
  create_link: false,
  complain_request: false,
  team_food_request: false,
  assets_management: false,
  airport_services: false,
  airport_control: false,
  meet_and_assist_service: false,
  airline_tickets: false,
  staff_management: false,
  IT_Department: false,
  departments_management: false,
  staff_users: false,
  team_workforce: false,
};
