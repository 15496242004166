import React, { useEffect } from "react";
import fetchDataQuery from "../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import { FieldErrorText, FormLabel } from "../FormComponents";

const CoustomArea = ({ onChange, city, Getlocal = {} }) => {
  const {
    data: AreaData,
    error: AreaError,
    isLoading: Arealoading,
  } = useQuery({
    queryKey: ["load-areas", city, Getlocal],
    queryFn: ({ queryKey }) => {
      const [_key, name] = queryKey;
      return fetchDataQuery(`/search/api/load-areas/?city_name=${city || Getlocal?.city?.value}`);
    },
    enabled: !!city || !!Getlocal?.city,
  });

  const dataSelector = AreaData
    ? AreaData.map((Area) => ({
        value: Area.id,
        label: Area.name,
      }))
    : [];

  const handleChange = (selectedOption) => {
    if (selectedOption?.value === "All") {
      // Select all areas
      onChange(
        "Allarea",
        AreaData?.map((Area) => Area.id),
      );
    } else {
      onChange("area", selectedOption);
    }
  };

  return (
    <div className="w-full">
      {" "}
      <FormLabel label={"Area"} htmlFor={"Area"} />
      <Select
        key={city} // This will reset the Select component when city changes
        name="area"
        id="area"
        isLoading={Arealoading}
        isDisabled={Arealoading}
        onChange={(e) => {
          handleChange(e);
        }}
        loadingMessage={() => "Getting area"}
        placeholder="Choose area"
        options={AreaData ? [{ label: "All", value: "All" }, ...dataSelector] : []}
        defaultValue={Getlocal?.area || (Getlocal?.Allarea && { label: "All", value: "All" })}
      />
      {AreaError && <FieldErrorText message={"Error fetching cities"} />}
    </div>
  );
};

export default CoustomArea;
