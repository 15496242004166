import axios from "axios";
import api_token from "../../api/UserApi";
import { GET_HOTELS_LIST, GET_CITY_HOTELS } from "../types/types";
import cookie from "react-cookies";
import { base_URL } from "../../urls";

const token = cookie.load("access_token");

function requestHotelListSuccessfull(data) {
  return {
    type: GET_HOTELS_LIST,
    payload: data,
  };
}

function requestCityHotelsSuccessfull(data) {
  return {
    type: GET_CITY_HOTELS,
    payload: data,
  };
}

export default function requestHotelList(page, sortingOrder, callback, pageSize, city, area) {
  console.log(page);
  return async function (dispatch) {
    // Check if both city and area are provided
    if (!city || !area) {
      console.log("City or area is missing, cannot fetch data.");
      return; // Exit early if city or area is not available
    }

    try {
      const data = await api_token(token).get(
        `/en/hotels/api/v1/add-get-hotels/?page=${page}&page_size=${pageSize}&sorting_order=${sortingOrder}&cities=[${city}]&areas=[${area}]`,
      );
      callback(data);
      console.log(data);

      dispatch(requestHotelListSuccessfull(data));
    } catch (error) {
      if (error.response?.status === 400) {
        try {
          const data = await api_token(token).get(
            `/en/hotels/api/v1/add-get-hotels/?page=${page}&page_size=${pageSize}&sorting_order=${sortingOrder}&city=[${city}]&area=[${area}]`,
          );
          callback(data);
          console.log(data);
          dispatch(requestHotelListSuccessfull(data));
        } catch (secondError) {
          console.error("Error fetching data after second attempt", secondError);
        }
      } else {
        console.error("Error fetching hotel data", error);
      }
    }
  };
}

export function requestCityHotels(city, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/hotels/api/v1/add-get-hotels/?city=${city}`);
      dispatch(requestCityHotelsSuccessfull(response.data));
      callback(response);
      console.log(response);
    } catch (error) {}
  };
}
