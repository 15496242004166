import React, { useEffect, useRef, useState } from "react";
import "./../AddHotelForm/DashboardForm.scss";
import { useDispatch, useSelector } from "react-redux";
import StaticFormSelect from "../../molecules/StaticFormSelect/StaticFormSelect";
import StaticFromInput from "../../molecules/StaticFormInput/StaticFromInput";
import DynamicFormInputText from "../../molecules/DynamicFormInputText/DynamicFormInputText";
import TimeRange from "../../molecules/TimeRange/TimeRange";
import Select from "react-select";
import requestHotelNames from "../../../redux/actions/requestHotelNames";
import { editHotelContactData, getDepartmentChoices, getSingleHotelContact } from "../../../redux/actions/hotelContact";
import { useNavigate, useParams } from "react-router";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import NoPermission from "../NoPermission/NoPermission";
import { LiaFileContractSolid } from "react-icons/lia";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import lightToast from "light-toast";
import { RxUpdate } from "react-icons/rx";

const wDaysOpt = [
  { label: "saturday", value: "sat" },
  { label: "sunday", value: "sun" },
  { label: "monday", value: "mon" },
  { label: "tuesday", value: "tue" },
  { label: "wednesday", value: "wed" },
  { label: "thursday", value: "thu" },
  { label: "friday", value: "fri" },
];
export default function EditHotelContact() {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isDisabled, setIsDisabled] = useState(false);
  const [timeRange, setTimeRange] = useState({ from: null, to: null });
  const [workingDays, setWorkingDays] = useState([]);
  const hotelOptions = useSelector((state) => state.hotelNames);
  const departmentChoices = useSelector((state) => state.departmentChoices);
  const departmentChoicesArr = departmentChoices.map((choice) => ({ name: choice, value: choice }));
  const hotelContactDetails = useSelector((state) => state.singleHotelContact);
  const [telephoneInputsCount, setTelephonInputsCount] = useState(null);
  const [emailInputsCount, setEmailInputsCount] = useState(null);

  useEffect(() => {
    if (Object.keys(hotelContactDetails.data).length > 0) {
      setTelephonInputsCount(Object.keys(hotelContactDetails.data.telephone_number).length);
      setEmailInputsCount(Object.keys(hotelContactDetails.data.email).length);
    }
    const form = formRef.current;
    if (form && form.telephone_number && form.email) {
      form.title.value = hotelContactDetails.data.title;
      form.hotel.value = hotelContactDetails.data.hotel.id;
      form.contact_name.value = hotelContactDetails.data.contact_name;
      form.department.value = hotelContactDetails.data.depertment;
      form.notes.value = hotelContactDetails.data.notes;

      form.telephone_number.length
        ? Array.from(form.telephone_number).forEach(
            (input, index) => (input.value = hotelContactDetails.data.telephone_number[index + 1]),
          )
        : (form.telephone_number.value = hotelContactDetails.data.telephone_number[1]);

      form.email.length
        ? Array.from(form.email).forEach((input, index) => (input.value = hotelContactDetails.data.email[index + 1]))
        : (form.email.value = hotelContactDetails.data.email[1]);

      setWorkingDays(
        Object.values(hotelContactDetails.data.working_days).map((day) => {
          let label;
          switch (day) {
            case "sun":
              label = "sunday";
              break;
            case "mon":
              label = "monday";
              break;
            case "tue":
              label = "tuesday";
              break;
            case "wed":
              label = "wednesday";
              break;
            case "thu":
              label = "thursday";
              break;
            case "fri":
              label = "friday";
              break;
            case "sat":
              label = "saturday";
              break;
          }
          return { label: label, value: day };
        }),
      );
      setTimeRange(hotelContactDetails.data.working_hours);
    }
  }, [hotelContactDetails]);

  useEffect(() => {
    dispatch(requestHotelNames());
    dispatch(getDepartmentChoices());
    dispatch(getSingleHotelContact(id));
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    setIsDisabled(true);
    const form = e.target;
    const formData = new FormData(form);
    const data = {
      title: form.title.value,
      contact_name: form.contact_name.value,
      depertment: form.department.value,
      telephone_number: {},
      email: {},
      notes: form.notes.value,
      working_hours: timeRange,
      working_days: {},
      hotel: form.hotel.value,
    };
    workingDays.forEach((day, index) => {
      data.working_days[index + 1] = day.value;
    });

    Array.from(formData.getAll("telephone_number")).forEach((elem, index) => {
      data.telephone_number[index + 1] = elem;
    });
    Array.from(formData.getAll("email")).forEach((elem, index) => {
      data.email[index + 1] = elem;
    });
    dispatch(
      editHotelContactData(id, data, () => {
        lightToast.success("Hotel Contact Form was completed successfully updated");
      }),
    );
    navigate(`/dashboard/add-hotel-info/hotel-contract-list/1/-id`);
  }

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["hotels_management.change_hotelcontact"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////

  return (
    <div>
      {hotelContactDetails.loading && !permissionFlag ? (
        <div className="w-full flex items-center justify-center h-screen">
          <TopBarProgress />
          <Loading />
        </div>
      ) : (
        <div className="formPage-wrap">
          {permissionFlag &&
            permission.map((item) => {
              if (item.name === "hotels_management.change_hotelcontact" && item.value === true) {
                return (
                  <>
                    {hotelContactDetails.loading && (
                      <div className="w-full flex items-center justify-center h-screen">
                        <TopBarProgress />
                        <Loading />
                      </div>
                    )}
                    {!hotelContactDetails.loading && (
                      <>
                        <div className="w-full ">
                          <div className=" flex items-center justify-center ">
                            <h2 className="header-h2 ">
                              <LiaFileContractSolid className="w-6 h-6" />
                              Edit Hotel Contact
                            </h2>
                          </div>
                        </div>
                        <form className="form-wrap" onSubmit={handleSubmit} ref={formRef}>
                          <div className="input-par">
                            <div className="input-chil">
                              <StaticFromInput text="Title" name="title" />
                            </div>
                            <div className="input-chil">
                              <StaticFormSelect
                                text="Hotel Name"
                                name="hotel"
                                options={hotelOptions}
                                placeholder="choose hotel"
                              />
                            </div>
                          </div>
                          <div className="input-par">
                            <div className="input-chil">
                              <StaticFromInput text="Contact Name" name="contact_name" />
                            </div>
                            <div className="input-chil">
                              <StaticFormSelect
                                text="Department"
                                name="department"
                                options={departmentChoicesArr}
                                placeholder="choose department"
                              />
                            </div>
                          </div>
                          <div className="input-par">
                            <div className="input-chil">
                              {telephoneInputsCount && (
                                <DynamicFormInputText
                                  text="Telephone Number"
                                  name="telephone_number"
                                  type="number"
                                  inputsNum={telephoneInputsCount}
                                />
                              )}
                            </div>
                            <div className="input-chil">
                              {emailInputsCount && (
                                <DynamicFormInputText
                                  text="Email"
                                  name="email"
                                  type="email"
                                  inputsNum={emailInputsCount}
                                />
                              )}
                            </div>
                          </div>
                          <div className="input-par">
                            <div className="input-chil">
                              <label htmlFor="working_days" className="text-slate-800 font-semibold">
                                Working Days
                              </label>
                              <Select
                                options={wDaysOpt}
                                value={workingDays}
                                isMulti
                                onChange={(e) => setWorkingDays(e)}
                              />
                            </div>
                            <div className="input-chil flex items-end gap-x-3">
                              <label htmlFor="" className="text-slate-800 font-semibold mb-3">
                                Working Hours
                              </label>
                              <TimeRange timeRange={timeRange} setTimeRange={setTimeRange} />
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <label htmlFor="notes" className="text-slate-800 font-semibold">
                              Notes
                            </label>
                            <textarea
                              name="notes"
                              id="notes"
                              cols="30"
                              rows="4"
                              className="w-full border rounded-md p-3 focus:outline-none focus:ring focus:border-blue-300"
                            ></textarea>
                          </div>
                          <div className="w-full flex justify-center items-center  mt-6">
                            <button type="submit" disabled={isDisabled} className="updateButton">
                              Update
                              {isDisabled ? (
                                <>
                                  {" "}
                                  <TopBarProgress /> <ButtonLoading />
                                </>
                              ) : (
                                <RxUpdate className="w-5 h-5" />
                              )}
                            </button>
                          </div>

                          {/* {isDisabled ? (
                            <>
                              <TopBarProgress />
                              <div className="h-[100px] rounded-lg bg-gray-900 bg-opacity-60 fixed top-[40vh] left-[45vw] flex flex-column justify-center items-center p-2 px-3">
                                <HashLoader size={30} color="white" />
                                <div className="text-white font-bold my-2">Submitting...</div>
                              </div>
                            </>
                          ) : null} */}
                        </form>
                      </>
                    )}
                  </>
                );
              } else {
                return (
                  <div className="w-full h-screen">
                    <NoPermission />
                  </div>
                );
              }
            })}
        </div>
      )}
    </div>
  );
}
