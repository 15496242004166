import React, { useEffect, useState } from "react";
import { DateRangePicker, DayPickerRangeController } from "react-dates";
import moment from "moment";
import { Button, Modal, ModalHeader, Popover } from "flowbite-react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useTranslation } from "react-i18next";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import { Badge } from "flowbite-react";
import { HiCheck, HiClock } from "react-icons/hi";
import { IoClose } from "react-icons/io5";

const DateValid = ({ AllDataWhatsApp, setOpenDateValid, OpenDateValid, room_id }) => {
  const [t, i18n] = useTranslation();

  const [focusedInput, setFocusedInput] = useState("startDate"); // Set initial focus
  const [dates, setDates] = useState({
    startDate: moment(`${AllDataWhatsApp.formCheckIn}`),
    endDate: moment(`${AllDataWhatsApp.formCheckOut}`),
  });

  const {
    data: inventoryData,
    error: inventoryError,
    isLoading: inventoryloading,
  } = useQuery({
    queryKey: ["exhange-rate", room_id],
    queryFn: ({ queryKey }) => {
      const [_key, name] = queryKey;
      return fetchDataQuery(`/inventory/api/room_inventory/unavailable/?room_id=${room_id}&start_date=${AllDataWhatsApp.formCheckIn}&end_date=${AllDataWhatsApp.formCheckOut}
`);
    },
    enabled: !!OpenDateValid,
  });

  const redDays = inventoryData?.map((day) => day);

  const isDayBlocked = (day) => {
    return redDays.some((redDay) => day.isSame(redDay, "day"));
  };

  const isOutsideRange = (day) => {
    return !day.isBetween(dates.startDate, dates.endDate, "days", "[]");
  };

  const initialVisibleMonth = () => dates.startDate;

  // Define the two dates
  const startDateVaild = new Date(dates.startDate);
  const endDateVaild = new Date(dates.endDate);

  // Extract the year and month from each date
  const startYear = startDateVaild.getFullYear();
  const startMonth = startDateVaild.getMonth();
  const endYear = endDateVaild.getFullYear();
  const endMonth = endDateVaild.getMonth();

  // Calculate the difference in months
  const monthDifference = (endYear - startYear) * 12 + (endMonth - startMonth);
  useEffect(() => {
    if (OpenDateValid) {
      const style = document.createElement("style");
      style.innerHTML = `
          .CalendarDay__blocked_calendar, 
          .CalendarDay__blocked_calendar:active, 
          .CalendarDay__blocked_calendar:hover {
            background: #ec7063 !important;
            border: 1px solid #ec7063 !important;
            color: #ffff !important;
          }
        `;
      document.head.appendChild(style);

      return () => {
        document.head.removeChild(style);
      };
    }
  }, [OpenDateValid]);

  return (
    <div>
      {OpenDateValid && AllDataWhatsApp.formCheckIn && AllDataWhatsApp.formCheckOut && (
        <Modal size={"lg"} dismissible show={OpenDateValid} onClose={() => setOpenDateValid(false)}>
          <Modal.Header></Modal.Header>
          <Modal.Body dir={t("dir")} className="h-auto !p-0">
            <p className="px-6 py-2 text-cyan-900 font-semibold"></p>

            <div className="flex flex-wrap gap-2 m-4">
              <Badge icon={HiCheck}>{t("availabledate")}</Badge>
              <Badge color="failure" icon={IoClose}>
                {t("notavailabledate")}
              </Badge>
            </div>

            {inventoryloading ? (
              <>
                <Loading /> <TopBarProgress />
              </>
            ) : (
              inventoryData &&
              redDays && (
                <div className="h-[60vh]">
                  <DayPickerRangeController
                    startDate={dates.startDate}
                    endDate={dates.endDate}
                    onDatesChange={() => {}}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => setFocusedInput(focusedInput || "startDate")}
                    isDayBlocked={isDayBlocked}
                    autoFocus
                    numberOfMonths={monthDifference + 1}
                    verticalHeight={550}
                    orientation="verticalScrollable"
                    hideKeyboardShortcutsPanel
                    initialVisibleMonth={initialVisibleMonth}
                    isOutsideRange={isOutsideRange}
                    weekDayFormat="ddd"
                    noNavButtons // Hide navigation buttons to prevent moving to other months\
                    small
                    autofocus
                    daySize={38}
                    // weekDayFormat={t("lan") === "en" ? "ddd" : "dddd"}
                  />
                </div>
              )
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button color="gray" onClick={() => setOpenDateValid(false)}>
              {t("nocancel")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default DateValid;
