import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
import Select from "react-select";
import Loading from "../Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { IoAdd } from "react-icons/io5";
import lightToast from "light-toast";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { FaWpforms } from "react-icons/fa6";
import TopBarProgress from "react-topbar-progress-indicator";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import { useFormik } from "formik";
import { addBankValidationSchema } from "./AddBankValidationSchema";
import useData from "../../../customHooks/useData";
import { sendData } from "../../../redux/actions/sendDataAction";
import { getCurrencyTypes_URL, sendBankAccount_URL } from "../../../urls";
import { getBankByIdAction, UpdateBankByIdAction } from "../../../redux/actions/addBankAction";
import { NotificationManager } from "react-notifications";
import { GrUpdate } from "react-icons/gr";

const AddBank = () => {
  /////permission/////////
  const permissionData = {
    permission_codes: ["accounting.add_bank"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);

  // select currency
  const [selectedCurrencies, setSelectedCurrencies] = useState();
  const params = useParams();

  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  // ---------------------

  // Form State
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setSubmitting,
    isSubmitting,
    touched,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: "",
      account_number: "",
      currency: "",
      account_balance: "",
      notes: "",
      is_active: null,
    },
    validationSchema: addBankValidationSchema,
    onSubmit: submit,
  });

  useEffect(() => {
    if (params.id) {
      dispatch(
        getBankByIdAction(token, params.id, (result) => {
          if (result.status === 200) {
            setFieldValue("name", result.data.name);
            setFieldValue("account_number", result.data.account_number);
            setFieldValue("account_balance", result.data.account_balance);
            setFieldValue("notes", result.data.notes);
            setFieldValue("is_active", result.data.is_active);
            setFieldValue("currency", result.data.currency);

            setSelectedCurrencies([{ label: result.data.currency, value: result.data.currency }]);
          }
        }),
      );
    }
  }, []);

  const currencies = useData(getCurrencyTypes_URL);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = cookie.load("access_token");

  let currenciesArr = currencies ? currencies.map((currency) => ({ value: currency, label: currency })) : [];

  function submit(data) {
    const newValues = {
      name: data.name,
      account_number: data.account_number,
      currency: data.currency,
      account_balance: data.account_balance,
      notes: data.notes,
      is_active: data.is_active,
    };
    if (params.id) {
      dispatch(
        UpdateBankByIdAction(token, params.id, newValues, (result) => {
          if (result.status === 200) {
            setSubmitting(false);
            lightToast.success("Bank List Updated successfully");
            navigate(-1);
          } else {
            setSubmitting(false);

            NotificationManager.error(result?.response?.data[0]?.message);
          }
        }),
      );
    } else {
      dispatch(
        sendData(
          sendBankAccount_URL,
          data,
          () => {
            lightToast.success("Bank is completed Added successfully");
            navigate(-1);
          },
          () => lightToast.fail("There was an error submitting the form try again later"),
        ),
      );
    }
  }

  return (
    <div className="formPage-wrap  ">
      <div className="flex flex-col items-center  h-full w-full">
        {currencies && permissionFlag ? (
          permission.map((item) => {
            if (item.name === "accounting.add_bank" && item.value === true) {
              return (
                <div className=" w-full flex flex-col gap-4  border-gray-300  rounded-lg p-5 ">
                  <div className=" flex  w-full text-center justify-center ">
                    {params.id ? (
                      <h2 className="header-h2">
                        <FaWpforms className="w-5 h-5" /> update Bank List
                      </h2>
                    ) : (
                      <h2 className="header-h2">
                        <FaWpforms className="w-5 h-5" />
                        Add Bank
                      </h2>
                    )}
                  </div>
                  <form className="form-wrap" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="w-full flex flex-col   gap-y-2">
                      <div className="input-par">
                        <div className="input-chil">
                          <label className="  w-full text-gray-600 font-bold">Bank Name</label>
                          <input
                            placeholder="Bank Name"
                            name="name"
                            type="text"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`w-full px-3 h-10  border rounded-lg bg-white ${
                              errors.name && touched.name && "border-red-900"
                            }`}
                          />
                          {errors.name && touched.name && (
                            <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                              {errors.name}
                            </div>
                          )}
                        </div>
                        <div className="input-chil">
                          <label className=" flex text-gray-600  font-bold ">Account Number</label>
                          <input
                            placeholder="Account Number"
                            name="account_number"
                            type="number"
                            step="any"
                            value={values.account_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`w-full px-3 h-10  border rounded-lg bg-white ${
                              errors.account_number && touched.account_number && "border-red-900"
                            }`}
                          />
                          {errors.account_number && touched.account_number && (
                            <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                              {errors.account_number}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="input-par">
                        <div className="input-chil">
                          <label className=" flex text-gray-600 font-bold">Currency</label>
                          <Select
                            name="currency"
                            options={currenciesArr}
                            isSearchable
                            deafultValue={selectedCurrencies}
                            className={`w-full  h-10  rounded-lg  bg-white ${
                              errors.currency && touched.currency && "border border-red-900"
                            }`}
                            value={
                              values.currency
                                ? [{ value: values.currency, label: values.currency }]
                                : selectedCurrencies
                            }
                            onChange={(e) => setFieldValue("currency", e.value)}
                            onBlur={handleBlur}
                            placeholder="Currency"
                          />
                          {errors.currency && touched.currency && (
                            <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                              {errors.currency}
                            </div>
                          )}
                        </div>
                        <div className="input-chil">
                          {" "}
                          <label className=" flex text-gray-600 font-bold  ">Account Balance</label>
                          <input
                            placeholder="Account Balance"
                            name="account_balance"
                            type="number"
                            step="any"
                            value={values.account_balance}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`w-full px-3 h-10  border rounded-lg bg-white ${
                              errors.account_balance && touched.account_balance && "border-red-900"
                            }`}
                          />
                          {errors.account_balance && touched.account_balance && (
                            <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                              {errors.account_balance}
                            </div>
                          )}
                        </div>
                      </div>

                      <label className="flex text-gray-600 font-bold">Notes</label>
                      <textarea
                        name="notes"
                        type="text"
                        value={values.notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="w-full p-3 border rounded-lg bg-white"
                      />

                      <div className="flex items-center justify-center gap-x-10 w-full mt-5">
                        <div className="flex items-center justify-center gap-x-3 border bg-white py-1.5 px-4 rounded-md ">
                          <label className="  text-gray-600 font-bold   text-lg ">Active</label>
                          <input
                            placeholder="Employee Name"
                            name="is_active"
                            type="radio"
                            value="true"
                            defaultChecked={values.is_active === true && true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className=" w-5 h-5  border rounded-lg bg-white "
                          />
                        </div>
                        <div className="flex items-center justify-center gap-x-3 border bg-white py-1.5 px-4 rounded-md ">
                          <label className="text-gray-600  font-bold text-lg">No Active</label>
                          <input
                            placeholder="Total Amount"
                            name="is_active"
                            type="radio"
                            value="false"
                            defaultChecked={values.is_active === false && true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className=" w-5 h-5  border rounded-lg bg-white  "
                          />
                        </div>
                      </div>
                      {errors.is_active && touched.is_active && (
                        <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                          {errors.is_active}
                        </div>
                      )}
                      <div className="w-full flex justify-center mt-6">
                        {!params.id ? (
                          <button type="Submit" disabled={isSubmitting} className="addButton">
                            Add{" "}
                            {isSubmitting ? (
                              <>
                                <ButtonLoading />
                                <TopBarProgress />
                              </>
                            ) : (
                              <IoAdd className="w-5 h-5" />
                            )}
                          </button>
                        ) : (
                          <button type="Submit" disabled={isSubmitting} className="updateButton">
                            Update{" "}
                            {isSubmitting ? (
                              <>
                                <ButtonLoading />
                                <TopBarProgress />
                              </>
                            ) : (
                              <GrUpdate className="w-3 h-3" />
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              );
            } else {
              return (
                <div className="w-full h-screen">
                  <NoPermission />
                </div>
              );
            }
          })
        ) : (
          <>
            <TopBarProgress /> <Loading />
          </>
        )}
      </div>
    </div>
  );
};

export default AddBank;
