import React from "react";
import StaticFormSelect from "../../molecules/StaticFormSelect/StaticFormSelect";
import { ErrorMessage, FieldArray, useFormikContext } from "formik";
import { FieldErrorText } from "../../../customComponents/FormComponents";
import StaticFromInput from "../../molecules/StaticFormInput/StaticFromInput";
import DeleteButton from "../../../customComponents/DeleteButton";
import { Button } from "flowbite-react";
import { toast } from "react-toastify";

function ChildrenAssistantChildInputs() {
  const { values, setFieldValue } = useFormikContext();

  const maxChildrenSelectOptions = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    name: i + 1,
  }));

  const maxAdultsSelectOptions = Array.from({ length: 12 }, (_, i) => ({
    id: i + 1,
    name: i + 1,
  }));

  const ageSelectOptions = Array.from({ length: 12 }, (_, i) => ({
    id: i,
    name: i,
  }));

  const handleOrderChange = (setFieldValue, index, values, order) => {
    const childOrder = Number(order);
    setFieldValue(`children[${index}].minAge`, "");
    setFieldValue(`children[${index}].maxAge`, "");

    const allOrderingChild = values.children.map((child) => child.order).filter(Boolean);

    if (childOrder !== 1 && !allOrderingChild.includes(childOrder - 1)) {
      toast.error("You must add the previous child order first");
      return;
    }

    setFieldValue(`children[${index}].order`, childOrder);
  };

  const handleMinAgeChange = (setFieldValue, index, values, age, child) => {
    const { maxAge } = child;

    if (age >= maxAge && maxAge) {
      toast.error("The minimum age must be less than the maximum age.");
      return;
    }

    const { order } = child;
    const conflictingAge = values.children.find(
      (child) => child.order === order && age >= child.minAge && age <= child.maxAge,
    );

    if (conflictingAge) {
      toast.error("The minimum age conflicts with an existing range for the same child order.");
      setFieldValue(`children[${index}].minAge`, "");
      setFieldValue(`children[${index}].maxAge`, "");
      return;
    }

    setFieldValue(`children[${index}].minAge`, Number(age));
  };

  const handleMaxAgeChange = (setFieldValue, index, values, age, child) => {
    const { minAge } = child;

    if (age <= minAge && minAge) {
      toast.error("The maximum age must be greater than the minimum age.");
      return;
    }

    const { order } = child;
    const conflictingAge = values.children.find(
      (child) => child.order === order && age >= child.minAge && age <= child.maxAge,
    );

    if (conflictingAge) {
      toast.error("The minimum age conflicts with an existing range for the same child order.");
      setFieldValue(`children[${index}].minAge`, "");
      setFieldValue(`children[${index}].maxAge`, "");
      return;
    }

    setFieldValue(`children[${index}].maxAge`, Number(age));
  };

  return (
    <FieldArray name="children">
      {({ push, remove }) => (
        <div>
          {values.children.map((child, index) => (
            <div key={index} className="w-full flex md:flex-row flex-col items-center justify-between px-2 py-2">
              <div>
                <StaticFormSelect
                  text="Child Order"
                  name={`children[${index}].order`}
                  id={`children[${index}].order`}
                  options={maxChildrenSelectOptions}
                  value={child.order}
                  canDefaultValueZero={true}
                  placeholder="Select Child Order"
                  contractCallBack={(e, order) => handleOrderChange(setFieldValue, index, values, order)}
                />
                <ErrorMessage name={`children[${index}].order`} render={(msg) => <FieldErrorText message={msg} />} />
              </div>

              <div>
                <StaticFormSelect
                  disabled={!child.order}
                  text="Min age"
                  name={`children[${index}].minAge`}
                  id={`children[${index}].minAge`}
                  options={ageSelectOptions}
                  value={child.minAge}
                  canDefaultValueZero={true}
                  placeholder="Min age"
                  contractCallBack={(e, age) => handleMinAgeChange(setFieldValue, index, values, age, child)}
                />
                <ErrorMessage name={`children[${index}].minAge`} render={(msg) => <FieldErrorText message={msg} />} />
              </div>

              <div>
                <StaticFormSelect
                  disabled={child.minAge === "" || !child.order}
                  text="Max age"
                  name={`children[${index}].maxAge`}
                  id={`children[${index}].maxAge`}
                  options={ageSelectOptions}
                  value={child.maxAge}
                  canDefaultValueZero={true}
                  placeholder="Max age"
                  contractCallBack={(e, age) => handleMaxAgeChange(setFieldValue, index, values, age, child)}
                />
                <ErrorMessage name={`children[${index}].maxAge`} render={(msg) => <FieldErrorText message={msg} />} />
              </div>

              <div>
                <StaticFromInput
                  className="w-32"
                  text="Percentage (%)"
                  type="number"
                  name={`children[${index}].percentage`}
                  id={`children[${index}].percentage`}
                  placeholder="Percentage"
                  min={0}
                  max={100}
                  func={(percentage) => {
                    setFieldValue(`children[${index}].percentage`, percentage);
                  }}
                />
                <ErrorMessage
                  name={`children[${index}].percentage`}
                  render={(msg) => <FieldErrorText message={msg} />}
                />
              </div>

              <div>
                <StaticFormSelect
                  disabled={!child.order}
                  text="Adult order"
                  name={`children[${index}].adultOrder`}
                  id={`children[${index}].adultOrder`}
                  options={maxAdultsSelectOptions}
                  value={child.adultOrder}
                  canDefaultValueZero={true}
                  placeholder="Select Adult Order"
                  contractCallBack={(e, order) => setFieldValue(`children[${index}].adultOrder`, Number(order))}
                />
                <ErrorMessage
                  name={`children[${index}].adultOrder`}
                  render={(msg) => <FieldErrorText message={msg} />}
                />
              </div>

              <DeleteButton onDelete={() => remove(index)} />
            </div>
          ))}
          <div className="w-full flex items-center justify-center mt-4">
            <Button
              color="success"
              onClick={() => {
                push({
                  minAge: "",
                  maxAge: "",
                  percentage: "",
                  order: "",
                  adultOrder: "",
                });
              }}
            >
              Add Child
            </Button>
          </div>
        </div>
      )}
    </FieldArray>
  );
}

export default ChildrenAssistantChildInputs;
