import React, { useEffect, useState } from "react";

import { FcCancel, FcList } from "react-icons/fc";
import { useDispatch } from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";

import { AiOutlineEye, AiOutlinePhone } from "react-icons/ai";
import { DataGrid } from "@mui/x-data-grid";

import { deletePermisssion, getSearchListAction } from "../../../../redux/actions/searchPermissionAction";
import Loading from "../../Loading/Loading";
import { FaBarcode, FaExchangeAlt, FaUserAlt } from "react-icons/fa";
import { MdDeleteForever, MdEmail, MdOutlineSettings } from "react-icons/md";
import { BsBuildingsFill } from "react-icons/bs";
import { RxUpdate } from "react-icons/rx";
import { Link } from "react-router-dom";
import usePermissions from "../../../../customHooks/usePermissions";
import NoPermission from "../../NoPermission/NoPermission";
import DeletePopUp from "../../../molecules/DeletePopUp/DeletePopUp";

const SearchPermissionsList = () => {
  const dispatch = useDispatch();
  const [searchList, setSearchList] = useState();
  const [deletePopup, setDeletePopup] = useState("");
  const [permissionID, setPermissionID] = useState("");
  const [isloading, setIsLoading] = useState(false);

  //Permission
  const [permissions, permissionsFlag] = usePermissions([
    "permissions_management.change_searchpermission",
    "permissions_management.view_searchpermission",
    "permissions_management.delete_searchpermission",
  ]);

  const fetchdata = () => {
    dispatch(
      getSearchListAction((result) => {
        if (result.status === 200) {
          setSearchList(result.data);
        }
      }),
    );
  };
  useEffect(() => {
    fetchdata();
  }, []);

  // this will show the popup message to confirm delete and will store the slug to be deleted in the hotel slug state
  function handleDeletePopUp(params) {
    setDeletePopup(`Permission for ${params.row.username}`);
    setPermissionID(params.row.id);
  }

  const handleDelete = (permissionID) => {
    dispatch(
      deletePermisssion(permissionID, (result) => {
        if (result.status === 204) {
          fetchdata();
        }
      }),
    );
    setDeletePopup(false);
  };

  const rows = searchList?.map((item) => {
    return {
      id: item.id,
      username: item.user.username,
      email: item.user.email,
      allowed_hotels: item.allowed_hotels.length === 0 ? "all hotels" : item.allowed_hotels[0].name,
      allowed_markup: item.allowed_markup.length === 0 ? "all markup" : item.allowed_markup[0].name,
      allowed_contoury: item.allowed_contoury.length === 0 ? "all contoury" : item.allowed_contoury[0].name,
      allowed_city: item.allowed_city.length === 0 ? "all city" : item.allowed_city[0].name,
    };
  });

  const columns = [
    {
      field: "id",
      headerName: (
        <div className=" flex items-center gap-x-1 text-xs">
          <FaExchangeAlt className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),

      flex: 1,
      editable: false,
    },
    {
      field: "username",
      headerName: (
        <div className=" flex items-center gap-x-1 text-xs">
          <FaUserAlt className="w-5 h-5  text-yellow-500" />
          USER NAME
        </div>
      ),

      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: (
        <div className=" flex items-center gap-x-1 text-xs">
          <MdEmail className="w-5 h-5  text-yellow-500" />
          EMAIL
        </div>
      ),

      flex: 1,
      editable: false,
    },
    {
      field: "allowed_markup",
      headerName: (
        <div className=" flex items-center gap-x-1 text-xs">
          <BsBuildingsFill className="w-5 h-5  text-yellow-500" />
          MARKUP
        </div>
      ),

      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="flex items-center gap-x-1  justify-center">{params.row.allowed_markup} ...</div>;
      },
    },
    {
      field: "allowed_city",
      headerName: (
        <div className=" flex items-center gap-x-1 text-xs">
          <BsBuildingsFill className="w-5 h-5  text-yellow-500" />
          CITIES
        </div>
      ),

      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="flex items-center gap-x-1  justify-center">{params.row.allowed_city} ...</div>;
      },
    },
    {
      field: "allowed_contoury",
      headerName: (
        <div className=" flex items-center gap-x-1 text-xs">
          <BsBuildingsFill className="w-5 h-5  text-yellow-500" />
          NATIONALITY
        </div>
      ),

      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="flex items-center gap-x-1  justify-center">{params.row.allowed_contoury} ...</div>;
      },
    },
    {
      field: "allowed_hotels",
      headerName: (
        <div className=" flex items-center gap-x-1 text-xs">
          <BsBuildingsFill className="w-5 h-5  text-yellow-500" />
          HOTELS
        </div>
      ),

      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="flex items-center gap-x-1  justify-center">{params.row.allowed_hotels} ...</div>;
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      headerName: (
        <div className=" flex items-center gap-x-1 text-xs ml-5">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),
      flex: 1,
      editable: false,

      renderCell: (params) => {
        return (
          <div className="flex items-center justify-center w-full gap-x-2">
            {permissions?.map((item) => {
              if (item.name === "permissions_management.change_searchpermission" && item.value === true) {
                return (
                  <Link
                    to={`/dashboard/permissions/search-permission/searchpermission-details/${params.row.id}`}
                    className="flex items-center gap-x-1  bg-yellow-400 px-1.5 py-1 rounded-md hover:bg-yellow-500 active:scale-95 duration-300 transition-all"
                  >
                    <AiOutlineEye className="w-5 h-5 text-gray-700 " />
                  </Link>
                );
              } else if (item.name === "permissions_management.view_searchpermission" && item.value === true) {
                return (
                  <Link
                    to={`/dashboard/permissions/search-permission/create/${params.row.id}/update`}
                    className="flex items-center gap-x-1 bg-green-700 px-1.5 py-1 rounded-md hover:bg-green-800 active:scale-95 duration-300 transition-all"
                  >
                    <RxUpdate className="w-5 h-5 text-white" />
                  </Link>
                );
              } else if (item.name === "permissions_management.delete_searchpermission" && item.value === true) {
                return (
                  <button className="flex items-center gap-x-1 bg-red-800 px-1.5 py-1 rounded-md hover:bg-red-700 active:scale-95 duration-300 transition-all">
                    <MdDeleteForever onClick={() => handleDeletePopUp(params)} className="w-5 h-5 text-white" />
                  </button>
                );
              }
            })}
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full ">
      {searchList && permissionsFlag ? (
        permissions.some((item) => item.value) ? (
          isloading ? (
            <div className="w-full flex items-center justify-center h-screen">
              <Loading />
              <TopBarProgress />
            </div>
          ) : (
            <>
              <div className="w-full flex  items-center justify-center ">
                <h2 className="header-h2 ">
                  <FcList className="w-6 h-6 text-yellow-600" /> Search Permissions List
                </h2>
              </div>
              <div className="w-full overflow-x-auto  ">
                <DataGrid className=" md:w-full  w-[300%]" autoHeight rows={rows} columns={columns} />
              </div>
              {deletePopup && (
                <DeletePopUp
                  name={deletePopup}
                  Func={() => handleDelete(permissionID)}
                  hideFunc={() => {
                    setDeletePopup(false);
                  }}
                />
              )}
            </>
          )
        ) : (
          <div className="w-full h-screen flex items-center justify-center">
            <NoPermission />
          </div>
        )
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default SearchPermissionsList;
