import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
import { AiOutlineEye, AiOutlinePhone } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "../Loading/Loading";
import { MdDeleteForever, MdOutlineSettings, MdOutlineSpeakerNotes } from "react-icons/md";
import TopBarProgress from "react-topbar-progress-indicator";
import Box from "@mui/material/Box";
import { LiaCalendar } from "react-icons/lia";
import { PiDatabase } from "react-icons/pi";
import { BiLogoWhatsapp } from "react-icons/bi";
import { GoPerson } from "react-icons/go";
import { BsCollection } from "react-icons/bs";
import { deleteCollectors, getAllCollectorsAction } from "../../../redux/actions/collectorsAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import { RxUpdate } from "react-icons/rx";
import DeletePopUp from "../../molecules/DeletePopUp/DeletePopUp";
import { toast } from "react-toastify";
import ListFilters from "../../../utils/ListFilters";

const CollectorsList = () => {
  const [load, setLoad] = useState(false);
  const [collectors, setCollectors] = useState();
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const params = useParams();
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const [id, setId] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [deletePopup, setDeletePopup] = useState("");
  const [permissionID, setPermissionID] = useState("");

  const fetchData = () => {
    setLoad(true);
    dispatch(
      getAllCollectorsAction(token, (result) => {
        if (result.status === 200) {
          setLoad(false);
          setCollectors(result.data.results);
        } else {
          toast.error(result.data.data || `Error in Fetching data, error status: ${result.status} `);
        }
      }),
    );
  };
  useEffect(() => {
    fetchData();
  }, []);
  const rows =
    collectors &&
    collectors.map((item) => {
      return {
        id: item.id,
        collector_name: item.collector_name,
        collector_department: item.collector_department,
        collector_mobile: item.collector_mobile,
        collector_whatsapp: item.collector_whatsapp,
        joining_date: item.joining_date,
        notes: item.notes,
        collection_date: item.created_date.slice(0, 10),
        collection_time: item.created_date.slice(11, 19),
      };
    });

  function handleDeletePopUp(params) {
    setDeletePopup(`Permission for ${params.name}`);
    setPermissionID(params.id);
  }

  const handleDelete = (permissionID) => {
    dispatch(
      deleteCollectors(token, permissionID, (result) => {
        if (result.status === 204) {
          fetchData();
        }
      }),
    );
    setDeletePopup(false);
  };

  const columns = [
    {
      key: "id",
      title: (
        <div className=" text-center  flex justify-center items-center gap-x-1  text-[11px] font-semibold">
          <PiDatabase className="w-5 h-5   text-yellow-500 mb-1" /> ID{" "}
        </div>
      ),
      dataIndex: "id",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={`./preview/${params.id}`}
            // onClick={() => navigate(`./preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 ml-4 h-full"
          >
            {params.id}
          </Link>
        );
      },
    },

    {
      key: "collector_name",
      title: (
        <div className="flex items-center gap-x-1   text-[11px] font-semibold">
          <GoPerson className="w-5 h-5  text-yellow-500" />
          COLLECTOR NAME
        </div>
      ),
      dataIndex: "collector_name",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={`./preview/${params.id}`}
            // onClick={() => navigate(`./preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.collector_name}
          </Link>
        );
      },
    },

    {
      key: "collector_mobile",
      title: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <AiOutlinePhone className="w-5 h-5 text-yellow-500" />
          COLLECTOR MOBILE
        </div>
      ),
      dataIndex: "collector_mobile",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={`./preview/${params.id}`}
            // onClick={() => navigate(`./preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 px-6 h-full"
          >
            {params.collector_mobile}
          </Link>
        );
      },
    },

    {
      key: "collector_whatsapp",
      title: (
        <div className="flex items-center gap-x-1 text-[11px] font-semibold">
          <BiLogoWhatsapp className="w-5 h-5  text-yellow-500" />
          COLLECTOR WHATSAPP{" "}
        </div>
      ),
      dataIndex: "collector_whatsapp",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={`./preview/${params.id}`}
            // onClick={() => navigate(`./preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.collector_whatsapp}
          </Link>
        );
      },
    },

    {
      key: "collector_department",
      title: (
        <div className="flex items-center gap-x-1   text-[11px] font-semibold">
          <BsCollection className="w-5 h-5 text-yellow-500" />
          COLLECTOR DEPARTMENT
        </div>
      ),
      dataIndex: "collector_department",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={`./preview/${params.id}`}
            // onClick={() => navigate(`./preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.collector_department}
          </Link>
        );
      },
    },

    // {
    //   key: "working_day",
    //   title: (
    //     <div className="flex items-center gap-x-1   text-[11px] font-semibold">
    //       <LiaCalendar className="w-5 h-5 text-yellow-500" />
    //       WORKING DAY{" "}
    //     </div>
    //   ),
    //   flex: 0.8,
    //   editable: false,
    //   render: (text,params) => {
    //     return (
    //       <Link to={`./preview/${params.id}`} className="w-full flex items-center gap-x-2 px-8 h-full">
    //         {/* {params.collector_department} */}
    //       </Link>
    //     );
    //   },
    // },

    // {
    //   key: "working_time",
    //   title: (
    //     <div className="flex items-center gap-x-1   text-[11px] font-semibold">
    //       <MdOutlineAccessTime className="w-5 h-5 text-yellow-500" />
    //       WORKING TIME{" "}
    //     </div>
    //   ),
    //   flex: 1,
    //   editable: false,
    //   render: (text,params) => {
    //     return (
    //       <Link to={`./preview/${params.id}`} className="w-full flex items-center gap-x-2 px-8 h-full">
    //         {/* {params.collector_department} */}
    //       </Link>
    //     );
    //   },
    // },

    {
      key: "joining_date",
      title: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <LiaCalendar className="w-5 h-5 text-yellow-500" />
          JOINING DATE{" "}
        </div>
      ),
      dataIndex: "joining_date",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./preview/${params.id}`} className="w-full flex items-center gap-x-2 px-8 h-full">
            {params.joining_date}
          </Link>
        );
      },
    },
    {
      key: "notes",
      title: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <MdOutlineSpeakerNotes className="w-5 h-5 text-yellow-500" />
          NOTES{" "}
        </div>
      ),
      dataIndex: "notes",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./preview/${params.id}`} className="w-full flex items-center gap-x-2 px-8 h-full capitalize">
            {params.notes}
          </Link>
        );
      },
    },

    // {
    //   key: "operations",
    //   flex: 1,
    //   title: <div className="flex items-center gap-x-1  text-[11px] font-semibold">OPERATIONS</div>,
    //   render: (text,params) => {
    //
    //     return (
    //       <div className="flex items-center  gap-x-3 ml-5">
    //         <Link
    //           to={`./preview/${params.id}`}
    //           className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
    //         >
    //           <AiOutlineEye className="w-5 h-5 text-slate-800 " />
    //         </Link>
    //         {/* <button onClick={()=>{setId(params.id);  setConfirm(true); console.log("delete")}}
    //                                     className='flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all'>
    //                                       <AiFillDelete  className='w-5 h-5 text-red-700'/>
    //                            </button> */}
    //       </div>
    //     );
    //   },
    // },
    {
      key: "operations",
      title: "Operations",
      title: (
        <div className=" flex items-center gap-x-1 text-xs ml-5">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),
      dataIndex: "operations",
      editable: false,

      render: (text, params) => {
        return (
          <div className="flex items-center justify-center w-full gap-x-2">
            <Link
              to={`/dashboard/accounting-setting/collector-list/preview/${params.id}`}
              className="flex items-center gap-x-1  bg-yellow-400 px-1.5 py-1 rounded-md hover:bg-yellow-500 active:scale-95 duration-300 transition-all"
            >
              <AiOutlineEye className="w-5 h-5 text-gray-700 " />
            </Link>

            <Link
              to={`/dashboard/accounting-setting/collector-list/create/${params.id}/update`}
              className="flex items-center gap-x-1 bg-green-700 px-1.5 py-1 rounded-md hover:bg-green-800 active:scale-95 duration-300 transition-all"
            >
              <RxUpdate className="w-5 h-5 text-white" />
            </Link>

            <button className="flex items-center gap-x-1 bg-red-800 px-1.5 py-1 rounded-md hover:bg-red-700 active:scale-95 duration-300 transition-all">
              <MdDeleteForever onClick={() => handleDeletePopUp(params)} className="w-5 h-5 text-white" />
            </button>
          </div>
        );
      },
    },
  ];

  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.view_collector"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////
  return (
    <div className="w-full formPage-wrap min-h-screen">
      {/* {confirm &&
    <div className='relative z-50'>
          <div className='absolute flex flex-col items-center justify-center gap-y-2 w-[300px] h-36 bg-white border-gray-300 border top-96 left-[500px] bg-center rounded-md '>
            <p className='text-2xl font-light'>Are You Sure?</p>
            <div className='w-full flex items-center justify-center gap-x-5 text-white'>
                <button onClick={()=>{setConfirm(false); handleDelete(id)}} className='bg-green-700 rounded-md px-3 py-1 hover:scale-105 active:scale-95 duration-300 transition-all'>Yes,I'm Sure</button>
                <button onClick={()=>setConfirm(false)} className='bg-red-700 rounded-md px-3 py-1 hover:scale-105 active:scale-95 duration-300 transition-all'>No,take me back</button>
            </div>
        </div>
    </div>
    } */}

      {collectors && load && !permissionFlag ? (
        <div className="flex items-center justify-center w-full h-full">
          <TopBarProgress /> <Loading />
        </div>
      ) : collectors ? (
        permissionFlag &&
        permission?.map((item) => {
          if (item.name === "room_booking.view_collector" && item.value === true) {
            return (
              <Box>
                <div className="w-full overflow-auto">
                  <ListFilters columns={columns} rows={rows} title="Collectors List" />
                </div>
                {/* <DataGrid
                  rows={rows}
                  columns={columns} 
                  // rowCount={ bookedHubRoomsStore.count}
                  // onSortModelChange={comparator}
                  // columnVisibilityModel={columnVisibility}
                  // onColumnVisibilityModelChange={(visibility) => {
                  //   setColumnVisibility(visibility);
                  //   localStorage.setItem("grid-visibility-booking-list", JSON.stringify(visibility));
                  // }}
                  // paginationModel={{ page: Number(params.page - 1), pageSize: 10 }}
                  // onPaginationModelChange={handlePageChange}
                  // paginationMode="server"
                />*/}
                {deletePopup && (
                  <DeletePopUp
                    name={deletePopup}
                    Func={() => handleDelete(permissionID)}
                    hideFunc={() => {
                      setDeletePopup(false);
                    }}
                  />
                )}
              </Box>
            );
          } else {
            return (
              <div className="w-full h-screen ">
                <NoPermission />
              </div>
            );
          }
        })
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default CollectorsList;
