import React, { useEffect, useMemo, useState } from "react";
import { FaWpforms } from "react-icons/fa6";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { destinationAction, getHotelCityAction } from "../../../redux/actions/searchformAction";
import cookie from "react-cookies";
import { requestOfferTypes } from "../../../redux/actions/addContract";
import {
  createCancellationPolicy,
  getCancellationCharge,
  getCancellationTime,
} from "../../../redux/actions/cancellation";
import { DateRangePicker } from "react-dates";
import { useNavigate, useParams } from "react-router";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import TopBarProgress from "react-topbar-progress-indicator";
import { MdPostAdd } from "react-icons/md";
import { NotificationContainer, NotificationManager } from "react-notifications";
import lightToast from "light-toast";
import Loading from "../Loading/Loading";
import { IoMdAdd } from "react-icons/io";
import { HiOutlineMinusSmall } from "react-icons/hi2";
import { GrUpdate } from "react-icons/gr";
import { getCurrencyTypes_URL } from "../../../urls";
import useData from "../../../customHooks/useData";

const token = cookie.load("access_token");

function reshapeOptions(option) {
  return option
    .split("_")
    .map((word) => word[0].toUpperCase() + word.split("").slice(1).join(""))
    .join(" ");
}

export default function CreateCancellationPolicy() {
  const [chargeForm, setChargeForm] = useState([{ id: 1, selectValue: "", input1: 0, input2: 0 }]);
  const [chargeValue, setChargeValue] = useState("");
  const [daysValue, setDaysValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  useRemoveScroll();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { destinationData } = useSelector((state) => state.SearchFormReducer);
  const [desFlag, setDesFlag] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [hotelFlag, setHotelFlag] = useState(false);
  const [charge, setCharge] = useState([]);
  const [cancellationTime, setCancellationTime] = useState([]);
  const [offerTypes, setOfferTypes] = useState([]);
  const [chosenCity, setChosenCity] = useState(null);
  const [chosenHotel, setChosenHotel] = useState(null);
  const [chosenOffer, setChosenoffer] = useState(null);
  const [chosenCharge, setChosenCharge] = useState([]);
  const [chosenCancellationTime, setChosenCancellationTime] = useState(null);
  const [dateRange, setDateRange] = useState([{ startDate: null, endDate: null }]);
  const [focusedInput, setFocusedInput] = useState([null]);
  const [btnFlag, setBtnFlag] = useState(false);
  const [chargeArrData, setChargeArr] = useState([]);
  const [selectedCurrency, setSelectedCurrence] = useState();
  const params = useParams();
  const citiesArr = useMemo(
    () => destinationData.map((city) => ({ label: city.name, value: city.id })),
    [destinationData],
  );

  const hotelsArr = useMemo(() => hotels.map((hotel) => ({ label: hotel.name, value: hotel.id })), [hotels]);
  const chargeArr = useMemo(() => charge.map((elem) => ({ label: reshapeOptions(elem), value: elem })), [charge]);
  const offerTypesArr = useMemo(() => offerTypes.map((type) => ({ label: type.name, value: type.id })), [offerTypes]);
  const cancellationTimeArr = useMemo(
    () => cancellationTime.map((elem) => ({ label: reshapeOptions(elem), value: elem })),
    [cancellationTime],
  );
  useEffect(() => {
    setChargeArr(charge.map((elem) => ({ label: reshapeOptions(elem), value: elem })));
  }, [charge]);
  console.log(chargeArr);
  const addCharge = () => {
    const selectedValues = chargeForm.map((form) => form.selectValue);
    const updatedChargeArr = chargeArrData.filter((option) => !selectedValues.includes(option.value));

    if (chargeForm.length < chargeArr.length) {
      const newId = chargeForm[chargeForm.length - 1].id + 1;
      setChargeForm([
        ...chargeForm,
        {
          id: newId,
          selectValue: "",
          input1: 0,
          input2: 0,
        },
      ]);
      setChargeArr(updatedChargeArr); // Update available options
    }
  };

  const handleChange = (id, field, value) => {
    const updatedForms = chargeForm.map((form) => {
      if (form.id == id) {
        return { ...form, [field]: value };
      }
      return form;
    });
    setChargeForm(updatedForms);
  };

  const removeCharge = (id) => {
    const removedForm = chargeForm.find((form) => form.id === id);
    setChosenCharge((prevChosenCharge) => [...prevChosenCharge.filter((value) => value !== removedForm.selectValue)]);

    if (chargeForm.length === 1) {
      return;
    }

    const selectedForm = chargeForm.filter((form) => form.id !== id);
    setChargeForm(selectedForm);
  };

  const handleSelectedChange = (id, value) => {
    handleChange(id, "selectValue", value);

    // Update available options
    const updatedChargeArr = chargeArr.filter(
      (option) => !chargeForm.some((form) => form.selectValue === option.value),
    );
    setChargeArr(updatedChargeArr);
  };

  useEffect(() => {
    dispatch(
      destinationAction(token, "en", (result) => {
        setDesFlag(true);
      }),
    );
    dispatch(requestOfferTypes((resp) => setOfferTypes(resp)));
    dispatch(getCancellationCharge((resp) => setCharge(resp)));
    dispatch(getCancellationTime((resp) => setCancellationTime(resp)));
  }, []);

  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
  };

  const currencies = useData(getCurrencyTypes_URL);

  let currenciesArr = currencies ? currencies.map((currency) => ({ value: currency, label: currency })) : [];

  const cancelCharge = chargeForm.map((form) => ({
    charge: form.selectValue,
    num_days: form.input1,
    price: form.input2,
  }));

  function handleSubmit(e) {
    e.preventDefault();
    setBtnFlag(true);
    const data = {
      cancellation_valid_from: dateRange.startDate.format("YYYY-MM-DD"),
      cancellation_valid_to: dateRange.endDate.format("YYYY-MM-DD"),

      cancellation_charge: cancelCharge,
      notes: e.target.notes.value,
      offer_type: chosenOffer.value,
      hotel: chosenHotel.value,
      cancellation_time: chosenCancellationTime.value,
      currency: selectedCurrency,
    };

    dispatch(
      createCancellationPolicy(
        data,
        // success function
        () => {
          setBtnFlag(false);
          lightToast.success("Your Cancellation Policy was created successfully");
          navigate("/dashboard/cancellation-policy/cancellation-policy-list");
        },
        // error function
        () => {
          setBtnFlag(false);
          NotificationManager.error("This Policy already Exists");
          navigate("/dashboard/cancellation-policy/cancellation-policy-list");
        },
      ),
    );
  }

  return (
    <div className=" formPage-wrap">
      <div className="flex flex-col items-center justify-center px-6 h-full w-full">
        {desFlag ? (
          <div className="  w-full  ">
            <div className=" flex justify-center ">
              <h2 className="header-h2">
                <FaWpforms className="w-5 h-5" />
                Create Cancellation Policy
              </h2>
            </div>
            <form className="form-wrap  w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
              <div className="w-full flex flex-col justify-center items-center gap-y-2">
                <div className="input-par flex items-center">
                  <div className="input-chil flex flex-col ">
                    <label className="  text-gray-900 font-semibold ">Date Range</label>
                    <DateRangePicker
                      startDate={dateRange.startDate}
                      startDateId="startDate"
                      endDate={dateRange.endDate}
                      endDateId="endDate"
                      onDatesChange={handleDateChange}
                      focusedInput={focusedInput}
                      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                      minimumNights={1} // Allow same-day selection
                      daySize={30}
                      required
                    />
                  </div>
                  <div className="input-chil">
                    <label className="  text-gray-900 font-semibold ">City</label>
                    <Select
                      required
                      className=" w-full  h-10 text-gray-800 font-semibold  border rounded-lg bg-white "
                      options={citiesArr}
                      placeholder="Select City"
                      onChange={(e) => {
                        setHotelFlag(true);
                        setChosenCity(e);

                        dispatch(
                          getHotelCityAction(token, e.value, (resp) => {
                            if (resp.status === 200) {
                              setHotelFlag(false);
                              setHotels(resp?.data);
                            } else {
                              setHotelFlag(false);
                            }
                          }),
                        );
                      }}
                      value={chosenCity}
                    />
                  </div>
                </div>

                <label className="  text-gray-900 font-semibold ">Hotel</label>
                {hotelFlag ? (
                  <div className="w-full flex items-center justify-center ">
                    <ButtonLoading /> <TopBarProgress />
                  </div>
                ) : (
                  <Select
                    className=" w-full  h-10 text-gray-800 font-semibold  border rounded-lg bg-white "
                    options={hotelsArr}
                    placeholder="Select Hotel"
                    onChange={(e) => {
                      setChosenHotel(e);
                    }}
                    value={chosenHotel}
                    required
                  />
                )}
                <label className="  text-gray-900 font-semibold ">Offer Type</label>
                <Select
                  className=" w-full  h-10 text-gray-800 font-semibold  border rounded-lg bg-white "
                  options={offerTypesArr}
                  placeholder="Select Offer Type"
                  onChange={(e) => {
                    setChosenoffer(e);
                  }}
                  value={chosenOffer}
                  required
                />

                {chargeForm?.map((form) => (
                  <div key={form.id} className="flex flex-col gap-2 w-full border rounded-md shadow-md p-2">
                    <div className="flex gap-2 w-full">
                      <div className=" flex flex-col gap-2 flex-1">
                        <label htmlFor="">Charge</label>

                        <select
                          required
                          className=" px-2 h-10 text-gray-800 font-semibold border rounded-lg bg-white w-full "
                          placeholder="Charge"
                          onChange={(e) => {
                            handleSelectedChange(form.id, e.target.value);
                            // setChargeValue(e.target.value);
                          }}
                          value={form.selectValue}
                        >
                          <option defaultValue="--select option--"></option>
                          {chargeArr?.map((option) => (
                            <option
                              key={option.value}
                              value={option.value}
                              disabled={chargeForm.some((form) => form.selectValue === option.value)}
                            >
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>

                      {form?.selectValue === "first_night" && (
                        <div className="flex items-center gap-2 flex-1">
                          <div className="flex flex-col gap-2 w-full">
                            <label htmlFor="">Number of days</label>

                            <input
                              required
                              type="number"
                              className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                              placeholder="Number of days"
                              name="num_days"
                              onChange={(e) => handleChange(form.id, "input1", Number(e.target.value))}
                            />
                          </div>
                        </div>
                      )}
                      {form?.selectValue === "first_two_night" && (
                        <div className="flex items-center gap-2 flex-1">
                          <div className="flex flex-col gap-2 w-full">
                            <label htmlFor="">Number of days</label>

                            <input
                              required
                              type="number"
                              className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                              placeholder="Number of days"
                              name="num_days"
                              onChange={(e) => handleChange(form.id, "input1", Number(e.target.value))}
                            />
                          </div>
                        </div>
                      )}
                      {form?.selectValue === "first_three_night" && (
                        <div className="flex items-center gap-2 flex-1">
                          <div className="flex flex-col gap-2 w-full">
                            <label htmlFor="">Number of days</label>

                            <input
                              required
                              type="number"
                              className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                              placeholder="Number of days"
                              name="num_days"
                              onChange={(e) => handleChange(form.id, "input1", Number(e.target.value))}
                            />
                          </div>
                        </div>
                      )}
                      {form?.selectValue === "whole_period" && (
                        <div className="flex items-center gap-2 flex-1">
                          <div className="flex flex-col gap-2 w-full">
                            <label htmlFor="">Number of days</label>

                            <input
                              required
                              type="number"
                              className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                              placeholder="Number of days"
                              name="num_days"
                              onChange={(e) => handleChange(form.id, "input1", Number(e.target.value))}
                            />
                          </div>
                        </div>
                      )}
                      {form?.selectValue === "percentage_amount" && (
                        <div className="flex items-center gap-2 flex-1  max-md:flex-col">
                          <div className="flex flex-col gap-2">
                            <label htmlFor="">Number of days</label>

                            <input
                              required
                              type="number"
                              className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                              placeholder="Number of days"
                              name="num_days"
                              onChange={(e) => handleChange(form.id, "input1", Number(e.target.value))}
                            />
                          </div>
                          <div className="flex flex-col gap-2">
                            <label htmlFor="">Percentage</label>

                            <input
                              required
                              type="number"
                              className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                              placeholder="percentage"
                              name="price"
                              onChange={(e) => handleChange(form.id, "input2", Number(e.target.value))}
                            />
                          </div>
                        </div>
                      )}
                      {form?.selectValue === "fixed_amount" && (
                        <div className="flex items-center gap-2 flex-1 justify-center  w-full max-md:flex-col">
                          <div className="flex flex-col gap-2 flex-1">
                            <label htmlFor="">Number of Days</label>

                            <input
                              required
                              type="number"
                              className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                              placeholder="Number of days"
                              name="num_days"
                              onChange={(e) => handleChange(form.id, "input1", Number(e.target.value))}
                            />
                          </div>
                          <div className=" flex flex-col gap-2 flex-1">
                            <label htmlFor="">Price</label>
                            <input
                              type="number"
                              className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                              placeholder="Price"
                              name="price"
                              onChange={(e) => handleChange(form.id, "input2", Number(e.target.value))}
                            />
                          </div>
                          <div className="flex flex-col gap-2 flex-1">
                            <label htmlFor="">Currency</label>

                            <Select
                              required
                              name="currency"
                              options={currenciesArr}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,

                                  borderBottom: "none",
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  boxShadow: "none",
                                  borderRadius: "0",
                                }),
                              }}
                              // value={[{ label: values.currency, value: values.currency }]}
                              className={`  border rounded-md text-sm md:text-base    `}
                              onChange={(e) => setSelectedCurrence(e.label)}
                              placeholder="Currency"
                              // value={selectedCurrency}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-full flex  justify-end items-center gap-4  ">
                      {chargeForm.length < chargeArr.length && (
                        <button
                          type="button"
                          onClick={addCharge}
                          className="flex w-full max-w-[fit-content]   bg-green-500 rounded-full text-white"
                        >
                          <IoMdAdd className="text-4xl" />
                        </button>
                      )}
                      {chargeForm.length > 1 && (
                        <button
                          type="button"
                          onClick={() => removeCharge(form.id)}
                          className="flex w-full max-w-[fit-content]   bg-red-500 rounded-full text-white"
                        >
                          <HiOutlineMinusSmall className="text-4xl" />
                        </button>
                      )}
                    </div>
                  </div>
                ))}

                <label className="  text-gray-900 font-semibold ">Time of Cancellation</label>
                <Select
                  required
                  className=" w-full  h-10 text-gray-800 font-semibold  border rounded-lg bg-white "
                  options={cancellationTimeArr}
                  menuPlacement="top"
                  placeholder="Time of Cancellation"
                  onChange={(e) => {
                    setChosenCancellationTime(e);
                  }}
                  value={chosenCancellationTime}
                />

                <label className="  text-gray-900 font-semibold ">Notes</label>
                <textarea
                  className="w-full px-3 py-1 h-10  border-2 border-black border-opacity-20 text-gray-800 font-semibold  rounded-lg bg-white"
                  name="notes"
                />
                <div className=" mt-6">
                  {params.id ? (
                    <button disabled={btnFlag} type="Submit" className="updateButton">
                      {btnFlag ? (
                        <>
                          <ButtonLoading /> <TopBarProgress />
                        </>
                      ) : (
                        <GrUpdate className="w-5 h-5" />
                      )}{" "}
                      Update
                    </button>
                  ) : (
                    <button disabled={btnFlag} type="Submit" className="addButton">
                      {btnFlag ? (
                        <>
                          <ButtonLoading /> <TopBarProgress />
                        </>
                      ) : (
                        <MdPostAdd className="w-5 h-5" />
                      )}{" "}
                      Create
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="w-full flex items-center justify-center h-screen">
            <Loading /> <TopBarProgress />
          </div>
        )}
      </div>
      <NotificationContainer />
    </div>
  );
}
