import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardCount from "../../organisms/Cart/CardCount";
import CardItem from "../../organisms/Cart/CardItem";
import CardEmpty from "../../organisms/Cart/CardEmpty";
import { statusCardAction, totalPirce } from "../../../redux/actions/bookingOrderAction";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WhatsAppMessage from "../../organisms/WhatsAppMessage/WhatsAppMessage";
import { FaWhatsapp } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import EmailMessage from "../../organisms/EmailMessage/EmailMessage";
import usePermissions from "../../../customHooks/usePermissions";
import { Spinner } from "flowbite-react";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";

const BookingOrder = ({ permissionFlag, permissionsError, permissionswhatsUpemail }) => {
  const dispatch = useDispatch();
  const { cardStatus } = useSelector((state) => state.cardReducer);
  const { cardData } = useSelector((state) => state.cardReducer);
  const { cardTotalPrice } = useSelector((state) => state.cardReducer);
  const [cardToggle, setCardToggle] = useState(true);
  const [t, i18n] = useTranslation();
  const localeCode = t("localeCode");
  const [openModal, setOpenModal] = useState(false);
  const [openModalEmail, setopenModalEmail] = useState(false);

  useEffect(() => {
    dispatch(totalPirce(cardData));
  }, [cardData]);

  // const {selectedDataFromForm} = useSelector((state) =>state.SelectedSearchReducer); // to send this with data multi booking page
  // const {area , city ,  from_date , nights , to_data    } = selectedDataFromForm;

  return (
    <div dir={t("dir")}>
      <div
        className={` fixed ${
          cardStatus ? "top-0 left-0 right-0 bottom-0 " : ""
        }  blur-effect-theme   w-full opacity-100 z-[250] overflow-x-hidden `}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            dispatch(statusCardAction(!cardStatus));
          }
        }}
      >
        <div
          className={`bg-white h-screen max-w-2xl w-full absolute right-0     ${
            cardStatus
              ? "opacity-100 visible   right-0 transition-all duration-400 transform "
              : "opacity-0 invisible -right-full translate-x-8 transition-all duration-400 transform "
          } `}
        >
          <CardCount />
          {cardData?.length === 0 ? (
            <CardEmpty />
          ) : (
            <div>
              <div className=" flex w-full justify-center">
                <div
                  dir={t("dir")}
                  className={`
                    font-sans max-w-2xl  flex flex-col items-center justify-start mt-5 gap-y-7 lg:gap-y-5 px-2 
                     overflow-y-scroll ${permissionswhatsUpemail?.some((item) => item.value) ? "md:h-[65vh] h-[53vh]" : "md:h-[75vh] h-[70vh] "}    w-full scroll-smooth md:py-3
                  `}
                >
                  {cardData?.map((item, index) => (
                    <CardItem cardData={item} key={index} />
                  ))}
                </div>
              </div>

              <div className="flex flex-col mt-4 w-full gap-y-2 h-full  ">
                <div className="flex items-center justify-start gap-x-5  ml-5 ">
                  <h1 className="text-sm sm:text-base font-semibold uppercase text-yellow-600">{t("subTotal")} </h1>
                  <div className="text-lg md:text-2xl rounded  text-slate-100 px-2 py-0.5  bg-[#002244]  flex  items-end gap-x-1">
                    {/* {new Intl.NumberFormat(localeCode).format(cardData)}{" "} */}
                    {new Intl.NumberFormat(localeCode).format(
                      cardData.reduce((accumulator, currentItem) => {
                        return accumulator + currentItem.price * currentItem.quantity;
                      }, 0),
                    )}
                    <span className="text-xs">{t(cardData[0].currency_type)}</span>{" "}
                  </div>
                </div>

                {/* <p className="text-sm font-medium text-center text-black">Taxes and Shipping Will Calculate At Shipping</p> */}
                <Link
                  to={"/multireservation"}
                  onClick={() => {
                    dispatch(statusCardAction(!cardToggle));
                  }}
                  className=" w-full  mb-2 py-3 bg-[#002244]   text-white text-center  hover:bg-blue-900 active:scale-95 duration-300 transition-all rounded-md"
                >
                  {t("bookNow")}
                </Link>

                {permissionFlag ? (
                  permissionswhatsUpemail?.some((item) => item.value) && (
                    <>
                      <button
                        onClick={() => setOpenModal(true)}
                        className=" w-full py-3 bg-[#075e54] flex justify-center items-center gap-2   text-white text-center  hover:bg-[#128c7e] active:scale-95 duration-300 transition-all rounded-md"
                      >
                        <FaWhatsapp className=" text-2xl text-[#25d366]" />
                        {t("sendwhatsapp")}
                      </button>

                      <button
                        onClick={() => setopenModalEmail(true)}
                        className=" w-full py-3 bg-indigo-800 hover:bg-indigo-900 flex justify-center items-center gap-2   text-white text-center  active:scale-95 duration-300 transition-all rounded-md"
                      >
                        <MdEmail className=" text-2xl text-white" />
                        {t("sendEmail")}
                      </button>
                    </>
                  )
                ) : (
                  <div className="w-full flex justify-center mb-[0.1rem]">
                    <Spinner aria-label="Default status example" />
                  </div>
                )}
              </div>
              {openModal && <WhatsAppMessage openModal={openModal} setOpenModal={setOpenModal} />}
              {openModalEmail && <EmailMessage openModalEmail={openModalEmail} setopenModalEmail={setopenModalEmail} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingOrder;
