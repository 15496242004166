import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Card } from "flowbite-react";
import React, { useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { GrSubtractCircle } from "react-icons/gr";

const PresonChild = ({ roomDetails, setRoomDetails }) => {
  const [roomCount, setRoomCount] = useState(1);

  const [childrenContArray, setchildrenContArray] = useState(Array.from({ length: roomCount }, () => 0));
  const [adultContArray, setAdultContArray] = useState(Array.from({ length: roomCount }, () => 1));
  const [adultPerson, setAdultPerson] = useState(1);

  const handleAdultsChange = (index, value) => {
    const updatedAdultContArray = [...adultContArray];
    updatedAdultContArray[index] = value;
    setAdultContArray(updatedAdultContArray);

    const updatedRoomDetails = [...roomDetails];
    updatedRoomDetails[index].adults = value;
    setRoomDetails(updatedRoomDetails);

    setAdultPerson(value);
  };

  const handleChildrenChange = (index, value) => {
    const updatedChildrenContArray = [...childrenContArray];
    updatedChildrenContArray[index] = value;
    setchildrenContArray(updatedChildrenContArray);

    const updatedRoomDetails = [...roomDetails];
    updatedRoomDetails[index].children = updatedRoomDetails[index].children.slice(0, value);
    if (value > updatedRoomDetails[index].children.length) {
      updatedRoomDetails[index].children = [
        ...updatedRoomDetails[index].children,
        ...new Array(value - updatedRoomDetails[index].children.length).fill(0),
      ];
    }
    setRoomDetails(updatedRoomDetails);
  };

  const handleChildAgeChange = (roomIndex, childIndex, event) => {
    const value = parseInt(event.target.value);
    const updatedRoomDetails = [...roomDetails];
    updatedRoomDetails[roomIndex].children[childIndex] = value;
    setRoomDetails(updatedRoomDetails);
  };

  console.log(roomDetails, "roomDetails");

  return (
    <div className="w-full">
      {roomDetails.map((room, roomIndex) => (
        <Card key={roomIndex} className="w-full">
          <div className="flex flex-col  gap-2 w-full items-center">
            <div className="flex justify-between gap-4 w-full items-center mb-2 ">
              <div className="flex flex-col">
                <span className="text-base font-medium">
                  adults <span className="text-sm text-gray-400 font-medium">(12+)</span>
                </span>
              </div>
              <div className="flex gap-2">
                <GrSubtractCircle
                  onClick={() => handleAdultsChange(roomIndex, Math.max(1, adultContArray[roomIndex] - 1))}
                  className="text-[#002244] text-2xl cursor-pointer"
                />
                {adultContArray[roomIndex]}
                <FiPlusCircle
                  onClick={() => handleAdultsChange(roomIndex, Math.min(12, adultContArray[roomIndex] + 1))}
                  className="text-[#002244] text-2xl cursor-pointer"
                />
              </div>
            </div>

            <div className="flex justify-between gap-4 w-full items-center pt-3 border-t ">
              <div className="flex flex-col">
                <span className="text-base font-medium">
                  children <span className="text-sm text-gray-400 font-medium">(0-11)</span>
                </span>
              </div>
              <div className="flex gap-2">
                <GrSubtractCircle
                  onClick={() => handleChildrenChange(roomIndex, Math.max(0, childrenContArray[roomIndex] - 1))}
                  className="text-[#002244] text-2xl cursor-pointer"
                />
                {childrenContArray[roomIndex]}
                <FiPlusCircle
                  onClick={() => handleChildrenChange(roomIndex, Math.min(5, childrenContArray[roomIndex] + 1))}
                  className="text-[#002244] text-2xl cursor-pointer"
                />
              </div>
            </div>

            <div className="flex flex-wrap gap-[0.8rem] w-full justify-center  text-center items-center">
              {room?.children?.map((childAge, childIndex) => (
                <div key={childIndex} className="flex flex-col w-[6rem]  ">
                  <FormControl sx={{ m: 1, minWidth: 10 }} size="small" className="w-[6rem]">
                    <InputLabel id="demo-select-small-label">
                      {"agechild"}
                      {childIndex + 1}{" "}
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={childAge !== undefined ? childAge : "Age needed"}
                      onChange={(event) => handleChildAgeChange(roomIndex, childIndex, event)}
                      label={"agechild" + " " + (childIndex + 1)}
                      size="small"
                    >
                      <MenuItem value={0}>
                        <em>less than one</em>
                      </MenuItem>
                      {[...Array(11).keys()].map((age) => (
                        <MenuItem key={age + 1} value={age + 1}>
                          {age + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              ))}
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default PresonChild;
