import React, { useEffect, useState } from "react";
import { FaWpforms } from "react-icons/fa6";
import { useParams } from "react-router";
import addBookingRequest, { getBookingRequestTypeChoices } from "../../../redux/actions/bookingRequests";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import Select from "react-select";
// import jwtDecode from "jwt-decode";
import toast from "react-hot-toast";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../NoPermission/NoPermission";

const token = cookie.load("access_token");
// const decodedToken = jwtDecode(token);
// const user = decodedToken.user_id;

export default function AddBookingRequest() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const requestTypes = useSelector((state) => state.bookingRequestTypeChoice);

  const requestTypesArr = requestTypes.map((typeObj) => ({ label: typeObj.types, value: typeObj.id }));
  const [requestTypeChoice, setRequestTypeChoice] = useState({ label: null, value: null });
  const [priorityChoice, setPriorityChoice] = useState({ label: "Normal Priorty", value: "normal_priority" });
  const priority = [
    { label: "Urgent Priority", value: "urgent_priority" },
    { label: "Normal Priorty", value: "normal_priority" },
  ];

  useEffect(() => {
    dispatch(getBookingRequestTypeChoices());
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      booked_room: id,
      client_name: 1,
      description: e.target.description.value,
      request_type: requestTypeChoice.value,
      request_priority: priorityChoice.value,
    };
    if (e.target.other_note) {
      data.other_note = e.target.other_note.value;
    }
    const finalData = new FormData();
    for (let elem in data) {
      finalData.append(`${elem}`, data[elem]);
    }
    const files = formData.getAll("attachments");
    for (let file of files) {
      finalData.append("attachments", file);
    }
    dispatch(addBookingRequest(finalData, () => toast.success("Your Request was Successfull")));
  }

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["room_booking.add_bookingrequest", "room_booking.view_bookingrequest"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  return (
    <div className="formPage-wrap ">
      {permissionFlag ? (
        permission.map((item) => {
          if (item.name === "room_booking.add_bookingrequest" && item.value === true) {
            return (
              <div className="flex flex-col items-center justify-center h-full w-full">
                <div className=" w-full ">
                  <div className=" flex justify-center ">
                    <h2 className="header-h2">
                      <FaWpforms className="w-5 h-5" />
                      Add Booking request
                    </h2>
                  </div>
                  <form className="form-wrap  w-full" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      {/* <label className=" flex text-gray-600 ">Title</label>
              <input
                placeholder="Title"
                name="title"
                type="text"
                required
                onChange={(e) => {}}
                className=" w-full px-3 h-10  border rounded-lg bg-white"
              /> */}
                      <label className=" flex text-gray-600 ">Request Type</label>
                      <Select
                        className=" w-full  h-10  border rounded-lg bg-white "
                        options={requestTypesArr}
                        onChange={(e) => setRequestTypeChoice(e)}
                      />
                      {requestTypeChoice.label === "Other" && (
                        <input
                          placeholder="Other"
                          name="other_note"
                          type="text"
                          required
                          onChange={(e) => {}}
                          className=" w-full px-3 h-10  border rounded-lg bg-white"
                        />
                      )}

                      <label className=" flex text-gray-600 ">Request Priority</label>
                      <Select
                        className=" w-full  h-10  border rounded-lg bg-white "
                        options={priority}
                        value={priorityChoice}
                        onChange={(e) => setPriorityChoice(e)}
                      />

                      <label className=" flex text-gray-600 ">Description</label>
                      <textarea
                        name="description"
                        className=" w-full px-3 h-40  border rounded-lg bg-white "
                      ></textarea>

                      <label className=" flex text-gray-600 ">Attachments</label>
                      <input
                        name="attachments"
                        type="file"
                        required
                        onChange={(e) => {}}
                        className=" w-full px-3 h-10  border rounded-lg bg-white"
                        multiple
                      />

                      <button
                        type="Submit"
                        className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            );
          } else {
            <div className="w-full h-screen">
              <NoPermission />
            </div>;
          }
        })
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
