import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import cookie from "react-cookies";
import { AiOutlineEye, AiOutlineNumber } from "react-icons/ai";
import Loading from "../Loading/Loading";
import { getBankListAction } from "../../../redux/actions/cashAction";
import { GiMoneyStack } from "react-icons/gi";
import { BsCurrencyExchange } from "react-icons/bs";
import { LiaCalendar } from "react-icons/lia";
import { BiTransfer } from "react-icons/bi";
import TopBarProgress from "react-topbar-progress-indicator";
import { MdDeleteForever, MdOutlineLocationOn, MdOutlineSettings } from "react-icons/md";
import { TbNotes } from "react-icons/tb";
import { allPosMachineAction, allPosMachineActionDelete } from "../../../redux/actions/posMachineAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import { RxUpdate } from "react-icons/rx";
import usePermissions from "../../../customHooks/usePermissions";
import DeletePopUp from "../../molecules/DeletePopUp/DeletePopUp";
import { toast } from "react-toastify";
import ListFilters from "../../../utils/ListFilters";

const PosMachineList = () => {
  //Permission
  const [permissions, permissionsFlag] = usePermissions([
    "permissions_management.change_searchpermission",
    "permissions_management.view_searchpermission",
    "permissions_management.delete_searchpermission",
  ]);

  const [deletePopup, setDeletePopup] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = cookie.load("access_token");
  const { allPosMachineData } = useSelector((state) => state.posMachineReducer);
  const { allPosMachineFlag } = useSelector((state) => state.posMachineReducer);
  const { bankData } = useSelector((state) => state.cashReducer);
  const [machine, setMachine] = useState(false);
  const [permissionID, setPermissionID] = useState("");
  const [dataMachine, setDataMachine] = useState();

  const fetchData = () => {
    dispatch(
      allPosMachineAction(token, (result) => {
        if (result.status === 200) {
          setMachine(true);
          setDataMachine(result.data);
        } else {
          toast.error(result.data.data || `Error in Fetching data, error status: ${result.status} `);
        }
      }),
    );
    dispatch(getBankListAction(token));
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (!allPosMachineData) {
      fetchData();
    }
  }, [allPosMachineData]);

  function handleDeletePopUp(params) {
    setDeletePopup(`Permission for ${params.location}`);
    setPermissionID(params.id);
  }

  let Bank = bankData
    ? bankData.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];

  const handleDelete = (permissionID) => {
    dispatch(
      allPosMachineActionDelete(token, permissionID, (result) => {
        if (result.status === 204) {
          fetchData();
        }
      }),
    );
    setDeletePopup(false);
  };
  const rows = dataMachine ? (
    dataMachine?.map((item, index) => {
      return {
        id: item.id,
        bank: item?.bank?.name,
        mid_number: item.mid_number,
        currency: item.currency,
        // name:item.name ,
        location: item.location,
        notes: item.notes,
        created_at: item.created_at.slice(0, 10),
        updated_at: item.updated_at,
      };
    })
  ) : (
    <Loading />
  );
  const columns = [
    {
      key: "id",
      title: (
        <div className="flex items-center gap-x-1">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
      dataIndex: "id",
      editable: false,
      render: (text, params) => {
        return <div className="ml-4">{params.id}</div>;
      },
    },

    {
      key: "bank",
      title: (
        <div className="flex items-center gap-x-1">
          <GiMoneyStack className="w-5 h-5  text-yellow-500" />
          BANK
        </div>
      ),
      dataIndex: "bank",
      editable: false,
      render: (text, params) => {
        const bankTable = Bank ? Bank.filter((item) => item.value === params.bank) : [];
        return (
          <div className="flex items-center justify-start gap-x-5">
            {/* {bankTable && bankTable?.map((item) => item.label)} */}
          </div>
        );
      },
    },

    {
      key: "mid_number",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <AiOutlineNumber className="w-5 h-5  text-yellow-500" />
          MID NUMBER
        </div>
      ),
      dataIndex: "mid_number",
      editable: false,
      render: (text, params) => {
        return <div className="ml-10">{params.mid_number}</div>;
      },
    },

    {
      key: "currency",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <BsCurrencyExchange className="w-5 h-5  text-yellow-500" />
          CURRENCY
        </div>
      ),

      dataIndex: "currency",
      editable: false,
      render: (text, params) => {
        return <div className="ml-10">{params.currency}</div>;
      },
    },
    {
      key: "location",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <MdOutlineLocationOn className="w-5 h-5  text-yellow-500" />
          LOCATION
        </div>
      ),
      dataIndex: "location",
      editable: false,
      render: (text, params) => {
        return <div className="ml-8">{params.location}</div>;
      },
    },
    {
      key: "notes",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <TbNotes className="w-5 h-5  text-yellow-500" />
          NOTES
        </div>
      ),
      dataIndex: "notes",
      editable: false,
      render: (text, params) => {
        return <div className="ml-5">{params.notes}</div>;
      },
    },

    {
      key: "created_at",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          CREATED DATE
        </div>
      ),
      dataIndex: "created_at",
      editable: false,
      render: (text, params) => {
        return <div className="ml-10">{params.created_at}</div>;
      },
    },
    {
      key: "updated_at",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          UDDATED DATE
        </div>
      ),

      dataIndex: "updated_at",
      editable: false,
      render: (text, params) => {
        return <div className="ml-10">{params.updated_at}</div>;
      },
    },
    {
      key: "operations",
      title: "Operations",
      title: (
        <div className=" flex items-center gap-x-1 text-xs ml-5">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),
      dataIndex: "operations",
      editable: false,

      render: (text, params) => {
        return (
          <div className="flex items-center justify-center w-full gap-x-2">
            {permissions?.map((item) => {
              if (item.name === "permissions_management.change_searchpermission" && item.value === true) {
                return (
                  <Link
                    to={`/dashboard/account-list/posmachinelist/${params.id}`}
                    className="flex items-center gap-x-1  bg-yellow-400 px-1.5 py-1 rounded-md hover:bg-yellow-500 active:scale-95 duration-300 transition-all"
                  >
                    <AiOutlineEye className="w-5 h-5 text-gray-700 " />
                  </Link>
                );
              } else if (item.name === "permissions_management.view_searchpermission" && item.value === true) {
                return (
                  <Link
                    to={`/dashboard/account-list/posmachinelist/create/${params.id}/update`}
                    className="flex items-center gap-x-1 bg-green-700 px-1.5 py-1 rounded-md hover:bg-green-800 active:scale-95 duration-300 transition-all"
                  >
                    <RxUpdate className="w-5 h-5 text-white" />
                  </Link>
                );
              } else if (item.name === "permissions_management.delete_searchpermission" && item.value === true) {
                return (
                  <button className="flex items-center gap-x-1 bg-red-800 px-1.5 py-1 rounded-md hover:bg-red-700 active:scale-95 duration-300 transition-all">
                    <MdDeleteForever onClick={() => handleDeletePopUp(params)} className="w-5 h-5 text-white" />
                  </button>
                );
              }
            })}
          </div>
        );
      },
    },
  ];
  /////permission/////////
  const permissionData = {
    permission_codes: ["accounting.view_posmachinetransaction"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////
  return (
    <div className="w-full formPage-wrap">
      <>
        {!allPosMachineData && !permissionFlag ? (
          <div className="w-full h-full flex items-center justify-center">
            <TopBarProgress /> <Loading />
          </div>
        ) : permissionFlag && machine ? (
          permission?.map((item) => {
            if (item.name === "accounting.view_posmachinetransaction" && item.value === true) {
              return (
                <>
                  <div className=" w-full overflow-x-auto ">
                    <ListFilters columns={columns} rows={rows} title="POS Machine List" />
                  </div>
                  {deletePopup && (
                    <DeletePopUp
                      name={deletePopup}
                      Func={() => handleDelete(permissionID)}
                      hideFunc={() => {
                        setDeletePopup(false);
                      }}
                    />
                  )}
                </>
              );
            } else {
              return (
                <div className="w-full h-screen">
                  <NoPermission />
                </div>
              );
            }
          })
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <TopBarProgress /> <Loading />
          </div>
        )}
      </>
    </div>
  );
};

export default PosMachineList;
