import React, { useCallback } from "react";
import { ErrorMessage, useFormikContext } from "formik";
import { FieldErrorText, FormLabel } from "../../../customComponents/FormComponents";
import Select from "react-select";

function ChildrenAssistantRoomSelector({ selectedRooms }) {
  const { values, setFieldValue } = useFormikContext();

  const handleRoomSelection = useCallback(
    (newValue, actionMeta) => {
      const { action, option, removedValue } = actionMeta;

      switch (action) {
        case "select-option": {
          const value = option.value;
          if (value === "all") {
            setFieldValue("rooms", selectedRooms);
            setFieldValue("allRooms", true);
          } else {
            setFieldValue("rooms", [...values.rooms, value]);
          }
          break;
        }

        case "remove-value":
        case "pop-value": {
          const value = removedValue.value;
          if (value === "all") {
            setFieldValue("rooms", []);
            setFieldValue("allRooms", false);
          } else {
            setFieldValue(
              "rooms",
              values.rooms.filter((room) => room !== value),
            );
          }
          break;
        }

        case "clear": {
          setFieldValue("allRooms", false);
          setFieldValue("rooms", []);
          break;
        }

        default:
          break;
      }
    },
    [selectedRooms, setFieldValue, values.rooms],
  );

  return (
    <>
      <FormLabel label={"Rooms"} htmlFor={"rooms"} />
      <Select
        value={
          values.allRooms
            ? [{ label: "All Rooms", value: "all" }]
            : values.rooms.map((room) => ({
                value: room,
                label: room.name,
              }))
        }
        isMulti
        placeholder="Select rooms"
        name={"rooms"}
        id={"rooms"}
        options={
          values.allRooms
            ? [{ label: "All", value: "all" }]
            : [
                { value: "all", label: "All rooms" },
                ...selectedRooms?.map((room) => ({
                  value: room,
                  label: room.name,
                })),
              ]
        }
        onChange={handleRoomSelection}
      />
      <ErrorMessage name="rooms" render={(msg) => <FieldErrorText message={msg} />} />
    </>
  );
}

export default ChildrenAssistantRoomSelector;
