import { BsFiletypeTxt, BsQuestionSquare } from "react-icons/bs";
import { FaRegFilePdf } from "react-icons/fa";
import { GrDocumentWord } from "react-icons/gr";
import { SiMicrosoftexcel } from "react-icons/si";
import { GoImage } from "react-icons/go";
import { toast } from "react-toastify";

// Function to get the icon based on the file extension
export function GetIconByFileExtension(extension) {
  const lowerCaseExtension = extension.toLowerCase();

  if (lowerCaseExtension === "jpg" || lowerCaseExtension === "jpeg" || lowerCaseExtension === "png") {
    return GoImage;
  } else if (lowerCaseExtension === "pdf") {
    return FaRegFilePdf;
  } else if (lowerCaseExtension === "doc" || lowerCaseExtension === "docx") {
    return GrDocumentWord;
  } else if (lowerCaseExtension === "xlsx") {
    return SiMicrosoftexcel;
  } else if (lowerCaseExtension === "txt") {
    return BsFiletypeTxt;
  }

  return BsQuestionSquare;
}

// Function to create a file object from an image URL
export const createFileFromUrl = async (url, id) => {
  try {
    if (!url) return null;
    const response = await fetch(url);
    if (!response.ok) throw new Error("Network response was not ok");

    const blob = await response.blob();
    const fileName = url.split("/").pop();
    const decodedFileName = decodeURIComponent(fileName);
    const file = new File([blob], decodedFileName, { type: blob.type });

    return {
      id,
      file,
      name: decodedFileName,
      size: blob.size,
      type: blob.type,
      valid: true,
      imageUrl: url,
    };
  } catch (error) {
    toast.error(`Error creating file from URL: ${error.message}`);
    return null;
  }
};

// Utility function to check if the file is an image
export const isImage = (fileName) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "tiff"];
  return imageExtensions.includes(fileName.split(".").pop().toLowerCase());
};

// Utility function to convert form data to JSON
export function formDataToJSON(formData) {
  const jsonObject = {};

  formData.forEach((value, key) => {
    // Check if key already exists
    if (jsonObject[key]) {
      // Convert single value to array if needed
      if (!Array.isArray(jsonObject[key])) {
        jsonObject[key] = [jsonObject[key]];
      }
      jsonObject[key].push(value);
    } else {
      jsonObject[key] = value;
    }
  });

  return jsonObject;
}
