import React, { useState } from "react";
import useData from "../../../customHooks/useData";
import Loading from "../Loading/Loading";
import { editData, getData } from "../../../redux/actions/sendDataAction";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import Select from "react-select";

function UsersComponent({ user, onCheck, isChecked }) {
  return (
    <div className="flex gap-2 items-center">
      <input type="checkbox" name={user.username} onChange={(e) => onCheck(e, user.id)} checked={isChecked || false} />
      <div>{user.username}</div>
    </div>
  );
}

export default function AssignUsersToGroups() {
  const dispatch = useDispatch();
  const [chosenGroup, setChosenGroup] = useState(null);
  const [showUsers, setShowUsers] = useState(false);
  const groups = useData("permissions/api/v1/user-groups/");
  const users = useData("/auth/api/v1/users/");
  const [chosenUsers, setChosenUsers] = useState([]);

  function handleCheck(e, id) {
    if (e.target.checked) {
      setChosenUsers([...chosenUsers, id]);
    } else {
      setChosenUsers(chosenUsers.filter((i) => i !== id));
    }
  }

  const groupsArr = groups ? groups.map((group) => ({ label: group.name, value: group.id })) : [];

  function getGroupUsers(groupId) {
    if (showUsers) {
      setShowUsers(false);
    }
    dispatch(
      getData(`permissions/api/v1/user-groups/${groupId}/`, (resp) => {
        setChosenUsers(resp.data.user_ids);
        setShowUsers(true);
      }),
    );
  }

  function handleSubmit(e) {
    e.preventDefault();
    const data = {
      user_ids: chosenUsers,
    };

    dispatch(
      editData(`permissions/api/v1/user-groups/${chosenGroup.value}/`, data, () =>
        toast.success("Users were added to the group Successfully"),
      ),
    );
  }

  return (
    <div className="w-full h-screen">
      {(!groups || !users) && <Loading />}
      {groups && users && (
        <form action="post" onSubmit={handleSubmit} className="py-5">
          <h1 className="p-3 bg-blue-900 text-white w-[80%] rounded-lg text-center font-bold mx-auto my-3 text-xl">
            Assign Users to Group
          </h1>

          <div className="w-[40%] mx-auto">
            <label htmlFor="group">Choose Group</label>
            <Select
              options={groupsArr}
              id="group"
              isSearchable
              onChange={(e) => {
                setChosenGroup(e);
                getGroupUsers(e.value);
              }}
            />
          </div>

          {showUsers && (
            <>
              <div className="grid grid-cols-3 p-5">
                {users.map((user) => {
                  return <UsersComponent user={user} onCheck={handleCheck} isChecked={chosenUsers.includes(user.id)} />;
                })}
              </div>
              <button
                type="submit"
                className="mx-10 flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Add Users
              </button>
            </>
          )}
        </form>
      )}
    </div>
  );
}
