import React, { useEffect, useState } from "react";
import {
  getAllAirline,
  getselectedAirportaction,
  updateAirportAction,
} from "../../../redux/actions/airportServiceAction";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import { TbListDetails } from "react-icons/tb";
import useHotelNames from "../../../customHooks/useHotelNames";
import { getAllAssistAction } from "../../../redux/actions/meetAssistAction";
import useData from "../../../customHooks/useData";
import { FcDocument } from "react-icons/fc";
import { RxUpdate } from "react-icons/rx";
import lightToast from "light-toast";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import usePermissions from "../../../customHooks/usePermissions";
import { Link } from "react-router-dom";

const AirportServiceDetails = () => {
  const [airport, setAirport] = useState();
  const params = useParams();
  const dispatch = useDispatch();
  const hotels = useHotelNames();
  const [members, setMembers] = useState();
  const [cities, setCities] = useState();
  const [airlines, setAirlines] = useState();
  const [load, setLoad] = useState(false);
  const [confirm, setConfirm] = useState(false);

  /////open modal //////
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  ///////////////////////
  const [permissions, permissionsFlag] = usePermissions([
    "airport_services.view_airportlimousineservice",
    "permissions_management.meet_assist_change_pending",
    "permissions_management.meet_assist_change_archive",
    "permissions_management.meet_assist_change_new",
  ]);

  useEffect(() => {
    //////////////// Cancellatioin Request ////////////////////
    const controller = new AbortController();
    const signal = controller.signal;
    ///////////////////////////////////////////////////////////
    dispatch(
      getselectedAirportaction(params.id, signal, (result) => {
        if (result.status === 200) {
          setAirport(result.data);
          // dispatch(
          //   getData(
          //     `/en/hotels/api/v1/get-cities/${result.data.country_flags_from_id}/`,
          //     (resp) => {
          //       if (resp.status === 200) {
          //         // toast.error("This Country has no Cities in the system Please choose another one");
          //         setCities(resp.data);
          //       }
          //     },
          //     (err) => {
          //       // toast.error("there was an error");
          //     }
          //   )
          // );
        }
      }),
    );
    dispatch(
      getAllAssistAction(signal, (result) => {
        if (result.status === 200) {
          setMembers(result.data);
        }
      }),
    );
    dispatch(
      getAllAirline(signal, (result) => {
        if (result.status === 200) {
          setAirlines(result.data);
        }
      }),
    );
    return () => {
      controller.abort();
      // window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  //////////////////Countries///////////////////////
  const countries = useData("site-settings/api/countries-flags");

  /////////////// handle update /////////////
  const navigate = useNavigate();

  const handleUpdate = () => {
    const { status, ticket_copy, representative_name, ...restData } = airport;
    const newStatus =
      status === "UnderReview" ? "New" : status === "New" ? "Pending" : status === "Pending" && "Archive";
    const repId = representative_name?.id;
    const airlineName = airport?.airline_name?.id;
    const updateAirport = { ...restData, status: newStatus, representative_name: repId, airline_name: airlineName };
    setLoad(true);
    dispatch(
      updateAirportAction(updateAirport, params.id, (result) => {
        if (result.status === 200) {
          lightToast.success(`Airport Service Form Turned To ${result?.data.status}`);

          setLoad(false);
          navigate(`/dashboard/meet-&-assist-service/${status}/1`);
        } else {
          setLoad(false);
        }
      }),
    );
  };

  return (
    <div className="formPage-wrap">
      {airport && hotels.length > 0 && members && countries && airlines && permissionsFlag ? (
        permissions?.some((item) => item.value) && (
          <div className="w-full ">
            <div className="flex flex-col justify-center items-center ">
              <div className=" flex items-center justify-center">
                <h2 className="header-h2">
                  <TbListDetails className="w-5 h-5" />
                  Meet & Assist Service Details{" "}
                </h2>
              </div>
              <div className="flex flex-col  form-wrap  w-full  text-black rounded-md  ">
                <Link
                  to={`/dashboard/meet-&-assist-service/New/${params.id}/update`}
                  className="flex items-center self-end  gap-x-1 bg-green-700 px-1.5 py-1 rounded-md hover:bg-green-600 hover:scale-95 duration-300 transition-all text-white md:text-[.9rem] text-sm font-medium w-20 mb-2"
                >
                  Update <RxUpdate className="w-5 h-5 text-white text-lg font-medium " />
                </Link>
                {/* Hotel id & id & persons*/}
                <div
                  className={`flex flex-col md:flex-row w-full justify-between items-center rounded-md  p-3 bg-gray-200 `}
                >
                  <div className="flex  justify-between items-center w-full md:w-[50%] ">
                    <div className="w-full md:w-[33%]  ">
                      <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold"> ID</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1 text-xs md:text-sm">{airport?.id}</p>
                    </div>
                  </div>
                  <div className="flex items-center w-full md:w-[50%]">
                    <div className="w-full md:w-[33%]  ">
                      <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold ">CREATED DATE</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1 text-xs md:text-sm">
                        {" "}
                        {airport?.created_date?.slice(0, 10)}
                      </p>
                    </div>
                  </div>
                </div>
                {airport?.booking_id && airport?.booking_order && (
                  <div className="flex flex-col md:flex-row w-full justify-between items-center  p-3">
                    <div className="flex  justify-between items-center w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">BOOKING ID</p>
                      </div>
                      <div className="w-[70%] font-semibold text-gray-800">
                        <p className="flex items-center gap-x-1 text-xs md:text-sm">{airport?.booking_id} </p>
                        <p className="flex items-center gap-x-1 text-xs md:text-sm"></p>
                      </div>
                    </div>
                    <div className="flex items-center w-full md:w-[50%]">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">BOOKINE ORDER</p>
                      </div>
                      <div className="w-[70%] font-semibold text-gray-800">
                        <p className="flex items-center gap-x-1 text-xs md:text-sm"> {airport?.booking_order}</p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="w-full border border-gray-300 rounded-md p-1 my-1">
                  <div className="flex w-full justify-start items-center  p-3   ">
                    <div className="flex flex-col md:flex-row  justify-start items-start w-full md:gap-0 gap-1 ">
                      <div className="w-full md:w-[19%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold ">PASSENGERS NAME</p>
                      </div>
                      <div className="w-full flex flex-col md:flex-row items-start md:items-center gap-y-1 md:gap-x-1  font-semibold text-gray-800">
                        {airport?.passengers_name.length > 0 &&
                          airport?.passengers_name.map((item, index) => (
                            <>
                              <div className="flex items-center  gap-x-2 text-xs md:text-sm">
                                {item} {index !== airport.passengers_name.length - 1 && <span>/</span>}
                              </div>
                            </>
                          ))}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row w-full justify-between items-center gap-2 p-3 bg-gray-200 ">
                    <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">IATA CODE </p>
                      </div>
                      <div className="w-[70%] font-semibold text-gray-800">
                        <p className="flex items-center gap-x-1 text-xs md:text-sm">
                          {airport?.iata_code?.toUpperCase()}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row  justify-between md:items-center gap-0 w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">COUNTRY / REGION</p>
                      </div>
                      <div className="w-full md:w-[70%] font-semibold text-gray-800">
                        <p className="flex items-center gap-x-1 text-xs md:text-sm">
                          {airport?.country_from} / {airport?.city_from}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Client & phone Number*/}
                  <div className="flex flex-col md:flex-row w-full justify-between items-center gap-2 p-3  ">
                    <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">AREA </p>
                      </div>
                      <div className="w-[70%] font-semibold text-gray-800">
                        <p className="flex items-center gap-x-1 text-xs md:text-sm">{airport?.area_from}</p>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">AIPORT NAME</p>
                      </div>
                      <div className="w-full md:w-[70%] font-semibold text-gray-800">
                        <p className="flex items-center gap-x-1 text-xs md:text-sm">{airport?.airport_name}</p>
                      </div>
                    </div>
                  </div>

                  {/* WhatsApp Number & Email*/}
                  <div className="flex flex-col md:flex-row w-full justify-between items-center gap-2 p-3 bg-gray-200 ">
                    <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">HOTEL NAME </p>
                      </div>
                      <div className="w-full md:w-[70%] font-semibold text-gray-800">
                        <p className="flex items-center gap-x-1 text-xs md:text-sm">
                          {airport?.hotel_name
                            ? hotels.filter((item) => item.id === airport?.hotel_name)[0].name
                            : airport?.hotel_name_other}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold ">HOTEL MAP ADDRESS</p>
                      </div>
                      <div className="w-full md:w-[70%] font-semibold text-gray-800 text-xs md:text-[14px]">
                        <a
                          target="_blank"
                          href={`https://www.google.com/maps/place/${encodeURIComponent(airport?.hotel_map_address)}`}
                          className="w-fit flex items-center gap-x-1 text-sky-600  hover:text-sky-700 active:scale-95 duration-300 transition-all cursor-pointer"
                          rel="noreferrer"
                        >
                          {" "}
                          {airport?.hotel_map_address}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row w-full justify-between items-center gap-2 p-3  ">
                    <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">HOTEL ADDRESS</p>
                      </div>
                      <div className="w-full md:w-[70%] font-semibold text-gray-800">
                        <p className="flex items-center gap-x-1 text-xs md:text-sm"> {airport?.address}</p>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">REP NAME</p>
                      </div>
                      <div className="w-[70%] font-semibold text-gray-800">
                        <p className="flex items-center gap-x-1 text-xs md:text-sm">
                          {airport?.representative_name?.name}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row w-full justify-between items-center gap-2  p-3 bg-gray-200 ">
                    <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">NUMBER OF PAX</p>
                      </div>
                      <div className="w-full md:w-[70%] font-semibold text-gray-800">
                        <p className="flex items-center gap-x-1 text-xs md:text-sm"> {airport?.number_of_pax}</p>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">TRANSFER FROM AIRPORT</p>
                      </div>
                      <div className="w-full md:w-[70%] font-semibold text-gray-800">
                        <p className="flex items-center gap-x-1 text-xs md:text-sm">
                          {" "}
                          {airport?.transfer_from_airport ? "Yes" : "No"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row w-full justify-between items-center gap-2  p-3   ">
                    <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold ">AMOUNT</p>
                      </div>
                      <div className="w-full md:w-[70%] font-semibold text-gray-800">
                        <p className="flex items-end gap-x-1 text-xs md:text-base"> {Number(airport?.amount)} </p>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">CURRENCY</p>
                      </div>
                      <div className="w-full md:w-[70%] font-semibold text-gray-800">
                        <p className="flex items-center gap-x-1 text-xs md:text-sm"> {airport?.currency}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row w-full justify-between items-center  p-3 gap-2 bg-gray-200 ">
                    <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold ">SERVICE TYPE</p>
                      </div>
                      <div className="w-full md:w-[70%] font-semibold text-gray-800">
                        <p className="flex items-end gap-x-1 text-xs md:text-sm"> {airport?.service_type} </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/*Flight Section*/}
                <div className="w-full border border-gray-300 rounded-md p-2 my-1 ">
                  <div className="flex flex-col md:flex-row w-full justify-between items-center gap-2 p-3  ">
                    <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">FLIGHT DATE</p>
                      </div>
                      <div className="w-full md:w-[70%] font-semibold text-gray-800">
                        <p className="flex items-center gap-x-1 text-xs md:text-sm">
                          {airport?.time_of_arrival.slice(0, 10)}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold ">TIME OF ARRIVAL</p>
                      </div>
                      <div className="w-full md:w-[70%] font-semibold text-gray-800">
                        <p className="flex items-center gap-x-1 text-xs md:text-sm">
                          {" "}
                          {airport?.time_of_arrival.slice(11, 19)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row w-full justify-between items-center gap-2 p-3  bg-gray-200">
                    <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold ">FLIGHT NUMBER</p>
                      </div>
                      <div className="w-full md:w-[70%] font-semibold text-gray-800">
                        <p className="flex items-center gap-x-1 text-xs md:text-sm"> {airport?.flight_number}</p>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                      <div className="w-full md:w-[33%]  ">
                        <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold ">AIRLINE NAME</p>
                      </div>
                      <div className="w-full md:w-[70%] font-semibold text-gray-800">
                        <p className="flex items-center gap-x-1 text-xs md:text-sm">
                          {" "}
                          {airport?.airline_name && airport?.airline_name.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row w-full justify-between items-center gap-2 p-3   ">
                  <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                    <div className="w-full md:w-[33%]  ">
                      <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">ORDER TYPE</p>
                    </div>
                    <div className="w-full md:w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1 text-xs md:text-sm"> {airport?.order_type}</p>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                    <div className="w-full md:w-[33%]  ">
                      <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">ADDED BY </p>
                    </div>
                    <div className="w-full md:w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1 text-xs md:text-sm">{airport?.added_by}</p>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row w-full justify-between items-center gap-2 p-3  bg-gray-200 ">
                  <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                    <div className="w-full md:w-[33%]  ">
                      <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">STATUS </p>
                    </div>
                    <div className="w-full md:w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1 text-xs md:text-sm"> {airport?.status}</p>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                    <div className="w-full md:w-[33%]  ">
                      <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold ">TICKET COPY</p>
                    </div>
                    <div className="w-full md:w-[70%] font-semibold text-gray-800">
                      {/* {window.open(airport?.ticket_copy, "_blank")} */}

                      {airport?.ticket_copy ? (
                        <button
                          onClick={() => window.open(airport?.ticket_copy, "_blank")}
                          type="button"
                          className="w-full overflow-x-auto flex items-center text-xs gap-x-1 text-sky-600  border-sky-600 hover:text-sky-700 active:scale-95 duration-300 transition-all cursor-pointer"
                        >
                          {/* Use substring and lastIndexOf function to extract the filename */}
                          {/* {airport?.ticket_copy?.substring(airport?.ticket_copy?.lastIndexOf("/") + 1)}{" "} */}
                          <FcDocument className="w-5 h-5" />
                        </button>
                      ) : (
                        <p className="flex items-center gap-x-1 text-xs md:text-sm"> No ticket copy provided </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row w-full justify-between items-center gap-2  p-3   ">
                  <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                    <div className="w-full md:w-[33%]  ">
                      <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold ">NOTES</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1 text-xs md:text-sm"> {airport?.notes}</p>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                    <div className="w-full md:w-[33%]  ">
                      <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">REPRESENTATIVE NOTES</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1 text-xs md:text-sm"> {airport?.representative_notes}</p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row w-full justify-between items-center gap-2  p-3   ">
                  <div className="flex flex-col md:flex-row  justify-between md:items-center  w-full md:w-[50%] ">
                    <div className="w-full md:w-[33%]  ">
                      <p className="text-xs md:text-sm flex items-center gap-x-1 font-bold">Referrer link </p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1 text-xs md:text-sm"> {airport?.referrer_link}</p>
                    </div>
                  </div>
                </div>

                {/* Hotel & Room Name*/}

                {/* Client text & Client Tag Request*/}
                {/* 
                {permissions.some(
                  (item) =>
                    (item.name === "permissions_management.meet_assist_change_pending" && item.value) ||
                    (item.name === "permissions_management.meet_assist_change_archive" && item.value)
                ) &&
                  airport?.status !== "Archive" && (
                    <div className="w-full flex items-center justify-end px-5 mt-3">
                      <button
                        type="button"
                        disabled={load}
                        onClick={() => setConfirm(true)}
                        className="flex items-center gap-x-1 text-xs md:text-sm px-2 py-0.5 rounded-lg  bg-green-800 text-white hover:bg-green-900 active:scale-95 duration-300 transition-all"
                      >
                        {airport?.status === "New"
                          ? "CHANGE TO PENDING"
                          : airport?.status === "Pending" && "CHANGE TO ARCHIVE"}
                        {load ? <ButtonLoading /> : <RxUpdate className="w-5 h-5" />}
                      </button>
                    </div>
                  )} */}

                {airport?.status !== "Archive" &&
                  airport?.status === "New" &&
                  permissions.some(
                    (item) => item.name === "permissions_management.meet_assist_change_pending" && item.value,
                  ) && (
                    <div className="w-full flex items-center justify-end px-5 mt-3">
                      <button
                        type="button"
                        disabled={load}
                        onClick={() => setConfirm(true)}
                        className="flex items-center gap-x-1 text-xs md:text-sm px-2 py-0.5 rounded-lg  bg-green-800 text-white hover:bg-green-900 active:scale-95 duration-300 transition-all"
                      >
                        CHANGE TO PENDING
                        {load ? <ButtonLoading /> : <RxUpdate className="w-5 h-5" />}
                      </button>
                    </div>
                  )}

                {airport?.status !== "Archive" &&
                  airport?.status === "Pending" &&
                  permissions.some(
                    (item) => item.name === "permissions_management.meet_assist_change_archive" && item.value,
                  ) && (
                    <div className="w-full flex items-center justify-end px-5 mt-3">
                      <button
                        type="button"
                        disabled={load}
                        onClick={() => setConfirm(true)}
                        className="flex items-center gap-x-1 text-xs md:text-sm px-2 py-0.5 rounded-lg  bg-green-800 text-white hover:bg-green-900 active:scale-95 duration-300 transition-all"
                      >
                        CHANGE TO ARCHIVE
                        {load ? <ButtonLoading /> : <RxUpdate className="w-5 h-5" />}
                      </button>
                    </div>
                  )}

                {airport?.status !== "Archive" &&
                  airport?.status !== "Pending" &&
                  airport?.status === "UnderReview" &&
                  permissions.some(
                    (item) => item.name === "permissions_management.meet_assist_change_new" && item.value,
                  ) && (
                    <div className="w-full flex items-center justify-end px-5 mt-3">
                      <button
                        type="button"
                        disabled={load}
                        onClick={() => setConfirm(true)}
                        className="flex items-center gap-x-1 text-xs md:text-sm px-2 py-0.5 rounded-lg  bg-green-800 text-white hover:bg-green-900 active:scale-95 duration-300 transition-all"
                      >
                        CHANGE TO NEW
                        {load ? <ButtonLoading /> : <RxUpdate className="w-5 h-5" />}
                      </button>
                    </div>
                  )}
              </div>
            </div>
            {/* <PopUp close={closeModal} isOpen={isOpen} image={airport?.ticket_copy} header={"Ticket Copy"} /> */}
          </div>
        )
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
      {/* Checked Pop Up */}

      {confirm && (
        <div
          className={` fixed ${
            confirm ? "top-0 left-0 right-0 bottom-0 h-screen  " : ""
          }  blur-effect-theme   w-full flex flex-col justify-center items-center gap-y-5  opacity-100 z-[250] overflow-x-hidden `}
        >
          <div
            className={`bg-black/70  max-w-sm w-full absolute  flex flex-col justify-center items-center gap-y-5 py-4    ${
              confirm
                ? "opacity-100 visible   transition-all duration-500 transform border-gray-300 border rounded-lg "
                : "opacity-0 invisible -right-full translate-x-8 transition-all duration-500 transform "
            } `}
          >
            <p className="text-2xl font-light  text-white">Are You Sure?</p>
            <div className="w-full flex items-center justify-center gap-x-5 text-white">
              <button
                onClick={() => {
                  setConfirm(false);
                  handleUpdate();
                }}
                className="bg-green-800 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Yes,I'm Sure
              </button>
              <button
                onClick={() => setConfirm(false)}
                className="bg-red-800 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                No,take me back
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AirportServiceDetails;
