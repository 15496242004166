import { React, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import cookie from "react-cookies";
import { AiFillDelete, AiOutlineEye, AiOutlineInfoCircle } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { getAllTransactionAction } from "../../../redux/actions/allTransactionsAction";
import Loading from "../Loading/Loading";
import { deleteBankTransferAction } from "../../../redux/actions/bankTransferAction";
import lightToast from "light-toast";
import { deleteCashAction } from "../../../redux/actions/cashAction";
import { deletePaymentTransactions } from "../../../redux/actions/paymentTransactionAction";
import { GiMoneyStack, GiTakeMyMoney } from "react-icons/gi";
import { GoPerson } from "react-icons/go";
import { PiPercent } from "react-icons/pi";
import { BsCurrencyExchange } from "react-icons/bs";
import { LiaCalendar } from "react-icons/lia";
import { IoKeyOutline } from "react-icons/io5";
import { BiTransfer } from "react-icons/bi";
import TopBarProgress from "react-topbar-progress-indicator";

const AllTransactions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = cookie.load("access_token");
  const [isOpen, setIsOpen] = useState(false);
  const params = useParams();
  const [check, setCheck] = useState(false);
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [transaction, setTransaction] = useState(null);
  const TransactionArray = ["Cash", "(POS) Visa", "Bank Transfer", "Jumbo4pay"];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    dispatch(getAllTransactionAction(token, params.page, params.sorting));
  }, [params.page, location]);

  useEffect(() => {
    const currentColumnVisibility = localStorage.getItem("grid-visibility-transaction-list");
    if (currentColumnVisibility) {
      setColumnVisibility({ ...JSON.parse(currentColumnVisibility), slug: false });
    }
  }, []);

  function handlePageChange(para) {
    navigate(`/dashboard/all-transactions/${para.page + 1}/${params.sorting}`);
  }

  const handleDelete = (id) => {
    if (type === "Cash") {
      dispatch(
        deleteCashAction(token, id, (result) => {
          if (result.status === 204) {
            lightToast.success("This Cash was completed Deleted");
            dispatch(getAllTransactionAction(token, params.page, params.sorting));
          }
        }),
      );
    } else if (type === "Jumbo4pay") {
      dispatch(
        deletePaymentTransactions(token, id, (result) => {
          if (result.status === 204) {
            lightToast.success("This Jumpo4Pay was completed Deleted");
            dispatch(getAllTransactionAction(token, params.page, params.sorting));
          }
        }),
      );
    } else if (type === "Bank Transfer") {
      dispatch(
        deleteBankTransferAction(token, id, (result) => {
          if (result.status === 204) {
            lightToast.success("This Bank Transfer was completed Deleted");
            dispatch(getAllTransactionAction(token, params.page, params.sorting));
          }
        }),
      );
    }
  };

  function comparator([para]) {
    if (para) {
      if (para.sort === "asc") {
        //    dispatch(sortBookingListAction(token,params.field))
        navigate(`/dashboard/all-transactions/${params.page}/${para.field}`);
      } else {
        //  dispatch(sortBookingListAction(token,"-"+params.field))
        navigate(`/dashboard/all-transactions/${params.page}/${para.field}`);
      }
    } else {
      // dispatch(sortBookingListAction(token,params.field))
      navigate(-1);
    }
  }

  const { allTransactionsData } = useSelector((state) => state.allTransactionsReducer);
  const { allTransactionsFlag } = useSelector((state) => state.allTransactionsReducer);
  const { deleteBankTransferFlag } = useSelector((state) => state.bankTransferReducer);
  const { deleteCashFlag } = useSelector((state) => state.cashReducer);
  const { deletePaymentFlag } = useSelector((state) => state.paymentTransactionReducer);

  // const handleChoose = (item) =>{
  //   if(item === 'Jumbo4pay')
  //   {
  //       dispatch(getJumboAction(token , (result)=>{
  //         console.log("aasasqsqd" , result)
  //       }))
  //   }
  // }

  const rows =
    allTransactionsData &&
    allTransactionsData.results.map((item, index) => {
      return {
        amount: item.amount,
        booked_room_id: item.booked_room_id,
        collected_by: item.collected_by,
        commission: item.commission,
        currency: item.currency,
        transaction_date: item.transaction_date.slice(0, 10),
        id: index,
        keyId: item.id,
        subtotal: item.subtotal,
        transaction_status: item.transaction_status,
        transaction_type: item.transaction_type,
      };
    });

  const columns = [
    {
      field: "transaction_type",
      headerName: (
        <div className="flex items-center gap-x-1">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          TYPE
        </div>
      ),

      flex: 1,
      editable: false,
    },

    {
      field: "amount",
      headerName: (
        <div className="flex items-center gap-x-1">
          <GiMoneyStack className="w-5 h-5  text-yellow-500" />
          AMOUNT
        </div>
      ),
      flex: 1,
      editable: false,
      // renderCell: (params) => {
      //

      //   return (
      //       <div>
      //             <button onClick={()=>navigate(`/dashboard/booking-list-single-id/${params.row.short_id}/${params.row.id}`)}   className='flex items-center gap-x-1' >
      //               {/* {params.row.id} */}
      //               {/* <AiOutlineEye className='w-5 h-5' /> */}
      //            </button>

      //       </div>

      //   );
      // },
    },

    {
      field: "collected_by",
      headerName: (
        <div className="flex items-center gap-x-1">
          <GoPerson className="w-5 h-5  text-yellow-500" />
          USERNAME
        </div>
      ),
      flex: 1,
      editable: false,
    },

    {
      field: "commission",
      headerName: (
        <div className="flex items-center gap-x-1">
          <PiPercent className="w-5 h-5  text-yellow-500" />
          COMMISSION
        </div>
      ),
      flex: 1,
      editable: false,
    },
    {
      field: "currency",
      headerName: (
        <div className="flex items-center gap-x-1">
          <BsCurrencyExchange className="w-5 h-5  text-yellow-500" />
          CURRENCY
        </div>
      ),

      flex: 1,
      editable: false,
    },
    {
      field: "transaction_date",
      headerName: (
        <div className="flex items-center gap-x-1">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          DATE
        </div>
      ),
      flex: 1,
      editable: false,
    },
    {
      field: "keyId",
      headerName: (
        <div className="flex items-center gap-x-1">
          <IoKeyOutline className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
      flex: 1,
      editable: false,
    },
    // {
    //   field: 'booked_room_id',
    //   headerName: 'ID',

    //   flex: 1,
    //   editable: false,
    // },
    {
      field: "subtotal",
      headerName: (
        <div className="flex items-center gap-x-1">
          <GiTakeMyMoney className="w-5 h-5  text-yellow-500" />
          SUBTOTAL
        </div>
      ),
      flex: 1,
      editable: false,
    },
    {
      field: "transaction_status",
      headerName: (
        <div className="flex items-center gap-x-1">
          <AiOutlineInfoCircle className="w-5 h-5  text-yellow-500" />
          STATUS
        </div>
      ),

      flex: 1,
      editable: false,
    },

    {
      field: "operations",
      filterable: false,
      sortable: false,
      flex: 1,
      headerName: "OPERATIONS",
      renderCell: (params) => {
        return (
          <div className="flex items-center justify-start gap-x-5">
            <Link
              to={`./preview/${params.row.booked_room_id}/${params.row.transaction_type}/${params.row.keyId}`}
              className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
            >
              <AiOutlineEye className="w-5 h-5 text-slate-900 " />
            </Link>
            <Link
              to={`./update/${params.row.booked_room_id}/${params.row.transaction_type}/${params.row.keyId}`}
              className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
            >
              <FiEdit className="w-5 h-5 text-green-800 " />
            </Link>
            <button
              onClick={() => {
                setId(params.row.keyId);
                setType(params.row.transaction_type);
                setConfirm(true);
              }}
              className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
            >
              <AiFillDelete className="w-5 h-5 text-red-800" />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full overflow-x-scroll">
      {confirm && (
        <div className="relative z-50 ">
          <div className="absolute flex flex-col items-center justify-center gap-y-2 w-[300px] h-36 bg-white border-gray-300 border top-96 left-[450px] bg-center rounded-md ">
            <p className="text-2xl font-light">Are You Sure?</p>
            <div className="w-full flex items-center justify-center gap-x-5 text-white">
              <button
                onClick={() => {
                  setConfirm(false);
                  handleDelete(id, type);
                }}
                className="bg-green-700 rounded-md px-3 py-1 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Yes,I'm Sure
              </button>
              <button
                onClick={() => setConfirm(false)}
                className="bg-red-700 rounded-md px-3 py-1 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                No,take me back
              </button>
            </div>
          </div>
        </div>
      )}
      {!allTransactionsFlag ? (
        deleteBankTransferFlag || deleteCashFlag || deletePaymentFlag ? (
          <>
            {" "}
            <TopBarProgress />
            <div className="w-full h-full flex items-center justify-center">
              <Loading />
            </div>
          </>
        ) : (
          <Box sx={{ height: "100%", width: "100%", backgroundColor: "white", overflowX: "auto" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight
              rowCount={allTransactionsData.count}
              onSortModelChange={comparator}
              columnVisibilityModel={columnVisibility}
              onColumnVisibilityModelChange={(visibility) => {
                setColumnVisibility(visibility);
                localStorage.setItem("grid-visibility-transaction-list", JSON.stringify(visibility));
              }}
              paginationModel={{ page: Number(params.page - 1), pageSize: 10 }}
              onPaginationModelChange={handlePageChange}
              paginationMode="server"
              className=" xl:w-full  md:w-[150%] w-[250%]"
            />
          </Box>
        )
      ) : (
        <>
          <TopBarProgress />
          <div className="w-full h-full flex items-center justify-center">
            <Loading />
          </div>
        </>
      )}
    </div>
  );
};

export default AllTransactions;
