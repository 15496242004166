import React from 'react';
import {VscAdd } from 'react-icons/vsc';
import {BsListUl} from 'react-icons/bs';
import {RiKeyboardLine} from 'react-icons/ri';

const DepartmentsManagment = () => {
  return (
    <div className='w-full border-gray-300 border rounded-lg bg-white'>
         <div className='w-[98%] flex justify-center mx-auto  border-[#002244] border text-[#002244]  rounded-lg text-2xl py-2 mt-3'>
            <p className='flex items-center gap-x-3'> <RiKeyboardLine className='w-5 h-5' />Department Management</p>
          </div>
         <div className='grid grid-cols-2  gap-y-5 mt-12'>
            
            <div class = "flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                  <button  
                          className='flex items-center gap-x-1 w-24 h-24 rounded-full bg-teal-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all'>
                  <div className='w-full flex justify-center items-center '>
                          <VscAdd className='w-12 h-12  ' /> 
                  </div>   
                  </button>
                  <p className='text-lg font-semibold'>Create Department</p>
            </div>

            <div class = "flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
              <button  
                      className='flex items-center gap-x-1 w-24 h-24 rounded-full  bg-slate-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all'>
                <div className='w-full flex justify-center items-center '>
                       <BsListUl className='w-12 h-12  ' /> 
                </div>   
              </button>
              <p className='text-lg font-semibold '>Departments List</p>
            </div>

        </div>
    </div>
  )
}

export default DepartmentsManagment