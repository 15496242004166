import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../../redux/actions/loginAction";
import { Link, useLocation, useNavigate } from "react-router-dom";
import cookie from "react-cookies";
import { TfiWorld } from "react-icons/tfi";
import { IoLogOutOutline } from "react-icons/io5";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import TopBarProgress from "react-topbar-progress-indicator";
import { AiOutlineWifi } from "react-icons/ai";
import { FiWifiOff } from "react-icons/fi";
import { FaSearchLocation } from "react-icons/fa";
import DashboardSidebar from "../DashboardSidebar/DashboardSidebar";
import PopUp from "../PopUp/PopUp";
import { FcMenu } from "react-icons/fc";
import { flagListAction } from "../../../redux/actions/addCashBoxAction";
import usePermissions from "../../../customHooks/usePermissions";
import { CgNotes, CgProfile } from "react-icons/cg";
import { getUserProfileAction } from "../../../redux/actions/userProfileAction";
import jwtDecode from "jwt-decode";
import { base_URL } from "../../../urls";
import { PiUsersThreeBold } from "react-icons/pi";
import ActiveUsers from "../ActiveUsers/ActiveUsers";
import BreadCrumbs from "./BreadCrumbs";
import { BsPersonRaisedHand } from "react-icons/bs";
import "./taskbutton.css";
const DashboardNavbar = () => {
  const dispatch = useDispatch();
  const refreshToken = cookie.load("refresh_token");
  const accessToken = cookie.load("access_token");
  const location = useLocation();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(true);
  const [userFlag, setUserFlag] = useState(false);
  const [userData, setUserData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const logOuthandle = () => {
    setLoad(true);
    dispatch(
      logoutAction(accessToken, refreshToken, async (result) => {
        try {
          if (result.status === 205) {
            setLoad(false);
            // Clear cookies
            cookie.remove("access_token", { path: "/" });
            cookie.remove("refresh_token", { path: "/" });
            cookie.remove("currency", { path: "/" });
            cookie.remove("language", { path: "/" });
            cookie.remove("code", { path: "/" });
            // Navigate to login page
            navigate("/login");
          } else {
            // Handle other statuses or conditions if needed
            setLoad(false);
          }
        } catch (error) {
          console.error("Logout failed:", error);
        }
      }),
    );
  };
  function handleOnline() {
    setIsConnected(true);
  }
  function handelOffline() {
    setIsConnected(false);
  }
  const token = cookie.load("access_token");
  const user = jwtDecode(token);
  useEffect(() => {
    //get user img
    dispatch(
      getUserProfileAction(user.user_id, (result) => {
        if (result.status === 200) {
          setUserData(result.data);
        }
      }),
    );
    const handleResize = () => {
      // Check if the screen width is "md" based on Tailwind CSS classes
      if (window.innerWidth < 1024) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };
    // Add a resize event listener to track changes in screen width
    window.addEventListener("resize", handleResize);
    // Initial check when the component is mounted
    handleResize();
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handelOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handelOffline);
    };
  });
  //permissions
  const [permissions, permissionsFlag] = usePermissions([
    "permissions_management.search_platform",
    "meet_and_assist_button",
  ]);
  return (
    <>
      {userFlag && <ActiveUsers userFlag={userFlag} setUserFlag={setUserFlag} />}
      <nav class="w-full bg-white  dark:bg-gray-900  z-50 border-gray-200 border-b     flex  items-center justify-between mx-auto md:px-6 px-2 py-4    ">
        <div class="w-full flex flex-col md:flex-row items-center justify-between gap-x-4 md:order-2  z-50 ">
          <Link
            to={"/dashboard/user-profile"}
            className="hover:scale-105 active:scale-95 cursor-pointer duration-500 transition-all"
          >
            {userData?.avatar ? (
              <div className="flex items-center gap-x-3">
                <img src={`${base_URL}${userData.avatar}`} className="w-10 h-10 rounded-full" />
                <p className="text-md font-bold text-[#002244]">
                  {userData.first_name} {userData.last_name}
                </p>
              </div>
            ) : (
              <div className="flex items-center gap-x-3">
                <img src="/images/app.jpg" className="w-10 h-10 rounded-full" />
                <p className="text-md font-bold text-[#002244]">
                  {userData?.first_name} {userData?.last_name}
                </p>
              </div>
            )}
          </Link>
          {/* {open && (
            <div className=" flex items-center gap-x-2">
              <button
                onClick={() => {
                  setIsOpen(true);
                  dispatch(flagListAction(true));
                }}
                className="text-gray-800"
              >
                <FcMenu className="w-5 h-5" />
              </button>
            </div>
          )} */}
          <div className="w-[70%] flex items-center justify-around md:justify-end gap-x-2 md:order-2  ">
            {/* {
              <div className="text-center">
                <Link to="/dashboard/task-list" className="tasks-Button">
                  <span className="spark__container">
                    <span className="spark" />
                  </span>
                  <span className="backdrop" />
                  <span className="text">My Task</span>
                </Link>
              </div>
            } */}
            {open && (
              <div className=" flex items-center gap-x-2">
                <button
                  onClick={() => {
                    setIsOpen(true);
                    dispatch(flagListAction(true));
                  }}
                  className="text-gray-800"
                >
                  <FcMenu className="w-5 h-5" />
                </button>
              </div>
            )}
            {permissions?.some((item) => item.name === "meet_and_assist_button" && item?.value) && (
              <div className=" flex items-center gap-x-2 cursor-pointer hover:scale-110 transition-transform">
                <Link
                  to="/dashboard/meet-&-assist-service/New/1"
                  className="flex items-center gap-2 text-[#002244] font-bold  w-full"
                >
                  <BsPersonRaisedHand size={20} />
                </Link>
              </div>
            )}
            <div
              onClick={() => {
                navigate("/dashboard/notes?pageSize=8&page=1&status=");
              }}
              className=" flex items-center gap-x-2 cursor-pointer hover:scale-110 transition-transform"
            >
              <div className="flex items-center gap-2 text-blue-700 font-bold  w-full">
                <CgNotes size={20} />
              </div>
            </div>
            {open ? (
              <div className=" flex items-center gap-x-2">
                {isConnected ? (
                  <div className="flex items-center gap-2 text-green-700 font-bold  w-full">
                    <AiOutlineWifi size={20} />
                  </div>
                ) : (
                  <div className="flex items-center gap-2 text-red-800 font-bold w-full">
                    <FiWifiOff size={20} />
                  </div>
                )}
              </div>
            ) : (
              <div className="">
                {isConnected ? (
                  <div className="flex items-center gap-2 text-green-800 font-bold  w-full">
                    <AiOutlineWifi size={20} />
                  </div>
                ) : (
                  <div className="flex items-center gap-2 text-red-800 font-bold w-full">
                    <FiWifiOff size={20} />
                  </div>
                )}
              </div>
            )}
            {open ? (
              <button
                onClick={() => setUserFlag(true)}
                className="inline-flex gap-x-1 items-center font-medium justify-center px-2 py-2 text-sm bg-gray-100 text-[#002244] dark:text-white rounded-lg cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white duration-500 transition-all"
              >
                <PiUsersThreeBold className="w-4 h-4 text-[#002244]" />{" "}
                <div className="flex items-center gap-x-2">
                  <div className="w-3 h-3 rounded-full bg-green-700"></div>
                </div>
              </button>
            ) : (
              <button
                onClick={() => setUserFlag(true)}
                className="inline-flex gap-x-1 items-center font-medium justify-center px-2 py-2 text-sm bg-gray-100 text-[#002244] dark:text-white rounded-lg cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white duration-500 transition-all"
              >
                <PiUsersThreeBold className="w-4 h-4 text-[#002244]" />{" "}
                <div className="flex items-center gap-x-2">
                  Active Users <div className="w-3 h-3 rounded-full bg-green-700"></div>
                </div>
              </button>
            )}
            {permissionsFlag ? (
              permissions.some((item) => item.value) && open ? (
                <Link to="/">
                  <FaSearchLocation className="w-4 h-4 text-gray-900" />
                </Link>
              ) : (
                permissions.some((item) => item.value) && (
                  <Link
                    to="/"
                    class="inline-flex gap-x-1 items-center font-medium justify-center px-2 py-2 text-sm text-[#002244] dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    <FaSearchLocation className="w-4 h-4 text-[#002244]" /> <p>Booking Search</p>
                  </Link>
                )
              )
            ) : (
              <ButtonLoading />
            )}
            {open ? (
              <button
                type="button"
                data-dropdown-toggle="language-dropdown-menu"
                class="inline-flex gap-x-1 items-center font-medium justify-center px-2 py-2 text-sm text-[#002244] dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                <TfiWorld className="w-4 h-4 text-gray-900" />
              </button>
            ) : (
              <button
                type="button"
                data-dropdown-toggle="language-dropdown-menu"
                class="inline-flex gap-x-1 items-center font-medium justify-center px-2 py-2 text-sm text-[#002244] dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                <TfiWorld className="w-4 h-4 text-[#002244]" /> English (US)
              </button>
            )}
            {open ? (
              <button
                disabled={load}
                type="button"
                onClick={() => logOuthandle()}
                className=" active:scale-95 text-black"
              >
                {load ? (
                  <>
                    <TopBarProgress />
                    <ButtonLoading />
                  </>
                ) : (
                  <IoLogOutOutline className="w-5 h-5" />
                )}
              </button>
            ) : (
              <button
                disabled={load}
                type="button"
                onClick={() => logOuthandle()}
                className="flex  items-center font-medium justify-center gap-x-2 px-3 py-2 text-sm text-white dark:text-white rounded-lg cursor-pointer bg-[#002244] active:scale-95"
              >
                Logout{" "}
                {load ? (
                  <>
                    <TopBarProgress />
                    <ButtonLoading />
                  </>
                ) : (
                  <IoLogOutOutline className="w-5 h-5" />
                )}
              </button>
            )}
          </div>
        </div>
        <PopUp close={closeModal} isOpen={isOpen} sidebar={<DashboardSidebar />} />
      </nav>
      <BreadCrumbs />
    </>
  );
};
export default DashboardNavbar;
