import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import FileUploader from "../FileUploader/FileUploader";
import { toast } from "react-toastify";
import ButtonLoading from "../ButtonLoading/ButtonLoading";

const AddNewTask = () => {
  const [toolDocument, setToolDcoument] = useState([]);
  const [adding, setAdding] = useState();
  const statusVal = [
    { label: "New", value: "new" },
    { label: "Pending", value: "pending" },
    { label: "Done", value: "done" },
  ];

  const departmentsVal = [
    { label: "Admin", value: "admin" },
    { label: "Contractors", value: "contractors" },
    { label: "Accounting", value: "accounting" },
  ];
  const taskTypes = [
    { label: "Type1", value: "type1" },
    { label: "Type2", value: "type2" },
    { label: "Type3", value: "type3" },
  ];

  const formik = useFormik({
    initialValues: {
      taskName: "", // Default empty if no initial values
      taskType: "",
      taskStatus: "",
      department: [],
      addUser: "",
      Details: "",
    },
    validationSchema: Yup.object({
      taskName: Yup.string().required("Task Name is required"),
      taskType: Yup.string().required("Task Type is required"),
      taskStatus: Yup.string().required("Task Status is required"),
      department: Yup.array().min(1, "Select at least one department").required("Department is required"),
      addUser: Yup.string().required("User is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setAdding(true);

      try {
        const formData = new FormData();
        formData.append("taskName", values.taskName);
        formData.append("taskType", values.taskType);
        formData.append("taskStatus", values.taskStatus);
        formData.append("department", values.department);
        formData.append("addUser", values.addUser);

        if (toolDocument.length > 0) {
          for (let doc of toolDocument) {
            if (!doc.valid) {
              toast.error(`File ${doc.name} is not valid because ${doc.errors[0]}`);
              return;
            }
            formData.append("upgrade_document", doc.file);
          }
        }

        // Use the FormData for the API call
        // await upgradeTool(formData);
      } catch (error) {
        // Handle error

        toast.error(error.message || "Failed to create new Task");
      } finally {
        setSubmitting(false);
        alert(JSON.stringify(values, null, 2));
      }
    },
  });

  return (
    <section className="formPage-wrap min-h-screen flex flex-col gap-4">
      <h2 className="header-h2">Add a new Task</h2>
      <form onSubmit={formik.handleSubmit} className="form-wrap flex flex-col gap-2">
        <div className="input-par">
          <div className="input-chil flex flex-col gap-1">
            <label htmlFor="taskName">Task Name</label>
            <input
              type="text"
              id="taskName"
              placeholder="Task Name"
              className="px-2 py-1.5 rounded-lg border"
              {...formik.getFieldProps("taskName")}
            />
            {formik.touched.taskName && formik.errors.taskName ? (
              <div className="text-red-500">{formik.errors.taskName}</div>
            ) : null}
          </div>
          <div className="input-chil flex flex-col gap-1">
            <label htmlFor="taskType">Task Type</label>
            <Select
              options={taskTypes}
              placeholder="Task Type"
              onChange={(option) => formik.setFieldValue("taskType", option.value)}
            />

            {formik.touched.taskType && formik.errors.taskType ? (
              <div className="text-red-500">{formik.errors.taskType}</div>
            ) : null}
          </div>
        </div>
        <div className="input-par">
          <div className="input-chil flex flex-col gap-1">
            <label htmlFor="taskStatus">Task Status</label>
            <Select
              options={statusVal}
              placeholder="Task Status"
              onChange={(option) => formik.setFieldValue("taskStatus", option.value)}
            />
            {formik.touched.taskStatus && formik.errors.taskStatus ? (
              <div className="text-red-500">{formik.errors.taskStatus}</div>
            ) : null}
          </div>
        </div>
        <div className="input-par">
          <div className="input-chil flex flex-col gap-1">
            <label htmlFor="department">Department</label>
            <Select
              options={departmentsVal}
              placeholder="Department"
              onChange={(option) =>
                formik.setFieldValue(
                  "department",
                  option.map((o) => o.value),
                )
              }
              isMulti
            />
            {formik.touched.department && formik.errors.department ? (
              <div className="text-red-500">{formik.errors.department}</div>
            ) : null}
          </div>
          <div className="input-chil flex flex-col gap-1">
            <label htmlFor="addUser">Add user</label>
            <input
              type="text"
              id="addUser"
              placeholder="Add user"
              className="px-2 py-1.5 rounded-lg border"
              {...formik.getFieldProps("addUser")}
            />
            {formik.touched.addUser && formik.errors.addUser ? (
              <div className="text-red-500">{formik.errors.addUser}</div>
            ) : null}
          </div>
        </div>
        <div className="input-chil flex flex-col gap-1">
          <label htmlFor="Details">Details</label>
          <textarea
            rows={5}
            id="Details"
            placeholder="More Details..."
            className="px-2 py-1.5 rounded-lg border"
            {...formik.getFieldProps("Details")}
          />
        </div>
        <div className="mt-4">
          <FileUploader
            maxFiles={1}
            maxFileSize={2}
            accept={"image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .csv"}
            setFiles={setToolDcoument}
            files={toolDocument}
            // onChange={(e) => onImageChange(e, setFieldValue)}
          />
        </div>
        <div className="flex justify-center w-full mt-6">
          <button type="submit" disabled={adding} className="addButton">
            {adding ? (
              <div className="flex items-center gap-2">
                {" "}
                Adding...
                <ButtonLoading />
              </div>
            ) : (
              "Add New Task"
            )}
          </button>
        </div>
      </form>
    </section>
  );
};

export default AddNewTask;
