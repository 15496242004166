import React from "react";
import { ErrorMessage, Field, useField } from "formik";
import PhoneInput from "react-phone-number-input";

export const FormSection = ({ title, children }) => {
  return (
    <div className="w-full form-wrap">
      <div className="flex flex-col items-center justify-center h-full w-full">
        <div className={`w-full `}>
          <div className=" flex justify-center  ">
            <h2 className="header-h2 !w-max px-4 py-2 rounded-lg !bg-[#002244] !text-white">{title.toUpperCase()}</h2>
          </div>

          <div className="space-y-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

export const FormLabel = ({ label, htmlFor }) => {
  return (
    <label htmlFor={htmlFor} className="flex mb-1 font-semibold  text-gray-600">
      {label}
    </label>
  );
};

export const FieldErrorText = ({ message }) => {
  return <p className="text-center font-medium tracking-wide text-red-500 text-xs !mt-1">{message}</p>;
};

export const FormPhoneInput = ({ ...props }) => {
  const [field, meta, helpers] = useField(props);

  function handleChange(value) {
    if (value === undefined) {
      void helpers.setValue("");

      field.onChange("");
      return;
    }

    void helpers.setValue(value);
    field.onChange(value);
  }

  return (
    <div className="w-full">
      <PhoneInput
        international={true}
        country={"EG"}
        withCountryCallingCode={true}
        value={field.value}
        onChange={handleChange}
        onBlur={field.onBlur}
        {...props}
        className={`w-full ${meta.touched && meta.error && "border-red-300"}`}
      />

      {meta.touched && meta.error && meta.error.length > 1 ? <FieldErrorText message={meta.error} /> : null}
    </div>
  );
};

export const FormTextInput = ({ label, type, Icon, className, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="w-full">
      {label && <FormLabel label={label} htmlFor={props.id || props.name} />}
      <div className="w-full relative text-gray-600">
        <input
          id={props.id || props.name}
          className={`w-full px-8 h-10 border rounded-lg bg-white ${meta.touched && meta.error && "border-red-500"} ${className} `}
          type={`${type || "text"}`}
          {...field}
          {...props}
          data-date-format={type === "date" && "DD/MM/YYYY"}
        />
        <div className="absolute top-[11px] left-2">
          <Icon className="w-5 h-5 text-gray-400" />
        </div>
      </div>
      {meta.touched && meta.error && meta.error.length > 1 ? <FieldErrorText message={meta.error} /> : null}
    </div>
  );
};

export const FormCheckboxInput = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });

  return (
    <div>
      <label className="flex gap-1 items-center justify-center cursor-pointer">
        <input className="cursor-pointer" type="checkbox" {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ? <FieldErrorText message={meta.error} /> : null}
    </div>
  );
};

export const FormTextAreaInput = ({ label, Icon, ...props }) => {
  const [field, meta] = useField({ ...props });

  return (
    <div className="w-full">
      {label && <FormLabel label={label} htmlFor={props.id} />}

      <div className="w-full relative text-gray-600">
        <Field
          id={props.id || props.name}
          className={` w-full py-2 px-8 border ${meta.touched && meta.error && "border-red-500"}  rounded-lg bg-white`}
          as="textarea"
          {...props}
        />
        <div className="absolute top-[11px] left-2">
          <Icon className="w-5 h-5 text-gray-400" />
        </div>
      </div>
      <ErrorMessage
        name={props.id || props.name}
        render={(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      />
    </div>
  );
};
