import React from "react";
import { RiKeyboardLine } from "react-icons/ri";

import { VscAdd } from "react-icons/vsc";
import { IoIosList, IoMdAirplane } from "react-icons/io";

import { Link } from "react-router-dom";
import { GiCommercialAirplane } from "react-icons/gi";
import usePermissions from "../../../customHooks/usePermissions";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { MdFolderDelete, MdOutlinePending } from "react-icons/md";
import { FiArchive } from "react-icons/fi";
import { SiCodereview } from "react-icons/si";

const AirportCar = ({ general }) => {
  const [permissions, permissionsFlag] = usePermissions([
    "airport_services.add_airportlimousineservice",
    "permissions_management.meet_assist_list_new",
    "permissions_management.meet_assist_list_archive",
    "permissions_management.meet_assist_list_pending",
    "permissions_management.meet_assist_list_under_review",
    "permissions_management.meet_assist_list_soft_deleted",
  ]);

  return (
    <div className="formPage-wrap">
      {permissionsFlag ? (
        <div className=" ">
          <div className="w-full flex justify-center">
            <h2 className="header-h2">
              <RiKeyboardLine className="w-5 h-5" />
              Meet & Assist Service
            </h2>
          </div>

          <div
            className={`grid ${general ? "grid-cols-1 xl:grid-cols-3 md:grid-cols-2 " : "grid-cols-1 md:grid-cols-2"} form-wrap`}
          >
            {permissions.some((item) => item.name === "airport_services.add_airportlimousineservice" && item.value) && (
              <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                <Link
                  to={`./add-meet-service`}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <div className="relative flex flex-col">
                      <GiCommercialAirplane className="w-12 h-12  " />
                      <VscAdd className="absolute top-10 left-11 w-4 h-4" />
                    </div>
                  </div>
                </Link>
                <p className="text-md md:text-lg font-semibold">Add Meet & Assist Service</p>
              </div>
            )}

            <>
              {general &&
                permissions.some(
                  (item) => item.name === "permissions_management.meet_assist_list_under_review" && item.value,
                ) && (
                  <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                    <Link
                      to={`./UnderReview`}
                      className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-emerald-700 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                    >
                      <div className="w-full flex justify-center items-center ">
                        <SiCodereview className="w-12 h-12   " />
                      </div>
                    </Link>

                    <p className="text-md md:text-lg font-semibold">Under Review & Assist Service List</p>
                  </div>
                )}

              {permissions.some(
                (item) => item.name === "permissions_management.meet_assist_list_new" && item.value,
              ) && (
                <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                  <Link
                    to={general && `./New`}
                    className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                  >
                    <div className="w-full flex justify-center items-center ">
                      <IoIosList className="w-12 h-12   " />
                    </div>
                  </Link>

                  <p className="text-md md:text-lg font-semibold">New Meet & Assist Service List</p>
                </div>
              )}
              {general &&
                permissions.some(
                  (item) => item.name === "permissions_management.meet_assist_list_pending" && item.value,
                ) && (
                  <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                    <Link
                      to={`./Pending`}
                      className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-yellow-600 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                    >
                      <div className="w-full flex justify-center items-center ">
                        <MdOutlinePending className="w-12 h-12   " />
                      </div>
                    </Link>

                    <p className="text-md md:text-lg font-semibold">Pending Meet & Assist Service List</p>
                  </div>
                )}
              {general &&
                permissions.some(
                  (item) => item.name === "permissions_management.meet_assist_list_archive" && item.value,
                ) && (
                  <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                    <Link
                      to={`./Archive`}
                      className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-red-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                    >
                      <div className="w-full flex justify-center items-center ">
                        <FiArchive className="w-12 h-12   " />
                      </div>
                    </Link>

                    <p className="text-md md:text-lg font-semibold">Archive Meet & Assist Service List</p>
                  </div>
                )}

              {general &&
                permissions.some(
                  (item) => item.name === "permissions_management.meet_assist_list_soft_deleted" && item.value,
                ) && (
                  <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                    <Link
                      to={`/dashboard/meet-&-assist-service/soft-deleted`}
                      className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-red-700 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                    >
                      <div className="w-full flex justify-center items-center ">
                        <MdFolderDelete className="w-12 h-12   " />
                      </div>
                    </Link>

                    <p className="text-md md:text-lg font-semibold">Deleted Meet & Assist Service List</p>
                  </div>
                )}
            </>
          </div>
        </div>
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default AirportCar;
