import { combineReducers } from "redux";
import { uriReducer } from "./uriReducer";
import { isNightModeReducer } from "./dashboardNightModeReducer";
import { SearchFormReducer } from "./SearchFormReducer";
import hotelListReducer from "./hotelListReducer";
import formCountriesReducer from "./formCountriesReducer";
import formFeaturesReducer from "./formFeaturesReducer";
import { SelectedSearchReducer } from "./SelectedSearchReducer";
import { filterReducer } from "./filterReducer";
import { reservationReducer } from "./reservationReducer";
import { cardReducer } from "./cardReducer";
import { multiReservationReducer } from "./multiReservationReducer";
import hotelDetailsReducer from "./hotelDetailsReducer";
import hotelNamesReducer from "./hotelNamesReducer";
import { bookingListReducer } from "./bookingListReducer";
import { loginReducerUser } from "./loginReducerUser";
import { roomsReducer, hotelRoomsReducer, roomDetailsReducer } from "./roomsReducer";
import addContractReducer from "./addContractReducer";
import contractValidityReducer from "./contractValidityReducer";
import { transactionReducer } from "./transactionReducer";
import { collectionReducer } from "./collectionReducer";
import { refundReducer } from "./refundReducer";
import {
  ContractStatusesReducer,
  getAllContractsReducer,
  getSingleContractReducer,
  underReviewContractsReducer,
  archivedContractsReducer,
  hotelContractsReducer,
} from "./getContractsReducer";
import { allTransactionsReducer } from "./allTransactionsReducer";
import { cashReducer } from "./cashReducer";
import { paymentTransactionReducer } from "./paymentTransactionReducer";
import { cityHotelsReducer } from "./hotelListReducer";
import inventoryListReducer from "./inventoryReducer";
import { bankTransferReducer } from "./bankTransferReducer";
import { addBankReducer } from "./addBankReducer";
import { bookingMailReducer } from "./bookingMailReducer";
import {
  bookingRequestsReducer,
  allBookingRequestsReducer,
  bookingRequestTypeChoiceReducer,
  bookingRequestDetailsReducer,
  bookingRequestsChatsReducer,
  bookingsStatsReducer,
} from "./bookingRequestsReducer";
import { allMarkupsReducer, markupPriceTypesReducer, userGroupsReducer, singleMarkupReducer } from "./MarkupReducers";
import { posMachineReducer } from "./posMachineReducer";
import { posVisaReducer } from "./posVisaReducer";
import { newBatchReducer } from "./newBatchReducer";
import {
  CurrencyRatesReducer,
  CurrencyRatesHistoryReducer,
  CurrencyRatesHistoryDetailsReducer,
} from "./CurrencyRatesReducer";
import { convertingCurrencyReducer } from "./convertingCurrencyReducer";
import { allHotelsInfoReducer, singleHotelInfoReducer, getSibgleHotelInfoByIdReducer } from "./HotelInfoReducers";
import { departmentChoicesReducer, singleHotelContactReducer } from "./hotelContactReducers";
import { FilterBookingListReducer } from "./filterBookingListReducer";
import { addCashBoxReducer } from "./addCashBoxReducer";
import {
  hotelBankAccountsReducer,
  hotelsWithBankAccountsReducer,
  paymentDetailsSingleBookingReducer,
} from "./hotelPaymentsReducer";
import { walletReducer } from "./walletReducer";
import { cancellationPolicyListReducer, cancellationPolicyArchiveReducer } from "./CancellationPolicyReducer";
import { dataOfWhatsAppReducer } from "./dataOfWhatsAppReducer";
import contractChildrenMealsReducer from "./contractChildrenMealsReducer";
import { finishResrvationReducer } from "./finishResrvationReducer";

const reducers = combineReducers({
  loginReducerUser: loginReducerUser,
  isNightMode: isNightModeReducer,
  SearchFormReducer: SearchFormReducer,
  SelectedSearchReducer: SelectedSearchReducer,
  filterReducer: filterReducer,
  reservationReducer: reservationReducer,
  SearchFormgitReducer: SearchFormReducer,
  hotelList: hotelListReducer,
  formCountries: formCountriesReducer,
  formFeatures: formFeaturesReducer,
  cardReducer: cardReducer,
  multiReservationReducer: multiReservationReducer,
  hotelDetails: hotelDetailsReducer,
  hotelNames: hotelNamesReducer,
  rooms: roomsReducer,
  hotelRooms: hotelRoomsReducer,
  roomDetails: roomDetailsReducer,
  addContract: addContractReducer,
  bookingListReducer: bookingListReducer,
  contractValidity: contractValidityReducer,
  contractChildrenMeals: contractChildrenMealsReducer,
  transactionReducer: transactionReducer,
  collectionReducer: collectionReducer,
  refundReducer: refundReducer,
  transactionReducer: transactionReducer,
  collectionReducer: collectionReducer,
  refundReducer: refundReducer,
  getAllContracts: getAllContractsReducer,
  getSingleContract: getSingleContractReducer,
  allTransactionsReducer: allTransactionsReducer,
  cashReducer: cashReducer,
  paymentTransactionReducer: paymentTransactionReducer,
  cityHotels: cityHotelsReducer,
  inventoryList: inventoryListReducer,
  bankTransferReducer: bankTransferReducer,
  addBankReducer: addBankReducer,
  bookingMailReducer: bookingMailReducer,
  bookingRequests: bookingRequestsReducer,
  contractStatuses: ContractStatusesReducer,
  allBookingRequests: allBookingRequestsReducer,
  allMarkups: allMarkupsReducer,
  posMachineReducer: posMachineReducer,
  posVisaReducer: posVisaReducer,
  newBatchReducer: newBatchReducer,
  markupPriceTypes: markupPriceTypesReducer,
  userGroups: userGroupsReducer,
  posMachineReducer: posMachineReducer,
  posVisaReducer: posVisaReducer,
  singleMarkup: singleMarkupReducer,
  CurrencyRates: CurrencyRatesReducer,
  CurrencyRatesHistory: CurrencyRatesHistoryReducer,
  CurrencyRatesHistoryDetails: CurrencyRatesHistoryDetailsReducer,
  convertingCurrencyReducer: convertingCurrencyReducer,
  allHotelsInfo: allHotelsInfoReducer,
  singleHotelInfo: singleHotelInfoReducer,
  bookingRequestTypeChoice: bookingRequestTypeChoiceReducer,
  bookingRequestDetails: bookingRequestDetailsReducer,
  bookingRequestsChats: bookingRequestsChatsReducer,
  departmentChoices: departmentChoicesReducer,
  FilterBookingListReducer: FilterBookingListReducer,
  addCashBoxReducer: addCashBoxReducer,
  singleHotelContact: singleHotelContactReducer,
  FilterBookingListReducer: FilterBookingListReducer,
  getSibgleHotelInfoById: getSibgleHotelInfoByIdReducer,
  underReviewContracts: underReviewContractsReducer,
  archivedContracts: archivedContractsReducer,
  hotelContracts: hotelContractsReducer,
  bookingsStats: bookingsStatsReducer,
  hotelBankAccounts: hotelBankAccountsReducer,
  hotelsWithBankAccounts: hotelsWithBankAccountsReducer,
  walletReducer: walletReducer,
  paymentDetailsSingleBooking: paymentDetailsSingleBookingReducer,
  cancellationPolicyList: cancellationPolicyListReducer,
  cancellationPolicyArchive: cancellationPolicyArchiveReducer,
  dataOfWhatsAppReducer: dataOfWhatsAppReducer,
  uriReducer: uriReducer,
  finishResrvationReducer: finishResrvationReducer,
});
export default reducers;
