import React, { useEffect, useState } from "react";
import { AiFillBank, AiOutlineInfo, AiOutlineUnorderedList } from "react-icons/ai";
import { RiKeyboardLine } from "react-icons/ri";
import { FaFileContract } from "react-icons/fa";
import { useNavigate } from "react-router";
import { CiViewList } from "react-icons/ci";
import cookie from "react-cookies";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import { useDispatch } from "react-redux";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../NoPermission/NoPermission";
import { Link } from "react-router-dom";

export default function HotelMainInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: [
      "hotels_management.add_hotelcontact",
      "hotels_management.view_hotelcontact",
      "hotels_management.add_mainhotelinfo",
      "hotels_management.view_mainhotelinfo",
      "hotels_management.add_hotelbankaccountnumber",
      "hotels_management.view_hotelbankaccountnumber",
    ],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="formPage-wrap min-h-screen">
      {permissionFlag ? (
        permission.some((item) => item.value) ? (
          <div className="w-full">
            <div className="w-full flex justify-center   ">
              <h2 className="header-h2">
                <RiKeyboardLine className="w-5 h-5" />
                Hotel Main Info
              </h2>
            </div>
            <div className="w-full grid lg:grid-cols-4 md:grid-cols-3 text-center sm:grid-cols-2 grid-cols-1 form-wrap">
              {permission.map((item) => {
                if (item.name === "hotels_management.add_mainhotelinfo" && item.value === true) {
                  return (
                    <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                      <Link
                        to={"/dashboard/add-hotel-main-info"}
                        // onClick={() => navigate("/dashboard/add-hotel-main-info")}
                        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-teal-900 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        <div className="w-full flex justify-center items-center ">
                          <AiOutlineInfo className="w-12 h-12  " />
                        </div>
                      </Link>
                      <p className="text-lg font-semibold">Add Hotel Main Info</p>
                    </div>
                  );
                }
              })}

              {permission.map((item) => {
                if (item.name === "hotels_management.add_hotelbankaccountnumber" && item.value == true) {
                  return (
                    <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                      <Link
                        to={"/dashboard/add-hotel-bank-details"}
                        // onClick={() => navigate("/dashboard/add-hotel-bank-details")}
                        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-red-900 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        <div className="w-full flex justify-center items-center ">
                          <AiFillBank className="w-12 h-12  " />
                        </div>
                      </Link>
                      <p className="text-lg font-semibold">Add Hotel Bank Details</p>
                    </div>
                  );
                }
              })}

              {permission.map((item) => {
                if (item.name === "hotels_management.add_hotelcontact" && item.value === true) {
                  return (
                    <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                      <Link
                        to={"/dashboard/add-hotel-contract"}
                        // onClick={() => navigate("/dashboard/add-hotel-contract")}
                        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        <div className="w-full flex justify-center items-center ">
                          <FaFileContract className="w-12 h-12  " />
                        </div>
                      </Link>
                      <p className="text-lg font-semibold">Add Hotel Contact</p>
                    </div>
                  );
                }
              })}

              {permission.map((item) => {
                if (item.name === "hotels_management.view_mainhotelinfo" && item.value === true) {
                  return (
                    <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                      <Link
                        to={"/dashboard/hotel-main-info-list/id/1"}
                        // onClick={() => navigate("/dashboard/hotel-main-info-list/id/1")}
                        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        <div className="w-full flex justify-center items-center ">
                          <FaFileContract className="w-12 h-12  " />
                        </div>
                      </Link>
                      <p className="text-lg font-semibold">Hotel Main Info List</p>
                    </div>
                  );
                }
              })}

              {permission.map((item) => {
                if (item.name === "hotels_management.view_hotelbankaccountnumber" && item.value === true) {
                  return (
                    <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                      <Link
                        to={"./hotel-bank-details-list"}
                        // onClick={() => navigate("./hotel-bank-details-list")}
                        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-teal-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        <div className="w-full flex justify-center items-center ">
                          <AiOutlineUnorderedList className="w-12 h-12  " />
                        </div>
                      </Link>
                      <p className="text-lg font-semibold">Hotel Bank Details List</p>
                    </div>
                  );
                }
              })}

              {permission.map((item) => {
                if (item.name === "hotels_management.view_hotelcontact" && item.value === true) {
                  return (
                    <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                      <Link
                        to={"/dashboard/add-hotel-info/hotel-contact-list/1/-id"}
                        // onClick={() => navigate("./hotel-contract-list")}
                        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-red-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        <div className="w-full flex justify-center items-center ">
                          <CiViewList className="w-12 h-12  " />
                        </div>
                      </Link>
                      <p className="text-lg font-semibold">Hotel Contact List</p>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        ) : (
          <div className="w-full h-screen">
            <NoPermission />
          </div>
        )
      ) : (
        <div className="flex items-center justify-center w-full h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
