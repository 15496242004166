import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import { Modal, ModalHeader } from "flowbite-react";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "react-image-lightbox/style.css";
import "../BookingListTableSingleId/BookingListTableSingle.css";
import "reactjs-popup/dist/index.css";
import "@splidejs/react-splide/css";
import Loading from "../Loading/Loading";
import { format, subDays, parseISO } from "date-fns";

import { useTranslation } from "react-i18next";
import {
  exchangeRate,
  exchangeRateFixed,
  getCancellationHotel,
  hotelInfoDATA,
} from "../../../redux/actions/cancellation";
import { FaCheck } from "react-icons/fa";

const CancelationModal = ({
  item,
  price,

  currencySearch,

  offerType,

  hotel,

  startDate,

  hotelId,
  contractOfferID,
}) => {
  const [data, setData] = useState();
  const [rate, setRate] = useState();
  const [roomsData, setAllRoomsData] = useState();
  const [freeVal, setFreeVal] = useState(true);
  // Function to adjust the date by a specified number of days
  // Function to adjust the date by a specified number of days
  // Function to adjust the date by a specified number of days
  const adjustDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const date = startDate ? startDate[0] : null;

  // Sort charges by num_days in descending order
  const sortedCharges = data && data[0]?.cancellation_charge?.sort((a, b) => b.num_days - a.num_days);

  // Get the maximum number of days from the charges
  const maxNumDays = data ? sortedCharges && sortedCharges[0]?.num_days : 1;

  // Initialize the start date based on the maxNumDays
  let currentStartDate = adjustDays(new Date(date), -maxNumDays);

  const chargesWithDates = sortedCharges?.map((charge, index) => {
    // Calculate the period for the current charge
    const nextChargeNumDays = sortedCharges[index + 1]?.num_days || 0;
    const numDaysForCurrentPeriod = charge.num_days - nextChargeNumDays;

    // Calculate the toDate by adding the number of days for the current period - 1 to the currentStartDate
    const toDate = adjustDays(currentStartDate, numDaysForCurrentPeriod - 1);

    const chargeDates = {
      charge: charge.charge,
      fromDate: format(new Date(currentStartDate), "yyyy-MM-dd"),
      toDate: format(new Date(toDate), "yyyy-MM-dd"),
    };

    // Update currentStartDate for the next charge period (next day after the current period ends)
    currentStartDate = adjustDays(new Date(toDate), 1);

    return chargeDates;
  });

  // Ensure the last charge period ends at the provided start date (search date)
  if (chargesWithDates?.length > 0) {
    const lastChargePeriod = chargesWithDates[chargesWithDates?.length - 1];
    lastChargePeriod.toDate = format(new Date(date), "yyyy-MM-dd");
  }
  const firstDate = chargesWithDates?.[0]?.fromDate ? adjustDays(new Date(chargesWithDates[0].fromDate), -1) : null;

  const formattedFirstDate = firstDate ? format(new Date(firstDate), "yyyy-MM-dd") : null;
  console.log(firstDate);
  // Reverse the order of the results
  const reversedChargesWithDates = data && chargesWithDates?.reverse();

  // Resulting date after subtraction
  const { moreRoomsData } = useSelector((state) => state.SelectedSearchReducer);
  const { singleRoomsData } = useSelector((state) => state.SelectedSearchReducer);
  useEffect(() => {
    if (moreRoomsData) {
      setAllRoomsData(moreRoomsData);
    }
  }, [moreRoomsData]);

  function compareDates(dateString1, dateObj2) {
    // Convert date1 (string) to a Date object
    const dateObj1 = new Date(dateString1);
    if (dateObj1.getTime() < dateObj2.getTime()) {
      setFreeVal(false);
    }
  }

  const hotelData = hotel;
  // const date = startDate && startDate[0];
  console.log(date);
  const [mergedFirstNight, setFirstNightMerged] = useState();
  const [mergedsecondNight, setSecondNightMerged] = useState();
  const [mergedThirdNight, setThirdNightMerged] = useState();
  const fetchData = (index) => {
    dispatch(
      getCancellationHotel(token, date, hotelId, contractOfferID, (result) => {
        if (result.status === 200) {
          setData(result?.data);
        }
      }),
    );
    console.log(singleRoomsData);
    const mergedFirstNight =
      singleRoomsData?.length > 0 &&
      singleRoomsData?.map(
        (room) =>
          room.rooms?.[index + 1]?.merged_contracts?.shared_validities?.[index + 1]?.days_data?.days_list[0]
            .total_price,
      );
    setFirstNightMerged(mergedFirstNight);
    const SecondNightMerged =
      singleRoomsData?.length > 0 &&
      singleRoomsData?.map(
        (room) =>
          room.rooms?.[index + 1]?.merged_contracts?.shared_validities?.[index + 1]?.days_data?.days_list[1]
            .total_price,
      );
    setSecondNightMerged(SecondNightMerged);
    const ThirdNightMerged =
      singleRoomsData?.length > 0 &&
      singleRoomsData?.map(
        (room) =>
          room.rooms?.[index + 1]?.merged_contracts?.shared_validities?.[index + 1]?.days_data?.days_list[2]
            .total_price,
      );
    setThirdNightMerged(ThirdNightMerged);
  };

  const [firstNightPrices, setFirstNightPrices] = useState();
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [chargPrice, setChargePrice] = useState();
  const [priceData, setTotalPriceData] = useState(0);
  const [detailsFlag, setdetailsFlag] = React.useState(false);
  const [fixedCurrency, setFixedCurrency] = React.useState();
  const [fixedCurrencyData, setFixedCurrencyData] = React.useState();
  const [roomDetails, setRoomDetails] = useState(false);
  const token = cookie.load("access_token");
  const [t, i18n] = useTranslation();
  const localeCode = t("localeCode");
  function replaceWithEasternArabicNumerals2(input) {
    // Convert input to a string if it's not already
    const inputString = String(input);

    console.log(inputString); // For debugging

    const westernToEasternMap = {
      0: "٠",
      1: "١",
      2: "٢",
      3: "٣",
      4: "٤",
      5: "٥",
      6: "٦",
      7: "٧",
      8: "٨",
      9: "٩",
    };

    // Replace Western Arabic numerals with Eastern Arabic numerals
    return inputString.replace(/[0-9]/g, function (match) {
      return westernToEasternMap[match];
    });
  }
  function addDays(date, days, locale) {
    console.log(locale);
    console.log(days);
    console.log(date);
    const result = new Date(date);
    result?.setDate(result?.getDate() + days);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };

    if (locale === "ar-EG") {
      options.day = "2-digit"; // Arabic numerals will be used automatically
      options.month = "short"; // Arabic month names will be used automatically
      options.year = "numeric"; // Arabic numerals for the year will be used automatically
    }

    return result.toLocaleDateString(locale, options);
  }
  // function subDays(date, days, locale) {
  //   console.log(locale);
  //   console.log(days);
  //   console.log(date);
  //   const result = new Date(date);
  //   result?.setDate(result?.getDate() - days);
  //   const options = {
  //     day: "2-digit",
  //     month: "short",
  //     year: "numeric",
  //   };

  //   if (locale === "ar-EG") {
  //     options.day = "2-digit"; // Arabic numerals will be used automatically
  //     options.month = "short"; // Arabic month names will be used automatically
  //     options.year = "numeric"; // Arabic numerals for the year will be used automatically
  //   }

  //   return result.toLocaleDateString(locale, options);
  // }

  const contractValidity = item?.validities && Object.entries(item?.validities)[0]?.[1]?.days_data?.days_list;

  const textEx = item?.validities && Object.entries(item?.validities)[0]?.[1]?.days_data?.days_list;
  const [initialDate, setInitialDate] = useState(data?.map((item) => item?.cancellation_valid_from) || []); // Ensure initialDate is initialized as an array
  const CityTime = data && data?.map((item) => (item?.cancellation_time === "city_local_time" ? true : false));
  // const totalPrice = priceData || 0; // Ensure totalPrice has a default value
  const firstNightPriceData = textEx && textEx[0] ? textEx[0] : {};

  // Ensure firstNightPriceData is initialized as an object if undefined
  const textExMerged = singleRoomsData[0].rooms[0].merged_contracts.shared_validities[0]?.days_data?.days_list;
  const firstNightPriceDataMerged = textExMerged && textExMerged[0] ? textExMerged[0] : {};
  const getPrice = (index) => {
    const offer = firstNightPriceData?.meals_offers?.[offerType]; // Check if meals_offers and offerType are defined
    const price = offer ? offer.total_price * rate : firstNightPriceData?.total_price * rate;
    return isNaN(price) ? 0 : price; // Ensure the returned value is a number
  };
  const getPriceMerged = (index) => {
    const offer = firstNightPriceDataMerged?.meals_offers?.[offerType]; // Check if meals_offers and offerType are defined
    const price = offer ? offer.total_price * rate : firstNightPriceDataMerged?.total_price * rate;
    return isNaN(price) ? 0 : price; // Ensure the returned value is a number
  };

  const firstNightPrice = getPrice(0) || getPriceMerged(0);
  const secondNightPrice = getPrice(1) || getPriceMerged(1);
  const thirdNightPrice = getPrice(2) || getPriceMerged(2);
  console.log(secondNightPrice);

  const secondNightPriceFormatted =
    typeof secondNightPrice === "number" ? (
      Math.ceil(secondNightPrice)
    ) : mergedsecondNight ? (
      mergedsecondNight
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <circle fill="#FF156D" stroke="#FF156D" stroke-width="15" r="15" cx="40" cy="100">
          <animate
            attributeName="opacity"
            calcMode="spline"
            dur="2"
            values="1;0;1;"
            keySplines=".5 0 .5 1;.5 0 .5 1"
            repeatCount="indefinite"
            begin="-.4"
          ></animate>
        </circle>
        <circle fill="#FF156D" stroke="#FF156D" stroke-width="15" r="15" cx="100" cy="100">
          <animate
            attributeName="opacity"
            calcMode="spline"
            dur="2"
            values="1;0;1;"
            keySplines=".5 0 .5 1;.5 0 .5 1"
            repeatCount="indefinite"
            begin="-.2"
          ></animate>
        </circle>
        <circle fill="#FF156D" stroke="#FF156D" stroke-width="15" r="15" cx="160" cy="100">
          <animate
            attributeName="opacity"
            calcMode="spline"
            dur="2"
            values="1;0;1;"
            keySplines=".5 0 .5 1;.5 0 .5 1"
            repeatCount="indefinite"
            begin="0"
          ></animate>
        </circle>
      </svg>
    ); // Ensure secondNightPriceFormatted is a number
  const thirdNightPriceFormatted =
    typeof thirdNightPrice === "number" ? (
      Math.ceil(thirdNightPrice)
    ) : mergedThirdNight ? (
      mergedThirdNight
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <circle fill="#FF156D" stroke="#FF156D" stroke-width="15" r="15" cx="40" cy="100">
          <animate
            attributeName="opacity"
            calcMode="spline"
            dur="2"
            values="1;0;1;"
            keySplines=".5 0 .5 1;.5 0 .5 1"
            repeatCount="indefinite"
            begin="-.4"
          ></animate>
        </circle>
        <circle fill="#FF156D" stroke="#FF156D" stroke-width="15" r="15" cx="100" cy="100">
          <animate
            attributeName="opacity"
            calcMode="spline"
            dur="2"
            values="1;0;1;"
            keySplines=".5 0 .5 1;.5 0 .5 1"
            repeatCount="indefinite"
            begin="-.2"
          ></animate>
        </circle>
        <circle fill="#FF156D" stroke="#FF156D" stroke-width="15" r="15" cx="160" cy="100">
          <animate
            attributeName="opacity"
            calcMode="spline"
            dur="2"
            values="1;0;1;"
            keySplines=".5 0 .5 1;.5 0 .5 1"
            repeatCount="indefinite"
            begin="0"
          ></animate>
        </circle>
      </svg>
    ); // Ensure secondNightPriceFormatted is a number

  const firstTwoNightsPrice = Number(firstNightPrice) + Number(secondNightPriceFormatted);
  const firstThreeNightsPrice =
    Number(firstNightPrice) + Number(secondNightPriceFormatted) + Number(thirdNightPriceFormatted);

  useEffect(() => {
    setFirstNightPrices(firstNightPriceData.meals_offers);
    data &&
      data?.length > 0 &&
      data?.map((item) =>
        item?.cancellation_charge?.map((ch) => ch?.charge === "fixed_amount" && setChargePrice(ch?.price)),
      );
  }, [data, firstNightPriceData.meals_offers]);

  const fixedPrice = Number(chargPrice);

  const mealType =
    offerType === "b.o"
      ? "Bed Only"
      : offerType === "b.b"
        ? "breakfast included"
        : offerType === "h.b"
          ? "half board"
          : offerType === "f.b"
            ? "full board"
            : offerType === "s.a"
              ? "Soft All"
              : "";

  const { dataFromSearchForReseve } = useSelector((state) => state.reservationReducer);

  const dateFrom = dataFromSearchForReseve?.from_date;

  // const searchCancelation = {};
  const [timeWorking, setTimeWorking] = useState();
  const [dayOfWeek, setDayOfWeek] = useState();
  const contractCurrency = item?.currency;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function replaceWithEasternArabicNumerals(input) {
    // Mapping of Western Arabic numerals to Eastern Arabic numerals
    const westernToEasternMap = {
      0: "٠",
      1: "١",
      2: "٢",
      3: "٣",
      4: "٤",
      5: "٥",
      6: "٦",
      7: "٧",
      8: "٨",
      9: "٩",
    };

    // Replace each numeral in the input string
    return input?.replace(/[0-9]/g, function (match) {
      return westernToEasternMap[match] || timeWorking;
    });
  }

  function arabicToDecimal(arabicNum) {
    const arabicToDecimalMap = {
      "٠": "0",
      "١": "1",
      "٢": "2",
      "٣": "3",
      "٤": "4",
      "٥": "5",
      "٦": "6",
      "٧": "7",
      "٨": "8",
      "٩": "9",
    };

    let decimalNum = "";
    for (let i = 0; i < arabicNum.length; i++) {
      const digit = arabicNum[i];
      if (arabicToDecimalMap[digit] !== undefined) {
        decimalNum += arabicToDecimalMap[digit];
      } else {
        decimalNum += digit;
      }
    }

    return decimalNum;
  }

  const fetchHotelInfo = () => {
    try {
      dispatch(
        hotelInfoDATA(hotelId, (result) => {
          setTimeWorking(result?.data[0]?.reservations_department_working_hours?.friday?.to || t("midnight"));
        }),
      );
    } catch (error) {
      console.error("Error fetching hotel info:", error);
      setTimeWorking(t("midnight"));
    }
  };

  const [showNote, setShowNote] = useState(false);
  const [resultDate, setResultDate] = useState(initialDate); // Resulting date after subtraction

  const fetchCurrExchabge = () => {
    dispatch(
      exchangeRate(contractCurrency, currencySearch, (res) => {
        if (res.status === 200 && contractCurrency !== currencySearch) {
          setRate(res?.data?.results[0]?.rate);
        } else {
          setRate(1);
        }
      }),
    );
  };

  const fixedDataPriceExcahnge = () =>
    dispatch(
      exchangeRateFixed((res) => {
        setFixedCurrencyData(res.data.results);
        // Log the response
      }),
    );

  const toggleNote = (index) => {
    setShowNote(!showNote);
    setOpenModal(true);
    fetchData(index);
    fixedDataPriceExcahnge();
    fetchCurrExchabge();
  };

  useEffect(() => {
    if (data) {
      const newData = data?.map((item) =>
        item?.cancellation_charge?.filter((ch) => ch?.charge === "fixed_amount" && ch?.currency),
      );
      const newDataCurrency = newData?.length !== 0 ? newData[0][0]?.currency : "";

      setFixedCurrency(newDataCurrency);
    }
  }, [data]);
  const zoomRef = React.useRef(null);
  const images = [
    roomDetails &&
      roomDetails.room_images.map((item) => {
        return { src: item.image, description: roomDetails.description };
      }),
  ];

  // const closeModal = () => setOpen(false);

  const [twoNightDate, setTwoNightDate] = useState();
  const [threeNightDate, setThreeNightDate] = useState();
  const [wholeDate, setWholeDate] = useState();
  const [firstNightDate, setFirstNightDate] = useState();

  const [percentageAmountDate, setPercentageAmountDate] = useState();
  const [fixedAmountDate, setFixedAmountDate] = useState();

  const DifferenceOfDays = (day1, day2) => {
    return Math.abs(day2 - day1); // Calculate the absolute difference between two numbers
  };

  const calculateDifference = () => {
    const datePairs = [
      [firstNightDate, twoNightDate],
      [firstNightDate, threeNightDate],
      [firstNightDate, percentageAmountDate],
      [firstNightDate, wholeDate],
      [firstNightDate, fixedAmountDate],
      [twoNightDate, threeNightDate],
      [twoNightDate, percentageAmountDate],
      [twoNightDate, wholeDate],
      [twoNightDate, fixedAmountDate],
      [threeNightDate, percentageAmountDate],
      [threeNightDate, wholeDate],
      [threeNightDate, fixedAmountDate],
      [percentageAmountDate, wholeDate],
      [percentageAmountDate, fixedAmountDate],
      [wholeDate, fixedAmountDate],
    ];

    for (const [day1, day2] of datePairs) {
      if (day1 !== undefined && day2 !== undefined) {
        return DifferenceOfDays(day1, day2);
      }
    }

    return null; // or any default value if no pairs are found
  };

  // Call the function to get the result
  const differenceInDays = calculateDifference();
  console.log(differenceInDays);
  const [adjustDatesVal, setAdjustDates] = useState([]);
  const [adjustDateVal1, setAdjustDate1] = useState([]);
  const [adjustDateVal2, setAdjustDate2] = useState();
  const [adjustDateVal3, setAdjustDate3] = useState();
  const [adjustDateVal4, setAdjustDate4] = useState();
  const [adjustDateVal5, setAdjustDate5] = useState();
  const [adjustDateVal6, setAdjustDate6] = useState();
  const [adjustDateVal11, setAdjustDate11] = useState([]);
  const [adjustDateVal22, setAdjustDate22] = useState();
  const [adjustDateVal33, setAdjustDate33] = useState();
  const [adjustDateVal44, setAdjustDate44] = useState();
  const [adjustDateVal55, setAdjustDate55] = useState();
  const [adjustDateVal111, setAdjustDate111] = useState();
  const [adjustDateVal222, setAdjustDate222] = useState();
  const [adjustDateVal333, setAdjustDate333] = useState();
  const [adjustDateVal444, setAdjustDate444] = useState();
  const [adjustDateVal555, setAdjustDate555] = useState();
  // const [adjustDateVal666, setAdjustDate666] = useState();
  const [USDAED, setUSDAED] = useState(null);
  const [USDSAR, setUSDSAR] = useState(null);
  const [SARUSD, setSARUSD] = useState(null);
  const [SAREGP, setSAREGP] = useState(null);
  const [SARAED, setSARAED] = useState(null);
  const [AEDSAR, setAEDSAR] = useState(null);
  const [AEDUSD, setAEDUSD] = useState(null);
  const [AEDEGP, setAEDEGP] = useState(null);
  const [USDEGP, setUSDEGP] = useState(null);
  const [EGPSAR, setEGPSAR] = useState(null);
  const [EGPUSD, setEGPUSD] = useState(null);
  const [EGPAED, setEGPAED] = useState(null);
  useEffect(() => {
    const fetchExchangeRate = () => {
      const currencyMappings = [
        { from: "SAR", to: "USD", setter: setUSDSAR },
        { from: "SAR", to: "AED", setter: setSARAED },
        { from: "SAR", to: "EGP", setter: setSAREGP },
        { from: "USD", to: "EGP", setter: setEGPUSD },
        { from: "USD", to: "AED", setter: setAEDUSD },
        { from: "USD", to: "SAR", setter: setSARUSD },
        { from: "USD", to: "EGP", setter: setUSDEGP },
        { from: "EGP", to: "AED", setter: setEGPAED },
        { from: "EGP", to: "USD", setter: setEGPUSD },
        { from: "EGP", to: "SAR", setter: setEGPSAR },
        { from: "AED", to: "USD", setter: setUSDAED },
        { from: "AED", to: "EGP", setter: setAEDEGP },
        { from: "AED", to: "SAR", setter: setAEDSAR },
      ];

      currencyMappings.forEach(({ from, to, setter }) => {
        const rate = fixedCurrencyData?.find((item) => item?.from_currency === from && item?.to_currency === to)?.rate;
        if (rate) setter(rate);
      });
    };

    fetchExchangeRate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedCurrencyData]);

  useEffect(() => {
    data &&
      data?.map((item) => {
        item?.cancellation_charge?.map((ch) =>
          ch?.charge === "first_two_night"
            ? setTwoNightDate(Number(ch.num_days))
            : ch?.charge === "first_three_night"
              ? setThreeNightDate(Number(ch.num_days))
              : ch?.charge === "whole_period"
                ? setWholeDate(Number(ch.num_days))
                : ch?.charge === "first_night"
                  ? setFirstNightDate(Number(ch.num_days))
                  : ch?.charge === "percentage_amount"
                    ? setPercentageAmountDate(Number(ch.num_days))
                    : setFixedAmountDate(Number(ch.num_days)),
        );
      });
  }, [data]);

  const newDate1 = dateFrom && new Date(dateFrom);
  console.log(dateFrom);
  const initialFirstDate = dateFrom && new Date(dateFrom);
  const initiallastDate = dateFrom && new Date(dateFrom);
  const oneDayBefore = new Date(initialFirstDate);
  oneDayBefore.setDate(oneDayBefore.getDate() - 1);
  console.log(oneDayBefore);
  useEffect(() => {
    if (data && data.length > 0) {
      const formatDate = (date, locale) => {
        return new Date(date).toLocaleDateString(locale, {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
      };

      const locale = t("lan") === "ar" ? "ar-EG" : "en-GB";

      const adjustedDates = data?.map((item) => {
        const cancellationCharges = item?.cancellation_charge || [];
        const formattedDates = cancellationCharges?.map((ch) => {
          const adjustDate = new Date(newDate1); // Assuming newDate1 is defined somewhere
          let adjustedDate = new Date(initialFirstDate); // Assuming initialFirstDate is defined somewhere

          switch (ch?.charge) {
            case "first_two_night":
              adjustDate.setDate(adjustDate.getDate() - Number(twoNightDate));
              break;
            case "first_three_night":
              adjustDate.setDate(adjustDate.getDate() - Number(threeNightDate));
              break;

            case "whole_period":
              adjustDate.setDate(adjustDate.getDate() - Number(wholeDate));
              break;
            case "first_night":
              adjustDate.setDate(adjustDate.getDate() - Number(firstNightDate));
              break;
            case "fixed_amount":
              adjustDate.setDate(adjustDate.getDate() - Number(fixedAmountDate));
              break;
            case "percentage_amount":
              adjustDate.setDate(adjustDate.getDate() - Number(percentageAmountDate));
              break;
            default:
              throw new Error("Invalid Charge Type");
          }

          return formatDate(new Date(adjustDate.getTime() + 1 * 24 * 60 * 60 * 1000), locale);
        });

        return formattedDates;
      });

      setAdjustDates(adjustedDates);
      setAdjustDate1(adjustedDates[0][0]);
      setAdjustDate2(adjustedDates[0][1]);
      setAdjustDate3(adjustedDates[0][2]);
      setAdjustDate4(adjustedDates[0][3]);
      setAdjustDate5(adjustedDates[0][4]);
      setAdjustDate6(adjustedDates[0][5]);

      const diffFirst = new Date(adjustDateVal2) - new Date(adjustDateVal1);
      const differenceInDays = diffFirst / (1000 * 60 * 60 * 24);
      const diffSecond = new Date(adjustDateVal3) - new Date(adjustDateVal2);
      const differenceInDaysSecond = diffSecond / (1000 * 60 * 60 * 24);
      const diffThird = new Date(adjustDateVal4) - new Date(adjustDateVal3);
      const differenceInDaysThird = diffThird / (1000 * 60 * 60 * 24);
      const diffFourth = new Date(adjustDateVal5) - new Date(adjustDateVal4);
      const differenceInDaysFourth = diffFourth / (1000 * 60 * 60 * 24);
      const diffFifth = new Date(adjustDateVal6) - new Date(adjustDateVal5);
      const differenceInDaysFifthth = diffFifth / (1000 * 60 * 60 * 24);

      var date1 = adjustDateVal1 && new Date(adjustDateVal1);
      adjustDateVal1 && date1.setDate(date1.getDate() + 1);
      var formattedDate1 = adjustDateVal1 && adjustDateVal1;

      var date11 = adjustDateVal1 && new Date(adjustDateVal1);
      adjustDateVal1 && date11.setDate(date11.getDate() + differenceInDays - 1);
      var formattedDate11 = adjustDateVal1 && adjustDateVal1;
      var date22 = adjustDateVal2 && new Date(adjustDateVal2);
      adjustDateVal2 && date22.setDate(date22.getDate() + differenceInDaysSecond - 1);
      var formattedDate22 = adjustDateVal2 && adjustDateVal2;
      var date33 = adjustDateVal3 && new Date(adjustDateVal3);
      adjustDateVal3 && date33.setDate(date33.getDate() + differenceInDaysThird - 1);
      var formattedDate33 = adjustDateVal3 && adjustDateVal3;

      var date44 = adjustDateVal4 && new Date(adjustDateVal4);
      adjustDateVal4 && date44.setDate(date44.getDate() + differenceInDaysFourth - 1);
      var formattedDate44 = adjustDateVal4 && adjustDateVal4;
      var date55 = adjustDateVal5 && new Date(adjustDateVal5);
      adjustDateVal5 && date55.setDate(date55.getDate() + differenceInDaysFifthth - 1);
      var formattedDate55 = adjustDateVal5 && adjustDateVal5;

      var date2 = adjustDateVal2 && new Date(adjustDateVal2);
      adjustDateVal2 && date2.setDate(date2.getDate() + 1);
      var formattedDate2 = adjustDateVal2 && adjustDateVal2;
      var date3 = adjustDateVal3 && new Date(adjustDateVal3);
      adjustDateVal3 && date3.setDate(date3.getDate() + 1);
      var formattedDate3 = adjustDateVal3 && adjustDateVal3;

      var date4 = adjustDateVal4 && new Date(adjustDateVal4);
      adjustDateVal4 && date4.setDate(date4.getDate() + 1);
      var formattedDate4 = adjustDateVal4 && adjustDateVal4;
      var date5 = adjustDateVal5 && new Date(adjustDateVal5);
      adjustDateVal5 && date5.setDate(date4.getDate() + 1);
      var formattedDate5 = adjustDateVal5 && adjustDateVal5;

      setAdjustDate11(formattedDate1);
      setAdjustDate22(formattedDate2);
      setAdjustDate33(formattedDate3);
      setAdjustDate44(formattedDate4);
      setAdjustDate55(formattedDate5);
      setAdjustDate111(formattedDate11);
      setAdjustDate222(formattedDate22);
      setAdjustDate333(formattedDate33);
      setAdjustDate444(formattedDate44);
      setAdjustDate555(formattedDate55);
    }
  }, [data, adjustDateVal1]);

  const firstDateFrom = new Date(adjustDateVal111);
  // Add one day to the initial date
  firstDateFrom.setDate(firstDateFrom.getDate() + 1);

  // Format the new date
  const formattedfirstDateFrom =
    t("lan") === "ar"
      ? firstDateFrom.toLocaleDateString("ar-EG", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
      : firstDateFrom.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });

  const secondDateFrom = new Date(adjustDateVal222);

  // Add one day to the initial date
  secondDateFrom.setDate(secondDateFrom.getDate() + 1);

  // Format the new date
  const formattedSecondDateFrom =
    t("lan") === "ar"
      ? secondDateFrom.toLocaleDateString("ar-EG", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
      : secondDateFrom.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });

  const thirdDateFrom = new Date(adjustDateVal333);

  // Add one day to the initial date
  thirdDateFrom && thirdDateFrom.setDate(thirdDateFrom.getDate() + 1);

  // Format the new date
  const formattedThirdDateFrom =
    t("lan") === "ar"
      ? thirdDateFrom.toLocaleDateString("ar-EG", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
      : thirdDateFrom.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });

  const fourthDateFrom = new Date(adjustDateVal444);

  // Add one day to the initial date
  adjustDateVal444 && fourthDateFrom.setDate(fourthDateFrom.getDate() + 1);

  // Format the new date
  const formattedFourthDateFrom =
    t("lan") === "ar"
      ? fourthDateFrom.toLocaleDateString("ar-EG", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
      : fourthDateFrom.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
  adjustDateVal444 && fourthDateFrom.setDate(fourthDateFrom.getDate() + 1);

  const fifthDateFrom = new Date(adjustDateVal555);

  // Format the new date
  const formattedFifthDateFrom =
    t("lan") === "ar"
      ? fifthDateFrom.toLocaleDateString("ar-EG", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
      : fifthDateFrom.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
  adjustDateVal555 && fifthDateFrom.setDate(fifthDateFrom.getDate() + 1);

  // const adjustDateVal1=adjustDatesVal
  const largeNum = Math.max(
    firstNightDate || 0,
    twoNightDate || 0,
    threeNightDate || 0,
    wholeDate || 0,
    percentageAmountDate || 0,
    fixedAmountDate || 0,
  );

  // sub the day of today from the day of reservation
  const diffFirstNight = new Date(newDate1) - new Date();
  const diffsOfDays = Math.ceil(diffFirstNight / (1000 * 60 * 60 * 24)) + 1;

  largeNum && initialFirstDate.setDate(initialFirstDate.getDate() - largeNum);
  useEffect(() => {
    compareDates(initialFirstDate, new Date());
  }, [initialFirstDate]);

  function addOneDay(dateStr) {
    let date = new Date(dateStr); // Parse the date string to a Date object
    date.setDate(date.getDate() + 1); // Add one day
    return date.toISOString().split("T")[0]; // Return the new date in YYYY-MM-DD format
  }

  function getDateOutput(
    innerIndex,
    datesLength,
    adjustDateVal11,
    adjustDateVal22,
    adjustDateVal33,
    adjustDateVal44,
    adjustDateVal55,
  ) {
    const today = new Date();
    const formattedToday =
      t("lan") === "ar"
        ? today.toLocaleDateString("ar-EG", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          })
        : today.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          });

    const conditions = [
      {
        condition: innerIndex === 1 && innerIndex !== datesLength - 1,
        outcome: adjustDateVal11 ? formattedfirstDateFrom : null,
      },
      {
        condition: innerIndex === 2 && adjustDateVal22 && innerIndex !== datesLength - 1,
        outcome: adjustDateVal22 ? formattedSecondDateFrom : null,
      },
      {
        condition: innerIndex === 3 && adjustDateVal33 && innerIndex !== datesLength - 1,
        outcome: adjustDateVal33 ? formattedThirdDateFrom : null,
      },
      {
        condition: innerIndex === 4 && innerIndex !== datesLength - 1 && adjustDateVal44,
        outcome: adjustDateVal44 ? formattedFourthDateFrom : null,
      },
      {
        condition: innerIndex === 5 && innerIndex !== datesLength - 1 && adjustDateVal55,
        outcome: adjustDateVal55 ? formattedFifthDateFrom : null,
      },
      {
        condition: innerIndex === 0,
        outcome:
          t("lan") === "ar"
            ? new Date(initialFirstDate.getTime() + 1 * 24 * 60 * 60 * 1000).toLocaleDateString("ar-EG", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
            : new Date(initialFirstDate.getTime() + 1 * 24 * 60 * 60 * 1000).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }),
      },
      {
        condition: innerIndex === 1 && innerIndex === datesLength - 1,
        outcome: adjustDateVal11 ? formattedfirstDateFrom : null,
      },
      {
        condition: innerIndex === 2 && adjustDateVal22 && innerIndex === datesLength - 1,
        outcome: adjustDateVal22 ? formattedSecondDateFrom : null,
      },
      {
        condition: innerIndex === 3 && adjustDateVal33 && innerIndex === datesLength - 1,
        outcome: adjustDateVal33 ? formattedThirdDateFrom : null,
      },
      {
        condition: innerIndex === 4 && innerIndex === datesLength - 1 && adjustDateVal44,
        outcome: adjustDateVal44 ? formattedFourthDateFrom : null,
      },
      {
        condition: innerIndex === 5 && innerIndex === datesLength - 1 && adjustDateVal55,
        outcome: adjustDateVal55 ? formattedFifthDateFrom : null,
      },
      {
        condition: true,
        outcome:
          t("lan") === "ar"
            ? new Date(initialFirstDate.getTime() + 1 * 24 * 60 * 60 * 1000).toLocaleDateString("ar-EG", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
            : new Date(initialFirstDate.getTime() + 1 * 24 * 60 * 60 * 1000).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }),
      },
    ];
    const matchedCondition = conditions.find((cond) => cond.condition);
    let outcomeDateStr = matchedCondition ? matchedCondition.outcome : null;

    // Ensure the outcome date is not earlier than today
    if (outcomeDateStr) {
      const [day, month, year] = outcomeDateStr.split(" ");
      const outcomeDate = new Date(`${day} ${month} ${year}`);
      if (outcomeDate < today) {
        outcomeDateStr = formattedToday;
      }
    }
    return outcomeDateStr;
  }

  function regularToArabic(number) {
    var regularToArabic = {
      0: "٠",
      1: "١",
      2: "٢",
      3: "٣",
      4: "٤",
      5: "٥",
      6: "٦",
      7: "٧",
      8: "٨",
      9: "٩",
    };
    var arabicNumber = "";
    for (var i = 0; i < number.length; i++) {
      arabicNumber += regularToArabic[number[i]] || number[i];
    }
    return arabicNumber;
  } // Function to add one day to an Arabic formatted date
  function addOneDayToArabicDate(arabicDate) {
    // Check if the Arabic date is empty or not in the expected format
    if (!arabicDate || typeof arabicDate !== "string") {
      return "Invalid date";
    }

    // Split the Arabic date into components
    var parts = arabicDate.trim().split(/\s+/);

    // Check if the date has three components (day, month, year)
    if (parts.length !== 3) {
      return "Invalid date";
    }
    var arabicToRegular = {
      "٠": "0",
      "١": "1",
      "٢": "2",
      "٣": "3",
      "٤": "4",
      "٥": "5",
      "٦": "6",
      "٧": "7",
      "٨": "8",
      "٩": "9",
    };
    var regularDay = "";
    for (var i = 0; i < parts[0].length; i++) {
      regularDay += arabicToRegular[parts[0][i]] || parts[0][i];
    }
    var regularYear = "";
    for (var j = 0; j < parts[2].length; j++) {
      regularYear += arabicToRegular[parts[2][j]] || parts[2][j];
    }
    // Debugging

    // Extract day, month, and year
    var day = parseInt(regularDay, 10);
    var month = parts[1];
    var year = parseInt(regularYear, 10);

    // Increment the day by 1
    day++;

    // Format the new date back into Arabic formatted date
    var newArabicDate = regularToArabic(day.toString()) + " " + month + " " + regularToArabic(year.toString());

    return newArabicDate;
  }

  // Usage
  const arabicNum = "١٢٣٤٥٦٧٨٩٠";

  // Output: 1234567890
  function getDateOutputAr(
    innerIndex,
    datesLength,
    adjustDateVal11,
    adjustDateVal22,
    adjustDateVal33,
    adjustDateVal44,
    adjustDateVal55,
  ) {
    const today = new Date();
    const formattedToday = today.toLocaleDateString("ar-EG", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const addOneDay = (date) => new Date(date.getTime() + 1 * 24 * 60 * 60 * 1000);
    const conditions = [
      {
        condition: innerIndex === 1 && innerIndex !== datesLength - 1,
        outcome: adjustDateVal11 ? addOneDayToArabicDate(adjustDateVal11) : null,
      },
      {
        condition: innerIndex === 2 && adjustDateVal22 && innerIndex !== datesLength - 1,
        outcome: adjustDateVal22 ? adjustDateVal22 && addOneDayToArabicDate(adjustDateVal22) : null,
      },
      {
        condition: innerIndex === 3 && adjustDateVal33 && innerIndex !== datesLength - 1,
        outcome: adjustDateVal33 ? addOneDayToArabicDate(adjustDateVal33) : null,
      },
      {
        condition: innerIndex === 4 && innerIndex !== datesLength - 1 && adjustDateVal44,
        outcome: adjustDateVal44 ? addOneDayToArabicDate(adjustDateVal44) : null,
      },
      {
        condition: innerIndex === 5 && innerIndex !== datesLength - 1 && adjustDateVal55,
        outcome: adjustDateVal55 ? addOneDayToArabicDate(adjustDateVal55) : null,
      },
      {
        condition: innerIndex === 0,
        outcome: addOneDay(initialFirstDate).toLocaleDateString("ar-EG", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),
      },
      {
        condition: innerIndex === 1 && innerIndex === datesLength - 1,
        outcome: adjustDateVal11 ? addOneDayToArabicDate(adjustDateVal11) : null,
      },
      {
        condition: innerIndex === 2 && adjustDateVal22 && innerIndex === datesLength - 1,
        outcome: adjustDateVal22 ? addOneDayToArabicDate(adjustDateVal22) : null,
      },
      {
        condition: innerIndex === 3 && adjustDateVal33 && innerIndex === datesLength - 1,
        outcome: adjustDateVal33 ? addOneDayToArabicDate(adjustDateVal33) : null,
      },
      {
        condition: innerIndex === 4 && innerIndex === datesLength - 1 && adjustDateVal44,
        outcome: adjustDateVal44 ? addOneDayToArabicDate(adjustDateVal44) : null,
      },
      {
        condition: innerIndex === 5 && innerIndex === datesLength - 1 && adjustDateVal55,
        outcome: adjustDateVal55 ? addOneDayToArabicDate(adjustDateVal55) : null,
      },
      {
        condition: true,
        outcome: addOneDay(initialFirstDate).toLocaleDateString("ar-EG", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),
      },
    ];

    const matchedCondition = conditions.find((cond) => cond.condition);
    let outcomeDateStr = matchedCondition ? matchedCondition.outcome : null;
    // Ensure the outcome date is not earlier than today
    if (typeof outcomeDateStr === "string") {
      const [day, month, year] = outcomeDateStr.split(" ");
      const outcomeDate = `${day} ${month} ${year}`;

      if (new Date(outcomeDate) < formattedToday) {
        outcomeDateStr = formattedToday;
      }
    }
    console.log(outcomeDateStr);
    return outcomeDateStr;
  }

  const adjustedDates =
    data &&
    data.length > 0 &&
    adjustDatesVal.map((dates) => {
      return (
        dates &&
        dates.map((date, innerIndex) => {
          const adjustments = [
            adjustDateVal111,
            adjustDateVal222,
            adjustDateVal333,
            adjustDateVal444,
            adjustDateVal555,
          ];
          let adjustedDate = "";

          if (innerIndex < adjustments.length && innerIndex !== dates.length - 1) {
            adjustedDate = adjustments[innerIndex];
          } else if (innerIndex === dates.length - 1) {
            const locale = t("lan") === "ar" ? "ar-EG" : "en-GB";
            adjustedDate = new Date(initiallastDate.getTime()).toLocaleDateString(locale, {
              day: "2-digit",
              month: "short",
              year: "numeric",
            });
          }

          const currentDate = new Date();

          const ArCurrentDate = currentDate.toLocaleDateString("ar-EG", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          });

          const isPastDate =
            t("lan") === "ar"
              ? new Date(adjustedDate) < new Date(ArCurrentDate)
              : new Date(adjustedDate) < new Date(currentDate.toDateString());

          return {
            adjustedDate,
            isPastDate,
          };
        })
      );
    });

  const fromDate_diff = data && data[0]?.cancellation_charge?.map((i) => (i.num_days <= diffsOfDays ? true : false));
  useEffect(() => {}, [fromDate_diff]);

  return (
    <>
      <button
        data-modal-target="default-modal"
        data-modal-toggle="default-modal"
        className="flex transition duration-300 ease-in-out gap-2 items-center text-red-700 bg-white    hover:text-red-900 max-w-[fit-content]  focus:outline-none font-medium rounded-lg text-xs  text-center  my-1"
        type="button"
        onClick={() => {
          fetchHotelInfo();
          toggleNote(-1);
        }}
      >
        {t("cancellation")}
      </button>
      <div className="text-xs font-semibold flex gap-2   items-start ">
        <span className="flex items-start">
          <FaCheck className="text-green-700  md:pt-0 mt-1" />
        </span>
        <span className="">{t("VAT")}</span>
      </div>

      <Modal
        dismissible
        show={openModal}
        onClose={() => setOpenModal(false)}
        className=" flex justify-center items-center absolute z-[1000] "
      >
        <ModalHeader>
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white"> {t("cancellation")}</h3>
        </ModalHeader>
        {data ? (
          <Modal.Body className="max-md:p-0 text-center md:full h-full   overflow-hidden">
            {data?.length > 0 ? (
              <div className=" grid max-md:w-[90vw]     items-center md:px-4">
                <div className=" flex w-full  md:justify-between shadow-md rounded-lg border-2 items-center md:gap-x-5 mt-3  ">
                  <>
                    <div dir={t("dir")} className="flex w-full md:mx-auto justify-between  ">
                      <div
                        dir={t("dir")}
                        className="p-0  md:p-5 items-center md:gap-2  flex flex-[4]   max-md:border-b border-r border-l"
                      >
                        <div
                          className={
                            `${t("lan") === "ar"}`
                              ? "pr-2  md:py-5 py-7  h-full  space-y-4 flex flex-col flex-[1]"
                              : "pr-2 border-r md:py-5 py-7  h-full  space-y-4 flex flex-col flex-[1]"
                          }
                        >
                          <h2 className="w-full border-b md:text-lg pb-1 text-sm font-bold">{t("from_date")}</h2>
                          {
                            <ul className="flex flex-col items-center">
                              {data?.length > 0 && freeVal && (
                                <li className="md:text-lg text-xs font-bold  pb-4 gap-4 flex ">
                                  {t("lan") === "ar"
                                    ? new Date().toLocaleDateString("ar-EG", {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                      })
                                    : new Date().toLocaleDateString("en-GB", {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                      })}{" "}
                                </li>
                              )}
                              <li className="md:text-lg text-xs font-bold ">
                                <div className="gap-1">
                                  {chargesWithDates?.reverse()?.map((charge, index) => (
                                    <li key={index} className="md:text-lg text-xs font-bold  gap-2 flex ">
                                      {t("lan") === "ar"
                                        ? new Date(charge.fromDate).toLocaleDateString("ar-EG", {
                                            day: "2-digit",
                                            month: "short",
                                            year: "numeric",
                                          })
                                        : new Date(charge.fromDate).toLocaleDateString("en-GB", {
                                            day: "2-digit",
                                            month: "short",
                                            year: "numeric",
                                          })}
                                    </li>
                                  ))}
                                </div>
                              </li>
                            </ul>
                          }
                        </div>
                        <div />
                        <div
                          className={
                            `${t("lan")}` === "ar"
                              ? "pr-2 py-5  h-full  space-y-4 flex flex-col flex-[1.8] border-r"
                              : "pr-2 py-5  h-full  space-y-4 flex flex-col flex-[1.8] border-l"
                          }
                        >
                          <h2 className="w-full border-b md:text-md pb-1 text-lg font-bold">{t("to_date")}</h2>

                          <div className="flex flex-col   ">
                            <ul className="flex flex-col items-center ">
                              {freeVal && (
                                <li className="md:text-lg text-xs font-bold pb-4  md:gap-4 gap-2 flex">
                                  {console.log(
                                    firstDate.toLocaleDateString("ar-EG", {
                                      day: "2-digit",
                                      month: "short",
                                      year: "numeric",
                                    }),
                                  )}
                                  {t("lan") === "ar"
                                    ? firstDate.toLocaleDateString("ar-EG", {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                      })
                                    : firstDate.toLocaleDateString("en-GB", {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                      })}
                                  <span>
                                    {CityTime[0]
                                      ? " " + t("midnight")
                                      : t("lan") === "ar"
                                        ? timeWorking && replaceWithEasternArabicNumerals(timeWorking)
                                        : timeWorking}
                                  </span>
                                </li>
                              )}
                              <li className="md:text-lg text-xs font-bold ">
                                {chargesWithDates?.map((charge, index) => (
                                  <li key={index} className="md:text-lg text-xs font-bold   gap-2 flex ">
                                    {t("lan") === "ar"
                                      ? new Date(charge.toDate).toLocaleDateString("ar-EG", {
                                          day: "2-digit",
                                          month: "short",
                                          year: "numeric",
                                        })
                                      : new Date(charge.toDate).toLocaleDateString("en-GB", {
                                          day: "2-digit",
                                          month: "short",
                                          year: "numeric",
                                        })}
                                    <span>
                                      {CityTime[0]
                                        ? " " + t("midnight")
                                        : t("lan") === "ar"
                                          ? timeWorking && replaceWithEasternArabicNumerals(timeWorking)
                                          : timeWorking}
                                    </span>
                                  </li>
                                ))}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className=" md:px-2  items-center py-5 md:py-10 justify-center h-full flex flex-col flex-[.8]">
                        <h2 className="w-full border-b px-2 md:pb-2 pb-2 font-bold ">{t("charge")}</h2>
                        {freeVal && (
                          <span className="pt-3">
                            {initialFirstDate.toLocaleDateString("en-GB") !==
                              new Date().toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              }) &&
                              data?.length > 0 && (
                                <span className="font-bold text-black md:text-lg text-xs "> {t("free")}</span>
                              )}
                          </span>
                        )}
                        <p className="  flex flex-col md:gap-4 gap-2 md:mt-4 mt-3  md:text-lg text-xs h-full items-center justify-center text-gray-500 dark:text-gray-400">
                          {/* {console.log(totalPrice)} */}
                          {data && data?.length > 0 ? (
                            data?.map((item) => (
                              <div className="flex flex-col  ">
                                {item?.cancellation_charge?.map((ch, index) => (
                                  <div>
                                    {ch.charge === "whole_period" &&
                                    (item?.cancellation_charge.length - 1 === index || diffsOfDays >= ch?.num_days) ? (
                                      <p className={` text-black text-md md:text-lg font-semibold `}>
                                        {new Intl.NumberFormat(localeCode).format(price)}{" "}
                                        <span className=" md:text-md text-sm md:font-semibold text-yellow-600">
                                          {t(currencySearch)}
                                        </span>
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {ch.charge === "first_two_night" &&
                                    (item?.cancellation_charge.length - 1 === index || diffsOfDays >= ch?.num_days) ? (
                                      <p className=" text-black text-md md:text-lg md:font-semibold ">
                                        {new Intl.NumberFormat(localeCode).format(Math.ceil(firstTwoNightsPrice))}{" "}
                                        <span className=" md:text-md text-sm md:font-semibold text-yellow-600">
                                          {t(currencySearch)}
                                        </span>
                                      </p>
                                    ) : (
                                      ""
                                    )}

                                    {ch.charge === "first_three_night" &&
                                    (item?.cancellation_charge.length - 1 === index || diffsOfDays >= ch?.num_days) ? (
                                      <p className=" text-black text-md md:text-lg md:font-semibold ">
                                        {new Intl.NumberFormat(localeCode).format(Math.ceil(firstThreeNightsPrice))}{" "}
                                        <span className=" md:text-md text-sm md:font-semibold text-yellow-600">
                                          {t(currencySearch)}
                                        </span>
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {ch.charge === "first_night" &&
                                    (item?.cancellation_charge.length - 1 === index || diffsOfDays >= ch?.num_days) ? (
                                      <p className=" text-black text-md md:text-lg font-semibold ">
                                        {new Intl.NumberFormat(localeCode).format(Math.ceil(firstNightPrice))}{" "}
                                        <span className=" md:text-md text-sm md:font-semibold text-yellow-600">
                                          {t(currencySearch)}
                                        </span>
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    <p>
                                      {ch.charge === "percentage_amount" &&
                                        (item?.cancellation_charge.length - 1 === index ||
                                          diffsOfDays >= ch?.num_days) && (
                                          <p className=" text-black text-md md:text-lg font-semibold ">
                                            {currencySearch !== contractCurrency
                                              ? new Intl.NumberFormat(localeCode).format(
                                                  Math.ceil((ch?.price / 100) * price),
                                                )
                                              : new Intl.NumberFormat(localeCode).format(
                                                  Math.ceil((ch?.price / 100) * price),
                                                )}{" "}
                                            <span className=" md:text-md text-sm font-semibold text-yellow-600">
                                              {t(currencySearch)}
                                            </span>
                                          </p>
                                        )}
                                    </p>
                                    <p>
                                      {ch?.charge === "fixed_amount" &&
                                        (item?.cancellation_charge.length - 1 === index ||
                                          diffsOfDays >= ch?.num_days) && (
                                          <p className=" text-black text-md md:text-lg font-semibold ">
                                            {fixedCurrencyData &&
                                            fixedCurrency !== 0 &&
                                            fixedCurrency === "USD" &&
                                            currencySearch === "SAR"
                                              ? new Intl.NumberFormat(localeCode).format(
                                                  Math.ceil(Number(SARUSD) * fixedPrice),
                                                )
                                              : fixedCurrency !== 0 &&
                                                  fixedCurrency === "USD" &&
                                                  currencySearch === "USD"
                                                ? new Intl.NumberFormat(localeCode).format(
                                                    Math.ceil(Number(1) * fixedPrice),
                                                  )
                                                : fixedCurrency !== 0 &&
                                                    fixedCurrency === "SAR" &&
                                                    currencySearch === "SAR"
                                                  ? new Intl.NumberFormat(localeCode).format(
                                                      Math.ceil(Number(1) * fixedPrice),
                                                    )
                                                  : fixedCurrency !== 0 &&
                                                      fixedCurrency === "AED" &&
                                                      currencySearch === "AED"
                                                    ? new Intl.NumberFormat(localeCode).format(
                                                        Math.ceil(Number(1) * fixedPrice),
                                                      )
                                                    : fixedCurrency !== 0 &&
                                                        fixedCurrency === "EGP" &&
                                                        currencySearch === "EGP"
                                                      ? new Intl.NumberFormat(localeCode).format(
                                                          Math.ceil(Number(1) * fixedPrice),
                                                        )
                                                      : fixedCurrency !== 0 &&
                                                          fixedCurrency === "USD" &&
                                                          currencySearch === "EGP"
                                                        ? new Intl.NumberFormat(localeCode).format(
                                                            Math.ceil(Number(USDEGP) * fixedPrice),
                                                          )
                                                        : fixedCurrency !== 0 &&
                                                            fixedCurrency === "SAR" &&
                                                            currencySearch === "EGP"
                                                          ? new Intl.NumberFormat(localeCode).format(
                                                              Math.ceil(Number(SAREGP) * fixedPrice),
                                                            )
                                                          : fixedCurrency !== 0 &&
                                                              fixedCurrency === "EGP" &&
                                                              currencySearch === "SAR"
                                                            ? new Intl.NumberFormat(localeCode).format(
                                                                Math.ceil(Number(EGPSAR) * fixedPrice),
                                                              )
                                                            : fixedCurrency !== 0 &&
                                                                fixedCurrency === "EGP" &&
                                                                currencySearch === "AED"
                                                              ? new Intl.NumberFormat(localeCode).format(
                                                                  Math.ceil(Number(EGPAED) * fixedPrice),
                                                                )
                                                              : fixedCurrency !== 0 &&
                                                                  fixedCurrency === "EGP" &&
                                                                  currencySearch === "USD"
                                                                ? new Intl.NumberFormat(localeCode).format(
                                                                    Math.ceil(Number(EGPUSD) * fixedPrice),
                                                                  )
                                                                : fixedCurrency !== 0 &&
                                                                    fixedCurrency === "AED" &&
                                                                    currencySearch === "SAR"
                                                                  ? new Intl.NumberFormat(localeCode).format(
                                                                      Math.ceil(Number(AEDSAR) * fixedPrice),
                                                                    )
                                                                  : fixedCurrency !== 0 &&
                                                                      fixedCurrency === "USD" &&
                                                                      currencySearch === "AED"
                                                                    ? new Intl.NumberFormat(localeCode).format(
                                                                        Math.ceil(Number(AEDUSD) * fixedPrice),
                                                                      )
                                                                    : fixedCurrency !== 0 &&
                                                                        fixedCurrency === "AED" &&
                                                                        currencySearch === "USD"
                                                                      ? new Intl.NumberFormat(localeCode).format(
                                                                          Math.ceil(Number(USDAED) * fixedPrice),
                                                                        )
                                                                      : fixedCurrency === "AED" &&
                                                                          currencySearch === "EGP"
                                                                        ? new Intl.NumberFormat(localeCode).format(
                                                                            Math.ceil(Number(AEDEGP) * fixedPrice),
                                                                          )
                                                                        : fixedCurrency === "SAR" &&
                                                                          currencySearch === "USD" &&
                                                                          new Intl.NumberFormat(localeCode).format(
                                                                            Math.ceil(Number(USDSAR) * fixedPrice),
                                                                          )}

                                            <span className=" md:text-md text-sm font-semibold text-yellow-600">
                                              {" "}
                                              {t(currencySearch)}
                                            </span>
                                          </p>
                                        )}
                                    </p>
                                  </div>
                                ))}{" "}
                              </div>
                            ))
                          ) : (
                            <Loading />
                          )}
                        </p>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            ) : (
              <p className="text-center font-bold py-2">No cancellation Policy</p>
            )}
          </Modal.Body>
        ) : (
          <Loading />
        )}
      </Modal>
    </>
  );
};

export default CancelationModal;
