import React, { useEffect, useState } from "react";
import { RiKeyboardLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { VscAdd } from "react-icons/vsc";
import { FcMoneyTransfer } from "react-icons/fc";
import { BsFillCreditCardFill } from "react-icons/bs";
import { GiMoneyStack } from "react-icons/gi";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
import { getJumboAction } from "../../../redux/actions/transactionListAction";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../NoPermission/NoPermission";

const Transactions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const [isOpen, setIsOpen] = useState(false);
  const [check, setCheck] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const TransactionArray = ["Cash", "(POS) Visa", "Bank Transfer", "Jumbo4pay"];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleChoose = (item) => {
    if (item === "Jumbo4pay") {
      dispatch(getJumboAction(token, (result) => {}));
    }
  };

  /////permission/////////
  const permissionData = {
    permission_codes: [
      "room_booking.add_banktransfer",
      "room_booking.add_cashpaymenttransaction",
      "accounting.add_posmachinetransaction",
      "room_booking.add_payment",
    ],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        permission.some((item) => item.value) ? (
          <div className="w-full">
            <div className="w-full flex justify-center ">
              <h2 className="header-h2">
                {" "}
                <RiKeyboardLine className="w-5 h-5" />
                Transactions Panel
              </h2>
            </div>

            <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1  form-wrap">
              {permission.map((item) => {
                if (item.name === "room_booking.add_banktransfer" && item.value === true) {
                  return (
                    <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                      <Link
                        to={"./add-bank-transfer"}
                        // onClick={() => navigate("./add-bank-transfer")}
                        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-[#0C4A6E] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        <div className="w-full flex justify-center items-center ">
                          <VscAdd className="w-12 h-12  " />
                        </div>
                      </Link>
                      <p className="text-lg font-semibold">Add a Bank Transfer</p>
                    </div>
                  );
                } else if (item.name === "room_booking.add_cashpaymenttransaction" && item.value === true) {
                  return (
                    <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
                      <Link
                        to={"./add-cash"}
                        // onClick={() => navigate("./add-cash")}
                        className="flex items-center gap-x-1 w-24 h-24 rounded-full  bg-[#064E3B] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        <div className="w-full flex justify-center items-center ">
                          <FcMoneyTransfer className="w-12 h-12  " />
                        </div>
                      </Link>
                      <p className="text-lg font-semibold ">Add Cash</p>
                    </div>
                  );
                } else if (item.name === "accounting.add_posmachinetransaction" && item.value === true) {
                  return (
                    <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
                      <Link
                        to={"./add-pos-visa"}
                        // onClick={() => navigate("./add-pos-visa")}
                        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-[#881337] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        <div className="w-full flex justify-center items-center ">
                          <BsFillCreditCardFill className="w-12 h-12  " />
                        </div>
                      </Link>
                      <p className="text-lg font-semibold">Add POS(Visa)</p>
                    </div>
                  );
                } else if (item.name === "room_booking.add_payment" && item.value === true) {
                  return (
                    <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
                      <Link
                        to={"./add-jumbo"}
                        // onClick={() => navigate("./add-jumbo")}
                        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-[#262626] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        <div className="w-full flex justify-center items-center ">
                          <GiMoneyStack className="w-12 h-12  " />
                        </div>
                      </Link>
                      <p className="text-lg font-semibold">Add Jumbo4pay </p>
                    </div>
                  );
                }
              })}

              <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                <Link
                  to={"./selected-all-transactions"}
                  // onClick={() => {
                  //   navigate("./selected-all-transactions");
                  // }}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-600 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <AiOutlineUnorderedList className="w-12 h-12  " />
                  </div>
                </Link>
                <p className="text-lg font-semibold">All Transaction </p>
              </div>
            </div>

            <div className="flex justify-center w-full mt-10">
              {/* <button  onClick={()=>navigate('/dashboard/selected-transaction')}
                      className='flex justify-center items-center gap-x-1 w-96 h-9 rounded-md border border-gray-300 shadow-sm  bg-gray-100 text-sm font-medium text-gray-700 hover:bg-gray-200  active:scale-95 duration-300 transition-all '>
                Go To All Transaction  <CgPushRight className='w-4 h-4 mt-1' />
              </button> */}

              {/* <div className="relative inline-block text-left">
              <button  onClick={toggleDropdown}
                      className='flex justify-center items-center gap-x-1 w-96 h-9 rounded-md border border-gray-300 shadow-sm  bg-gray-100 text-sm font-medium text-gray-700 hover:bg-gray-200  active:scale-95 duration-300 transition-all '>
                 {transaction ? transaction : 'Transaction type '} <BiSolidDownArrow className='w-4 h-4 mt-1' />
              </button>
              {isOpen && 
                <div className="origin-top-right absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1" role="menu" aria-orientation="horizontal" aria-labelledby="options-menu">
                      {TransactionArray.map((item) => {
                        return(
                          <button  onClick={()=>{setIsOpen(false);setTransaction(item); handleChoose(item); setCheck(true) } } className="w-full flex items-center justify-around px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" >
                          {item}
                        </button>
                        )
                      })}
                      </div>
                </div>
              }
            </div> */}
            </div>
          </div>
        ) : (
          <div className="w-full h-screen">
            <NoPermission />
          </div>
        )
      ) : (
        <div className="flex items-center justify-center w-full h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default Transactions;
