import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import cookie from "react-cookies";
import { getClientRequestTag, postReservationRoomAction } from "../../../redux/actions/reservationAction";
import { MdOutlineDone } from "react-icons/md";
import { GiNightSleep, GiPerson } from "react-icons/gi";
import { multiReservationDataAction, savedButtonAction } from "../../../redux/actions/MultiReservationAction";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "./MultiReservationForm.css";
import { useTranslation } from "react-i18next";
import ClientNameReservationForm from "../ClientNameForm/ClientNameReservationForm";
import jwtDecode from "jwt-decode";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";

const MultiBookingForm = ({ roomData, currentStep, saveScale, setSaveBtn, saveBtn, key }) => {
  const token = cookie.load("access_token");
  const userID = jwtDecode(token).user_id;
  const { data: user } = useQuery({
    queryKey: ["userdata", userID],
    queryFn: () => {
      return fetchDataQuery(`/auth/api/v1/profile/${userID}/`);
    },
  });

  const userType = user?.user_type;
  const userEmail = userType === "b2b" || userType === "b2c" ? user?.email : "";
  const userMobileNumber = user?.profile?.mobile_numbers?.["1"];
  const userWhatsappNumber = user?.profile?.whats_app_numbers?.["1"];

  useEffect(() => {
    if (userEmail) {
      setEmail(userEmail);
    }
    if (userMobileNumber) {
      setPhonenumber(userMobileNumber);
    }

    if (userWhatsappNumber) {
      setWhatsappnumber(userWhatsappNumber);
    }
  }, [userEmail, userMobileNumber, userWhatsappNumber]);

  const [t, i18n] = useTranslation();
  const localeCode = t("localeCode");
  const dispatch = useDispatch();

  // useEffect(() => {}, [roomData]);

  const { clientRequestData } = useSelector((state) => state.reservationReducer);
  const { notificationResveFlag } = useSelector((state) => state.reservationReducer);
  const clientRequsetOption = [];

  clientRequestData.map((item) => {
    clientRequsetOption.push({ value: item.id, label: item.tag });
  });

  //get client request tag from api to put it as value in select option

  const [reservation, setReservation] = useState("reservation");
  const [clientnames, setClientName] = useState("");
  const [showPrice, setShowPrice] = useState(0);
  const [addtoprice, setAddtoprice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(parseInt(roomData.price));
  const [phonenumber, setPhonenumber] = useState("");
  const [whatsappnumber, setWhatsappnumber] = useState("");
  const [email, setEmail] = useState("");
  const [clientrequesttags, setClientrequesttags] = useState([]);
  const [voucher, setVoucher] = useState(false);
  const [clientrequest, setClientrequest] = useState("");
  const [clientNameAr, setClientNameAr] = useState([]);
  const [clientNameEn, setClientNameEn] = useState([]);
  const [validationOption, setValidationOption] = useState(false);
  const [arrivalTimeFrom, setArrivalTimeFrom] = useState("");
  const [arrivalTimeTo, setArrivalTimeTo] = useState("");

  const [validationPhone, setValidationPhone] = useState(false);
  const [submitPhone, setSubmitPhone] = useState(true);
  const [validationRadio, setValidationRadio] = useState(true);
  const [saveBtnStatus, setSaveBtnStatus] = useState(true);
  const [tagStatus, setTagStatus] = useState(false);
  const [ClientArray, setClientArray] = useState([]);

  const [numOfPerson, setNumOfPerson] = useState();

  useEffect(() => {
    dispatch(getClientRequestTag(token));
    setShowPrice(roomData.price);
    setNumOfPerson(parseInt(roomData.number_of_persons));
  }, []);

  const { dataFromSearchForReseve } = useSelector((state) => state.reservationReducer); // this date get from search when search

  const { saveBtnFlag } = useSelector((state) => state.multiReservationReducer);

  useEffect(() => {
    if ((numOfPerson && clientNameAr?.length > 0) || (numOfPerson && clientNameEn?.length > 0)) {
      const newClientArray = Array.from({ length: numOfPerson }, (value, index) => ({
        arabic_name: clientNameAr[index] || "",
        english_name: clientNameEn[index] || "",
      }));
      setClientArray(newClientArray);
    }
  }, [numOfPerson, clientNameAr, clientNameEn]);

  const handleTotalPrice = (e) => {
    // setShowPrice( parseInt(roomData.price) + parseInt(addtoprice) )
    setTotalPrice(parseInt(e) + parseInt(roomData.price));
  };
  const handlePhoneInputChange = (newPhoneNumber) => {
    setPhonenumber(newPhoneNumber);
    setSaveBtn(false);
  };

  const handleWhatsappnumber = (value) => {
    setWhatsappnumber(value);
    setSaveBtn(false);
  };

  const reservationData = {
    number_of_persons: roomData.number_of_persons,
    client_names: ClientArray,
    phone_number: phonenumber,
    whatsapp_number: whatsappnumber,
    booking_type: voucher ? "voucher" : "no voucher",
    email: email,
    room_id: roomData.room_id,
    currency_type: roomData.currency_type,
    client_requests_text: clientrequest,
    hotel: roomData.hotel,
    room: roomData.selectedroom,
    meal_plan: roomData.mealType,
    adults: roomData.number_of_persons,
    client_requests_tags: tagStatus ? [clientrequesttags] : clientrequesttags,
    arrival_time_from: arrivalTimeFrom,
    arrival_time_to: arrivalTimeTo,
    add_to_total_price: parseInt(addtoprice),
    total_price: parseInt(roomData.price),
    from_date: roomData.formCheckIn_whats_app,
    to_date: roomData.formCheckOut_whats_app,
    nights: roomData.nights_whats_app,
    nationality: roomData.nationality,
    payment_type: roomData.payment_type,
    inventory_status: roomData.inventory_status,
    net_price: roomData.net_price,
    first_night: roomData.first_night,
    additional_data: roomData.additional_data,
    contract_meal: roomData.contract_meal,
    searchPrice: roomData.searchPrice,
    searchCurrency: roomData.searchCurrency,
    hotel_address: roomData.hotel_address,
    hotel_map_address: roomData.hotel_map_address,
    unique_code: cookie.load("code") ? cookie.load("code") : null,
    booked_by: roomData?.booked_by,
    user_type: roomData?.user_type,
    source_channel: roomData?.source_channel,
  };

  const [objectArray, setObjectArray] = useState([]);
  const { cardTotalAmount } = useSelector((state) => state.cardReducer);

  const multiReservationArrayOfData = {
    hotel: {
      name: roomData.hotel,
      rooms: [
        {
          number_of_persons: roomData.number_of_persons,
          client_names: ClientArray,
          phone_number: phonenumber,
          whatsapp_number: whatsappnumber,
          booking_type: voucher ? "voucher" : "no voucher",
          email: email,
          room_id: roomData.room_id,
          currentStep: currentStep,
          currency_type: roomData.currency_type,
          meal_plan: roomData.mealType,
          adults: roomData.number_of_persons,
          client_requests_tags: tagStatus ? [clientrequesttags] : clientrequesttags,
          client_requests_text: clientrequest,
          arrival_time_from: arrivalTimeFrom,
          arrival_time_to: arrivalTimeTo,
          add_to_total_price: parseInt(addtoprice),
          total_price: parseInt(roomData.price),
          from_date: roomData.formCheckIn_whats_app,
          to_date: roomData.formCheckOut_whats_app,
          nights: roomData.nights_whats_app,
          nationality: roomData.nationality,
          payment_type: roomData.payment_type,
          inventory_status: roomData.inventory_status,
          net_price: roomData.net_price,
          first_night: roomData.first_night,
          additional_data: roomData.additional_data,
          contract_meal: roomData.contract_meal,
          searchPrice: roomData.searchPrice,
          searchCurrency: roomData.searchCurrency,
          hotel_address: roomData.hotel_address,
          hotel_map_address: roomData.hotel_map_address,
          unique_code: cookie.load("code") ? cookie.load("code") : null,
          booked_by: roomData?.booked_by,
          user_type: roomData?.user_type,
          source_channel: roomData?.source_channel,
        },
      ],
    },
  };

  // Conditionally add agent_id if user_type is "b2b"
  if (roomData?.agent_id) {
    multiReservationArrayOfData.hotel.rooms[0].agent_id = roomData?.agent_id;
  }

  const roomDetails = {
    number_of_persons: roomData.number_of_persons,
    client_names: ClientArray,
    phone_number: phonenumber,
    whatsapp_number: whatsappnumber,
    booking_type: voucher ? "voucher" : "no voucher",
    email: email,
    room_id: roomData.room_id,
    currency_type: roomData.currency_type,
    meal_plan: roomData.mealType,
    adults: roomData.number_of_persons,
    currentStep: currentStep,
    client_requests_tags: tagStatus ? [clientrequesttags] : clientrequesttags,
    client_requests_text: clientrequest,
    arrival_time_from: arrivalTimeFrom,
    arrival_time_to: arrivalTimeTo,
    add_to_total_price: parseInt(addtoprice),
    total_price: parseInt(roomData.price),
    from_date: roomData.formCheckIn_whats_app,
    to_date: roomData.formCheckOut_whats_app,
    nights: roomData.nights_whats_app,
    nationality: roomData.nationality,
    payment_type: roomData.payment_type,
    inventory_status: roomData.inventory_status,
    net_price: roomData.net_price,
    first_night: roomData.first_night,
    additional_data: roomData.additional_data,
    contract_meal: roomData.contract_meal,
    searchPrice: roomData.searchPrice,
    searchCurrency: roomData.searchCurrency,
    hotel_address: roomData.hotel_address,
    hotel_map_address: roomData.hotel_map_address,
    unique_code: cookie.load("code") ? cookie.load("code") : null,
    booked_by: roomData?.booked_by,
    user_type: roomData?.user_type,
    source_channel: roomData?.source_channel,
  };

  // Conditionally add agent_id if user_type is "b2b"
  if (roomData?.agent_id) {
    reservationData.agent_id = roomData?.agent_id;
    roomDetails.agent_id = roomData?.agent_id;
  }

  const { multiReservationData } = useSelector((state) => state.multiReservationReducer);

  const handleBook = (e) => {
    e.preventDefault();

    setValidationPhone(true);
    setSaveBtn(true);
    let x = 0;

    if (cardTotalAmount > 1) {
      dispatch(
        multiReservationDataAction(
          multiReservationArrayOfData,
          roomData.hotel,
          roomDetails,
          roomData.selectedroom,
          currentStep,
          roomData.roomtype_id,
        ),
      ); // this dispatch to post data to api
      // dispatch(multiReservationDisplayAction(multiReservationArrayOfData)) // this dispatch to dipaly details of hotels and room in splide in the final page after reserv
      dispatch(savedButtonAction(saveBtnStatus));
    } else {
      dispatch(postReservationRoomAction(token, reservationData));

      dispatch(savedButtonAction(saveBtnStatus));
    }
  };
  //

  //

  function handleClientNameTranslation() {
    // if (clientNameAr[0]?.length === 0 && clientNameEn[0]?.length > 0) {
    //   translateTextToArabic(clientNameEn[0]).then((res) => {
    //     setClientNameAr(res);
    //   });
    // }
  }

  return (
    <div key={key} className="w-full  flex flex-col lg:flex-row justify-center   overflow-y-auto  ">
      <div className=" flex flex-col lg:flex-row justify-center items-center h-full w-full lg:w-[35%]  text-white   ">
        <div className="flex flex-col lg:flex-row w-full ">
          <div className="w-full h-full flex flex-col items-start text-start md:px-6  text-black ">
            <div className="flex items-center justify-between w-full">
              <p className="flex text-sm lg:text-3xl  font-serif font-bold text-[#002244]">{roomData.selectedroom}</p>
              <p className="flex items-center  text-md md:text-3xl font-semibold text-[#002244]">
                {new Intl.NumberFormat(localeCode).format(roomData.price + +addtoprice)}
                <span className="text-sm text-yellow-600 mt-2">{t(roomData.currency_type)}</span>
              </p>
            </div>
            <p className="text-xs sm:text-sm mt-3 line-clamp-2">{roomData.room_description}</p>
            <img src={roomData.roomImage} className="w-full shadow-md rounded-md lg:h-[300px] mb-2 mt-3" />
            <p className="flex items-center text-sm font-serif font-semibold">{roomData.hotel}</p>
            <div className="flex justify-between w-full mt-2">
              <div className=" grid items-center gap-y-3    ">
                <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold  ">
                  <MdOutlineDone className="w-5 h-5 text-lion font-bold  " />
                  {t("checkIn")}: {roomData.formCheckIn_whats_app}{" "}
                </p>
                <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold  ">
                  <MdOutlineDone className="w-5 h-5 text-lion font-bold  " />
                  {t("checkOut")}: {roomData.formCheckOut_whats_app}{" "}
                </p>
                <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold  ">
                  <GiNightSleep className="w-5 h-5 text-lion " />
                  {t("nights")}: {new Intl.NumberFormat(localeCode).format(roomData.nights_whats_app)}{" "}
                </p>
                <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold ">
                  <GiPerson className="w-5 h-5 text-lion " />
                  {t("persons")}: {new Intl.NumberFormat(localeCode).format(roomData.number_of_persons)}{" "}
                </p>
                {/* <p className="flex items-center gap-x-1 text-sm lg:text-md  font-semibold "><IoLogoUsd className="w-5 h-5 text-lion  " />{showPrice}<span className="text-[10px] text-lion">USD</span></p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col    justify-start items-center  w-full  h-full lg:w-[60%] text-black lg:mt-0 mt-6  ">
        <p
          className="w-full bg-[#002244]  rounded-t-md  lg:w-[70%] py-3 text-xs md:text-lg xl:text-2xl text-white font-serif"
          dir="ltr"
        >
          {roomData.selectedroom} {t("form")}
        </p>
        <form
          key={key}
          className="flex flex-col justify-center items-center gap-y-4 w-full  rounded-b-md   lg:w-[70%] bg-white/50 border-gray-200 border p-5  shadow-xl shadow-gray-100  "
          onSubmit={handleBook}
        >
          <ClientNameReservationForm
            setClientNameAr={setClientNameAr}
            setClientNameEn={setClientNameEn}
            numOfPerson={numOfPerson}
            setSaveBtn={setSaveBtn}
          />

          <div className="flex flex-col md:flex-row justify-center items-center gap-5   w-full ">
            <div className="flex flex-col items-start w-full  text-sm lg:w-[45%]">
              <label className=" flex   font-body ">{t("addPrice")}</label>
              <input
                // placeholder="Enter price"
                name="addtoprice"
                type="number"
                min={0}
                className="w-full  h-6 border-gray-200  border-opacity-100 border-b-2 "
                onChange={(e) => {
                  setAddtoprice(e.target.value === "" ? 0 : e.target.value);
                  setSaveBtn(false);
                }}
              />
            </div>
            <div className="flex flex-col items-start w-full text-sm text-black lg:w-[45%]">
              <label className="  font-body  ">
                {t("email")}
                <span className="text-yellow-600">*</span>
              </label>
              <input
                // placeholder="Enter email"
                value={email}
                name="email"
                type="email"
                dir="ltr"
                required
                className="w-full   border-gray-200  border-opacity-100 border-b-2 "
                onChange={(e) => {
                  setEmail(e.target.value);
                  setSaveBtn(false);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col  md:flex-row justify-center items-center gap-5   w-full">
            <div className="flex flex-col items-start w-full text-sm lg:w-[45%]">
              <label className="  font-body   ">
                {t("whatsapp")}
                <span className="text-yellow-600">*</span>
              </label>

              <PhoneInput
                name="whatsappnumber"
                type="text"
                required
                style={{ outline: "0px", direction: "ltr" }}
                international={true}
                withCountryCallingCode={true}
                value={whatsappnumber}
                className={`w-full border-gray-200   border-b-2`}
                onChange={handleWhatsappnumber}
              />
            </div>
            <div className="flex flex-col items-start w-full text-sm lg:w-[45%]">
              <label className="  flex   font-body  ">
                {t("phoneNumber")}
                <span className="text-yellow-600">*</span>
              </label>
              <PhoneInput
                // placeholder="Enter phone number"
                name="phonenumber"
                type="text"
                required
                style={{ outline: "0px", direction: "ltr" }}
                international={true}
                withCountryCallingCode={true}
                value={phonenumber}
                className={`w-full ${submitPhone ? "border-gray-200   border-b-2" : "border-red-600  border-b-2"}   `}
                onChange={handlePhoneInputChange}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-center items-center gap-5    w-full ">
            <div className="flex flex-col items-start w-full lg:w-[45%]  text-sm text-black  ">
              <label className="font-body">{t("clientRequestTag")}</label>
              <Select
                // className="w-full  rounded-lg placeholder: border-opacity-0 "
                name="clientrequesttags"
                options={clientRequsetOption}
                onChange={(e) => {
                  setClientrequesttags(e.label);
                  setTagStatus(true);
                  setSaveBtn(false);
                }}
                isSearchable
                className="w-full"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderBottom: "2px thin gray",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderRadius: "0",
                  }),
                }}
                // placeholder=""
              />
            </div>
            <div className="flex flex-col items-start w-full text-sm lg:w-[45%]">
              <label className="font-body">{t("clientRequest")}</label>
              <textarea
                name="clientrequest"
                type="text"
                className="w-full  lg:h-9 border-gray-200  border-opacity-100 border-b-2 "
                onChange={(e) => {
                  setClientrequest(e.target.value);
                  setSaveBtn(false);
                }}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-center items-center gap-5   w-full">
            <div className="flex flex-col sm:flex-row justify-between items-center gap-x-2 lg:gap-x-2 w-full lg:w-[45%] ">
              <div className="flex flex-col  items-start w-full lg:w-[50%]">
                <p className="font-body text-sm md:mb-3">
                  {t("arrivalFrom")}
                  <span className="text-yellow-600 ">*</span>
                </p>
                <input
                  type="time"
                  id="meeting-time"
                  name="meeting-time"
                  className="w-full text-xs lg:h-6 floating-input form-control session-date border-gray-200  border-opacity-100 border-b-2 px-1 "
                  onChange={(e) => {
                    setArrivalTimeFrom(e.target.value);
                    setSaveBtn(false);
                  }}
                  required
                />
              </div>
              <div className="flex flex-col items-start w-full lg:w-[50%]">
                <p className="font-body text-sm md:mb-3">
                  {t("arrivalto")}
                  <span className="text-yellow-600 ">*</span>
                </p>
                <input
                  type="time"
                  id="meeting-time-to"
                  name="meeting-time"
                  className="w-full floating-input form-control session-date text-xs lg:h-6 border-gray-200  border-opacity-100 border-b-2 px-1 "
                  onChange={(e) => {
                    setArrivalTimeTo(e.target.value);
                    setSaveBtn(false);
                  }}
                  required
                />
              </div>
            </div>
            <div className="flex flex-col  items-start  w-full lg:w-[45%]    ">
              <p className="font-body text-sm md:mb-3">
                {t("voucher")}
                <span className="text-yellow-600 ">*</span>
              </p>

              <div className="flex flex-col gap-y-5 text-xl  lg:flex-row  w-full   border-gray-200  border-opacity-100 border-b-2 justify-around items-center  ">
                <div className="flex justify-center items-center w-full   lg:w-[45%] gap-x-2 ">
                  <input
                    name="voucher"
                    type="radio"
                    id="option1"
                    className="text-md "
                    required
                    onChange={(e) => {
                      setVoucher(true);
                      setValidationRadio(true);
                      setSaveBtn(false);
                    }}
                  />
                  <label className="text-xs font-body " for="option1">
                    {t("voucher")}
                  </label>
                </div>
                <div className="flex justify-center items-center w-full   lg:w-[45%] gap-x-2">
                  <input
                    name="voucher"
                    type="radio"
                    className="text-md "
                    id="option2"
                    required
                    onChange={(e) => {
                      setVoucher(false);
                      setValidationRadio(true);
                      setSaveBtn(false);
                    }}
                  />
                  <label className="text-xs font-body " for="option2">
                    {t("noVoucher")}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <button
            className={`md:px-2  py-1 text-white bg-[#002244] w-full lg:w-[20%] rounded-lg ${
              saveScale ? "bg-[#002244]" : ""
            }   font-serif text-sm md:text-[16px]  hover:scale-105 active:scale-95 duration-300 transition-all  `}
            type="submit"
          >
            {saveBtn ? t("saved") : t("save")}
          </button>
        </form>

        {/* <div className="grid grid-cols-3  gap-6 mt-6">
                      <div className="flex items-center  gap-x-2 text-lion text-sm"><FaHotel className="w-6 h-6 "/>{roomData.selectedroom}</div>
                      <div className="flex items-center gap-x-2 text-lion text-sm"><BiBed className="w-5 h-5 " />{roomData.mealType}</div>
                      <div className="flex items-center gap-x-2 text-lion text-sm"><FaBath className="w-5 h-5 " />Bath</div>
                      <div className="flex items-center gap-x-1 text-lion text-sm  "><IoLogoUsd className="w-5 h-5 " />{roomData.price}<span className="text-xs">USD</span></div>
                      <div className="flex items-center gap-x-2 text-lion text-sm"><BiSolidDrink className="w-5 h-5 " />Drinks</div>
                      <div className="flex items-center gap-x-2 text-lion text-sm"><CgGym className="w-5 h-5 " />Gym</div>
                      <div className="flex items-center gap-x-2 text-lion text-sm"><FaSwimmingPool className="w-5 h-5 " />Swimming Pool</div>
                 </div> */}
        <div className="grid justify-start items-start w-full lg:w-[60%]  mt-2 ">
          {/* <p className=" text-[15px] font-serif font-semibold">Room Facilities</p>
                    <p className="text-xs"> Lorem Ipsum is simply dummy text of the printing and typesetting industryLorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown printer took aLorem Ipsum is simply dummy text of the printing
                     and typesetting industryLorem Ipsum has been the industry's</p> */}
        </div>
      </div>
    </div>
  );
};

export default MultiBookingForm;
