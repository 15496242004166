import React, { useEffect, useRef, useState } from "react";
import "./workorder.css";
import { useLocation, useParams } from "react-router";
import QRCode from "qrcode";
import { main_URL } from "../../../urls";
import Loading from "../../organisms/Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import Errorfetch from "../../organisms/Errorfetch/Errorfetch";
import workorderimg from "./workorderimg.jpg";
import html2canvas from "html2canvas";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";

const WorkOrder = () => {
  const { id } = useParams();
  const location = useLocation();
  const [qrcode, setQrcode] = useState("");
  const [path, setPath] = useState("");
  const [nationality, setNationality] = useState([]);
  const [loadingimg, setLoadingimg] = useState(false);
  const [loadimg, setloadimg] = useState(false);
  const htmlcontent = useRef(null);

  const {
    data: meetData,
    error: meetError,
    isLoading: meetloading,
  } = useQuery({
    queryKey: [`meet-and-assist${id}`],
    queryFn: () => fetchDataQuery(`/airport-services/api/v1/meet-and-assist-services/${id}/`),
  });

  const {
    data: countriesData,
    error: countriesError,
    isLoading: countriesLoading,
  } = useQuery({
    queryKey: ["countries-flags"],
    queryFn: () => fetchDataQuery(`/site-settings/api/countries-flags`),
  });

  useEffect(() => {
    if (location.pathname && main_URL) {
      const url = `${main_URL}${location.pathname}`;
      QRCode.toDataURL(url)
        .then((url) => {
          setQrcode(url);
        })
        .catch((err) => {});
    }
  }, [location.pathname, main_URL]);

  useEffect(() => {
    const convertToPng = async () => {
      if (qrcode && htmlcontent.current && meetData && countriesData) {
        setLoadingimg(true);
        html2canvas(htmlcontent.current).then((canvas) => {
          // Convert canvas to PNG image
          const image = canvas.toDataURL("image/png");

          // Do something with the image, e.g., save or display
          setLoadingimg(false);
          setPath(image);
        });
      }
    };
    if (loadimg) {
      convertToPng();
    }
  }, [qrcode, meetData, countriesData, htmlcontent.current, loadimg]);

  useEffect(() => {
    if (countriesData && meetData?.nationality) {
      const filteredNationalities = countriesData.filter((item) => item.name === meetData?.nationality[0]);
      setNationality(filteredNationalities);
    }
  }, [countriesData, meetData?.nationality]);

  return (
    <>
      {!path && meetData && countriesData && qrcode && workorderimg ? (
        <section className="bg-white z-10 overflow-x-scroll flex flex-col justify-center items-center">
          <div
            id="dev"
            ref={htmlcontent}
            className="z-[-1]  relative flex flex-col justify-center items-center w-[800px] h-[578px]   "
          >
            <p className="text-2xl  text-red-600 font-bold absolute  mb-[61%] ml-[-4%]">{meetData?.id}</p>
            <p className="text-[1.3rem]  text-end text-gray-900 font-semibold absolute mb-[27%] w-[50%]  ">
              {meetData?.passengers_name[0]}
            </p>
            <p className="text-[1.3rem]   text-gray-900 font-semibold absolute  mb-[16.5%] w-[50%] text-end">
              {meetData?.nationality.length > 1
                ? "متعدد الجنسيات"
                : meetData?.nationality.length <= 1 && nationality[0]?.name_ar
                  ? nationality[0]?.name_ar
                  : nationality[0]?.name}
            </p>
            <p className="text-[1.3rem]   text-gray-900 font-semibold absolute  mb-[16%] ml-[-54%]">
              {meetData?.number_of_pax}
            </p>
            <p className="text-[1.3rem]   text-gray-900 font-semibold absolute  mb-[6%] w-[50%] text-end">
              {meetData?.flight_number}
            </p>
            <p className=" text-end text-[1.3rem]   text-gray-900 font-semibold absolute  mb-[-3%] w-[50%] ">
              {meetData?.time_of_arrival.slice(0, 10)} / {meetData?.time_of_arrival.slice(11, 19)}
            </p>
            <p
              className={`text-[1.3rem]   !leading-[2.1]  break-words  text-gray-900 
            w-[33%] ml-[17%]  text-end font-semibold absolute mb-[-18.5%]  
            `}
            >
              {meetData.service_type === "Arrival" ? " أستقبال " : " تسفير "} من المطار وعمل الازم رقم تصريح المندوب{" "}
              {meetData.representative_name?.licenses_id_number}
            </p>
            <p className="text-[1.3rem]   text-gray-900 font-semibold absolute  mb-[-51%] w-[51%] text-end">
              {meetData.representative_name?.name}
            </p>
            <p className="text-[1.3rem]   text-gray-900 font-semibold absolute  mb-[-51%] ml-[-52%] ">
              محمد محمود طايع محمد
            </p>
            <img id="qrcode" src={qrcode} className="w-[12%] h-auto absolute  mb-[-39%] ml-[77%]" />
            <img id="workorderimg" src={workorderimg} onLoad={() => setloadimg(true)} className="w-[100%] h-screen" />
          </div>
        </section>
      ) : path && loadimg && qrcode ? (
        <div className=" w-full h-[100vh] flex flex-col justify-center items-center ">
          <img src={path} alt="Converted Image" />
        </div>
      ) : loadingimg || meetloading || countriesLoading ? (
        <div className="w-full flex items-center justify-center h-screen">
          <TopBarProgress /> <Loading />
        </div>
      ) : meetError || countriesError ? (
        <Errorfetch Error={meetError || countriesError} />
      ) : null}
    </>
  );
};

export default WorkOrder;
