import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

export const getUserProfileAction = (id, callback) => {
  return async (dispaych, getState) => {
    try {
      const response = await api_token(token).get(`/auth/api/v1/profiles/${id}/`);
      callback(response);
    } catch (error) {}
  };
};

export const updateUserProfileAction = (data, id, callback) => {
  return async (dispaych, getState) => {
    try {
      const response = await api_token(token).put(`/auth/api/v1/profiles/${id}/`, data);
      callback(response);
    } catch (error) {}
  };
};

export const changePassword = (password, callback) => {
  return async (dispaych, getState) => {
    try {
      const response = await api_token(token).put(`/auth/api/v1/change-password/`, password);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};
