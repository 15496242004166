import React, { useEffect, useState } from "react";
import { SlLocationPin } from "react-icons/sl";
import { VscCalendar } from "react-icons/vsc";
import { TfiWorld } from "react-icons/tfi";
import { IoBedOutline } from "react-icons/io5";
import { HiMiniQuestionMarkCircle } from "react-icons/hi2";
import { CgToolbox } from "react-icons/cg";
import { BiStreetView } from "react-icons/bi";
import { GoSearch } from "react-icons/go";
import { TbBuildingEstate } from "react-icons/tb";
import {
  areaAction,
  destinationAction,
  getNationalityAction,
  hotelSearchAreaAction,
} from "../../../redux/actions/searchformAction";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import {
  checkMarkup,
  defaultArea,
  defaultHotel,
  selectedSearchAction,
} from "../../../redux/actions/SelectedSearchAction";
import { GetDataFromSearchForReseve } from "../../../redux/actions/reservationAction";
import DateRangePickerComponent from "../../organisms/calendar/calendar";
import Travel from "../../travell";
import "@splidejs/react-splide/css";
import { useTranslation } from "react-i18next";
// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
import AsyncSelect from "react-select/async";

// or only core styles
import "@splidejs/react-splide/css/core";
import { getAllMarkups } from "../../../redux/actions/markupControl";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useDebouncedCallback } from "use-debounce";
import { flushSync } from "react-dom";
import { toast } from "react-toastify";

const DashboardSearch = () => {
  //
  // 1- System
  // 2- Agent
  // 3- Client
  const [markupType, setMarkupType] = useState("system");
  const [agentInfo, setAgentInfo] = useState("");

  const [clientInfo, setClientInfo] = useState("");

  const {
    refetch: fetchAgents,
    isRefetchError: isRefetchAgentsError,
    data: agentsMarkupData,
  } = useQuery({
    queryFn: () => {
      if (agentInfo.length >= 3) {
        return fetchDataQuery(`/auth/api/v1/user-agent/?search=${agentInfo}`).then((agents) =>
          agents.length !== 0
            ? agents.map((agent) => ({
                label: agent.profile_agent.agent_name,
                value: agent.profile_agent.markup,
              }))
            : [],
        );
      }
      return Promise.resolve([]);
    },
    queryKey: ["agent", agentInfo],
    enabled: true,
    networkMode: "always",
    throwOnError: (error) => {
      toast.error(error || "Error fetching agent markup");
    },
  });

  const {
    refetch: fetchClients,
    isRefetchError: isRetchClientsError,
    data: clientsMarkupData,
  } = useQuery({
    queryFn: () => {
      if (clientInfo.length >= 3) {
        return fetchDataQuery(`/auth/api/v1/user-customer/?search=${clientInfo}`).then((clients) =>
          clients.length !== 0
            ? clients.map((client) => ({
                label: client.profile_customer.customer_name,
                value: client.profile_customer.markup,
              }))
            : [],
        );
      }
      return Promise.resolve([]);
    },
    queryKey: ["client", clientInfo],
    enabled: true,
    networkMode: "always",
    throwOnError: (error) => {
      toast.error(error || "Error fetching client markup");
    },
  });

  const loadAgentsOptions = useDebouncedCallback((inputValue, callback) => {
    flushSync(() => {
      setAgentInfo(inputValue);
    });
    fetchAgents().then((result) => {
      callback(result.data || []);
    });
  }, 1000);

  const loadClientsOptions = useDebouncedCallback((inputValue, callback) => {
    flushSync(() => {
      setClientInfo(inputValue);
    });
    fetchClients().then((result) => {
      callback(result.data || []);
    });
  }, 1000);

  const toqaddddasdwqken = cookie.load("refresh_token");
  /////////////Log OuT/////////////////

  const dispatch = useDispatch();
  const refreshToken = cookie.load("refresh_token");
  const accessToken = cookie.load("access_token");
  const navigate = useNavigate();
  const token = cookie.load("access_token");
  const [t, i18n] = useTranslation();
  const localeCode = t("localeCode");
  const currencySearch = cookie.load("currency");
  const [langToggle, setLangToggle] = useState(false);

  const [load, setLoad] = useState(false);
  const images = [
    "/images/0929c4be-08f9-41f7-8090-58b15a72f380.webp",
    "/images/Vegas-wallpaper.jpeg",
    "/images/saubeiwtygxkp9oxju1l.webp",
    // Add more image URLs here
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { dataFromSearchForReseve } = useSelector((state) => state.reservationReducer);
  let destinationsDefault;
  if (dataFromSearchForReseve.city) {
    destinationsDefault = dataFromSearchForReseve.city;
  }
  // else {
  //   destinationsDefault = "Cairo";
  // }

  let areaDefault;
  if (dataFromSearchForReseve.area) {
    areaDefault = dataFromSearchForReseve.area;
  }
  // else {
  //   areaDefault = "Nile View";
  // }

  useEffect(() => {
    const interval = setInterval(changeBackgroundImage, 6000);

    return () => {
      // Clear the interval when the component is unmounted to prevent memory leaks
      clearInterval(interval);
    };
  }, [currentImageIndex]);

  const changeBackgroundImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const backgroundImageUrl = images[currentImageIndex];

  const style = {
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100vh",
    transition: "background-image 2s ease",
  };

  ////////////////////////////////////

  const location = useLocation();
  const [flag, setFlag] = useState([]);
  const [toggle, setToggle] = useState(false);
  const { destinationData } = useSelector((state) => state.SearchFormReducer);
  const { areaData } = useSelector((state) => state.SearchFormReducer);
  const { hotelData } = useSelector((state) => state.SearchFormReducer);
  const { nationalities } = useSelector((state) => state.SearchFormReducer);

  // store data of form
  const [calendarDashboard, setCalendarDashboard] = useState(false);
  const [formDes, setFormDes] = useState();
  const [formApi, setFormApi] = useState();
  const [formDesLoading, setFormDesLoading] = useState(false);
  const [formDesLabel, setFormDesLabel] = useState();
  const [formDesLabelLoading, setFormDesLabelLoading] = useState(false);
  const [formAreaLabelLoading, setFormAreaLabelLoading] = useState(false);
  const [formArea, setFormArea] = useState();
  const [formAreaLabel, setFormAreaLabel] = useState();
  const [formHotel, setFormHotel] = useState();
  const [formHotelId, setFormHotelId] = useState();
  const [formNation, setFormNation] = useState("Saudi Arabia");
  const [formNationImg, setFormNationImg] = useState("https://flagcdn.com/w320/sa.png");
  const [formCheckIn, setFormCheckIn] = useState();
  const [formCheckOut, setFormCheckOut] = useState();
  const [person, setPerson] = useState(1);
  const [adultPerson, setAdultPerson] = useState(1);
  const [formRoom, setFormRooms] = useState(1);
  const [formkids, setFormKids] = useState(0);
  const [nights, setNights] = useState(1);
  const [validation, setValidation] = useState(false);
  const [details, setDetails] = useState();
  const [markup, setMarkp] = useState();
  const [markupId, setMarkupId] = useState();
  const [markupLabel, setMarkupLabel] = useState();
  const [home, setHome] = useState(false);
  const [dashboardSearchDate, setDashboardSearchDate] = useState(true);

  useEffect(() => {
    dispatch(destinationAction(token, "en"));
    dispatch(getNationalityAction(token, "en"));
    dispatch(
      getAllMarkups((result) => {
        if (result.status === 200) {
          setMarkp(result.data);
        }
      }, "en"),
    );
    setCalendarDashboard(true);
    setHome(true);
  }, []);

  let destination = destinationData
    ? destinationData.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];

  let area = areaData
    ? areaData.map((item, index) => {
        return { value: JSON.stringify([item.id]), label: item.name };
      })
    : [];

  //    let hotel = hotelData ? hotelData.map((item) =>{
  //       return ({value:item , label:item})
  //    }):[]
  let hotel = hotelData
    ? hotelData.map((item) => {
        return { value: item.id, label: item.name };
      })
    : [];

  let flagData = nationalities
    ? nationalities.map((item) => {
        return { value: item.name, label: item.name, image: item.flag };
      })
    : [];

  let markupList = markup
    ? markup.map((item) => {
        return { value: item.id, label: item.name };
      })
    : [];

  let detailsArray = [];

  if (details) {
    details.map((item) => {
      detailsArray.push(item.adults);
    });
  }

  let nationName;
  let nationValue;
  let nationImage;
  let defaultNation = flagData
    ? flagData.filter((item) => {
        if (item.label === "Saudi Arabia") {
          nationName = item.label;
          nationValue = item.value;
          nationImage = item.image;
        }
      })
    : [];

  const selectedData = {
    city: formDes,
    area: formArea,
    from_date: formCheckIn,
    to_date: formCheckOut,
    nights: nights,
    persons: JSON.stringify(detailsArray),
    country: formNation,
    currency: currencySearch,
    hotel: formHotelId,
    markup: markupId,
    people: JSON.stringify(details),
  };
  const selectedDataForReseve = {
    city: formDes,
    area: formArea,
    areaName: formAreaLabel,
    from_date: formCheckIn,
    to_date: formCheckOut,
    nights: nights,
    persons: JSON.stringify(detailsArray),
    nationality: formNation,
    nationalityImg: formNationImg,
    details: details,
    formRoom: formRoom,
    hotel: formHotelId,
    hotelName: formHotel,
    markup: markupId,
    markupName: markupLabel,
    people: JSON.stringify(details),
  };

  const handleSubmit = () => {
    if (formDes == null && formArea == null && formCheckIn == null && formCheckOut == null) {
      setValidation(true);
    } else {
      setValidation(false);

      dispatch(selectedSearchAction(token, selectedData, t("lan")));
      dispatch(GetDataFromSearchForReseve(selectedDataForReseve));
      navigate("/", { state: "dashboardSearch" });
    }
  };

  const { max_price, min_price, rates, features, ...filteredData } = selectedData;
  const handlelanguageSearch = () => {
    setFormDesLoading(true);
    setFormDesLabel("");
    setFormAreaLabel("");
    setFormHotel("");
    area = [];
    dispatch(
      destinationAction(token, "en", (result) => {
        if (result.status === 200) {
          setFormDesLoading(false);
        }
      }),
    );
  };

  return (
    <div className="w-full px-6 mx-auto flex gap-2 my-3 flex-wrap justify-between">
      <div className="w-full shadow-2xl shadow-gray-400 border rounded-xl bg-white mt-5 py-5">
        <form className="grid md:grid-cols-2 grid-cols-1 md:px-5 px-1" onSubmit={handleSubmit}>
          <div class="flex flex-col gap-y-4 items-start md:px-7 px-1 pb-12 pt-3  border-[#002244] border-b   ">
            <p className="text-md font-medium md:md:px-10  px-2">
              Enter Destination or Property<span className="text-md text-red-700"> *</span>
            </p>
            <div className="w-full flex items-center gap-x-2">
              <SlLocationPin className="w-8 h-8 text-[#002244]  " />
              <Select
                className="w-full   "
                // name="destination"
                options={formDesLoading ? [] : destination}
                value={formDesLabel ? [{ label: formDesLabel, value: formDes }] : null}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "md:18px ",
                    fontWeight: "bold",
                    color: "red",
                    backgroundColor: "white",
                    fontStyle: "bold",
                    borderBottom: "none",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderRadius: "0",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#002244", // Change this to the desired color for the placeholder
                  }),
                }}
                onChange={(e) => {
                  setFormDesLabelLoading(true);
                  dispatch(
                    areaAction(token, e.value, "en", (result) => {
                      if (result.status === 200) {
                        setFormDesLabelLoading(false);
                      }
                    }),
                  );
                  setFormDes(e.value);
                  setFormDesLabel(e.label);
                  setFormAreaLabel("");
                  setFormHotel("");
                }}
                required
                isSearchable
                placeholder={formDesLoading ? "Loading..." : "Destination"}
              />
            </div>
          </div>

          <div class="flex flex-col gap-y-4 items-start md:px-7 px-1 pb-12 pt-3  border-[#002244] border-b  md:border-l   ">
            <p className="flex items-center gap-x-1 text-md font-medium md:px-10 px-2">
              Area<span className="text-md text-red-700"> *</span>
              <HiMiniQuestionMarkCircle className="text-amber-500 w-4 h-4" />
            </p>
            <div className="w-full flex items-center gap-x-2">
              <BiStreetView className="w-8 h-8 text-[#002244]  " />
              <Select
                className="w-full"
                name="area"
                options={formDesLabelLoading ? [] : formDesLabel ? [{ label: "All Area", value: "all" }, ...area] : []}
                value={formAreaLabel ? { label: formAreaLabel, value: 1 } : null}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "md:18px ",
                    fontWeight: "bold",
                    color: "red",
                    backgroundColor: "white",
                    fontStyle: "bold",
                    borderBottom: "none",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderRadius: "0",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#002244", // Change this to the desired color for the placeholder
                  }),
                }}
                onChange={(e) => {
                  setFormAreaLabelLoading(true);
                  dispatch(defaultArea([{ label: e.label, value: e.value }]));
                  dispatch(
                    hotelSearchAreaAction(
                      token,
                      e.value,
                      "en",
                      (result) => {
                        if (result.status === 200) {
                          setFormAreaLabelLoading(false);
                        }
                      },
                      formDes,
                    ),
                  );

                  // Handle the case where selectedValues is empty (cleared selection)

                  setFormArea(e.value);
                  setFormAreaLabel(e.label);
                  setFormHotel("");
                }}
                required
                isSearchable
                placeholder={formDesLabel ? (formDesLabelLoading ? "Loading..." : "Area") : "Please Select Destination"}
              />
            </div>
          </div>

          <div class="flex flex-col gap-y-4 items-start md:px-7 px-1 pb-12 pt-3  border-[#002244] border-b    ">
            <p className="flex items-center gap-x-1 text-md font-medium md:px-10 px-2">
              Hotels
              <HiMiniQuestionMarkCircle className="text-amber-500 w-4 h-4" />
            </p>
            <div className="w-full flex items-center gap-x-2">
              <TbBuildingEstate className="w-8 h-8 text-[#002244]  " />
              <Select
                className="w-full  "
                // name="hotel"
                options={formAreaLabelLoading ? [] : formAreaLabel ? hotel : []}
                value={formHotel ? { label: formHotel, value: formHotelId } : null}
                onChange={(e) => {
                  if (e) {
                    dispatch(defaultHotel([{ label: e.label, value: e.value }]));
                    setFormHotel(e.label);
                    setFormHotelId(e.value);
                  } else {
                    setFormHotel(null);
                    setFormHotelId(null);
                    dispatch(defaultHotel(null)); // Clear the selection if the user clears the input
                  }
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "md:18px ",
                    fontWeight: "bold",
                    color: "red",
                    backgroundColor: "white",
                    fontStyle: "bold",
                    borderBottom: "none",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderRadius: "0",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#002244", // Change this to the desired color for the placeholder
                  }),
                }}
                isClearable
                isSearchable
                placeholder={formAreaLabelLoading ? "Loading..." : "Hotels"}
              />
            </div>
          </div>
          <div class="flex flex-col gap-y-4 items-start md:px-7 px-1 pb-12 pt-3  border-[#002244] border-b   md:border-l  ">
            <p className="flex items-center gap-x-1 text-md font-medium md:px-10 px-2">
              Nationality or Residency
              <HiMiniQuestionMarkCircle className="text-amber-500 w-4 h-4" />
            </p>
            <div className="w-full flex items-center gap-x-2">
              <TfiWorld className="w-8 h-8 text-[#002244]  " />
              <Select
                className="w-full  "
                // name="Nationality"
                options={flagData}
                defaultValue={{
                  value: "Saudi Arabia",
                  label: "Saudi Arabia",
                  image: "https://flagcdn.com/w320/sa.png",
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "md:18px ",
                    fontWeight: "bold",
                    color: "red",
                    backgroundColor: "white",
                    fontStyle: "bold",
                    borderBottom: "none",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderRadius: "0",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#002244", // Change this to the desired color for the placeholder
                  }),
                }}
                onChange={(e) => {
                  setFormNation(e.label);
                  setFormNationImg(e.image);
                }}
                formatOptionLabel={(flagData) => (
                  <div className="flex justify-start items-center  gap-x-3 country-option ">
                    <img src={flagData.image} alt="country-image" className="w-7" />
                    <span>{flagData.label}</span>
                  </div>
                )}
                isSearchable
                placeholder="Nationality"
              />
            </div>
          </div>

          <div class="flex w-full  flex-col gap-y-4  justify-between md:px-7 px-1 pb-12 pt-3  border-[#002244] border-b      ">
            <div className="w-full flex justify-between items-center ">
              <div className="w-full flex flex-col  justify-between gap-y-3">
                <p className="text-md font-medium md:px-10 px-2 text-left">
                  Travel Dates <span className="text-md text-red-700"> *</span>
                </p>
                <div className="w-full flex items-center gap-x-2">
                  <VscCalendar className="w-8 h-8 text-[#002244]  md:block hidden" />
                  <DateRangePickerComponent
                    setFormCheckIn={setFormCheckIn}
                    setFormCheckOut={setFormCheckOut}
                    setNights={setNights}
                    calendarDashboard={calendarDashboard}
                    dashboardSearch={true}
                    setDashboardSearchDate={setDashboardSearchDate}
                    dashboardSearchDate={dashboardSearchDate}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-y-4 items-start md:px-7 px-1 pb-12 pt-3  border-[#002244]   md:border-l border-b  ">
            <p className="text-md font-medium md:px-10 px-2">
              Total Room(s)<span className="text-md text-red-700"> *</span>
            </p>
            <div className="w-full flex items-center justify-center gap-x-6">
              <IoBedOutline className="w-8 h-8 text-[#002244] md:block hidden " />
              <Travel
                setPerson={setPerson}
                setFormRooms={setFormRooms}
                setFormKids={setFormKids}
                setDetails={setDetails}
                setAdultPerson={setAdultPerson}
                calendarDashboard={calendarDashboard}
              />
            </div>
          </div>

          <div class="flex flex-col gap-y-4 items-start md:px-7 px-1 pt-3 pb-8 border-[#002244]    max-md:border-b   ">
            <p className="flex items-center gap-x-1 text-md font-medium md:px-10 px-2">
              Markup<span className="text-md text-red-700"> *</span>
              <HiMiniQuestionMarkCircle className="text-amber-500 w-4 h-4" />
            </p>
            <div className="w-full flex justify-between items-center gap-x-4">
              <CgToolbox className="w-8 h-8 text-[#002244]  " />
              <Select
                defaultValue={{ label: "System", value: "system" }}
                className="w-[40%]"
                onChange={(e) => {
                  setMarkupType(e.value);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "md:18px ",
                    fontWeight: "bold",
                    color: "red",
                    backgroundColor: "white",
                    fontStyle: "bold",
                    borderBottom: "none",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderRadius: "0",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#002244", // Change this to the desired color for the placeholder
                  }),
                }}
                placeholder={"Select Markup Type"}
                options={[
                  {
                    label: "System",
                    value: "system",
                  },
                  {
                    label: "Agent",
                    value: "agent",
                  },
                  {
                    label: "Client",
                    value: "client",
                  },
                ]}
              ></Select>

              {markupType === "system" && (
                <Select
                  className="w-full"
                  value={markupId ? [{ label: markupLabel, value: markupId }] : null}
                  options={markup ? markupList : []}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "md:18px ",
                      fontWeight: "bold",
                      color: "red",
                      backgroundColor: "white",
                      fontStyle: "bold",
                      borderBottom: "none",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      boxShadow: "none",
                      borderRadius: "0",
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "#002244", // Change this to the desired color for the placeholder
                    }),
                  }}
                  isClearable
                  required
                  onChange={(e) => {
                    if (e) {
                      setMarkupId(e.value);
                      setMarkupLabel(e.label);
                      dispatch(checkMarkup([{ label: e.label, value: e.value }]));
                    } else {
                      setMarkupId("");
                      setMarkupLabel("");
                    }
                  }}
                  isSearchable
                  placeholder={"Markup"}
                />
              )}

              {markupType === "agent" && (
                <div className="flex w-full flex-col gap-1">
                  <AsyncSelect
                    placeholder="Agent name or mobile number"
                    isClearable
                    onChange={(e) => {
                      if (e) {
                        setMarkupId(e.value);
                        setMarkupLabel(e.label);
                        dispatch(checkMarkup([{ label: e.label, value: e.value }]));
                      } else {
                        setMarkupId("");
                        setMarkupLabel("");
                      }
                    }}
                    loadOptions={loadAgentsOptions}
                    className="w-full"
                  />
                  {!agentsMarkupData && (
                    <p className="font-medium text-red-700">
                      Error fetching agent data, check your internet connection
                    </p>
                  )}
                </div>
              )}

              {markupType === "client" && (
                <div className="flex w-full flex-col gap-1">
                  <AsyncSelect
                    placeholder="Client name or mobile number"
                    isClearable
                    onChange={(e) => {
                      if (e) {
                        setMarkupId(e.value);
                        setMarkupLabel(e.label);
                        dispatch(checkMarkup([{ label: e.label, value: e.value }]));
                      } else {
                        setMarkupId("");
                        setMarkupLabel("");
                      }
                    }}
                    loadOptions={loadClientsOptions}
                    className="w-full"
                  />
                  {!clientsMarkupData && (
                    <p className="font-medium text-red-700">
                      Error fetching client data, check your internet connection
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div class="flex flex-col gap-y-4 items-start md:px-7 px-1 pt-3 pb-8 border-[#002244] md:border-l    ">
            <div className="w-full h-full flex items-end justify-end">
              <button
                type="submit"
                className="flex items-center gap-x-1 py-1.5 px-3 bg-yellow-500 hover:bg-yellow-600 duration-300 transition-all text-white font-semibold rounded-md"
              >
                <GoSearch className="w-5 h-5" /> Search
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DashboardSearch;
