import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Loading from "../Loading/Loading";
import { getCancellationPolicyId } from "../../../redux/actions/cancellation";

const ViewCancellation = () => {
  const [cancellationData, setCancellationData] = useState([]);
  const token = cookie.load("access_token");
  const dispatch = useDispatch();
  const params = useParams();
  function reshapeText(text) {
    let firstReshape = text && text?.replace(/_/g, " ");
    let secondReshape = firstReshape && firstReshape[0]?.toUpperCase() + firstReshape?.slice(1);
    return secondReshape;
  }
  const fetchData = () => {
    dispatch(
      getCancellationPolicyId(token, params.id, (res) => {
        try {
          if (res.status === 200) {
            setCancellationData(res?.data);
          }
        } catch (error) {}
      }),
    );
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <section className="formPage-wrap p-6 bg-gray-50">
      <div className="flex justify-center mb-6">
        <h2 className="header-h2 text-2xl font-bold text-gray-800">Cancellation Policy Details</h2>
      </div>
      {cancellationData ? (
        <div className="w-full bg-white p-6 rounded-lg shadow-lg">
          <table className="min-w-full border border-gray-300 rounded-lg overflow-hidden">
            <tbody>
              <tr className="bg-gray-200">
                <td className="p-3 font-bold border-r border-b">ID:</td>
                <td className="p-3 border-b">{cancellationData?.id || "N/A"}</td>
              </tr>
              <tr className="bg-white">
                <td className="p-3 font-bold border-r border-b">Cancellation from:</td>
                <td className="p-3 border-b">{cancellationData?.cancellation_valid_from?.slice(0, 10) || "N/A"}</td>
              </tr>
              <tr className="bg-gray-200">
                <td className="p-3 font-bold border-r border-b">Cancellation to:</td>
                <td className="p-3 border-b">{cancellationData?.cancellation_valid_to?.slice(0, 10) || "N/A"}</td>
              </tr>
              <tr className="bg-white">
                <td className="p-3 font-bold border-r border-b">Offer type:</td>
                <td className="p-3 border-b">{cancellationData?.offer_type?.name || "N/A"}</td>
              </tr>
            </tbody>
          </table>

          {/* Full-width cancellation charges section */}
          <div className="w-full p-4 bg-gray-100 mt-4 rounded-lg shadow-sm">
            <div className="w-full p-4 bg-gray-100 mt-4 rounded-lg shadow-sm">
              <h2 className="flex justify-center text-[#002244] font-semibold text-xl">Cancelation charges</h2>
              <div className={`p-4 bg-white border-2 rounded-lg shadow-sm`}>
                <table className="w-full text-center">
                  <thead>
                    <tr className="border-b ">
                      <th className="p-2 font-bold text-gray-700">Charge</th>
                      <th className="p-2 font-bold text-gray-700">Number of Days</th>
                      <th className="p-2 font-bold text-gray-700">Price</th>
                      <th className="p-2 font-bold text-gray-700">Currency</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {cancellationData?.cancellation_charge?.map((policy, idx) => (
                      <tr key={idx} className="border-b">
                        <td className="p-2 text-gray-600 text-center">
                          {policy?.charge === "first_two_night" && "First Two Nights"}
                          {policy?.charge === "first_three_night" && "First Three Nights"}
                          {policy?.charge === "first_night" && "First Night"}
                          {policy?.charge === "fixed_amount" && "Fixed Amount"}
                          {policy?.charge === "percentage_amount" && "Percentage Amount"}
                          {policy?.charge === "whole_period" && "Whole Period"}
                        </td>
                        <td className="p-2 text-gray-600">{policy?.num_days || 0}</td>
                        <td className="p-2 text-gray-600">
                          {(policy?.charge === "fixed_amount" || policy?.charge === "percentage_amount") &&
                          policy?.price > 0
                            ? policy?.price
                            : "N/A"}
                        </td>
                        <td className="p-2 text-gray-600">
                          {policy?.charge === "fixed_amount" ? policy?.currency || "N/A" : "N/A"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Continue with the rest of the table */}
          <table className="min-w-full border border-gray-300 rounded-lg overflow-hidden mt-4">
            <tbody>
              <tr className="bg-gray-200">
                <td className="p-3 font-bold border-r border-b">Hotel:</td>
                <td className="p-3 border-b">{cancellationData?.hotel?.name || "N/A"}</td>
              </tr>
              <tr className="bg-white">
                <td className="p-3 font-bold border-r border-b">Cancellation Time:</td>
                <td className="p-3 border-b">{reshapeText(cancellationData?.cancellation_time || "")}</td>
              </tr>
              <tr className="bg-gray-200">
                <td className="p-3 font-bold border-r border-b">Status:</td>
                <td className="p-3 border-b">{cancellationData?.status || "N/A"}</td>
              </tr>
              <tr className="bg-white">
                <td className="p-3 font-bold border-r border-b">Notes:</td>
                <td className="p-3 border-b">{cancellationData?.notes || "N/A"}</td>
              </tr>
              <tr className="bg-gray-200">
                <td className="p-3 font-bold border-r border-b">Created At:</td>
                <td className="p-3 border-b">{cancellationData?.created_at?.slice(0, 10) || "N/A"}</td>
              </tr>
              <tr className="bg-white">
                <td className="p-3 font-bold border-r border-b">Updated At:</td>
                <td className="p-3 border-b">{cancellationData?.updated_at || "N/A"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <Loading />
      )}
    </section>
  );
};

export default ViewCancellation;
