import api_token from "../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

export default async function fetchDataQuery(url, headers = {}) {
  try {
    const response = await api_token(token).get(url, { headers: headers });
    console.log("API Response:", response.data); // Log the API response
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
