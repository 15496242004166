import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyAction } from "../../../redux/actions/bookingListAction";
import cookie from "react-cookies";
import Select from "react-select";
import Loading from "../Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import lightToast from "light-toast";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { FaWpforms } from "react-icons/fa6";
import { getSelectedBankTransferAction, updateBankTransfrAction } from "../../../redux/actions/bankTransferAction";
import { RxUpdate } from "react-icons/rx";
import { getBankListAction } from "../../../redux/actions/cashAction";
import TopBarProgress from "react-topbar-progress-indicator";
import dayjs from "dayjs";
import { TimePicker } from "antd";
import { convertingCurrencyAction } from "../../../redux/actions/convertingCurrency";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import { useFormik } from "formik";
import * as Yup from "yup";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";
import PopUp from "../PopUp/PopUp";

const UpdateBankTransfer = () => {
  const [amount, setAmount] = useState("");
  const [toggle, setToggle] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [imgFile, setImgFile] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const format = "HH:mm";

  function closeModal() {
    setIsOpen(false);
  }

  useRemoveScroll();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const token = cookie.load("access_token");
  const { selectedBankTransferFlag } = useSelector((state) => state.bankTransferReducer);
  const { selectedBankTransfer } = useSelector((state) => state.bankTransferReducer);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: {
      bank_name: "",
      transfer_amount: "",
      date: "",
      time: "",
      transferor_name: "",
      transfer_acc_number: "",
      transfer_receipt: null,
    },
    validationSchema: Yup.object({
      bank_name: Yup.string().required("Please select bank name"),
      transfer_amount: Yup.number()
        .required("Please enter a transfer amount")
        .positive("Transfer amount must be positive number")
        .integer("Transfer amount must be positive number")
        .min(0, "Transfer amount must be greater than or equal to 0"),
      date: Yup.date().required("Please enter transaction date"),
      time: Yup.string().required("Please enter transaction time"),
      transferor_name: Yup.string()
        .required("Please enter a transfer name")
        .min(2, "Transfer name must be greater than or equal to 2")
        .max(50, "Transfer name must be smaller than or equal to 50"),
      transfer_acc_number: Yup.number()
        .required("Please enter a transfer account number")
        .positive("Transfer account number must be positive number")
        .integer("Transfer account number must be positive number")
        .min(1, "Transfer account number must be greater than  0"),
      // transfer_receipt: Yup.mixed().required("Please upload a transfer receipt"),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      const { date, time, transfer_receipt, ...restData } = values;
      Object.keys(restData).forEach((key) => {
        formData.append(key, restData[key]);
      });
      formData.append("booked_room", params.bookedroom);
      formData.append("transaction_date", `${date},${time}`);
      formData.append("transaction_status", "pending");
      if (values.transfer_receipt) {
        formData.append("transfer_receipt", values.transfer_receipt);
      }
      setSubmitting(true);
      dispatch(
        updateBankTransfrAction(token, params.id, formData, (result) => {
          if (result.status === 200) {
            setSubmitting(false);
            lightToast.success("The bank transfer Form was completed Updated");
            navigate(-1);
          } else {
            setSubmitting(false);
          }
        }),
      );
    },
  });

  useEffect(() => {
    //////////handle when reload page , alert show to make sure this i want reload ////////////
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave? Your changes may not be saved.";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    //////////////// Cancellatioin Request ////////////////////
    const controller = new AbortController();
    const signal = controller.signal;
    ////////////////////////////////////////////////////////
    dispatch(getBankListAction(token, (result) => {}, signal));
    dispatch(getCurrencyAction(token, signal));
    dispatch(
      getSelectedBankTransferAction(
        token,
        params.id,
        (result) => {
          if (result.status === 200) {
            setFieldValue("transferor_name", result.data.transferor_name);
            setFieldValue("transfer_amount", result.data.transfer_amount);
            setFieldValue("currency_type", result.data.currency_type);
            setFieldValue("bank_account_number", result.data.bank_account_number);
            setFieldValue("bank_name", result.data.bank_name);
            setFieldValue("date", result.data.transaction_date.slice(0, 10));
            setFieldValue("time", result.data.transaction_date.slice(11, 19));
            setFieldValue("transfer_acc_number", result.data.transfer_acc_number);
            setFieldValue("conversion_rate_price", result.data.conversion_rate_price);
            setFieldValue("conversion_rate", result.data.conversion_rate);
            setFieldValue("contract_currency", result.data.contract_currency);
            setToggle(true);
          }
        },
        signal,
      ),
    );
    return () => {
      controller.abort();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const { currencyList } = useSelector((state) => state.bookingListReducer);
  const { bankData } = useSelector((state) => state.cashReducer);
  let CurrencyType = currencyList
    ? currencyList.map((item, index) => {
        return { value: item, label: item };
      })
    : [];
  let Bank = bankData
    ? bankData.map((item, index) => {
        return { value: item.id, label: item.name, currency: item.currency, bankAccountNumber: item.account_number };
      })
    : [];
  let BankFilter = Bank ? Bank.filter((item) => item.value === values.bank_name) : [];

  const handleAmount = (e) => {
    setFieldValue("transfer_amount", e.target.value);
    if (values.conversion_rate) {
      setFieldValue("conversion_rate_price", e.target.value * values.conversion_rate);
    }
  };

  const handleTimeChange = (newTime) => {
    const date = new Date(newTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setFieldValue("time", `${hours1}:${minutes1}`);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("convertAmount ", Number(convertAmount).toFixed(2));
  //   if (time === "") {
  //     alert("Please select a time."); // You can replace this with your validation logic
  //     return;
  //   } else {
  //     const formData = new FormData(e.target);
  //     const transaction_date = `${date},${e.target.time.value}`;
  //     formData.append("booked_room", params.bookedroom);
  //     formData.append("bank_name", bankId);
  //     formData.append("transaction_date", transaction_date);
  //     formData.append("currency_type", currency);
  //     formData.append("bank_account_number", accountNumberBank);
  //     formData.append("conversion_rate_price", Number(convertAmount).toFixed(2));
  //     formData.append("conversion_rate", convertRate);
  //     formData.append("contract_currency", contractCurrency);
  //     dispatch(
  //       updateBankTransfrAction(token, params.id, formData, (result) => {
  //         if (result.status === 200) {
  //           lightToast.success("The bank transfer Form was completed Updated");
  //           navigate(-1);
  //         }
  //         // else
  //         // {
  //         //     toast.success(`The bank transfer was completed successfully`)
  //         // }
  //       })
  //     );
  //   }
  // };

  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.change_banktransfer"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="w-full  ">
      <div className="flex flex-col items-center justify-start h-full w-full">
        {selectedBankTransferFlag && !permissionFlag ? (
          <>
            {" "}
            <Loading /> <TopBarProgress />
          </>
        ) : (
          BankFilter.length > 0 &&
          permissionFlag &&
          permission.map((item) => {
            if (item.name === "room_booking.change_banktransfer" && item.value === true) {
              return (
                <div className=" w-[70%]  border-gray-300 border rounded-lg p-5 ">
                  <div className=" flex justify-center mx-auto w-full bg-[#002244]  text-white  rounded-lg text-2xl  h-12 mt-3">
                    <p className="flex items-center gap-x-3  text-md">
                      {" "}
                      <FaWpforms className="w-5 h-5" />
                      Update Bank Transfer Form{" "}
                    </p>
                  </div>
                  <form className="mt-2  w-full" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      <label className=" flex text-gray-600   ">Bank Name</label>
                      <Select
                        placeholder="Bank Name"
                        defaultValue={[
                          { value: BankFilter && BankFilter[0].value, label: BankFilter && BankFilter[0].label },
                        ]}
                        type="text"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: `${touched.bank_name && errors.bank_name && "rgb(153 27 27)"}`,
                          }),
                        }}
                        options={Bank}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("bank_name", e.value);
                          setFieldValue("currency_type", e.currency);
                          setFieldValue("bank_account_number", e.bankAccountNumber);
                          setLoadingToggle(true);
                          setToggle(false);
                          dispatch(
                            convertingCurrencyAction(token, e.currency, values.contract_currency, (result) => {
                              if (result.status === 200) {
                                result.data.results.length !== 0
                                  ? setFieldValue(
                                      "conversion_rate_price",
                                      values.transfer_amount &&
                                        values.transfer_amount * result.data.results.map((item) => item.rate),
                                    )
                                  : setFieldValue(
                                      "conversion_rate_price",
                                      values.transfer_amount && values.transfer_amount * 1,
                                    );
                                result.data.results.length !== 0
                                  ? setFieldValue("conversion_rate", ...result.data.results.map((item) => item.rate))
                                  : setFieldValue("conversion_rate", 1);
                                setToggle(true);
                                setLoadingToggle(false);
                              }
                            }),
                          );
                        }}
                        isSearchable
                        className=" w-full  h-10  rounded-lg  bg-white "
                      />
                      {touched.bank_name && errors.bank_name ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.bank_name}
                        </div>
                      ) : null}
                      {values.currency_type && toggle ? (
                        <>
                          <label className=" flex text-gray-600 ">Currency</label>
                          <div name="currency" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                            <p className="text-black mt-2">{values.currency_type}</p>
                          </div>
                          <label className=" flex text-gray-600 ">Account Number</label>
                          <div name="currency" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                            <p className="text-black mt-2">{values.bank_account_number}</p>
                          </div>
                        </>
                      ) : (
                        loadingToggle && (
                          <>
                            <ButtonLoading />
                          </>
                        )
                      )}

                      <label className=" flex text-gray-600 ">The Amount</label>
                      <input
                        placeholder="The Amount"
                        name="transfer_amount"
                        type="number"
                        step="any"
                        defaultValue={values.transfer_amount}
                        onChange={handleAmount}
                        onBlur={handleBlur}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.transfer_amount && errors.transfer_amount && "border border-red-800"
                        } `}
                      />
                      {touched.transfer_amount && errors.transfer_amount ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.transfer_amount}
                        </div>
                      ) : null}

                      {amount && (
                        <>
                          <label className=" flex text-gray-600 ">
                            Amount With Currency Of Contract ({values.contract_currency})
                          </label>
                          <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                            <div className="text-black flex items-end gap-x-1">
                              {values.conversion_rate_price}{" "}
                              <span className="text-sm font-semibold text-gray-700">{values.contract_currency}</span>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="flex items-center gap-x-5 w-full">
                        <div className="w-[50%]">
                          <label className="flex justify-center text-gray-600 "> Date</label>
                          <input
                            placeholder="Tranfer Date"
                            defaultValue={values.date}
                            type="date"
                            name="date"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={` w-full px-3 h-10  border rounded-lg bg-white floating-input ${
                              touched.date && errors.date && "border border-red-800"
                            } `}
                          />
                          {touched.date && errors.date ? (
                            <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                              {errors.date}
                            </div>
                          ) : null}
                        </div>
                        <div className="w-[50%]">
                          <label className=" flex justify-center text-gray-600 ">Time</label>
                          <TimePicker
                            clearIcon={false}
                            name="time"
                            defaultValue={dayjs(
                              selectedBankTransfer && selectedBankTransfer.transaction_date.slice(11, 19),
                              format,
                            )}
                            format={format}
                            className={` w-full py-2  border rounded-lg bg-white ${
                              touched.time && errors.time && "border border-red-800"
                            } `}
                            onChange={handleTimeChange}
                          />
                          {touched.time && errors.time ? (
                            <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                              {errors.time}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <label className=" text-center w-full text-gray-600   ">Transferor Name</label>
                      <input
                        placeholder="Transferor Name"
                        name="transferor_name"
                        type="text"
                        defaultValue={values.transferor_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.transferor_name && errors.transferor_name && "border border-red-800"
                        } `}
                      />
                      {touched.transferor_name && errors.transferor_name ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.transferor_name}
                        </div>
                      ) : null}
                      <label className=" flex text-gray-600   ">Sender Bank Account Number</label>
                      <input
                        placeholder="Sender Bank Account Number"
                        name="transfer_acc_number"
                        type="number"
                        defaultValue={values.transfer_acc_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.transfer_acc_number && errors.transfer_acc_number && "border border-red-800"
                        } `}
                      />
                      {touched.transfer_acc_number && errors.transfer_acc_number ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.transfer_acc_number}
                        </div>
                      ) : null}
                      {/* <input
                                  placeholder="Bank Name"
                                  name="bank_name"
                                  type="text"
                                  
                                  onChange={(e)=>setBank(e.target.value)}
                                  className=" w-full px-3 h-10  border rounded-lg bg-white "
                                              />       */}
                      <label className=" flex text-gray-600   ">Choose A File</label>
                      <input
                        placeholder="Bank Name"
                        name="transfer_receipt"
                        type="file"
                        onChange={(e) => {
                          setFieldValue("transfer_receipt", e.target.files[0]);
                          setImgFile(URL.createObjectURL(e.target.files[0]));
                        }}
                        onBlur={handleBlur}
                        className={` w-full px-3 h-10 py-1 border rounded-lg bg-white `}
                      />
                      {/* {touched.transfer_receipt && errors.transfer_receipt ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.transfer_receipt}
                        </div>
                      ) : null} */}

                      {imgFile ? (
                        <div className="w-full flex justify-center items-center">
                          <button
                            onClick={() => setIsOpen(true)}
                            type="button"
                            className=" flex items-center justify-center my-5"
                          >
                            <img src={imgFile} className="w-48 h-48 " />
                          </button>
                        </div>
                      ) : (
                        selectedBankTransfer &&
                        selectedBankTransfer.transfer_receipt && (
                          <button
                            onClick={() => setIsOpen(true)}
                            type="button"
                            className=" flex items-center justify-center my-5"
                          >
                            <img src={selectedBankTransfer.transfer_receipt} className="w-48 h-48" />
                          </button>
                        )
                      )}

                      <button
                        type="Submit"
                        disabled={isSubmitting}
                        className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        Update{" "}
                        {isSubmitting ? (
                          <>
                            <TopBarProgress />
                            <ButtonLoading />
                          </>
                        ) : (
                          <RxUpdate className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  </form>
                  <PopUp
                    close={closeModal}
                    isOpen={isOpen}
                    image={imgFile ? imgFile : selectedBankTransfer?.transfer_receipt}
                  />
                </div>
              );
            } else {
              return <NoPermission />;
            }
          })
        )}
      </div>
    </div>
  );
};

export default UpdateBankTransfer;
