import React, { useState, useEffect } from "react";
import CustomCity from "../CityAreaHotelCustom/CustomCity";
import CoustomArea from "../CityAreaHotelCustom/CoustomArea";

const CityAreaHotel = ({ render, localStorageName = "CITYAREA" }) => {
  const initialState = {
    city: "",
    area: "",
    Allarea: "",
    hotel: "",
  };

  // Initialize formValue from localStorage if available
  const [formValue, setFormValue] = useState(() => {
    const stored = localStorage.getItem(localStorageName);
    return stored ? JSON.parse(stored) : initialState;
  });

  const { city, area, Allarea } = formValue;

  // Handle form value changes
  const onChange = (name, value) => {
    let updatedFormValue = { ...formValue };

    if (name === "city") {
      // Clear area and Allarea when a new city is selected
      updatedFormValue = {
        ...formValue,
        city: value,
        area: "", // Clear the area value when city changes
        Allarea: "", // Clear Allarea as well
        hotel: "",
      };
      render({ city: value, area: "" });
    } else if (name === "area") {
      updatedFormValue = {
        ...formValue,
        area: value,
        Allarea: "", // Clear Allarea if a specific area is selected
      };
      render({ city: formValue.city, area: value });
    } else if (name === "Allarea") {
      updatedFormValue = {
        ...formValue,
        Allarea: value,
        area: "", // Clear area if Allarea is selected
      };
      render({ city: formValue.city, area: value });
    }

    setFormValue(updatedFormValue);
    localStorage.setItem(localStorageName, JSON.stringify(updatedFormValue));
  };

  // Sync formValue with localStorage on component mount
  useEffect(() => {
    const stored = localStorage.getItem(localStorageName);
    if (stored) {
      setFormValue(JSON.parse(stored));
    }
  }, [localStorageName]);

  console.log(formValue);

  return (
    <div className="w-full md:flex-row flex-col flex gap-2">
      <CustomCity onChange={onChange} Getlocal={formValue} />
      <CoustomArea onChange={onChange} city={city?.value} Getlocal={formValue} />
    </div>
  );
};

export default CityAreaHotel;
