import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../Loading/Loading";
import { MdOutlineAccessTime, MdOutlineDone } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { toast } from "react-hot-toast";
import "./OperationInfo.css";
import {
  deleteAction,
  getBookingStatusAction,
  getBookingTypeAction,
  updateOperationAction,
} from "../../../redux/actions/bookingListAction";
import { differenceInMilliseconds } from "date-fns";
// import jwtDecode from "jwt-decode";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import dayjs from "dayjs";
import { TimePicker } from "antd";
import TopBarProgress from "react-topbar-progress-indicator";
import { getSelectedTransactionAction } from "../../../redux/actions/allTransactionsAction";
import { useReloadPage } from "../../../customHooks/useReloadPage";

const OperationInfo = ({ test, paymentType, note, operationBookingStatus }) => {
  const token = cookie.load("access_token");

  // const decodedToken = jwtDecode(token);
  const [operationDataId, setOperationId] = useState(test);
  const [loadData, setLoadData] = useState(false);
  const format = "HH:mm";

  useEffect(() => {
    setOperationId(test);
    if (test) {
      const parts = operationDataId?.time_average_response?.split(" ");

      if (parts) {
        const days = parts?.length > 1 ? parseInt(parts[0]) : 0;

        const timeParts = parts[1] ? parts[1]?.split(":") : parts[0]?.split(":");

        const hours = parseInt(timeParts[0]);
        const minutes = parseInt(timeParts[1]);
        const seconds = parseInt(timeParts[2]);

        // Calculate the total seconds
        const totalSeconds = days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;

        // Calculate individual units
        const convertedDays = Math.floor(totalSeconds / (24 * 60 * 60));
        const remainingSeconds = totalSeconds % (24 * 60 * 60);
        const convertedHours = Math.floor(remainingSeconds / (60 * 60));
        const remainingSeconds2 = remainingSeconds % (60 * 60);
        const convertedMinutes = Math.floor(remainingSeconds2 / 60);
        const convertedSeconds = remainingSeconds2 % 60;
        setResponseTimeShow(
          `${convertedDays} days, ${convertedHours} hours, ${convertedMinutes} minutes, ${convertedSeconds} seconds`,
        );
      }
    }
  }, [test]);

  useEffect(() => {
    dispatch(
      getSelectedTransactionAction(token, params.id, (result) => {
        if (result.status === 200) {
          setLoadData(true);
        }
      }),
    );
    dispatch(getBookingTypeAction(token));
    dispatch(getBookingStatusAction(token, ...bookingIdOfTypeMap));
  }, []);

  const { selectedTransaction } = useSelector((state) => state.allTransactionsReducer);

  const { selectedTransactionFlag } = useSelector((state) => state.allTransactionsReducer);
  let optionTimeDate = new Date(operationDataId && operationDataId.option_date);
  let optionTimeDate1 = new Date(operationDataId && operationDataId.option_date);
  const hours = optionTimeDate1.getUTCHours();
  const minutes = optionTimeDate1.getUTCMinutes();
  const formattedHours = hours < 10 ? `0${hours}` : hours.toString();
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
  // Format the time string
  const formattedTime = `${formattedHours}:${formattedMinutes}`;

  const dateParts = optionTimeDate.toLocaleDateString().split("/");

  // Create a new Date object in the desired format
  const convertedDate = `${dateParts[2]}-${dateParts[0].padStart(2, "0")}-${dateParts[1].padStart(2, "0")}`;

  let confirmationTime = new Date(operationDataId && operationDataId.time_of_hotel_confirmation);
  let confirmationTime1 = new Date(operationDataId && operationDataId.time_of_hotel_confirmation);
  const hours1 = confirmationTime1.getUTCHours();
  const minutes1 = confirmationTime1.getUTCMinutes();
  const formattedMinutes1 = minutes1 < 10 ? `0${minutes1}` : minutes1.toString();

  // Format the time string
  const confirmationTime2 = `${hours1}:${formattedMinutes1}`;

  const handleTimeChange = (newTime) => {
    const date = new Date(newTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setOptionTime(`${hours1}:${minutes1}`);
  };

  const [checkId, setCheckId] = useState(false);
  const [checkOption, setCheckOption] = useState(false);
  const [checkConfirm, setCheckConfirm] = useState(false);
  const [hotelId, setHotelId] = useState(
    operationDataId !== undefined ? operationDataId.hotel_reservation_id : "maran",
  );
  const [optionDate, setOptionDate] = useState(convertedDate);

  const [optionTime, setOptionTime] = useState(formattedTime);

  const [mustOptionDate, setMustOptionDate] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(operationDataId.time_of_hotel_confirmation);
  const [currentDateShow, setCurrentDateShow] = useState(confirmationTime.toLocaleDateString());
  const [currentTimeShow, setCurrentTimeShow] = useState(confirmationTime2);
  const [responseTime, setResponseTime] = useState(operationDataId.time_average_response);
  const [responseTimeShow, setResponseTimeShow] = useState("");
  const [bookingTypeState, setBookingTypeState] = useState(operationDataId && operationDataId.booking_type_for_status);
  const [bookingStatusState, setBookingStatusState] = useState(operationDataId && operationDataId.booking_status);
  const { bookingTypes } = useSelector((state) => state.bookingListReducer);

  const { bookingStatus } = useSelector((state) => state.bookingListReducer);
  const reloadPage = useReloadPage();

  const bookingIdOfType =
    bookingTypes && bookingTypes.filter((item) => item.name === operationDataId.booking_type_for_status);
  const bookingIdOfTypeMap = bookingIdOfType
    ? bookingIdOfType.map((item) => {
        return item.id;
      })
    : [];

  const bookingStatusFilter =
    bookingStatus && bookingStatus.filter((item) => item.name === operationDataId.booking_status);
  const bookingIdOfStatus = bookingStatusFilter
    ? bookingStatusFilter.map((item) => {
        return item.id;
      })
    : [];

  const [bookingTypeId, setBookingTypeId] = useState(...bookingIdOfTypeMap);
  const [bookingStatusId, setBookingStatusId] = useState(...bookingIdOfStatus);
  const [btnCheck, setBtnCheck] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);

  const { operationUpdataFlag } = useSelector((state) => state.bookingListReducer);

  const handleDateTime = () => {
    const now = new Date();
    const twoHoursLater = new Date(now.getTime() + 2 * 60 * 60 * 1000); // Adding 2 hours in milliseconds
    const formattedDateTime = twoHoursLater.toISOString();

    setCurrentDateTime(formattedDateTime);
    const date1 = new Date(selectedSingleRoom.created_date);
    const date2 = new Date(formattedDateTime);
    const timeDifference = date2 - date1;
    const timeDifference2 = differenceInMilliseconds(date2, date1);
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    const isoDuration = `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
    const average = `P${days}DT${hours}H${minutes}M`;
    setResponseTime(average);
    setResponseTimeShow(isoDuration);
  };

  const handleDateTimeShow = () => {
    const now = new Date();
    const oneHourAgo = new Date(now.getTime() - 0 * 60 * 60 * 1000);
    const formattedDate = oneHourAgo.toLocaleDateString();
    const formattedTime = oneHourAgo.toLocaleTimeString();
    setCurrentDateShow(formattedDate);
    setCurrentTimeShow(formattedTime);
  };

  const handleEdit = () => {
    if (optionDate) {
      setCheckId(true);
      setBtnCheck(false);
    } else {
      setMustOptionDate(true);
    }
  };

  const [isOpenType, setIsOpenType] = useState(false);
  const [isOpenStatus, setIsOpenStatus] = useState(false);

  const toggleDropdownType = () => {
    setIsOpenType(!isOpenType);
  };
  const toggleDropdownStatus = () => {
    setIsOpenStatus(!isOpenStatus);
  };
  //   ];
  // const handleHotelId = () =>{
  //   const newData = selectedSingleBookedRoom.map((item) =>{
  //     return {...item , hotel_reservation_id : hotelId}
  //   })
  //   console.log("newData",newData)
  // }
  const handleDelete = () => {
    dispatch(
      deleteAction(token, selectedSingleRoom, Number(params.id), (result) => {
        if (result.status === 200) {
          toast.success(`Order of ${selectedSingleRoom.id} is Removed`);
          navigate(-1);
        }
      }),
    );
  };

  // const formData = new FormData();
  // formData.append('file', file);

  const operationOfData = {
    booked_room: Number(params.id),
    employee_name: 1,
    paid: selectedTransaction && selectedTransaction.results.reduce((acc, balc) => acc + parseFloat(balc.amount), 0),
    outstanding_amount:
      selectedTransaction &&
      selectedSingleRoom.total_price -
        selectedTransaction.results.reduce((acc, balc) => acc + parseFloat(balc.amount), 0),
    status: "new",
    // booking_type: "voucher",
    booking_type_for_status: bookingTypeId,
    booking_status: bookingStatusId,
    source: 1,
    transport_from_airport_to_hotel: "yes",
    hotel_reservation_id: hotelId,
    option_date: `${optionDate},${optionTime}`,
    time_of_hotel_confirmation: currentDateTime,
    time_average_response: responseTime,
    total_profit: operationDataId.total_profit,
    total_net_price: operationDataId.total_net_price,
    special_request: "Late check-in",
    airport_service: "yes",
    cars: "yes",
    payment_type: paymentType,
    note: note,
  };

  //   const handleOperation = () =>{
  //
  //     if(hotelId && optionDate)
  //     {

  //              dispatch(operationAction(token , operationOfData, (result)=>{
  //
  //               // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}/${result.id}`)
  //              }))
  //     }
  //     else
  //     {

  //       toast.error("Hotel ID Or Option Date is Empty ")
  //     }
  //   }
  const handleUpdateOperation = () => {
    if (hotelId && optionDate) {
      dispatch(
        updateOperationAction(token, operationDataId && operationDataId.id, operationOfData, (result) => {
          // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}/${result.id}`)
          reloadPage();
        }),
      );
    } else {
      toast.error("Hotel ID Or Option Date is Empty ");
    }
  };
  return (
    <div>
      {operationDataId !== undefined && selectedTransaction ? (
        <div className="w-full mt-10">
          <p className="text-3xl font-thin text-white flex justify-center items-center w-full bg-[#002244] py-2 rounded-t-lg  ">
            Operation Info
          </p>
          <div className="flex flex-col   gap-y-5  w-full bg-gray-300 text-black rounded-b-md p-4 ">
            {/* Hotel id & Booking Type*/}
            <div className="flex w-full justify-between items-center ">
              <div className="flex  justify-between items-center w-[50%]  ">
                <div className="w-[33%] ">
                  <p className="flex items-center gap-x-1 font-bold">Booking Type </p>
                </div>
                <div className="w-[70%]">
                  <div className="relative inline-block text-left">
                    <div>
                      <button
                        onClick={toggleDropdownType}
                        type="button"
                        className="flex justify-center items-center w-48 h-9 rounded-md border border-gray-300 shadow-sm  bg-gray-100 text-sm font-medium text-gray-700 hover:bg-gray-200  active:scale-95 duration-300 transition-all"
                      >
                        {bookingTypeState ? bookingTypeState : " Booking Types"}
                        <svg
                          className="-mr-1 ml-2 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>

                    {isOpenType && (
                      <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                        <div className="py-1" role="menu" aria-orientation="horizontal" aria-labelledby="options-menu">
                          {bookingTypes &&
                            bookingTypes.map((item) => {
                              return (
                                <button
                                  className="w-full flex items-center justify-around px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  onClick={() => {
                                    setIsOpenType(!isOpenType);
                                    setBookingTypeState(item.name);
                                    setBookingStatusState("");
                                    setBookingTypeId(item.id);
                                    dispatch(getBookingStatusAction(token, item.id));
                                  }}
                                  key={item.id}
                                >
                                  <p>{item.name}</p>
                                </button>
                              );
                            })}

                          {/* <div className="flex items-center justify-around px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                              <p className='w-[70%]'>Pending bookings</p> 
                                              <input type="radio" name="options" value="Option 2" />
                                            </div>
                                            <div className="flex items-center justify-around px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            <p className='w-[70%]'>Confirmed bookings</p> 
                                              <input type="radio" name="options" value="Option 3" />
                                            </div>
                                            <div className="flex items-center justify-around px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            <p className='w-[70%]'> Room follow up</p>
                                              <input type="radio" name="options" value="Option 3" />
                                            </div>
                                            <div className="flex items-center justify-around px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            <p className='w-[70%]'>Canceled bookings</p>  
                                              <input type="radio" name="options" value="Option 3" />
                                            </div>
                                            <div className="flex items-center justify-around px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            <p className='w-[70%]'>Invoiced bookings</p> 
                                              <input type="radio" name="options" value="Option 3" />
                                            </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex items-center w-[50%]">
                <div className="w-[33%] ">
                  <p className="flex items-center gap-x-1 font-bold">Booking Status</p>
                </div>
                <div className="w-[70%]">
                  <div className="relative inline-block text-left ">
                    <div>
                      <button
                        onClick={toggleDropdownStatus}
                        type="button"
                        className="flex justify-center items-center w-48 h-9 rounded-md border border-gray-300 shadow-sm  bg-gray-100 text-sm font-medium text-gray-700 hover:bg-gray-200  active:scale-95 duration-300 transition-all"
                      >
                        {bookingStatusState ? bookingStatusState : " Booking Status"}
                        <svg
                          className="-mr-1 ml-2 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>

                    {isOpenStatus && (
                      <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                        <div className="py-1" role="menu" aria-orientation="horizontal" aria-labelledby="options-menu">
                          {bookingStatus &&
                            bookingStatus.map((item) => {
                              return (
                                <button
                                  className="w-full flex items-center justify-around px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  onClick={() => {
                                    setIsOpenStatus(!isOpenStatus);
                                    setBookingStatusState(item.name);
                                    setBookingStatusId(item.id);
                                  }}
                                  key={item.id}
                                >
                                  <p>{item.name}</p>
                                </button>
                              );
                            })}

                          {/* <div className="flex items-center justify-around px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                              <p className='w-[70%]'>Pending bookings</p> 
                                              <input type="radio" name="options" value="Option 2" />
                                            </div>
                                            <div className="flex items-center justify-around px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            <p className='w-[70%]'>Confirmed bookings</p> 
                                              <input type="radio" name="options" value="Option 3" />
                                            </div>
                                            <div className="flex items-center justify-around px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            <p className='w-[70%]'> Room follow up</p>
                                              <input type="radio" name="options" value="Option 3" />
                                            </div>
                                            <div className="flex items-center justify-around px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            <p className='w-[70%]'>Canceled bookings</p>  
                                              <input type="radio" name="options" value="Option 3" />
                                            </div>
                                            <div className="flex items-center justify-around px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            <p className='w-[70%]'>Invoiced bookings</p> 
                                              <input type="radio" name="options" value="Option 3" />
                                            </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {/* Booking Status & Option Date*/}
            <div className="flex w-full justify-between items-center">
              <div className="flex items-center w-[50%]">
                <div className="w-[33%]  ">
                  <p className="flex items-center gap-x-1 font-bold">Hotel Reservation ID</p>
                </div>
                <div>
                  {checkId ? (
                    <div className="flex items-center gap-x-1">
                      <input
                        className=" flex items-center gap-x-1 bg-white  rounded-md w-40 h-[24px] p-2 font-semibold text-gray-700"
                        defaultValue={hotelId}
                        onChange={(e) => setHotelId(e.target.value)}
                      />
                      <button
                        className="bg-[#002244] text-white rounded-md hover:scale-105 hover:bg-sky-600 active:scale-95 duration-300 transition-all p-0.5"
                        onClick={() => {
                          setCheckId(false);
                          setMustOptionDate(false);
                          handleDateTime();
                          handleDateTimeShow();
                        }}
                      >
                        <MdOutlineDone className="w-4 h-4" />
                      </button>
                    </div>
                  ) : (
                    <div className="flex items-center gap-x-1 font-semibold text-slate-900    ">
                      {hotelId}
                      <button
                        className="bg-[#002244] text-white rounded-md hover:scale-105 hover:bg-sky-600 active:scale-95 duration-300 transition-all p-1"
                        onClick={handleEdit}
                      >
                        <FiEdit className="w-4 h-4" />
                      </button>
                      {mustOptionDate && <p className="text-sm  text-red-500">You Should Enter Option Date First</p>}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%]  ">
                  <p className="flex items-center gap-x-1 font-bold">Option Date</p>
                </div>
                <div className="w-[70%]">
                  {checkOption ? (
                    <div className="flex items-center gap-x-1">
                      <input
                        type="date"
                        className="floating-input text-sm flex items-center gap-x-1 bg-white  rounded-md w-32 h-[24px] p-2"
                        defaultValue={optionDate}
                        onChange={(e) => setOptionDate(e.target.value)}
                      />
                      <TimePicker
                        clearIcon={false}
                        defaultValue={dayjs(optionTime, format)}
                        format={format}
                        onChange={handleTimeChange}
                        className="h-[24px]"
                      />
                      {/* <input type='time' className='floating-input flex items-center gap-x-1 bg-gray-100 border-gray-500 border rounded-md w-36 h-6' defaultValue={optionTime} onChange={(e)=>setOptionTime(e.target.value)}/> */}
                      <button
                        className="bg-[#002244] text-white rounded-md hover:scale-105 hover:bg-sky-600 active:scale-95 duration-300 transition-all p-0.5"
                        onClick={() => {
                          setCheckOption(false);
                          setMustOptionDate(false);
                        }}
                      >
                        <MdOutlineDone className="w-4 h-4" />
                      </button>
                    </div>
                  ) : (
                    <div className="flex items-center gap-x-4">
                      {optionDate ? (
                        <p className="flex items-center gap-x-1 font-semibold text-slate-900    ">
                          <FaRegCalendarAlt className="w-4 h-4 text-[#002244]  " />
                          {optionDate}
                        </p>
                      ) : (
                        ""
                      )}
                      {optionTime ? (
                        <p className="flex items-center gap-x-1 font-semibold text-slate-900    ">
                          <MdOutlineAccessTime className="w-5 h-5 text-[#002244] " />
                          {optionTime}
                        </p>
                      ) : (
                        ""
                      )}
                      <button
                        className="bg-[#002244] text-white rounded-md hover:scale-105 hover:bg-sky-600 active:scale-95 duration-300 transition-all p-1"
                        onClick={() => {
                          setCheckOption(true);
                          setBtnCheck(false);
                        }}
                      >
                        <FiEdit className="w-4 h-4" />
                      </button>{" "}
                    </div>
                  )}
                </div>
              </div>
            </div>{" "}
            <hr />
            <div className="flex w-full justify-between items-center">
              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%]  ">
                  <p className="flex items-center gap-x-1 font-bold">Hotel Net Price</p>
                </div>
                <div className="w-[70%] flex items-end  gap-x-1 font-semibold text-slate-900    ">
                  <p>{selectedSingleRoom.net_price} </p>
                  <p className="text-sm">{selectedSingleRoom.currency_type}</p>
                </div>
              </div>
              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%]  ">
                  <p className="flex items-center gap-x-1 font-bold">Status</p>
                </div>
                <div className="w-[70%]">
                  <p className="flex items-center gap-x-1 font-semibold text-slate-900 ">New</p>
                </div>
              </div>
            </div>{" "}
            <hr />
            <div className="flex w-full justify-between items-center">
              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%]  ">
                  <p className="flex items-center gap-x-1 font-bold">Paid</p>
                </div>
                <div className="w-[70%] flex items-end gap-x-1 font-semibold text-slate-900 ">
                  <p>
                    {" "}
                    {selectedTransaction &&
                      selectedTransaction.results
                        .filter((item) => item.transaction_status === "confirmed")
                        .reduce((acc, balc) => acc + parseFloat(balc.conversion_rate_price), 0)}
                  </p>
                  <p className="text-sm">{selectedSingleRoom.currency_type}</p>
                </div>
              </div>
              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%]  ">
                  <p className="flex items-center gap-x-1 font-bold">Cars</p>
                </div>
                <div className="w-[70%]">
                  <p className="flex items-center gap-x-1 font-semibold text-slate-900    ">
                    {operationDataId && operationDataId.cars} <MdOutlineDone className="w-5 h-5  text-green-500" />
                  </p>
                </div>
              </div>
            </div>{" "}
            <hr />
            {/* Total Profit & Booking Status*/}
            <div className="flex w-full justify-between items-center">
              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%]  ">
                  <p className="flex items-center gap-x-1 font-bold">Total Profit </p>
                </div>
                <div className="w-[70%] flex items-end gap-x-1 font-semibold text-slate-900    ">
                  <p> {+selectedSingleRoom.total_price - +selectedSingleRoom.net_price}</p>
                  <p className="text-sm">{selectedSingleRoom.currency_type}</p>
                  {/* <p className="flex items-center gap-x-1">{operationDataId && operationDataId.total_profit}</p> */}
                </div>
              </div>
              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%]  ">
                  <p className="flex items-center gap-x-1 font-bold">Meet & Assist Service </p>
                </div>
                <div className="w-[70%]">
                  <p className="flex items-center gap-x-1 font-semibold text-slate-900    ">
                    {operationDataId && operationDataId.airport_service}{" "}
                    <MdOutlineDone className="w-5 h-5  text-green-500" />
                  </p>
                </div>
              </div>
            </div>{" "}
            <hr />
            {/* Balance & Special Request*/}
            <div className="flex w-full justify-between items-center">
              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%]  ">
                  <p className="flex items-center gap-x-1 font-bold">Outstanding Amount </p>
                </div>
                <div className="w-[70%]">
                  <div className="flex items-end gap-x-1 font-semibold text-slate-900    ">
                    <div>
                      {selectedTransaction.results &&
                      selectedSingleRoom.total_price -
                        selectedTransaction.results
                          .filter((item) => item.transaction_status === "confirmed")
                          .reduce((acc, balc) => acc + parseFloat(balc.conversion_rate_price), 0) >
                        0 ? (
                        <p className="text-red-700">
                          {selectedTransaction &&
                            selectedSingleRoom.total_price -
                              selectedTransaction.results
                                .filter((item) => item.transaction_status === "confirmed")
                                .reduce((acc, balc) => acc + parseFloat(balc.conversion_rate_price), 0)
                                .toFixed(2)}{" "}
                        </p>
                      ) : (
                        <p className="text-green-700">
                          {selectedTransaction &&
                            selectedSingleRoom.total_price -
                              selectedTransaction.results
                                .filter((item) => item.transaction_status === "confirmed")
                                .reduce((acc, balc) => acc + parseFloat(balc.conversion_rate_price), 0)
                                .toFixed(2)}{" "}
                        </p>
                      )}
                    </div>
                    <p className="text-sm">{operationDataId.currency_type}</p>
                  </div>
                </div>
              </div>
              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%]  ">
                  <p className="flex items-center gap-x-1 font-bold">Special Request</p>
                </div>
                <div className="w-[70%]">
                  <p className="flex items-center gap-x-1 font-semibold text-slate-900    ">
                    {operationDataId && operationDataId.special_request}
                  </p>
                </div>
              </div>
            </div>{" "}
            <hr />
            {/*Transport To Hotel & Time Of Hotel Confirmation*/}
            <div className="flex w-full justify-between items-center">
              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%]  ">
                  <p className="flex items-center gap-x-1 font-bold ">Transport To Hotel</p>
                </div>
                <div className="w-[70%]">
                  <p className="flex items-center gap-x-1 font-semibold text-slate-900    ">
                    {" "}
                    {operationDataId && operationDataId.transport_from_airport_to_hotel}{" "}
                    <MdOutlineDone className="w-5 h-5  text-green-500" />{" "}
                  </p>
                </div>
              </div>

              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%]  ">
                  <p className="flex items-center gap-x-1 font-bold">Time Of Hotel Confirmation</p>
                </div>
                <div className="w-[70%]">
                  <div className="flex items-center gap-x-1">
                    {hotelId && optionDate ? (
                      <div className="flex items-center gap-x-4 font-semibold text-slate-900    ">
                        <p className="flex items-center gap-x-1">
                          <FaRegCalendarAlt className="w-4 h-4 text-[#002244]" />
                          {currentDateShow}
                        </p>
                        <p className="flex items-center gap-x-1">
                          <MdOutlineAccessTime className="w-5 h-5 text-[#002244]" />
                          {currentTimeShow}
                        </p>
                      </div>
                    ) : (
                      "Hotel Id Or Option Date is Empty"
                    )}
                  </div>
                </div>
              </div>
            </div>{" "}
            <hr />
            {/* Time Average Response*/}
            <div className="flex w-full justify-between items-center">
              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%]  ">
                  <p className="flex items-center gap-x-1 font-bold">Response Time</p>
                </div>
                <div className="w-[70%]">
                  <p className="flex items-center gap-x-1 font-semibold text-slate-900     ">{responseTimeShow}</p>
                </div>
              </div>
              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%]  ">
                  <p className="flex items-center gap-x-1 font-bold">Source</p>
                </div>
                <div className="w-[70%]">
                  <p className="flex items-center gap-x-1 font-semibold text-slate-900    ">
                    {operationDataId && operationDataId.source}{" "}
                  </p>
                  <p className="flex items-center gap-x-1"></p>
                </div>
              </div>
            </div>{" "}
            <hr />
            {/* Time Average Response*/}
            <div className="flex w-full justify-between items-center">
              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%] bg-gray-300">
                  <p className="flex items-center gap-x-1 font-bold">Refund Amount</p>
                </div>
                <div className="w-[70%] font-semibold text-slate-900">
                  {/* <p className="flex items-center gap-x-1">{responseTimeShow}</p> */}
                </div>
              </div>

              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%] bg-gray-300 ">
                  <p className="flex items-center gap-x-1 font-bold">Channel Source</p>
                </div>
                <div className="w-[70%] font-semibold text-slate-900">
                  {/* <p className="flex items-center gap-x-1">{responseTimeShow}</p> */}
                </div>
              </div>
            </div>{" "}
            <hr />
            {operationBookingStatus === "pending cancelation" || !operationBookingStatus ? (
              <div className="flex items-center justify-end gap-x-2 p-2">
                <button
                  disabled={operationUpdataFlag}
                  onClick={handleUpdateOperation}
                  className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  {operationUpdataFlag ? (
                    <>
                      <ButtonLoading /> <TopBarProgress />
                    </>
                  ) : (
                    <RxUpdate className="w-4 h-4" />
                  )}{" "}
                  Update Operation
                </button>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <>
          <Loading /> <TopBarProgress />
        </>
      )}
    </div>
  );
};

export default OperationInfo;
