import { Fragment, useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useLocation, useNavigate } from "react-router";
import {
  FcServices,
  FcAssistant,
  FcCollaboration,
  FcHeadset,
  FcSms,
  FcBusinessman,
  FcDam,
  FcSalesPerformance,
  FcDocument,
  FcSteam,
  FcRules,
  FcInfo,
  FcMoneyTransfer,
  FcKindle,
  FcPlus,
  FcOvertime,
  FcPodiumWithAudience,
  FcMenu,
  FcSettings,
} from "react-icons/fc";
import { IoMdAirplane } from "react-icons/io";
import { IoFastFoodSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { flagList, flagListAction } from "../../../redux/actions/addCashBoxAction";
import useData from "../../../customHooks/useData";
import getUserId from "../../../redux/actions/getUserId.js";
import generateMenu from "./generateMenu.js";
import { Link, NavLink } from "react-router-dom";
import ButtonLoading from "../ButtonLoading/ButtonLoading.js";

const DashboardSidebar = () => {
  const { user_id } = getUserId();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allowedMenu = useData(`/menu/api/v1/user-menu/?user=${user_id}`);
  const [open, setOpen] = useState(() => JSON.parse(localStorage.getItem("menuOpen")) ?? true);
  const [subOpen, setSubOpen] = useState(() => JSON.parse(localStorage.getItem("submenuOpen")) ?? false);
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(() => JSON.parse(localStorage.getItem("openSubMenuIndex")));
  const [searchQuery, setSearchQuery] = useState("");
  const [activeMenuIndex, setActiveMenuIndex] = useState(() => JSON.parse(localStorage.getItem("activeMenuIndex")));

  const Menus = allowedMenu ? generateMenu(allowedMenu[0]?.json_menu.menu[0]) : [];

  useEffect(() => {
    localStorage.setItem("menuOpen", JSON.stringify(open));
  }, [open]);

  useEffect(() => {
    localStorage.setItem("submenuOpen", JSON.stringify(subOpen));
  }, [subOpen]);

  useEffect(() => {
    localStorage.setItem("openSubMenuIndex", JSON.stringify(openSubMenuIndex));
  }, [openSubMenuIndex]);
  useEffect(() => {
    localStorage.setItem("activeMenuIndex", JSON.stringify(activeMenuIndex));
  }, [activeMenuIndex]);
  const goTo = () => {
    dispatch(flagListAction(false));
    setSubOpen(true);
    setOpen(true);
  };

  const toggleSubMenu = (index) => {
    setOpenSubMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleMenuClick = (index) => {
    toggleSubMenu(index);
    setOpen(true);
    setSubOpen(true);
    setActiveMenuIndex(index);
  };

  const location = useLocation();
  const currentPath = location.pathname;
  const parts = currentPath.split("/");
  const lastParams = parts[2];

  return (
    <div className="flex bg-white sticky top-0 z-50">
      <div
        className={` ${open ? "w-72" : "w-20 pt-8 "} bg-dark-purple h-screen overflow-y-auto p-5 duration-300 border-gray-200 border rounded-xl`}
      >
        {allowedMenu ? (
          <>
            <div
              className={`relative w-full ${open ? "py-3 flex items-center justify-around" : "flex flex-col items-center gap-y-5"}`}
            >
              <img
                onClick={() => navigate("/dashboard")}
                src={`${open ? "/images/logo.png" : "/images/logo12.ico.png"}`}
                className={`cursor-pointer duration-500 ${open ? "rotate-[360deg] w-36 h-36 absolute" : "w-8 h-8"}`}
              />
              <FcMenu
                className={`absolute cursor-pointer w-6 h-6 rounded-full ${!open ? "rotate-180 -top-7 right-1" : "top-1 right-4"}`}
                onClick={() => setOpen(!open)}
              />
            </div>
            <div
              className={`relative flex items-center rounded-md border-gray-300 border w-full mt-6 ${!open ? "px-1 py-4" : "px-4"} text-black`}
            >
              <BsSearch
                className={`absolute text-lg float-left ${open && "mr-2"} text-gray-800`}
                onClick={() => setOpen(true)}
              />
              <input
                type="search"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={`text-base h-full w-full py-2 pl-6 outline-none ${!open && "hidden"}`}
              />
            </div>
            <ul className="pt-4 text-black duration-200 transition-all">
              {Menus.map((menu, index) => {
                const isVisible =
                  menu.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  (menu.submenu &&
                    menu.submenuItems.some((item) => item.toLowerCase().includes(searchQuery.toLowerCase())));

                const isActiveMenu = activeMenuIndex === index;

                return (
                  isVisible && (
                    <Fragment key={index}>
                      <Link>
                        <li
                          onClick={() => handleMenuClick(index)}
                          className={`w-full flex rounded-md ${isActiveMenu ? "bg-[#002244] text-white" : "bg-gray-100"} ${open && "p-2 justify-center "}hover:bg-black/50 hover:text-white duration-200 transition-all cursor-pointer hover:bg-light-white font-semibold text-sm items-center gap-x-4
                      ${menu.gap ? "mt-9" : "mt-2"} ${index === 0 && "bg-light-white"}`}
                        >
                          <div className={`w-full flex ${!open && "justify-center"} items-center gap-x-1`}>
                            <menu.src className={`${!open ? "w-9 h-9" : "w-7 h-7"}`} />
                            <span className={`${!open && "hidden"} origin-left duration-200`}>{menu.title}</span>
                          </div>
                          {menu.submenu && open && (
                            <MdKeyboardArrowDown className={`${openSubMenuIndex === index ? "rotate-180" : ""}`} />
                          )}
                        </li>
                      </Link>
                      {menu.submenu && openSubMenuIndex === index && open && (
                        <ul className="flex ease-in-out delay-150 flex-col gap-1 bg-gray-100 my-1 text-black p-4 rounded-md duration-200 transition-all">
                          {menu.submenuItems.map((submenuItem, subIndex) => {
                            const isActive = lastParams === submenuItem.split(" ").join("-").toLowerCase();
                            return (
                              <li key={subIndex} className="w-full rounded-md">
                                <NavLink
                                  activeClassName="is-active"
                                  className={`${
                                    isActive ? "bg-[#002244] text-white" : "bg-gray-100  text-black"
                                  } transition ease-in-out delay-150 w-full flex flex-col items-start rounded-md py-2 pl-4 cursor-pointer hover:bg-[#002244b2] hover:text-white font-semibold text-sm gap-x-4`}
                                  onClick={goTo}
                                  to={`/dashboard/${submenuItem.split(" ").join("-").toLowerCase()}`}
                                >
                                  {submenuItem}
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </Fragment>
                  )
                );
              })}
            </ul>
          </>
        ) : (
          <div className="w-full h-screen flex items-center justify-center">
            <ButtonLoading />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardSidebar;
