import React, { useEffect, useState } from "react";
import "./RoomDetailsCard.scss";
import { deleteRoom, getRoomDetails } from "../../../redux/actions/getRooms";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import DeletePopUp from "../../molecules/DeletePopUp/DeletePopUp";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { IoIosAddCircleOutline } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import { MdOutlineBalcony, MdOutlineBedroomParent, MdOutlineDescription } from "react-icons/md";
import { LuHotel } from "react-icons/lu";
import { FaStreetView } from "react-icons/fa6";
import { RxSpaceBetweenHorizontally } from "react-icons/rx";
import { GrShareOption } from "react-icons/gr";
import { FaInfoCircle } from "react-icons/fa";

const splideOptionsMultiForms = {
  perPage: 1,
  perMove: 1,
  rewind: false,
  arrows: true,
  keyboard: "global",
  gap: "2rem",
  pagination: false,
  padding: "0",
};

export default function RoomDetailsCard({ onRoomDeleted }) {
  const [roomData, setRoomData] = useState([]);

  console.log("room data", roomData);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const room = useSelector((state) => state.roomDetails);
  const [popUp, setPopUp] = useState(false);

  useEffect(() => {
    dispatch(
      getRoomDetails(id, (res) => {
        if (res) {
          setRoomData(res.data);
        }
      }),
    );
  }, [dispatch, id]);

  function handleDelete() {
    setPopUp(false);
    dispatch(
      deleteRoom(id, (result) => {
        if (result.status === 200) {
          const storedRooms = JSON.parse(localStorage.getItem("roomsList"));
          const updatedRooms = storedRooms.filter((room) => room.id !== id);
          localStorage.setItem("roomsList", JSON.stringify(updatedRooms));
          onRoomDeleted(id);
          navigate(-1);
        } else {
          // Handle deletion error (optional)
        }
      }),
    );
  }

  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: [
      "rooms_management.add_rooms",
      "rooms_management.change_rooms",
      "rooms_management.delete_rooms",
      "rooms_management.view_rooms",
    ],
  };
  const [permission, setPermission] = useState();
  const [permissionFlag, setPermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setPermissionFlag(true);
          setPermission(result.data);
        }
      }),
    );
  }, [dispatch, token]);

  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        <div className="roomDetailsCard">
          {roomData.room_name === "" ? (
            <div className="w-full flex items-center justify-center h-screen">
              <Loading />
              <TopBarProgress />
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <div className="roomNameCard">
                <Splide options={splideOptionsMultiForms}>
                  {roomData &&
                    roomData?.room_images?.map((imageObj, index) => {
                      return (
                        <SplideSlide key={index} className="mb-0.5">
                          <img
                            src={`${imageObj.image}`}
                            className="block object-cover w-full lg:h-[500px] rounded-lg"
                            alt="Room"
                          />
                        </SplideSlide>
                      );
                    })}
                </Splide>

                <div className="w-full flex max-sm:flex-col justify-between gap-2 items-center">
                  <div className="roomName text-[#002244] ">{roomData && roomData?.room_name}</div>
                  <div className="flex-btns">
                    {permission.map((item) => {
                      if (item.name === "rooms_management.add_rooms" && item.value === true) {
                        return (
                          <button
                            key="add"
                            className="action-btn hover:scale-105 active:scale-95 duration-300 transition-all"
                            onClick={() => navigate("/dashboard/add-room")}
                          >
                            <IoIosAddCircleOutline className="w-5 h-5 text-white" />
                            <span>Add Room</span>
                          </button>
                        );
                      } else if (item.name === "rooms_management.change_rooms" && item.value === true) {
                        return (
                          <button
                            key="edit"
                            className="action-btn-edit hover:scale-105 active:scale-95 duration-300 transition-all"
                            onClick={() => navigate(`/dashboard/edit-room/${id}`)}
                          >
                            <FiEdit className="w-5 h-5 text-white" />
                            <span>Edit</span>
                          </button>
                        );
                      }
                    })}
                  </div>
                  {/* <div className="flex-btns">
                    {permission.map((item) => {
                      if (item.name === "rooms_management.change_rooms" && item.value === true) {
                        return (
                          <button
                            key="edit"
                            className="action-btn-edit hover:scale-105 active:scale-95 duration-300 transition-all"
                            onClick={() => navigate(`/dashboard/edit-room/${id}`)}
                          >
                            <FiEdit className="w-5 h-5 text-white" />
                            <span>Edit</span>
                          </button>
                        );
                      } else if (item.name === "rooms_management.add_rooms" && item.value === true) {
                        return (
                          <button
                            key="add"
                            className="action-btn hover:scale-105 active:scale-95 duration-300 transition-all"
                            onClick={() => navigate("/dashboard/add-room")}
                          >
                            <IoIosAddCircleOutline className="w-5 h-5 text-white" />
                            <span>Add Room</span>
                          </button>
                        );
                      } else if (item.name === "rooms_management.delete_rooms" && item.value === true) {
                        return (
                          <button
                            key="delete"
                            className="action-btn-delete hover:scale-105 active:scale-95 duration-300 transition-all"
                            onClick={() => {
                              setPopUp(true);
                            }}
                          >
                            <MdDeleteSweep className="w-5 h-5 text-white" />
                            <span>Delete</span>
                          </button>
                        );
                      }
                      return null;
                    })}
                  </div> */}
                </div>
              </div>
              <div className="flex flex-col tableDiv w-full">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="w-full rounded-md font-light">
                        <tbody className="rounded-md">
                          <tr className="border-b border-primary">
                            <td className="flex items-center gap-x-2 border-r px-6 py-4 font-medium bg-[#002244] bg-opacity-5 text-[#002244]">
                              <LuHotel className="w-5 h-5" /> Hotel Name
                            </td>
                            <td className="px-6 py-4 font-semibold text-gray-600">{roomData.hotel_name}</td>
                          </tr>
                          <tr className="border-b border-primary">
                            <td className="flex items-center gap-x-2 border-r px-6 py-4 font-medium bg-[#002244] bg-opacity-5 text-[#002244]">
                              <MdOutlineBedroomParent className="w-5 h-5" /> Room Arabic Name
                            </td>
                            <td className="px-6 py-4 font-semibold text-gray-600">{roomData.room_name_ar}</td>
                          </tr>
                          <tr className="border-b border-primary">
                            <td className="flex items-center gap-x-2 border-r px-6 py-4 font-medium bg-[#002244] bg-opacity-5 text-[#002244]">
                              <MdOutlineDescription className="w-5 h-5" /> Description
                            </td>
                            <td className="px-6 py-4 font-semibold text-gray-600">{roomData.description}</td>
                          </tr>
                          <tr className="border-b border-primary">
                            <td className="flex items-center gap-x-2 border-r px-6 py-4 font-medium bg-[#002244] bg-opacity-5 text-[#002244]">
                              <MdOutlineDescription className="w-5 h-5" /> Arabic Description
                            </td>
                            <td className="px-6 py-4 font-semibold text-gray-600">{roomData.description_ar}</td>
                          </tr>
                          <tr className="border-b border-primary">
                            <td className="flex items-center gap-x-2 px-6 border-r py-4 font-medium bg-[#002244] bg-opacity-5 text-[#002244]">
                              <FaStreetView className="w-5 h-5" /> View
                            </td>
                            <td className="px-6 py-4 font-semibold text-gray-600">{roomData.view}</td>
                          </tr>
                          <tr className="border-b border-primary">
                            <td className="flex items-center gap-x-2 border-r px-6 py-4 font-medium bg-[#002244] bg-opacity-5 text-[#002244]">
                              <FaStreetView className="w-5 h-5" /> Arabic View
                            </td>
                            <td className="px-6 py-4 font-semibold text-gray-600">{roomData.view_ar}</td>
                          </tr>
                          <tr className="border-b border-primary">
                            <td className="flex items-center gap-x-2 border-r px-6 py-4 font-medium bg-[#002244] bg-opacity-5 text-[#002244]">
                              <MdOutlineBalcony className="w-5 h-5" /> Balcony
                            </td>
                            <td className="px-6 py-4 font-semibold text-gray-600">{roomData.balcony}</td>
                          </tr>
                          <tr className="border-b border-primary">
                            <td className="flex items-center gap-x-2 border-r px-6 py-4 font-medium bg-[#002244] bg-opacity-5 text-[#002244]">
                              <RxSpaceBetweenHorizontally className="w-5 h-5" /> Space
                            </td>
                            <td className="px-6 py-4 font-semibold text-gray-600">
                              {roomData.space} m<sup>2</sup>
                            </td>
                          </tr>
                          <tr className="border-b border-primary">
                            <td className="flex items-center gap-x-2 px-6 border-r py-4 font-medium bg-[#002244] bg-opacity-5 text-[#002244]">
                              <GrShareOption className="w-5 h-5" /> Features
                            </td>
                            <td className="px-6 py-4 font-semibold text-gray-600">
                              <ul>
                                {roomData &&
                                  roomData?.features?.map((feature, index) => <li key={index}>{feature}</li>)}
                              </ul>
                            </td>
                          </tr>
                          <tr className="border-b border-primary">
                            <td className="flex items-center gap-x-2 px-6 border-r py-4 font-medium bg-[#002244] bg-opacity-5 text-[#002244]">
                              <FaInfoCircle className="w-5 h-5" /> Extra Info
                            </td>
                            <td className="px-6 py-4 font-semibold text-gray-600">
                              <ul>
                                {roomData?.extra_info?.map((info, index) => (
                                  <li key={index}>{info?.name}</li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {popUp && <DeletePopUp name="room" Func={handleDelete} hideFunc={() => setPopUp(false)} />}
            </div>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
