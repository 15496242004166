import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRoomDescription, setDataOFBookingOrder } from "../../redux/actions/bookingOrderAction";
import { useDispatch, useSelector } from "react-redux";
import { GiPerson } from "react-icons/gi";
import { IoIosPerson, IoMdDoneAll } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { FiSend } from "react-icons/fi";
import { TbViewportWide } from "react-icons/tb";
import { FaChildren, FaStreetView } from "react-icons/fa6";
import { MdOutlineBalcony, MdOutlineEventAvailable, MdOutlineFreeCancellation } from "react-icons/md";
import { TbBuildingEstate } from "react-icons/tb";
import cookie from "react-cookies";
import { Button, Modal, ModalHeader, Popover } from "flowbite-react";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "react-image-lightbox/style.css";
import "./BookingListTableSingleId/BookingListTableSingle.css";
import "reactjs-popup/dist/index.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import Loading from "./Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { useTranslation } from "react-i18next";
import { Badge, Card, Space } from "antd";
import {
  exchangeRate,
  exchangeRateDynamic,
  exchangeRateFixed,
  getCancellationHotel,
  hotelInfoDATA,
} from "../../redux/actions/cancellation";
import { map } from "jquery";
import { backdropClasses } from "@mui/material";
import { set } from "date-fns";
import CancelationModal from "./cancelation_policyModel/CancelationModal";
import CPTest from "./cancelation_policyModel/CPTest";
import jwtDecode from "jwt-decode";
import fetchDataQuery from "../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import Errorfetch from "./Errorfetch/Errorfetch";
import { CgBoy } from "react-icons/cg";
import { Spin } from "antd";
import DateValidModal from "./MoreDataListSelectedRoom/DateValidModal";
import PriceDetailsModal from "./MoreDataListSelectedRoom/PriceDetailsModal";
import { LuPercent } from "react-icons/lu";
import RoomsExtraOffers from "./MoreDataListSelectedRoom/RoomsExtraOffers";
import { BiSolidOffer } from "react-icons/bi";

const ListSelectedRoom = ({
  room_extra_info,
  selectedroom,
  item,
  price,
  originalPrice,
  room_id,
  currency,
  currencySearch,
  commissions,
  roomtype_id,
  offerType,
  nameofhotel,
  persons,
  roomImage,
  room_description,
  inventoryStatus,
  paymentType,
  net_Price,
  firstNight,
  daysDataList,
  contractMeal,
  hotel_En,
  hotel_address,
  hotel_map_address,
  hotel,
  offerType_id,
  startDate,
  hotel_pk,
  hotelId,
  hotel_AR,
  uniqueId,
  children_num,
  children_ages,
  userinfo,
  rooms_extra_offers,
}) => {
  const [data, setData] = useState();
  const [rate, setRate] = useState();
  const [roomsData, setAllRoomsData] = useState();
  const [freeVal, setFreeVal] = useState(true);

  const [rateSecond, setRatesecond] = useState(); // Resulting date after subtraction
  const { moreRoomsData } = useSelector((state) => state.SelectedSearchReducer);
  const { singleRoomsData } = useSelector((state) => state.SelectedSearchReducer);
  const selectedDataOfHotel = useSelector((state) => state.SelectedSearchReducer.selectedHotel);
  const { cardData } = useSelector((state) => state.cardReducer);

  useEffect(() => {
    if (moreRoomsData) {
      setAllRoomsData(moreRoomsData);
    }
  }, [moreRoomsData]);

  const contractOfferType = item?.contract_offer_type;
  const contractOfferID = item?.contract_offer_type_id;
  const cancellationPolicy = item?.contract_cancellation_policy;
  const [open, setOpen] = React.useState(false);
  const [detailsFlag, setdetailsFlag] = React.useState(false);
  const [roomDetails, setRoomDetails] = useState(false);
  const token = cookie.load("access_token");
  const [t, i18n] = useTranslation();
  const localeCode = t("localeCode");

  const mealType =
    offerType === "b.o"
      ? "Bed Only"
      : offerType === "b.b"
        ? "breakfast included"
        : offerType === "h.b"
          ? "half board"
          : offerType === "f.b"
            ? "full board"
            : offerType === "s.a"
              ? "Soft All"
              : "";

  const { dataFromSearchForReseve } = useSelector((state) => state.reservationReducer);

  const roomId = parseInt(room_id);
  const AllDataWhatsApp = useSelector((state) => state.dataOfWhatsAppReducer.data);

  const bookingOrderData = {
    hotel_AR: hotel_AR,
    hotel: hotel_En,
    hotel_En: hotel_En,
    selectedroom: selectedroom,
    roomtype_id: roomtype_id,
    mealType: mealType,
    room_id: parseInt(room_id),
    price: originalPrice,
    currency_type: currency,
    commissions: commissions,
    contractOfferType: contractOfferType,
    cancellationPolicy: cancellationPolicy,
    number_of_persons: persons,
    room_description: room_description,
    roomImage: roomImage,
    nationality: dataFromSearchForReseve.nationality,
    payment_type: paymentType,
    inventory_status: inventoryStatus,
    net_price: net_Price,
    first_night: firstNight,
    additional_data: daysDataList,
    contract_meal: contractMeal,
    searchPrice: price,
    searchCurrency: currencySearch,
    hotel_address: hotel_address,
    hotel_map_address: hotel_map_address,
    startDate: startDate,
    hotelId: hotelId,
    contractOfferID: contractOfferID,
    currencySearch: currencySearch,
    item: item,
    cpHotel: hotel,
    nights_whats_app: AllDataWhatsApp.nights,
    persons_Of_search: AllDataWhatsApp.person,
    formCheckIn_whats_app: AllDataWhatsApp.formCheckIn,
    formCheckOut_whats_app: AllDataWhatsApp.formCheckOut,
    uniqueId: uniqueId,
    booked_by: userinfo?.username,
    user_type: userinfo?.user_type,
    source_channel: cookie.load("code") ? "Client link" : "Original system",
    children_num: children_num,
    children_ages: children_ages,
    // agent_id: userinfo?.user_type === "b2b" ? userinfo?.profile?.credit_info?.agent_id : null,
  };

  // Conditionally add agent_id if user_type is "b2b"
  if (userinfo?.user_type === "b2b") {
    bookingOrderData.agent_id = userinfo?.profile?.credit_info?.agent_id;
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBookingOrderNow = () => {
    dispatch(setDataOFBookingOrder(bookingOrderData, roomId, mealType, selectedroom, roomtype_id, uniqueId));
    navigate("/multireservation");
  };

  const handleBookingOrder = () => {
    dispatch(setDataOFBookingOrder(bookingOrderData, roomId, mealType, selectedroom, roomtype_id, uniqueId));
  };

  ////////////////////////////////////////////////////////////////////////////////////////////

  const handlemoreDetails = () => {
    dispatch(
      getRoomDescription(token, room_id, t("lan"), (result) => {
        if (result.status === 200) {
          setdetailsFlag(true);
          setRoomDetails(result.data);
        }
      }),
    );
  };

  const zoomRef = React.useRef(null);
  const images = [
    roomDetails &&
      roomDetails.room_images.map((item) => {
        return { src: item.image, description: roomDetails.description };
      }),
  ];
  const [photoIndex, setPhotoIndex] = useState(0);
  const customStyles = {
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    title: {
      flex: 1, // Take remaining space
      textAlign: "left",
    },
    caption: {
      flex: 1, // Take remaining space
      textAlign: "right",
    },
  };

  const splideOptions = {
    perPage: 1,
    perMove: 1,
    arrows: true,
    keyboard: "global",
    rewind: true, // Set rewind to true for looping
    pagination: false, // Enable pagination if needed
    direction: t("dir") === "en" ? "ltr" : "rtl",
    autoplay: true, // Enable autoplay
    interval: 3000, // Set interval for autoplay in milliseconds (optional)
    speed: 500, // Set transition speed if desired (in milliseconds)
  };

  const [SearchInfo, setSearchInfo] = useState();
  const [OpenDateValid, setOpenDateValid] = useState(null);
  const [OpenpriceDetails, setOpenpriceDetails] = useState(null);

  const {
    data: ExtraData,
    error: ExtraError,
    isLoading,
  } = useQuery({
    queryKey: ["room-extra-info", SearchInfo],
    queryFn: ({ queryKey }) => {
      const [_key, name] = queryKey;
      return fetchDataQuery(`/rooms/api/v1/room-extra-info?name=${name}`, { "Accept-Language": t("lan") });
    },
    enabled: !!SearchInfo,
  });

  const [OffersRoomInfo, setOffersRoomInfo] = useState(false);

  const {
    data: RoomOffersData,
    error: RoomOffersError,
    isLoading: RoomOffersloading,
  } = useQuery({
    queryKey: ["room-upgrade-periods", OffersRoomInfo],
    queryFn: ({ queryKey }) => {
      const [_key, name] = queryKey;
      return fetchDataQuery(
        `/upgrade-tool/room-upgrade-periods/?start_date=${dataFromSearchForReseve?.from_date}&end_date=${dataFromSearchForReseve?.to_date}&room_id=${room_id}`,
        { "Accept-Language": t("lan") },
      );
    },
    enabled: !!OffersRoomInfo,
  });

  return (
    <Badge.Ribbon
      text={
        <span
          onClick={(e) => {
            setOpenpriceDetails(true);
          }}
          style={{ cursor: "pointer" }}
        >
          {t("pricesDetails")}
        </span>
      }
      style={{
        backgroundColor: "#27ae60",
        color: "#27ae60",
      }}
      className=" cursor-pointer "
      onClick={() => setOpenpriceDetails(true)}
    >
      <div className="w-full   py-5 px-[1rem] md:px-4 md:mb-0 mb-7 boxShadow border  rounded-2xl relative  ">
        {rooms_extra_offers && (
          <div
            class={`z-10 !mt-7 bg-red-600 text-white ant-ribbon ant-ribbon-placement-end  cursor-pointer  css-dev-only-do-not-override-98ntnt`}
            style={{ backgroundColor: "#eb3833 ", color: "#eb3833 " }}
            onClick={() => setOffersRoomInfo(true)}
          >
            <span class="ant-ribbon-text showHide">
              <span style={{ cursor: "pointer" }} className="flex justify-center items-center gap-1">
                {t("FreeUpgrade")}
              </span>
            </span>
            <div class="ant-ribbon-corner "></div>
          </div>
        )}

        <RoomsExtraOffers
          OffersRoomInfo={OffersRoomInfo}
          RoomOffersData={RoomOffersData}
          RoomOffersloading={RoomOffersloading}
          setOffersRoomInfo={setOffersRoomInfo}
        />
        <DateValidModal
          AllDataWhatsApp={AllDataWhatsApp}
          OpenDateValid={OpenDateValid}
          setOpenDateValid={setOpenDateValid}
          room_id={room_id}
        />
        <PriceDetailsModal
          setOpenpriceDetails={setOpenpriceDetails}
          OpenpriceDetails={OpenpriceDetails}
          daysDataList={daysDataList}
          currencySearch={currencySearch}
          currency={currency}
          offerType={offerType}
        />

        {open && (
          <Modal size={"6xl"} dismissible show={open} onClose={() => setOpen(false)}>
            <Modal.Header></Modal.Header>
            <Modal.Body dir={t("dir")}>
              {detailsFlag ? (
                <div className="flex lg:flex-row flex-col justify-between">
                  <div className="  flex flex-col  justify-start items-start bg-white lg:w-[60%]  h-full  ">
                    <Splide options={splideOptions}>
                      {roomDetails &&
                        roomDetails.room_images.map((item) => (
                          <SplideSlide>
                            <div className="w-full ">
                              <img src={item.image} className="w-full lg:h-[340px] h-[200px] rounded-xl" />
                            </div>
                          </SplideSlide>
                        ))}
                    </Splide>
                    <div className="px-6 flex  flex-col   gap-y-2    w-full ">
                      {roomDetails.balcony === "Yes" ? (
                        <p className="w-full flex  gap-x-1 text-[#002244] font-bold  text-xs sm:text-sm  ">
                          <MdOutlineBalcony className="w-5 h-5" /> {t("Balcony")}
                        </p>
                      ) : (
                        ""
                      )}

                      <p className="w-full flex  gap-x-1 text-[#002244] font-bold  text-xs sm:text-sm  ">
                        <TbViewportWide className="w-5 h-5" />
                        {roomDetails.space} {t("Meter")}
                      </p>
                      <p className="w-full flex  gap-x-1 text-[#002244] font-bold  text-xs sm:text-sm  ">
                        <FaStreetView className="w-5 h-5" />
                        {roomDetails.view}
                      </p>

                      <div>
                        {/* <CancelationModal
                          startDate={startDate}
                          hotelId={hotelId}
                          contractOfferID={contractOfferID}
                          currencySearch={currencySearch}
                          item={item}
                          price={price}
                          hotel={hotel}
                        /> */}
                        <CPTest
                          startDate={startDate}
                          hotelId={hotelId}
                          contractOfferID={contractOfferID}
                          currencySearch={currencySearch}
                          item={item}
                          price={price}
                          hotel={hotel}
                        />
                      </div>
                    </div>

                    <div className=" flex flex-col gap-y-2  items-center   gap-x-6   w-full  mt-5">
                      {inventoryStatus ? (
                        <button
                          className="w-[90%] py-1 px-2 text-sm   bg-[#002244] text-white rounded-md hover:bg-slate-950 active:scale-95 duration-500 transition-all"
                          onClick={handleBookingOrderNow}
                        >
                          {t("bookNow")}
                        </button>
                      ) : (
                        <button
                          className="w-[90%] flex items-center justify-center gap-x-1 py-1 px-2 text-sm  bg-yellow-300 text-black rounded-md hover:bg-[#002244] active:scale-95 duration-500 transition-all"
                          onClick={handleBookingOrderNow}
                        >
                          {" "}
                          <FiSend className="w-4 h-4 text-black" />
                          {t("bookingRequest")}
                        </button>
                      )}
                      <button
                        className=" w-[90%] py-1 px-1 flex items-center justify-center gap-x-1 text-xs  bg-[#002244] text-white rounded-md hover:bg-slate-950  active:scale-95 duration-500 transition-all"
                        onClick={handleBookingOrder}
                      >
                        <AiOutlinePlus className="w-5 h-5 text-white" />
                        {t("bookingOrder")}{" "}
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col justify-start items-center  gap-y-3 bg-white mt-8 lg:mr-10 lg:w-[50%] ">
                    <div className="w-full flex flex-col lg:flex-row gap-y-2 lg:justify-between  justify-center">
                      <div className=" flex flex-col lg:items-start items-center    ">
                        <p className="lg:text-2xl text-lg text-[#002244] font-bold tracking-wider flex items-center gap-x-5">
                          {roomDetails.room_name}
                        </p>
                        <p className="flex items-center gap-x-1 lg:text-md text-sm text-black font-semibold">
                          <TbBuildingEstate className="w-5 h-5" />
                          {roomDetails.hotel_name}
                        </p>
                      </div>

                      <div className="flex flex-col   justify-center   items-center gap-y-1 md:ml-10">
                        <div className=" flex gap-2 ">
                          <p className="flex items-center  lg:text-lg text-base font-semibold ">
                            {new Intl.NumberFormat(localeCode).format(persons)} {t("adults")}
                          </p>
                          <p className="flex items-center  lg:text-xl text-base font-semibold ">
                            {new Intl.NumberFormat(localeCode).format(children_num)} {t("children")}
                          </p>
                        </div>

                        <p className="flex items-center justify-center bg-[#002244] rounded-xl w-[11rem] py-1 text-white  font-medium text-xs  capitalize  ">
                          {t(mealType)}
                        </p>
                        <p className="text-gray-500  text-xs sm:text-sm font-normal ">
                          {commissions}
                          {contractOfferType}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-2 ">
                      {roomDetails?.extra_info?.map((item) =>
                        item.details_en || item.details_ar ? (
                          <Popover
                            content={
                              <div className="z-10 w-64 text-sm text-gray-500 dark:text-gray-400">
                                <div className="border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-gray-700">
                                  <h3 className="font-semibold text-gray-900 dark:text-white">{t("details")}</h3>
                                </div>
                                <div className="px-3 py-2">
                                  <p>{t("lan") === "en" ? item.details_en : item.details_ar}</p>
                                </div>
                              </div>
                            }
                          >
                            <p className="cursor-pointer text-black text-xs bg-yellow-500 p-1 rounded-md">
                              {t("lan") === "en" ? item.name_en : item.name_ar}
                            </p>
                          </Popover>
                        ) : (
                          <p className=" text-black text-xs bg-yellow-500 p-1 rounded-md">
                            {t("lan") === "en" ? item.name_en : item.name_ar}
                          </p>
                        ),
                      )}
                    </div>
                    <p className="line-clamp-none  lg:px-0 px-2 ">{roomDetails.description}</p>
                  </div>
                </div>
              ) : (
                <div className="ww-full flex h-full items-center justify-between ">
                  <TopBarProgress />
                  <Loading />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button color="gray" onClick={() => setOpen(false)}>
                {t("nocancel")}
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        <div className="flex flex-wrap w-full justify-between items-center  ">
          <div className=" flex xl:flex-row flex-col xl:flex justify-start items-center gap-x-3 gap-y-2 w-full xl:w-[70%]   ">
            <img
              src={roomImage}
              className="w-full xl:w-44  xl:h-44 xl:mt-0 mt-3 rounded-2xl cursor-pointer"
              onClick={() => {
                setOpen(true);
                handlemoreDetails();
              }}
            />

            <div className="w-full grid relative  ">
              <div className=" w-full gap-y-2   md:mb-4 md:flex items-center justify-between">
                <p
                  className=" pb-2 text-sm lg:text-lg font-serif font-bold text-[#002244] w-[11rem]  cursor-pointer hover:text-black duration-300 transition-all"
                  onClick={() => {
                    setOpen(true);
                    handlemoreDetails();
                  }}
                >
                  {selectedroom}
                </p>

                <p className="flex items-center justify-center bg-[#002244]  w-[11rem] rounded-xl py-1 text-white  font-medium text-xs  capitalize  ">
                  {t(mealType)}
                </p>

                <img
                  src="images/schedule.png"
                  alt="calnder"
                  className=" w-8 h-8 cursor-pointer md:m-3 mt-2 "
                  onClick={() => setOpenDateValid(true)}
                />
              </div>
              <div className="flex flex-wrap gap-2 my-1">
                {room_extra_info?.map((item) => (
                  <Popover
                    content={
                      ExtraData?.some((item) => item.details_en || item.details_ar) ? (
                        <div className="z-10 w-64 text-sm text-gray-500 dark:text-gray-400">
                          <div className="border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-gray-700">
                            <h3 className="font-semibold text-gray-900 dark:text-white">{t("details")}</h3>
                          </div>
                          <div className="px-3 py-2">
                            {ExtraData?.map((item) => (
                              <p>{t("lan") === "en" ? item.details_en : item.details_ar}</p>
                            ))}
                          </div>
                        </div>
                      ) : isLoading ? (
                        <div className="z-10 w-20 text-sm text-gray-500 dark:text-gray-400">
                          <div className="px-3 py-2 flex justify-center">
                            <Spin />
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    }
                    aria-labelledby="default-popover"
                  >
                    <p
                      onClick={() => {
                        setSearchInfo(item);
                      }}
                      className="cursor-pointer text-black text-xs bg-yellow-500 p-1 rounded-md"
                    >
                      {item}
                    </p>
                  </Popover>
                ))}
              </div>
              <p className="text-xs lg:text-sm line-clamp-2 sm:mt-0 mt-3  md:w-[17.4rem] break-words ">
                {room_description}
              </p>

              <div>
                {/* <CancelationModal
                  startDate={startDate}
                  hotelId={hotelId}
                  contractOfferID={contractOfferID}
                  currencySearch={currencySearch}
                  item={item}
                  price={price}
                  hotel={hotel}
                /> */}
                <CPTest
                  startDate={startDate}
                  hotelId={hotelId}
                  contractOfferID={contractOfferID}
                  currencySearch={currencySearch}
                  item={item}
                  price={price}
                  hotel={hotel}
                />
              </div>
              <p className=" text-red-700   text-xs mt-2 sm:text-sm font-bold">{contractOfferType}</p>
            </div>
          </div>

          <div className="grid  justify-center  w-full xl:w-[20%]   gap-y-2 mt-5 z-10">
            <div className="flex justify-center gap-x-2">
              <p className="flex items-center gap-1 text-[#002244] text-xl font-semibold ">
                {new Intl.NumberFormat(localeCode).format(persons)} <IoIosPerson className="h-5 w-5 " />
              </p>
              <p className="flex items-center gap-1 text-cyan-700 text-xl font-semibold ">
                {new Intl.NumberFormat(localeCode).format(children_num)} <CgBoy className="h-5 w-5 text-cyan-700" />
              </p>
              <p className="flex items-end gap-x-1  text-xl text-[#002244] font-semibold ">
                {" "}
                {new Intl.NumberFormat(localeCode).format(price)}{" "}
                <span className=" md:text-md text-sm font-semibold text-yellow-600">{t(currencySearch)}</span>
              </p>
            </div>
            {inventoryStatus ? (
              <button
                className="w-full py-1 px-2 text-sm  bg-[#002244] text-white rounded-md hover:scale-110 active:scale-95 duration-500 transition-all"
                onClick={handleBookingOrderNow}
              >
                {t("bookNow")}
              </button>
            ) : (
              <button
                className="w-full flex items-center justify-center gap-x-1 py-1 px-2 text-sm  bg-yellow-300 text-black rounded-md hover:scale-110 active:scale-95 duration-500 transition-all"
                onClick={handleBookingOrderNow}
              >
                {" "}
                <FiSend className="w-4 h-4 text-black" />
                {t("bookingRequest")}
              </button>
            )}
            <button
              className={`${cardData?.some((cart) => cart.uniqueId === uniqueId) ? "bg-green-600" : " bg-[#002244]"}  w-full py-1 px-1 flex items-center justify-center gap-x-1 text-xs   text-white rounded-md hover:scale-105 active:scale-95 duration-500 transition-all`}
              onClick={handleBookingOrder}
            >
              <AiOutlinePlus className="w-5 h-5 text-white" />
              {t("bookingOrder")}{" "}
            </button>
          </div>
        </div>
      </div>
    </Badge.Ribbon>
  );
};

export default ListSelectedRoom;
