import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import Select from "react-select";
import { useFormik } from "formik";
import TopBarProgress from "react-topbar-progress-indicator";
import ButtonLoading from "../../ButtonLoading/ButtonLoading";
import { IoAdd, IoLink, IoLocationOutline, IoPersonAdd, IoPersonRemove, IoTimeOutline } from "react-icons/io5";
import { FaCar, FaHotel, FaMoneyBillWave, FaRegBuilding, FaWpforms } from "react-icons/fa";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getNationalityAction } from "../../../../redux/actions/searchformAction";
import { TimePicker } from "antd";
import Loading from "../../Loading/Loading";
import useRemoveScroll from "../../../../customHooks/useRemoveScroll";

import lightToast from "light-toast";
import { GoPerson } from "react-icons/go";
import { MdOutlinePayments } from "react-icons/md";
import { RiFlightTakeoffLine } from "react-icons/ri";
import { CiCalendar } from "react-icons/ci";
import { TfiWorld } from "react-icons/tfi";
import { GiWorld } from "react-icons/gi";
import { SlPeople } from "react-icons/sl";
import { SiEthiopianairlines } from "react-icons/si";
import { TbBus, TbRoad } from "react-icons/tb";

import { Link } from "react-router-dom";
import { BiSolidCity, BiStreetView } from "react-icons/bi";
import * as Yup from "yup";
import { NotificationContainer, NotificationManager } from "react-notifications";
import Errorfetch from "../../Errorfetch/Errorfetch";
import { Button, Modal } from "flowbite-react";
import { LuPhoneCall } from "react-icons/lu";
import dayjs from "dayjs";
import { useDebounce } from "use-debounce";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import {
  addAirportFormAction,
  getAirportNameForm,
  getAllAirlineForm,
  getHotelsByCityForm,
} from "../../../../redux/actions/airportFormsAction";
import { IoMdCloseCircleOutline } from "react-icons/io";

const ArrivalForm = ({ servicetypeState }) => {
  const [t, i18n] = useTranslation();
  //Get last params to check if user or guest
  const navigate = useNavigate();

  const { lang } = useParams();

  const [hotels, setHotels] = useState();
  const [Error, setError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [flagDataArr, setFlagDataArr] = useState([]); // Assuming you're using useState to manage flagData

  const [airLines, setAirlines] = useState();

  const arrivalform = cookie.load("arrivalform");
  // const lang = cookie.load("language");
  const token = cookie.load("access_token");

  const dispatch = useDispatch();

  const { nationalities } = useSelector((state) => state.SearchFormReducer);

  // useEffect(() => {
  //   setFlagDataArr(
  //     nationalities?.map((item) => {
  //       return { value: item.name_en, label: item.name, image: item.flag };
  //     })
  //   );
  // }, [nationalities]);

  const format = "HH:mm";
  useRemoveScroll();

  const getInitialValues = {
    buy_visa: "",
    delivery_place_type: "",
    passport_type: "",
    car_type: "",
    passengerNum: "",
    payment_method: "",
    passengers_name: [""],
    flight_date: "",
    flight_number: "",
    nationality: [],
    time_of_arrival: "",
    iata_code: "",
    country_from: "",
    city_from: "",
    area_from: "",
    airport_name: "",
    number_of_pax: "",
    airline_name: "",
    hotel_name: "",
    hotel_name_other: "",
    address: "",
    amount: "",
    service_type: servicetypeState,
    currency: "",
    transfer_from_airport: "",
    status: "New",
    recaptcha_token: "",
    order_type: "Private",
  };

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: arrivalform || getInitialValues, // Set the initialValues to your formData

    validationSchema: Yup.object({
      car_type: Yup.string().required(lang === "en" ? "Please enter type of car" : "الرجاء إدخال نوع السيارة"),
      passport_type: Yup.string().required(
        lang === "en" ? "Please enter passport type" : "الرجاء إدخال نوع جواز السفر",
      ),
      // delivery_place_type: Yup.string().required("Please enter Type of place"),
      payment_method: Yup.string().required(lang === "en" ? "Please enter payment method" : "الرجاء إدخال طريقة الدفع"),
      buy_visa: Yup.string().required(lang === "en" ? "Please enter buy visa " : "الرجاء اختيار شراءالفيزا"),
      passengers_name: Yup.array().of(
        Yup.string()
          .matches(
            /^\S.*\S$/,
            lang === "en"
              ? "Name Must Have Not Spaces At The Start And End"
              : "يجب أن لا يحتوي الاسم على مسافات في البداية والنهاية",
          )
          .required(lang === "en" ? "Please enter passenger name" : "الرجاء إدخال اسم الراكب"),
      ),
      flight_number: Yup.string().required(lang === "en" ? "Please enter flight number" : "الرجاء إدخال رقم الرحلة"),
      nationality: Yup.array().min(1, lang === "en" ? "Select at least one nationality" : "اختر جنسية واحدة على الأقل"),
      time_of_arrival: Yup.string().required(lang === "en" ? "Please enter time" : "الرجاء إدخال الوقت"),
      flight_date: Yup.date().required(lang === "en" ? "Please enter date" : "الرجاء إدخال التاريخ"),
      iata_code: Yup.string().required(
        lang === "en" ? "Please enter IATA Code " : "الرجاء إدخال رمز اتحاد النقل الجوي الدولي",
      ),
      country_from: Yup.string().required(lang === "en" ? "Please enter country name " : "الرجاء إدخال اسم البلد"),
      city_from: Yup.string().required(lang === "en" ? "Please enter region name" : "الرجاء إدخال اسم المنطقة"),
      area_from: Yup.string().required(lang === "en" ? "Please enter area name " : "الرجاء إدخال اسم المكان"),
      airport_name: Yup.string().required(lang === "en" ? "Please enter airport name" : "الرجاء إدخال اسم المطار"),
      // hotel_name: general && Yup.string().required("Please enter hotel "),
      number_of_pax: Yup.number()
        .required(lang === "en" ? "Please enter number of persons" : "الرجاء إدخال عدد الأشخاص")
        .positive(lang === "en" ? "Number of persons must be positive number" : "يجب أن يكون عدد الأشخاص رقمًا موجبًا")
        .integer(lang === "en" ? "Number of persons must be positive number" : "يجب أن يكون عدد الأشخاص رقمًا موجبًا")
        .min(1, lang === "en" ? "Number of persons must be greater than  0" : "يجب أن يكون عدد الأشخاص أكبر من 0"),
      amount: Yup.number()
        .required(lang === "en" ? "Please enter amount" : "الرجاء إدخال المبلغ")
        .positive(lang === "en" ? "Amount must be positive number" : "يجب أن يكون عدد الأشخاص رقمًا موجبًا")
        .integer(lang === "en" ? "Amount must be positive number" : "يجب أن يكون عدد الأشخاص رقمًا موجبًا")
        .min(1, lang === "en" ? "Amount must be greater than  0" : "يجب أن يكون عدد الأشخاص أكبر من 0"),

      // currency: Yup.string().required("Please enter currency"),
      airline_name: Yup.string().required(
        lang === "en" ? "Please enter airline name " : "الرجاء إدخال اسم شركة الطيران",
      ),
      // address: Yup.string().required("Please enter an address"),
      // order_type: Yup.string().required("Please enter order type "),
      transfer_from_airport: Yup.bool().required(
        lang === "en" ? "Please enter transfer from airport" : "يرجى إدخال النقل من المطار",
      ),
      // notes: Yup.string().required("Please enter your notes"),
    }),
    onSubmit: (values) => {
      const formData = new FormData();

      const { delivery_place_type, transfer_from_airport, hotel_name, flight_date, time_of_arrival, ...restData } =
        values;

      const newValus = {
        ...restData,
        hotel_name,
        flight_date,
        time_of_arrival,
        transfer_from_airport,
        delivery_place_type,
        recaptcha_token: "",
      };

      // formData.append("service_type", servicetypeState);

      if (
        delivery_place_type !== "hotel" ||
        delivery_place_type !== "فندق" ||
        transfer_from_airport === "false" ||
        hotel_name === "اخرى" ||
        hotel_name === "Other"
      ) {
        formData.delete("hotel_name");
        // formData.delete("address");
      } else {
        formData.append("hotel_name", hotel_name);
      }

      Object.keys(restData).forEach((key) => {
        if (key === "passengers_name" || key === "nationality") {
          formData.append(key, JSON.stringify(restData[key]));
        } else {
          formData.append(key, restData[key]);
        }
      });

      formData.append("time_of_arrival", `${flight_date},${time_of_arrival}`);

      dispatch(
        addAirportFormAction(formData, (result) => {
          if (result.status === 201) {
            lightToast.success("Meet & Assist Service Form was completed successfully");
            if (servicetypeState === "Departure") {
              cookie.save("depatureform", newValus, { path: "/" });
            } else if (servicetypeState === "Arrival") {
              cookie.save("arrivalform", newValus, { path: "/" });
            }
            // navigate(-1);
            setSubmitting(false);
          } else {
            setSubmitting(false);
            NotificationManager.error(`${result?.response?.data[0]?.field_name} ${result?.response?.data[0]?.message}`);
          }
        }),
      );
    },
  });

  useEffect(() => {
    const Arrnationality = nationalities?.map((item) => {
      return { value: item.name, label: item.name, image: item.flag };
    });
    // Define the order of GCC countries
    const gccCountriesOrder = ["Bahrain", "Kuwait", "Oman", "Qatar", "Saudi Arabia", "United Arab Emirates"];

    // Separate GCC countries and other countries
    const gccCountries = [];
    const otherCountries = [];

    // Reorder the flagData array
    Arrnationality?.forEach((item) => {
      if (gccCountriesOrder.includes(item.label)) {
        gccCountries.push(item);
      } else {
        otherCountries.push(item);
      }
    });

    // Combine GCC countries and other countries
    const reorderedData = gccCountries.concat(otherCountries);
    setFlagDataArr(reorderedData);

    // Check if values and reorderedData are defined
    // if (values?.nationality && reorderedData) {
    //   // Filter items based on nationality
    //   const filteredData = reorderedData.filter((item) => values.nationality.includes(item.label));
    //   // Set flag data only if there are matching items
    //

    //   if (filteredData.length > 0) {
    //     filteredData?.map((item) => {
    //       setFlagDataDefault([{ value: item.value, label: item.label, image: item.image }]);
    //     });
    //   } else {
    //     setFlagDataDefault([]);
    //   }
    // } else {
    //   // Handle case where values or reorderedData are undefined
    //   setFlagDataDefault([]);
    // }

    //

    // setFlagDataDefault(flagData);
  }, [nationalities]);

  //////////delivery_place_type///////////////////
  const delivery_Array = ["hotel", "apartment", "villa"];
  const delivery_Array_AR = ["فندق", "فيلا", "شقة"];

  let typeofplaceArr = delivery_Array
    ? lang === "en"
      ? delivery_Array.map((Type) => ({ value: Type, label: Type }))
      : delivery_Array_AR.map((Type) => ({ value: Type, label: Type }))
    : [];
  //////////////////////////////////

  //////////passport_type///////////////////

  const passport_type = ["normal", "private", "diplomat"];
  const passport_type_AR = ["عادي", "خاص", "دبلوماسي"];

  let passporttypeArr = passport_type
    ? lang === "en"
      ? passport_type.map((Type) => ({ value: Type, label: Type }))
      : passport_type_AR.map((Type) => ({ value: Type, label: Type }))
    : [];

  useEffect(() => {
    //////////////// Cancellatioin Request ////////////////////
    const controller = new AbortController();
    const signal = controller.signal;
    ///////////////////////////////////////////////////////////

    dispatch(
      //get cairo 922
      getHotelsByCityForm(922, lang, (resultHotel) => {
        if (resultHotel.status === 200) {
          setHotels(resultHotel.data);
        } else {
          setError(true);
        }
      }),
    );

    dispatch(
      getAllAirlineForm(
        signal,
        (result) => {
          if (result.status === 200) {
            setAirlines(result.data);
          } else {
            setError(true);
          }
        },
        (error) => {
          setError(true);
        },
      ),
    );

    return () => {
      controller.abort();
      // window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  useEffect(() => {
    dispatch(getNationalityAction(token, lang));
    i18n.changeLanguage(lang);
  }, [lang]);

  ////////// Handle Add ////////////
  const handleInput = (valueInput, index) => {
    const updateValue = [...values.passengers_name];
    updateValue[index] = valueInput;
    setFieldValue("passengers_name", updateValue);
  };

  //////Handle Delete /////////////
  const handleAdd = () => {
    setFieldValue("passengers_name", [...values.passengers_name, ""]);
  };
  const handleDelete = (index) => {
    if (values.passengers_name.length > 1) {
      const newValue = [...values.passengers_name];
      newValue.splice(index, 1);
      setFieldValue("passengers_name", newValue);
    }
  };

  //////// flagData /////////////
  // Function to reorder the flagData array
  const [egyptSelected, setEgyptSelected] = useState(false);

  // const reorderFlagData = (data) => {
  //   // Define the order of GCC countries
  //   const gccCountriesOrder = ["Bahrain", "Kuwait", "Oman", "Qatar", "Saudi Arabia", "United Arab Emirates"];

  //   // Separate GCC countries and other countries
  //   const gccCountries = [];
  //   const otherCountries = [];

  //   // Reorder the flagData array
  //   data.forEach((item) => {
  //     if (gccCountriesOrder.includes(item.label)) {
  //       gccCountries.push(item);
  //     } else {
  //       otherCountries.push(item);
  //     }
  //   });

  //   // Combine GCC countries and other countries
  //   const reorderedData = gccCountries.concat(otherCountries);

  //   return reorderedData;
  // };

  // Function to handle onChange event
  const handleSelectChange = (e) => {
    const selectedCountries = e.map((item) => item.value);
    if (selectedCountries.includes("Egypt") && !egyptSelected) {
      setOpenModal(true);
      setEgyptSelected(true); // Marking Egypt as selected
    } else if (selectedCountries.includes("مصر") && !egyptSelected) {
      setOpenModal(true);
      setEgyptSelected(true);
      // Marking Egypt as selected
      // } else if (selectedCountries.includes("Saudi Arabia")) {
      //   const reorderedFlagData = reorderFlagData(flagDataArr);
      //   setFlagDataArr(reorderedFlagData);
      // } else {
      //   setFlagDataArr(
      //     nationalities.map((item) => {
      //       return { value: item.name, label: item.name, image: item.flag };
      //     })
      //   );
      // }
    }
  };

  //////////// Hotels //////////////
  // const hotels = useHotelNames();
  const hotelsArr = hotels
    ? hotels?.map((hotel) => ({
        label: hotel.name,
        value: hotel.id,
        address: hotel.address,
        map_address: hotel.hotel_map_address,
      }))
    : [];

  ////////Airline //////////
  let airlinesArr = airLines
    ? airLines.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      })
    : [];
  /////////////////////////////////////
  const handleTimeChange = (newTime) => {
    const date = new Date(newTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setFieldValue("time_of_arrival", `${hours1}:${minutes1}`);
  };

  //////////// Default flag ///////////////
  let flagDataDefault = values?.nationality
    ? values.nationality?.map((item) => {
        return { value: item, label: item, image: item.flag };
      })
    : [];

  ////////////// Airport data ///////////////
  const [AirportNameData, setAirportNameData] = useState();
  const [AirportNameFlag, setAirportNameFlag] = useState(false);
  const [AirportNameError, setAirportNameError] = useState();

  const [searchairline, setsearchairline] = useState("");
  const [LangText, setLangText] = useState("");
  const [query] = useDebounce(searchairline, 2000);

  useEffect(() => {
    if (query) {
      setAirportNameFlag(true);

      const detectLanguage = () => {
        // Regular expressions to match Arabic and English characters
        var arabicPattern = /[\u0600-\u06FF]/;
        var englishPattern = /[A-Za-z]/;

        // Check if the text contains Arabic characters
        if (arabicPattern.test(query)) {
          return setLangText("ar");
        }
        // Check if the text contains English characters
        else if (englishPattern.test(query)) {
          return setLangText("en");
        }
        // If neither Arabic nor English characters are found, return "Unknown"
        else {
          return setLangText("en");
        }
      };

      dispatch(
        getAirportNameForm(query, (result) => {
          if (result.status === 200) {
            setAirportNameData(result.data);
            setAirportNameFlag(false);
          } else if (result.status !== 200) {
            setAirportNameFlag(false);
            setAirportNameError(result.response?.data[0].message);
          }
        }),
      );
      detectLanguage();
    }
  }, [query]);
  //

  const AirportArr = AirportNameData
    ? AirportNameData.map((item) => ({
        label: LangText === "en" ? item.name_en : item.name_ar,
        value: LangText === "en" ? item.name_en : item.name_ar,
      }))
    : [];

  useEffect(() => {
    if (values?.airport_name) {
      const filteredList = AirportNameData?.filter((item) => {
        return item.name_ar === values?.airport_name || item.name_en === values?.airport_name;
      });
      if (filteredList) {
        setFieldValue("city_from", LangText === "en" ? filteredList[0]?.region_en : filteredList[0]?.region_ar);
        setFieldValue("country_from", LangText === "en" ? filteredList[0]?.country_en : filteredList[0]?.country_ar);
        setFieldValue("area_from", filteredList[0]?.municipality);
        setFieldValue("iata_code", filteredList[0]?.iata_code);
      }
    }
  }, [values?.airport_name]);

  return (
    <>
      {(lang && lang === "ar") || (lang && lang === "en") ? (
        <div className="w-full h-auto   " dir={lang === "en" ? "ltr" : "rtl"}>
          {openModal && (
            <Modal show={openModal} size="md" onClose={() => setOpenModal(false)}>
              <Modal.Header />
              <Modal.Body>
                <div className="text-center">
                  <LuPhoneCall className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                  <h3 className="mb-5 text-lg font-semibold text-[#002244] ">{t("Pleasecall")}</h3>
                  <div className="flex justify-center gap-4">
                    <Button color="success" onClick={() => setOpenModal(false)}>
                      <a href="tel:00202168098">{t("call")}</a>
                    </Button>
                    <Button color="gray" onClick={() => setOpenModal(false)}>
                      {t("nocancel")}
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}

          {hotels && airLines ? (
            <div className="flex flex-col items-center justify-start h-full w-full">
              <div className=" w-[98%] md:w-[85%] mb-3 flex justify-center mx-auto  bg-[#002244]  text-white  rounded-lg text-2xl  h-12 mt-3">
                <p className="flex items-center gap-x-3 text-sm  md:text-2xl">
                  {" "}
                  <FaWpforms className="md:w-5 md:h-5  w-4 h-4" />
                  {t("Departurefrom")}
                </p>
              </div>
              <div
                className={`boxShadow sh w-[98%] md:w-[85%]  p-2 md:p-8  ounded-xl bg-white bg-clip-border text-gray-700 rounded-lg `}
              >
                <form className="mt-2  w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
                  <div className="w-full flex flex-col justify-center items-center gap-y-5">
                    <div className="w-full">
                      <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                        {t("NumberOfPersons")}
                      </label>
                      <div className="w-full relative flex items-center gap-x-1">
                        <input
                          min={0}
                          placeholder={t("NumberOfPersons")}
                          type="number"
                          name="number_of_pax"
                          onChange={handleChange}
                          value={values?.number_of_pax}
                          onBlur={handleBlur}
                          className={` w-full ${
                            lang === "en" ? "pl-8 md:pl-10" : "pr-8 md:pr-10"
                          } h-10  border rounded-lg bg-white floating-input text-xs md:text-base ${
                            touched.number_of_pax && errors.number_of_pax && "border border-red-800"
                          } `}
                        />
                        <div className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                          <SlPeople className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                        </div>
                      </div>
                      {touched.number_of_pax && errors.number_of_pax ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                          {errors.number_of_pax}
                        </div>
                      ) : null}
                    </div>

                    <div className="w-full flex flex-col items-start justify-center gap-y-2 ">
                      <label className=" text-center w-full text-gray-700 font-semibold text-sm md:text-[16px] ">
                        {t("PassengerName")}
                      </label>
                      <div className="w-full flex flex-col border rounded-md p-1">
                        {values.passengers_name.map((item, index) => {
                          return (
                            <div className="w-full flex flex-col gap-y-10 my-1 ">
                              <div className="w-full flex flex-col gap-y-1">
                                <div className="w-full flex items-center gap-x-1 relative">
                                  <input
                                    placeholder={`${t("PassengerName")} ${index + 1}`}
                                    name={`passengers_name`}
                                    type="text"
                                    onChange={(e) => handleInput(e.target.value, index)}
                                    value={item}
                                    onBlur={handleBlur}
                                    className={` w-full ${
                                      lang === "en" ? "pl-7 md:pl-10" : "pr-7 md:pr-10"
                                    } h-10  border rounded-lg bg-white floating-input text-xs md:text-base ${
                                      touched.passengers_name && errors.passengers_name && "border border-red-800"
                                    } `}
                                  />
                                  <div className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                                    <GoPerson className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                                  </div>
                                </div>

                                <div className="w-full flex justify-between ">
                                  {touched.passengers_name &&
                                  Array.isArray(errors.passengers_name) &&
                                  errors.passengers_name.length > 0 ? (
                                    <div className="w-full flex items-center gap-x-1 justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                                      {" "}
                                      {errors.passengers_name[index]}
                                    </div>
                                  ) : null}
                                  <div className=" w-full flex items-center justify-end gap-x-1">
                                    <button type="button" onClick={handleAdd}>
                                      <IoPersonAdd className="md:w-5 md:h-5  w-4 h-4 text-green-900 hover:scale-105 active:scale-95 duration-300 transition-all" />
                                    </button>
                                    {values.passengers_name.length > 1 && (
                                      <button type="button" onClick={() => handleDelete(index)}>
                                        <IoPersonRemove className="md:w-5 md:h-5  w-4 h-4 text-red-900  hover:scale-105 active:scale-95 duration-300 transition-all" />
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="w-full">
                      <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                        {t("Nationality")}
                      </label>
                      <div className="w-full relative flex items-center gap-x-1">
                        <Select
                          className={`w-full  ${
                            lang === "en" ? "pl-5 md:pl-8" : "pr-5 md:pr-8"
                          }  border rounded-md text-sm md:text-base ${
                            touched.nationality && errors.nationality && "border-red-800"
                          } `}
                          defaultValue={flagDataDefault}
                          name="nationality"
                          options={flagDataArr}
                          onBlur={handleBlur}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderBottom: "none",
                              borderTop: "none",
                              borderLeft: "none",
                              borderRight: "none",
                              boxShadow: "none",
                              borderRadius: "0",
                            }),
                          }}
                          isMulti
                          onChange={(e) => {
                            handleSelectChange(e);
                            setFieldValue(
                              "nationality",
                              e.map((item) => item.value),
                            );
                          }}
                          formatOptionLabel={(flagData) => (
                            <div className="flex justify-start items-center  gap-x-3 country-option  ">
                              <img src={flagData.image} alt="country-image" className="w-5 h-3" />
                              <span>{flagData.label}</span>
                            </div>
                          )}
                          isSearchable
                          placeholder={t("Nationality")}
                        />
                        <div className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                          <TfiWorld className="md:w-5 md:h-5  w-3 h-3 text-[#002244]" />
                        </div>
                      </div>
                      {touched.nationality && errors.nationality ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                          {errors.nationality}
                        </div>
                      ) : null}
                    </div>

                    <label className=" flex justify-center text-gray-700 font-semibold  text-sm md:text-[16px]">
                      {t("DepartureFrom")}
                    </label>
                    <div className="w-full border rounded-md p-2">
                      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-1 gap-y-1">
                        <div className="w-full flex flex-col items-center justify-center">
                          <div className="w-full flex flex-col items-center justify-center">
                            <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                              {t("AirportName")}
                            </label>
                            <div className="w-full relative flex items-center gap-x-1">
                              <Select
                                isLoading={AirportNameFlag}
                                placeholder={t("AirportName")}
                                isSearchable
                                className={`w-full  ${
                                  lang === "en" ? "pl-5 md:pl-8" : "pr-5 md:pr-8"
                                } border rounded-md text-sm md:text-base ${
                                  touched.airport_name && errors.airport_name && "border-red-800"
                                } `}
                                name="airport_name"
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,

                                    borderBottom: "none",
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                  }),
                                }}
                                options={AirportArr}
                                onChange={(e) => {
                                  setFieldValue("airport_name", e.value);
                                }}
                                onInputChange={(inputValue) => {
                                  setsearchairline(inputValue);
                                }}
                                onBlur={handleBlur}
                                defaultValue={[
                                  {
                                    label: values.airport_name,
                                    value: values.airport_name,
                                  },
                                ]}
                              />
                              <div className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                                <SiEthiopianairlines className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                              </div>
                            </div>

                            {AirportNameError ? (
                              <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                                {AirportNameError}
                              </div>
                            ) : touched.airport_name && errors.airport_name ? (
                              <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                                {errors.airport_name}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {/* <div className="w-full flex flex-col items-center justify-center">
                                <label className=" flex justify-center text-gray-700 font-semibold  text-sm md:text-[16px]">
                                  IATA Code
                                </label>
                                <div className="w-full relative  ">
                                  <input
                                    className={` w-full pl-8 md:pl-11 h-10  border rounded-lg bg-white floating-input text-xs md:text-base flex items-center ${
                                      touched.iata_code && errors.iata_code && values.iata_code === "" && "border border-red-800"
                                    }  `}
                                    type="text"
                                    name="iata_code"
                                    disabled={true}
                                    value={values.iata_code}
                                  />
                                  <div className="absolute top-3 md:top-[10px] left-3">
                                    <GiWorld className="md:w-6 md:h-6 w-4 h-4 text-[#002244]" />
                                  </div>
                                </div>
                                {touched.iata_code && errors.iata_code && values.iata_code === "" ? (
                                  <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                                    {errors.iata_code}
                                  </div>
                                ) : null}
                              </div> */}

                        <div className="w-full flex flex-col items-center justify-center">
                          <label className=" flex justify-center text-gray-700 font-semibold  text-sm md:text-[16px]">
                            {t("Country")}
                          </label>
                          <div className="w-full relative  ">
                            <input
                              className={` w-full ${
                                lang === "en" ? "pl-8 md:pl-11" : "pr-8 md:pr-11"
                              }  h-10  border rounded-lg bg-white floating-input text-xs md:text-base flex items-center ${
                                touched.country_from &&
                                errors.country_from &&
                                values.country_from === "" &&
                                "border border-red-800"
                              }  `}
                              type="text"
                              name="country_from"
                              disabled={true}
                              value={values.country_from}
                            />
                            <div className={`absolute top-3 md:top-[10px] ${lang === "en" ? "left-3" : "right-3"} `}>
                              <GiWorld className="md:w-6 md:h-6 w-4 h-4 text-[#002244]" />
                            </div>
                          </div>
                          {touched.country_from && errors.country_from && values.country_from === "" ? (
                            <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                              {errors.country_from}
                            </div>
                          ) : null}
                        </div>

                        <div className="w-full flex flex-col items-center justify-center">
                          <label className=" flex justify-center text-gray-700 font-semibold  text-sm md:text-[16px] ">
                            {t("Area")}
                          </label>
                          <div className="w-full relative  ">
                            <input
                              className={` w-full ${
                                lang === "en" ? "pl-8 md:pl-11" : "pr-8 md:pr-11"
                              } h-10  border rounded-lg bg-white floating-input text-xs md:text-base flex items-center ${
                                touched.area_from &&
                                errors.area_from &&
                                values.area_from === "" &&
                                "border border-red-800"
                              }  `}
                              type="text"
                              name="area_from"
                              disabled={true}
                              value={values.area_from}
                            />
                            <div className={`absolute top-3 md:top-[10px] ${lang === "en" ? "left-3" : "right-3"} `}>
                              <BiSolidCity className="md:w-6 md:h-6 w-4 h-4 text-[#002244]" />
                            </div>
                          </div>
                          {touched.area_from && errors.area_from && values.area_from === "" ? (
                            <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                              {errors.area_from}
                            </div>
                          ) : null}
                        </div>

                        <div className="w-full flex flex-col items-center justify-center">
                          <label className=" flex justify-center text-gray-700 font-semibold  text-sm md:text-[16px]">
                            {t("Region")}
                          </label>
                          <div className="w-full relative  ">
                            <input
                              className={` w-full ${
                                lang === "en" ? "pl-8 md:pl-11" : "pr-8 md:pr-11"
                              } h-10  border rounded-lg bg-white floating-input text-xs md:text-base flex items-center ${
                                touched.city_from &&
                                errors.city_from &&
                                values.city_from === "" &&
                                "border border-red-800"
                              }  `}
                              type="text"
                              name="city_from"
                              disabled={true}
                              value={values.city_from}
                            />
                            <div className={`absolute top-3 md:top-[10px] ${lang === "en" ? "left-3" : "right-3"} `}>
                              <BiStreetView className="md:w-6 md:h-6 w-4 h-4 text-[#002244]" />
                            </div>
                          </div>
                          {touched.city_from && errors.city_from && values.city_from === "" ? (
                            <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                              {errors.city_from}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="w-full flex flex-col md:flex-row items-center gap-x-1 gap-y-5">
                      <div className="w-full md:w-[50%] flex flex-col items-center justify-start">
                        <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                          {t("FlightDate")}
                        </label>
                        <div className="w-full relative flex items-center gap-x-1 ">
                          <input
                            id="FlightDate"
                            placeholder={t("FlightDate")}
                            type="date"
                            name="flight_date"
                            onChange={handleChange}
                            value={values.flight_date}
                            onBlur={handleBlur}
                            className={`floating-input w-full ${
                              lang === "en" ? "pl-7 md:pl-10 text-left" : "pr-7 md:pr-10 text-right"
                            } h-10  border rounded-lg bg-white  text-xs md:text-base text-gray-600 ${
                              touched.flight_date && errors.flight_date && "border border-red-800"
                            } `}
                          />
                          <label for="FlightDate" className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                            <CiCalendar className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                          </label>
                        </div>

                        {touched.flight_date && errors.flight_date ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                            {errors.flight_date}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full md:w-[50%] flex flex-col items-center justify-start">
                        <label className=" flex justify-center text-gray-700 font-semibold  text-sm md:text-[16px]">
                          {t("FlightTime")}
                        </label>
                        <div className="w-full relative flex items-center gap-x-1">
                          <TimePicker
                            clearIcon={false}
                            name="time_of_arrival"
                            as={TimePicker}
                            format={format}
                            defaultValue={values.time_of_arrival && dayjs(values.time_of_arrival, format)}
                            className={` w-full py-2 ${
                              lang === "en" ? "pl-7 md:pl-10" : "pr-7 md:pr-10"
                            }  border rounded-lg bg-white ${
                              touched.time_of_arrival && errors.time_of_arrival && "border border-red-800"
                            } `}
                            onChange={handleTimeChange}
                            onBlur={handleBlur}
                          />
                          <div className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                            <IoTimeOutline className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                          </div>
                        </div>
                        {touched.time_of_arrival && errors.time_of_arrival ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                            {errors.time_of_arrival}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="w-full">
                      <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                        {t("FlightNumber")}
                      </label>
                      <div className="w-full relative flex items-center gap-x-1">
                        <input
                          placeholder={t("FlightNumber")}
                          type="text"
                          name="flight_number"
                          onChange={handleChange}
                          value={values.flight_number}
                          onBlur={handleBlur}
                          className={` w-full ${
                            lang === "en" ? "pl-8 md:pl-10" : "pr-8 md:pr-10"
                          } h-10  border rounded-lg bg-white floating-input text-xs md:text-base ${
                            touched.flight_number && errors.flight_number && "border border-red-800"
                          } `}
                        />
                        <div className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                          <RiFlightTakeoffLine className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                        </div>
                      </div>
                      {touched.flight_number && errors.flight_number ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                          {errors.flight_number}
                        </div>
                      ) : null}
                    </div>

                    <div className="w-full">
                      <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                        {t("AirlineName")}
                      </label>
                      <div className="w-full relative flex items-center gap-x-1">
                        <Select
                          placeholder={t("AirlineName")}
                          isSearchable
                          className={`w-full  ${
                            lang === "en" ? "pl-5 md:pl-8" : "pr-5 md:pr-8"
                          } border rounded-md text-sm md:text-base ${
                            touched.airline_name && errors.airline_name && "border-red-800"
                          } `}
                          name="airline_name"
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,

                              borderBottom: "none",
                              borderTop: "none",
                              borderLeft: "none",
                              borderRight: "none",
                              boxShadow: "none",
                              borderRadius: "0",
                            }),
                          }}
                          options={airlinesArr}
                          onChange={(e) => setFieldValue("airline_name", e.value)}
                          // styles={{
                          //   control: (baseStyles, state) => ({
                          //     ...baseStyles,
                          //     borderColor: `${touched.airline_name && errors.airline_name && "rgb(153 27 27)"}`,
                          //   }),
                          // }}
                          // value={values.airlines_name}
                          onBlur={handleBlur}
                          defaultValue={[
                            {
                              label: values.airline_name?.name,
                              value: values.airline_name?.id,
                            },
                          ]}
                        />
                        <div className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                          <SiEthiopianairlines className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                        </div>
                      </div>
                      {touched.airline_name && errors.airline_name ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                          {errors.airline_name}
                        </div>
                      ) : null}
                    </div>

                    <div className="w-full ">
                      <label className=" text-center w-full text-gray-700 font-semibold flex items-center justify-center  text-sm md:text-[16px]">
                        {t("Amount")}
                      </label>

                      <div className="w-full relative flex items-center gap-x-1">
                        <input
                          value={values.amount}
                          name="amount"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Amount")}
                          className={` w-full ${
                            lang === "en" ? "pl-8 md:pl-10" : "pr-8 md:pr-10"
                          } h-10  border rounded-lg bg-white floating-input text-xs md:text-base ${
                            touched.amount && errors.amount && "border border-red-800"
                          } `}
                        />
                        <div className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                          <FaMoneyBillWave className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                        </div>
                      </div>
                      {touched.amount && errors.amount ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                          {errors.amount}
                        </div>
                      ) : null}
                    </div>

                    <div className="w-full">
                      <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                        {t("TransferfromAirport")}
                      </label>
                      <div
                        className={` w-full px-3 h-10 flex items-center justify-between  border rounded-lg bg-white floating-input text-xs md:text-base ${
                          touched.transfer_from_airport && errors.transfer_from_airport && "border border-red-800"
                        } `}
                      >
                        <TbBus className="w-[2.5rem] md:h-5 h-4 text-[#002244]" />

                        <div className="flex items-center justify-center gap-x-3  w-[50%]">
                          <input
                            placeholder="Employee Name"
                            name="transfer_from_airport"
                            type="radio"
                            value="true"
                            defaultChecked={values.transfer_from_airport === "true" && true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className=" md:w-5 md:h-5  w-4 h-4  border rounded-lg bg-white "
                          />
                          <label className="  text-gray-600    text-sm md:text-lg ">{t("yes")}</label>
                        </div>
                        <div className="flex items-center justify-center gap-x-3 w-[50%]">
                          <input
                            placeholder="Total Amount"
                            name="transfer_from_airport"
                            type="radio"
                            value="false"
                            defaultChecked={values.transfer_from_airport === "false" && true}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue("hotel_name_other", "");
                              setFieldValue("hotel_name", "");
                              setFieldValue("address", "");
                              setFieldValue("hotel_map_address", "");
                              setFieldValue("delivery_place_type", "");
                            }}
                            onBlur={handleBlur}
                            className=" md:w-5 md:h-5  w-4 h-4  border rounded-lg bg-white  "
                          />
                          <label className="text-gray-600   text-sm md:text-lg">{t("no")}</label>
                        </div>
                      </div>{" "}
                      {errors.transfer_from_airport && touched.transfer_from_airport && (
                        <div className="w-full flex justify-start capitalize text-xs md:text-sm text-red-800  font-semibold">
                          {errors.transfer_from_airport}
                        </div>
                      )}
                    </div>

                    {values.transfer_from_airport === "true" && (
                      <>
                        <div className="w-full ">
                          <label className=" text-center w-full text-gray-700 font-semibold flex items-center justify-center mt-4  text-sm md:text-[16px]">
                            {t("Typeofplace")}
                          </label>
                          <div className="w-full relative flex items-center gap-x-1">
                            <Select
                              name="delivery_place_type"
                              options={typeofplaceArr}
                              isSearchable
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,

                                  borderBottom: "none",
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  boxShadow: "none",
                                  borderRadius: "0",
                                }),
                              }}
                              // value={[{ label: values.currency, value: values.currency }]}
                              className={`w-full  ${
                                lang === "en" ? "pl-5 md:pl-8" : "pr-5 md:pr-8"
                              } border rounded-md text-sm md:text-base ${
                                touched.delivery_place_type && errors.delivery_place_type && "border-red-800"
                              } `}
                              onChange={(e) => {
                                setFieldValue("delivery_place_type", e.value);
                                setFieldValue("hotel_name", "");
                                setFieldValue("address", "");
                                setFieldValue("hotel_name", "");
                                setFieldValue("hotel_map_address", "");
                              }}
                              onBlur={handleBlur}
                              placeholder={t("Typeofplace")}
                              value={[{ label: values.delivery_place_type, value: values.delivery_place_type }]}
                            />
                            <div className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                              <FaRegBuilding className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                            </div>
                          </div>
                          {errors.delivery_place_type && touched.delivery_place_type && (
                            <div className="w-full flex justify-start text-xs md:text-sm text-red-800 font-semibold">
                              {errors.delivery_place_type}
                            </div>
                          )}
                        </div>
                        {values.delivery_place_type === "hotel" || values.delivery_place_type === "فندق" ? (
                          <>
                            {((lang === "ar" && values.hotel_name !== "اخرى") ||
                              (lang === "en" && values.hotel_name !== "Other")) && (
                              <>
                                <div className="w-full ">
                                  <label className=" flex justify-center text-gray-700 font-semibold  text-sm md:text-[16px]">
                                    {t("Hotel")}
                                  </label>
                                  <div className="w-full relative flex items-center gap-x-1">
                                    <Select
                                      className={`w-full ${
                                        lang === "en" ? "pl-5 md:pl-8" : "pr-5 md:pr-8"
                                      } border rounded-md text-sm md:text-base ${
                                        touched.hotel_name && errors.hotel_name && "border-red-800"
                                      } `}
                                      name="hotel_name"
                                      options={[
                                        lang === "en"
                                          ? { label: "Other", value: "Other" }
                                          : { label: "اخرى", value: "اخرى" },
                                        ...hotelsArr,
                                      ]}
                                      onBlur={handleBlur}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,

                                          borderBottom: "none",
                                          borderTop: "none",
                                          borderLeft: "none",
                                          borderRight: "none",
                                          boxShadow: "none",
                                          borderRadius: "0",
                                        }),
                                      }}
                                      defaultValue={[
                                        {
                                          label: hotels?.filter((item) => item.id === values.hotel_name)[0]?.name,
                                          value: values.hotel_name,
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setFieldValue("hotel_name", e.value);
                                        setFieldValue("address", e.address);
                                        setFieldValue("hotel_map_address", e.map_address);
                                      }}
                                      isSearchable
                                    />
                                    <div className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                                      <FaHotel className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                                    </div>
                                  </div>
                                  {touched.hotel_name && errors.hotel_name ? (
                                    <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                                      {errors.hotel_name}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="w-full ">
                                  <label className=" text-center w-full text-gray-700 font-semibold flex items-center justify-center  text-sm md:text-[16px]">
                                    {t("HotelAddress")}
                                  </label>
                                  <div
                                    className={`flex items-center justify-start gap-x-3 w-full px-2 md:px-3 py-1 md:h-10  border rounded-lg bg-white`}
                                  >
                                    <TbRoad className="md:w-5 md:h-5  w-4 h-4 text-[#002244] text-sm md:text-base" />{" "}
                                    {values.address}
                                  </div>
                                </div>
                                <div className="w-full ">
                                  <label className=" text-center w-full text-gray-700 font-semibold flex items-center justify-center  text-sm md:text-[16px]">
                                    {t("HotelMapAddress")}
                                  </label>
                                  <div
                                    className={`flex items-center justify-start gap-x-1 w-full px-3 py-1 md:h-10  border rounded-lg bg-white`}
                                  >
                                    <IoLocationOutline className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                                    <Link
                                      target="_blank"
                                      to={`https://www.google.com/maps/place/${encodeURIComponent(
                                        values.hotel_map_address,
                                      )}`}
                                      className="flex items-center gap-x-1 text-sky-600 border-b-[1px] border-opacity-50 border-sky-600 text-sm md:text-base hover:text-sky-700 active:scale-95 duration-300 transition-all cursor-pointer"
                                    >
                                      {values.hotel_map_address}
                                      <IoLink className="md:w-5 md:h-5  w-4 h-4 text-sky-600" />
                                    </Link>
                                  </div>
                                </div>
                              </>
                            )}

                            {/* other hotel */}
                            {values.hotel_name === "Other" || values.hotel_name === "اخرى" ? (
                              <>
                                <IoMdCloseCircleOutline
                                  onClick={() => {
                                    setFieldValue("address", "");
                                    setFieldValue("hotel_map_address", "");
                                    setFieldValue("hotel_name", "");
                                    setFieldValue("hotel_name_other", "");
                                  }}
                                  className="md:w-5 md:h-5  w-4 h-4 text-red-600"
                                />
                                <label className=" text-center w-full text-gray-700 font-semibold flex items-center justify-center  text-sm md:text-[16px]">
                                  {t("OtherHotel")}
                                </label>
                                <div className="w-full relative flex items-center gap-x-1">
                                  <input
                                    value={values.hotel_name_other}
                                    name="hotel_name_other"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder={t("OtherHotel")}
                                    className={` w-full ${
                                      lang === "en" ? "pl-8 md:pl-10" : "pr-8 md:pr-10"
                                    } h-10  border rounded-lg bg-white floating-input text-xs md:text-base  `}
                                  />
                                  <div className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                                    <FaHotel className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                                  </div>
                                </div>
                                <label className=" text-center w-full text-gray-700 font-semibold flex items-center justify-center  text-sm md:text-[16px]">
                                  {t("HotelAddress")}
                                </label>
                                <div className="w-full relative flex items-center gap-x-1">
                                  <input
                                    value={values.address}
                                    name="address"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder={t("HotelAddress")}
                                    className={` w-full ${
                                      lang === "en" ? "pl-8 md:pl-10" : "pr-8 md:pr-10"
                                    } h-10  border rounded-lg bg-white floating-input text-xs md:text-base `}
                                  />
                                  <div className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                                    <TbRoad className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                                  </div>
                                </div>
                                <label className=" text-center w-full text-gray-700 font-semibold flex items-center justify-center  text-sm md:text-[16px]">
                                  {t("HotelMapAddress")}
                                </label>
                                <div className="w-full relative flex items-center gap-x-1">
                                  <input
                                    value={values.hotel_map_address}
                                    name="hotel_map_address"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder={t("HotelMapAddress")}
                                    className={` w-full ${
                                      lang === "en" ? "pl-8 md:pl-10" : "pr-8 md:pr-10"
                                    } h-10  border rounded-lg bg-white floating-input text-xs md:text-base `}
                                  />
                                  <div className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                                    <IoLocationOutline className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </>
                        ) : null}

                        {/* Address hotel */}
                        {values.delivery_place_type !== "hotel"
                          ? values.delivery_place_type !== "فندق" && (
                              <>
                                <label className=" text-center w-full text-gray-700 font-semibold flex items-center justify-center  text-sm md:text-[16px]">
                                  {t("Address")}
                                </label>
                                <div className="w-full relative flex items-center gap-x-1">
                                  <input
                                    name="address"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder={t("Address")}
                                    className={` w-full ${
                                      lang === "en" ? "pl-8 md:pl-10" : "pr-8 md:pr-10"
                                    } h-10  border rounded-lg bg-white floating-input text-xs md:text-base `}
                                    value={values.address}
                                  />
                                  <div className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                                    <TbRoad className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                                  </div>
                                </div>
                              </>
                            )
                          : null}
                      </>
                    )}
                    <div className="w-full flex flex-col items-center justify-center gap-y-2 ">
                      <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[17px] ">
                        {t("buyvisa")}
                      </label>
                      <div
                        className={` w-full px-3 h-10 flex items-center justify-center gap-x-1 md:justify-between  border rounded-lg bg-white floating-input text-xs md:text-base ${
                          touched.buy_visa && errors.buy_visa && "border border-red-800"
                        } `}
                      >
                        <MdOutlinePayments className="w-[2.5rem] h-5 text-[#002244]" />

                        <div className="flex items-center justify-center gap-x-3 w-full md:w-[50%]">
                          <input
                            name="buy_visa "
                            type="radio"
                            value="yes"
                            onChange={(e) => {
                              setFieldValue("buy_visa", "yes");
                            }}
                            defaultChecked={values.buy_visa === "yes" && true}
                            onBlur={handleBlur}
                            className=" md:w-5 md:h-5  w-4 h-4  border rounded-lg bg-white "
                          />
                          <label className="  text-gray-600    text-sm md:text-lg  ">{t("yes")}</label>
                        </div>
                        <div className="flex items-center justify-center gap-x-3 w-full md:w-[50%]">
                          <input
                            name="buy_visa "
                            type="radio"
                            value="no"
                            defaultChecked={values.buy_visa === "no" && true}
                            onChange={(e) => {
                              setFieldValue("buy_visa", "no");
                            }}
                            onBlur={handleBlur}
                            className=" md:w-5 md:h-5  w-4 h-4  border rounded-lg bg-white "
                          />
                          <label className="text-gray-600    text-sm md:text-lg ">{t("no")}</label>
                        </div>
                      </div>{" "}
                      {errors.buy_visa && touched.buy_visa && (
                        <div className="w-full flex justify-start capitalize text-xs md:text-sm text-red-800 font-semibold">
                          {errors.buy_visa}
                        </div>
                      )}
                    </div>
                    <div className="w-full">
                      <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                        {t("typeofcar")}
                      </label>
                      <div className="w-full relative flex items-center gap-x-1">
                        <input
                          placeholder={t("typeofcar")}
                          type="text"
                          name="car_type"
                          onChange={handleChange}
                          value={values.car_type}
                          onBlur={handleBlur}
                          className={` w-full ${
                            lang === "en" ? "pl-8 md:pl-10" : "pr-8 md:pr-10"
                          } h-10  border rounded-lg bg-white floating-input text-xs md:text-base ${
                            touched.car_type && errors.car_type && "border border-red-800"
                          } `}
                        />
                        <div className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                          <FaCar className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                        </div>
                      </div>
                      {touched.car_type && errors.car_type ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                          {errors.car_type}
                        </div>
                      ) : null}
                    </div>

                    <div className="w-full ">
                      <label className=" text-center w-full text-gray-700 font-semibold flex items-center justify-center mt-4  text-sm md:text-[16px]">
                        {t("PassportType")}
                      </label>
                      <div className="w-full relative flex items-center gap-x-1">
                        <Select
                          name="passport_type"
                          options={passporttypeArr}
                          isSearchable
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,

                              borderBottom: "none",
                              borderTop: "none",
                              borderLeft: "none",
                              borderRight: "none",
                              boxShadow: "none",
                              borderRadius: "0",
                            }),
                          }}
                          // value={[{ label: values.currency, value: values.currency }]}
                          className={`w-full  ${
                            lang === "en" ? "pl-5 md:pl-8" : "pr-5 md:pr-8"
                          } border rounded-md text-sm md:text-base ${
                            touched.passport_type && errors.passport_type && "border-red-800"
                          } `}
                          onChange={(e) => {
                            setFieldValue("passport_type", e.value);
                          }}
                          onBlur={handleBlur}
                          placeholder={t("PassportType")}
                          value={[{ label: values.passport_type, value: values.passport_type }]}
                        />
                        <div className={`absolute ${lang === "en" ? "pl-2" : "pr-2"}`}>
                          <FaRegBuilding className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                        </div>
                      </div>
                      {errors.passport_type && touched.passport_type && (
                        <div className="w-full flex justify-start text-xs md:text-sm text-red-800 font-semibold">
                          {errors.passport_type}
                        </div>
                      )}
                    </div>

                    <div className="w-full flex flex-col items-center justify-center gap-y-2 ">
                      <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[17px] ">
                        {t("paymentmethod")}
                      </label>
                      <div
                        className={` w-full px-3 h-10 flex items-center justify-center gap-x-1 md:justify-between  border rounded-lg bg-white floating-input text-xs md:text-base ${
                          touched.payment_method && errors.payment_method && "border border-red-800"
                        } `}
                      >
                        <MdOutlinePayments className="w-[2.5rem] h-5 text-[#002244]" />

                        <div className="flex items-center justify-center gap-x-3 w-full md:w-[50%]">
                          <input
                            name="payment_method "
                            type="radio"
                            value="now"
                            onChange={(e) => {
                              setFieldValue("payment_method", "now");
                            }}
                            defaultChecked={values.payment_method === "now" && true}
                            onBlur={handleBlur}
                            className=" w-3 h-3 md:w-5 md:h-5  border rounded-lg bg-white "
                          />
                          <label
                            className={` ${
                              lang === "en" ? "md:w-8" : "md:w-6"
                            } text-gray-600  text-sm md:text-lg font-semibold`}
                          >
                            {t("now")}
                          </label>
                        </div>

                        <div className="flex items-center justify-center gap-x-3 w-full md:w-[50%]">
                          <input
                            name="payment_method "
                            type="radio"
                            value="Upondeparture"
                            defaultChecked={values.payment_method === "Upondeparture" && true}
                            onChange={(e) => {
                              setFieldValue("payment_method", "Upondeparture");
                            }}
                            onBlur={handleBlur}
                            className=" w-3 h-3 md:w-5 md:h-5  border rounded-lg bg-white  "
                          />
                          <label
                            className={`text-gray-600  ${
                              lang === "en" ? "md:w-32" : "md:w-28"
                            } text-sm md:text-lg font-semibold`}
                          >
                            {t("UpondepartureArr")}
                          </label>
                        </div>
                      </div>{" "}
                      {errors.payment_method && touched.payment_method && (
                        <div className="w-full flex justify-start capitalize text-xs md:text-sm text-red-800 font-semibold">
                          {errors.payment_method}
                        </div>
                      )}
                    </div>
                    <ReCAPTCHA
                      sitekey="6Les06opAAAAAIoeX1KlNwQUqqkmVWNVz5IHyGIW"
                      name="recaptcha_token"
                      onChange={(e) => {
                        setFieldValue("recaptcha_token", e);
                      }}
                    />
                    {values.recaptcha_token && (
                      <button
                        type="Submit"
                        disabled={isSubmitting}
                        className="flex items-center justify-center gap-x-1 w-[95%] md:w-[20%] py-2 md:px-3 md:py-1 md:text-md text-sm rounded-md bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        {t("add")}
                        {isSubmitting ? (
                          <>
                            <TopBarProgress />
                            <ButtonLoading />
                          </>
                        ) : (
                          <IoAdd className="md:w-5 md:h-5  w-4 h-4" />
                        )}
                      </button>
                    )}
                  </div>
                </form>
                <NotificationContainer />
              </div>
            </div>
          ) : !Error ? (
            <div className="w-full flex items-center justify-center h-screen">
              <TopBarProgress /> <Loading />
            </div>
          ) : (
            Error && <Errorfetch />
          )}
        </div>
      ) : null}
    </>
  );
};

export default ArrivalForm;
