import React from "react";
import { LuBanknote } from "react-icons/lu";
import { Link } from "react-router-dom";

const AddBankChequeRelated = () => {
  return (
    <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
      <Link
        to={"/dashboard/accounting-setting/add-bank-cheques"}
        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
      >
        <div className="w-full flex justify-center items-center ">
          <LuBanknote className="w-12 h-12   " />
        </div>
      </Link>
      <p className="text-lg font-semibold">Add bank cheques </p>
    </div>
  );
};
export default AddBankChequeRelated;
