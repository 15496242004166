import api_token from "../../api/UserApi";
import cookie from "react-cookies";
import {
  CLEAR_CANCELLATION_POLICY_Archive,
  CLEAR_CANCELLATION_POLICY_LIST,
  GET_CANCELLATION_POLICY_ARCHIVE,
  GET_CANCELLATION_POLICY_LIST,
} from "../types/types";

const token = cookie.load("access_token");

export function getCancelationReasons(callback) {
  return async function () {
    try {
      const response = await api_token(token).get(`/cancellation/api/reason`);
      if (response) {
        callback(response.data);
      }
    } catch (error) {}
  };
}

export function getCancellationCharge(callback) {
  return async function () {
    try {
      const response = await api_token(token).get(`/cancellation/api/charge`);
      if (response) {
        callback(response.data);
      }
    } catch (error) {}
  };
}

export function getCancellationChargeId(token, id, callback) {
  return async function () {
    try {
      const response = await api_token(token).get(`/cancellation/api/charge/${id}/`);

      if (response) {
        callback(response.data);
      }
    } catch (error) {}
  };
}

export function getCancellationTime(callback) {
  return async function () {
    try {
      const response = await api_token(token).get(`/cancellation/api/cancallations-time`);
      if (response) {
        callback(response.data);
      }
    } catch (error) {}
  };
}

export function createCancellationPolicy(data, successCallback, errorCallback) {
  return async function () {
    try {
      const response = await api_token(token).post(`/cancellation/api/policy`, data);

      if (response.status === 201) {
        successCallback();
      }
    } catch (error) {
      if (error.response.status === 400) {
        errorCallback();
      }
    }
  };
}

export function getCancellationPolicyList() {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/cancellation/api/policy`);

      dispatch({ type: GET_CANCELLATION_POLICY_LIST, payload: response.data });
    } catch (error) {}
  };
}

export function getCancellationPolicyListCities(token, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/search/api/v1/get-cities-search/`);

      callback(response);
    } catch (error) {}
  };
}

export function getCancellationPolicyId(token, id, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/cancellation/api/policy/${id}/`);
      callback(response);
    } catch (error) {}
  };
}

export function updateCancellationPolicy(token, id, data, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).patch(`/cancellation/api/policy/${id}/`, data);

      callback(response);
    } catch (error) {}
  };
}

export function deleteCancellationPolicy(token, id, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).delete(`/cancellation/api/policy/${id}/`);

      callback(response);
    } catch (error) {}
  };
}

export function clearCancellationPolicyList() {
  return function (dispatch) {
    dispatch({ type: CLEAR_CANCELLATION_POLICY_LIST });
  };
}

export function getCancellationPolicyArchive() {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/cancellation/api/policy/archive`);
      dispatch({ type: GET_CANCELLATION_POLICY_ARCHIVE, payload: response.data });
    } catch (error) {}
  };
}

export function clearCancellationPolicyArchive() {
  return function (dispatch) {
    dispatch({ type: CLEAR_CANCELLATION_POLICY_Archive });
  };
}

export function getCancellationHotel(token, startDate, hotelId, offerId, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(
        `/cancellation/api/policy?date=${startDate}&hotel=${hotelId}&offer_type=${offerId}`,
      );
      callback(response);
    } catch (error) {}
  };
}

export function getCancellationHotelFilter(hotelId, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/cancellation/api/policy?hotel=${hotelId}`);
      callback(response);
    } catch (error) {}
  };
}

export function exchangeRate(fir_curr, sec_curr, callBack) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(
        `/site-settings/api/exhange-rate?from_currency=${fir_curr}&to_currency=${sec_curr}`,
      );
      callBack(response);
    } catch (error) {}
  };
}

export function exchangeRateDynamic(fir_curr, sec_curr, callBack) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(
        `/site-settings/api/exhange-rate?from_currency=${fir_curr}&to_currency=${sec_curr}`,
      );
      callBack(response);
    } catch (error) {}
  };
}

export function exchangeRateFixed(callBack) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/site-settings/api/exhange-rate`);
      callBack(response);
    } catch (error) {}
  };
}

export function hotelInfoDATA(id, callBack) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/en/hotels/api/main-hotel-info?hotel=${id}`);
      callBack(response);
    } catch (error) {}
  };
}

export async function cancelBooking(reasonId, bookingId) {
  try {
    const response = await api_token(token).post("cancellation/api/booking", {
      reason: reasonId,
      booking: bookingId,
    });

    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      throw new Error("Error canceling booking");
    }
  } catch (e) {
    throw new Error(e);
  }
}

export async function confirmCancelBooking(bookingId) {
  try {
    const response = await api_token(token).post(`/cancellation/api/booking/${bookingId}/confirm_cancelation/`);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      throw new Error("Error canceling booking");
    }
  } catch (e) {
    throw new Error(e);
  }
}
