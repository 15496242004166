import React, { useEffect, useState } from "react";
import SearchForm from "../../organisms/SearchForm/SearchForm";
import Navbar from "../../organisms/Navbar/Navbar";
import SelectedSearch from "../../organisms/SelectedSearch/SelectedSearch";
import { useDispatch, useSelector } from "react-redux";
import RadioInput from "../../organisms/RadioInput/RadioInput";
import Loading from "../../organisms/Loading/Loading";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { BsFilterRight } from "react-icons/bs";
import cookie from "react-cookies";
import "@splidejs/react-splide/css";
import "./Hotels.css";
import { getCurrencyAction } from "../../../redux/actions/bookingListAction";
import { selectedSearchFilterAction } from "../../../redux/actions/SelectedSearchAction";
import Slider from "@mui/material/Slider";

import { useTranslation } from "react-i18next";
import Footer from "../Footer.js/Footer";
import usePermissions from "../../../customHooks/usePermissions";
import TopBarProgress from "react-topbar-progress-indicator";
import { useLocation, useNavigate } from "react-router";
import NoPermission from "../../organisms/NoPermission/NoPermission";
import HotelsImg from "./HotelsImg";
import { getData } from "../../../redux/actions/sendDataAction";
import { toast } from "react-toastify";
import { logoutAction } from "../../../redux/actions/loginAction";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import { Slide } from "@mui/material";
import jwtDecode from "jwt-decode";
import Errorfetch from "../../organisms/Errorfetch/Errorfetch";

const Hotels = () => {
  const [navState, setNavState] = useState(false);
  const [navState2, setNavState2] = useState(false);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [star, setStar] = useState();
  const [searchFlag, setSearchFlag] = useState(false); // this state to know search action has dispatched or not to remove photos and show results
  const [hotelImages, setHotelImages] = useState([]);
  const [CityFormHotel, setCityFormHotel] = useState({ formDesLabel: "", formDesLoading: "" });
  const token = cookie.load("access_token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [t, i18n] = useTranslation();
  const localeCode = t("localeCode");
  const { errorStatus } = useSelector((state) => state.SelectedSearchReducer);
  const { currencyList } = useSelector((state) => state.bookingListReducer);
  const selectedDataOfHotel = useSelector((state) => state.SelectedSearchReducer.selectedHotel);
  const hotelsFilters = useSelector((state) => state.SelectedSearchReducer.hotelsFilter);
  const { selectedDataFromForm } = useSelector((state) => state.SelectedSearchReducer);

  const {
    data: dataImages,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["search-slider-images"],
    queryFn: () => fetchDataQuery(`/en/site-settings/api/v1/search-slider-images/`),
  });

  const flag = [
    "/images/united-kingdom-uk-svgrepo-com.svg",
    "/images/yemen-svgrepo-com.svg",
    "/images/flag-for-saudi-arabia-svgrepo-com.svg",
    "/images/united-arab-emirates-svgrepo-com.svg",
  ];

  let currencyWithFlag = currencyList
    ? currencyList.map((item, index) => {
        return { value: currencyList[index], img: flag[index] };
      })
    : [];
  const toggleDropdownStatus = () => {
    setIsOpenStatus(!isOpenStatus);
  };

  let count = [];
  count = selectedDataOfHotel ? selectedDataOfHotel.map((selectedHotel) => count.push("1")) : [];

  //Hotels img
  useEffect(() => {
    // get Hotels img
    dispatch(
      getData(
        `/hotels/api/v1/hotels-slider/`,
        (resp) => {
          if (resp.status === 200) {
            setHotelImages(resp.data);
          }
        },
        (err) => {},
        t("lan"),
      ),
    );
  }, [cookie.load("language")]);

  useEffect(() => {
    dispatch(getCurrencyAction(token));
    if (!selectedDataOfHotel.length == 0) {
    }
  }, [selectedDataOfHotel]);
  useEffect(() => {}, [selectedDataOfHotel]);

  const onNavScroll = () => {
    if (window.scrollY > 200) {
      setNavState(true);
    } else {
      setNavState(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onNavScroll);
    return () => {
      window.removeEventListener("scroll", onNavScroll);
    };
  }, []);

  const onNavScroll2 = () => {
    if (window.scrollY > 300) {
      setNavState2(true);
    } else {
      setNavState2(false);
    }
  };

  useEffect(() => {
    if (location.state === "dashboardSearch") {
      setSearchFlag(true);
    }
    window.addEventListener("scroll", onNavScroll2);
    return () => {
      window.removeEventListener("scroll", onNavScroll2);
    };
  }, []);

  let testArray1 = [];

  const { loader } = useSelector((state) => state.SelectedSearchReducer);

  useEffect(() => {}, [loader]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Set the language for i18n
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImageIndex2, setCurrentImageIndex2] = useState(0);
  const [toggle, setToggle] = useState(false);
  const images = [
    // '/images/panoramic-view-coast-island-symi-260nw-493674346.webp',
    "/images/bghome8.jpg",
    // "/images/front.959.png",
    // Add more image URLs here
  ];

  const backgroundImageUrl = images[currentImageIndex];
  const style = {
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
    // height: "55vh",
    // borderRadius: "3%",

    // transition: "background-image 2s ease",
  };

  const images2 = ["/images/ww.webp", "/images/www.webp", "/images/wwww.webp", "/images/wwwww.webp"];
  const images3 = ["/images/ww.webp", "/images/www.webp", "/images/wwww.webp", "/images/wwwww.webp"];
  const backgroundImageUrl2 = images2[currentImageIndex2];
  const stylePhoto = {
    backgroundImage: `url(${backgroundImageUrl2})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    transition: "background-image 2s ease",
  };

  useEffect(() => {
    const interval = setInterval(changeBackgroundImage, 4000);

    return () => {
      // Clear the interval when the component is unmounted to prevent memory leaks
      clearInterval(interval);
    };
  }, [currentImageIndex]);

  const changeBackgroundImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    setCurrentImageIndex2((prevIndex) => (prevIndex + 1) % images2.length);
  };

  let featureArray = [];
  const [valuesArray, setValuesArray] = useState([]);
  const handleCheckboxChange = (id) => {
    setValuesArray((prevValuesArray) => {
      let updatedArray;

      // Check if the id is already in the array

      if (prevValuesArray.includes(id)) {
        // If it is, remove it
        updatedArray = prevValuesArray.filter((item) => item !== id);
      } else {
        // If it's not, add it
        updatedArray = [...prevValuesArray, id];
      }

      // Log the updated valuesArray to the console

      // Dispatch an action with the updated feature array

      // Return the updated array to update state
      return updatedArray;
    });
  };

  // ${navState ?'fixed top-0 left-0 right-0 lg:top-20 xl:left-0  bg-opacity-100 transform duration-300 ease-in-out z-50' : ' opacity-100 z-50'}
  function valuetext(value) {
    return `${value}°C`;
  }

  const changeCurrency = cookie.load("currency");

  const [value, setValue] = useState([0, 0]);

  useEffect(() => {
    if (hotelsFilters) {
      if (value[0] === 0) {
        setValue([hotelsFilters.range_prices.min, hotelsFilters.range_prices.max]);
      }
    }
  }, [hotelsFilters, changeCurrency]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBlur = () => {
    // Ensure the minimum value is not less than hotelsFilters.range_prices.min
    const updatedValue = [
      Math.max(value[0], hotelsFilters?.range_prices.min),
      Math.min(value[1], hotelsFilters?.range_prices.max),
    ];

    setValue(updatedValue);
  };

  //permission
  const [permissions, permissionsFlag] = usePermissions([
    "permissions_management.search_platform",
    "permissions_management.main_dashboard",
  ]);

  const [load, setLoad] = useState(false);

  useEffect(() => {
    const refreshToken = cookie.load("refresh_token");
    const accessToken = cookie.load("access_token");

    if (permissionsFlag && permissions) {
      const mainDashboardPermission = permissions?.find(
        (permission) => permission.name === "permissions_management.main_dashboard",
      );
      const searchPlatformPermission = permissions?.find(
        (permission) => permission.name === "permissions_management.search_platform",
      );

      // go to code or No Permission

      if (
        mainDashboardPermission &&
        mainDashboardPermission.value === false &&
        searchPlatformPermission &&
        searchPlatformPermission.value === false &&
        cookie.load("code")
      ) {
        navigate(`/${cookie.load("code")}`);
      } else if (
        mainDashboardPermission &&
        mainDashboardPermission.value === false &&
        searchPlatformPermission &&
        searchPlatformPermission.value === false &&
        !cookie.load("code")
      ) {
        setLoad(true);
        if (accessToken && refreshToken) {
          dispatch(
            logoutAction(accessToken, refreshToken, (result) => {
              if (result.status === 205) {
                setLoad(false);
                // Clear cookies
                cookie.remove("access_token");
                cookie.remove("refresh_token");
                cookie.remove("currency");
                cookie.remove("language");
                cookie.remove("code");
                // Navigate to login page
                navigate("/login");
                toast.error("you don't have any permissions");
              } else {
                // Handle other statuses or conditions if needed
                setLoad(false);
                toast.error("Logout failed");
              }
            }),
          );
        }
      }

      // go to dashboard or search

      if (
        mainDashboardPermission &&
        mainDashboardPermission.value === true &&
        searchPlatformPermission &&
        searchPlatformPermission.value === false
      ) {
        navigate("/dashboard");
      } else if (searchPlatformPermission && searchPlatformPermission.value === true) {
        navigate("/");
      }
    }
  }, [permissionsFlag]);

  const splideOptionsMultiForms = {
    perPage: 1,
    perMove: 1,
    rewind: true,
    arrows: false,
    autoplay: true, // Enable autoplay
    interval: 3000, // Set interval to 1 second
    speed: 1000, // Set transition speed if desired (in milliseconds)
    keyboard: "global",
    pagination: false,
    direction: t("dir") === "en" ? "ltr" : "rtl",
    breakpoints: {
      1200: { perPage: 1 },
      991: { perPage: 1 },
      768: { perPage: 1 },
      500: { perPage: 1 },
      425: { perPage: 1 },
      280: { perPage: 1 },
    },
  };

  const user = jwtDecode(cookie.load("access_token"));

  const {
    data: userinfo,
    error: errorLoading,
    isLoading: Userloading,
  } = useQuery({
    queryKey: ["user", user?.user_id],
    queryFn: () => fetchDataQuery(`/auth/api/v1/profile/${user?.user_id}/`),
  });

  // if (Userloading) {
  //   return (
  //     <>
  //       <Loading /> <TopBarProgress />
  //     </>
  //   );
  // }

  return (
    <>
      {permissionsFlag && hotelImages.length > 0 ? (
        permissions?.some((item) => item.name === "permissions_management.search_platform" && item.value === true) ||
        cookie.load("code") ? (
          <div className="w-full h-[100%] ">
            <div className={`w-full  bg-white`} dir={t("dir")}>
              <Navbar setValue={setValue} setValuesArray={setValuesArray} />

              <div
                className="  bg-cover bg-center bg-no-repeat  justify-center flex flex-col rounded-b-[3%] md:p-10 p-0 sectionSlider  "
                style={style}
              >
                <div className=" w-full flex justify-center ">
                  <div className=" divSlider  sm:h-[27rem] h-[15rem]  w-[55rem]   rounded-md mt-[-1%]">
                    <Splide options={splideOptionsMultiForms}>
                      {dataImages?.map((item, index) => (
                        <SplideSlide key={index}>
                          <img
                            key={index + item.orders}
                            className=" w-full imgSlider sm:h-[17rem] h-[9rem] rounded-xl "
                            src={item?.image}
                            alt={`Image ${index + 1}`}
                          />
                        </SplideSlide>
                      ))}
                    </Splide>
                  </div>
                </div>
              </div>

              <div
                className={`sticky flex-1 h-full flex w-full xl:w-[95%] mx-auto items-center justify-stretch flex-col text-center mb-5 mt-5  md:mt-12   transform transition duration-500 ease-in-out z-50  lg:-translate-y-36 ${
                  navState
                    ? "bg-white rounded-2xl shadow-md sticky top-0 left-0 right-0 lg:top-[110px] xl:left-0 lg:-translate-y-1/2  bg-opacity-100 transform transition duration-500 ease-in-out z-50"
                    : " opacity-100 z-50"
                } `}
              >
                <SearchForm
                  setNavState={setNavState}
                  setValue={setValue}
                  setValuesArray={setValuesArray}
                  setStar={setStar}
                  setSearchFlag={setSearchFlag}
                  setCityFormHotel={setCityFormHotel}
                />

                <button
                  onClick={() => {
                    setToggle(!toggle);
                  }}
                  type="button"
                  class={`mb-4 flex items-end justify-center  gap-x-1 lg:hidden  w-[90%] mt-1  tex-sm bg-[#002244] text-white p-2  rounded-xl `}
                >
                  {t("filter")} <BsFilterRight className="w-5 h-5" />
                </button>

                {/*Filter for small screen */}
                {toggle && (
                  <div
                    dir={t("dir")}
                    className={`grid  lg:hidden sm:w-full w-[90%] border-gray-300 py-5 px-2 border boxShadow rounded-lg xl:w-[15%] lg:w-[20%]   bg-white   `}
                  >
                    <div className="flex items-center justify-between w-full  text-lg text-black font-extrabold border-gray-300 border-b px-1 py-2  ">
                      <p className="font-extrabold text-md">{t("filter")} :</p>
                      <button
                        onClick={() => {
                          dispatch(
                            selectedSearchFilterAction(
                              token,
                              {
                                ...selectedDataFromForm,
                                features: JSON.stringify(valuesArray),
                                min_price: value[0],
                                max_price: value[1],
                                rates: star,
                              },
                              t("lan"),
                            ),
                          );
                        }}
                        className="  rounded-md px-2 py-1 bg-[#002244] text-white font-thin text-sm  cursor-pointer  hover:scale-105  active:scale-95 duration-300 transition-all"
                      >
                        {t("Done")}
                      </button>
                    </div>
                    <div className="grid gap-y-2 py-2 px-2">
                      {hotelsFilters?.features.map((item) => {
                        return (
                          <div className="w-full flex items-center justify-start gap-x-1">
                            <input
                              type="checkbox"
                              className="w-5 h-5 rounded-2xl"
                              onChange={() => {
                                handleCheckboxChange(item.id);
                              }}
                            />
                            <p className="text-sm font-semibold text-black capitalize">{item.feature}</p>
                          </div>
                        );
                      })}
                      <hr></hr>
                      <div className="w-full flex flex-col items-start gap-y-1">
                        <p className="font-bold text-md">{t("budget")}</p>
                        <div className="w-full flex items-center justify-start  gap-x-1 ">
                          <input
                            type="number"
                            className="text-sm w-[30%] border-gray-300 border rounded-md pl-1"
                            value={hotelsFilters ? value[0] : 0}
                            onChange={(e) => handleChange(e, [parseInt(e.target.value), value[1]])}
                            onBlur={handleBlur}
                          />
                          <p className="text-sm">{t(cookie.load("currency"))}</p>
                          <input
                            type="number"
                            className="text-sm w-[30%] border-gray-300 border rounded-md pl-1"
                            value={hotelsFilters ? value[1] : 0}
                            onChange={(e) => handleChange(e, [value[0], parseInt(e.target.value)])}
                            onBlur={handleBlur}
                          />
                          <p className="text-sm">-{t(cookie.load("currency"))}+</p>
                        </div>

                        <Slider
                          getAriaLabel={() => "Minimum distance"}
                          value={hotelsFilters ? value : 0}
                          min={hotelsFilters && hotelsFilters.range_prices.min}
                          max={hotelsFilters && hotelsFilters.range_prices.max}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          getAriaValueText={valuetext}
                          disableSwap
                          // disabled={!hotelsFilters}
                          style={{ zIndex: 0 }}
                        />
                      </div>
                      <hr></hr>
                      <div className="w-full flex flex-col items-start gap-y-1">
                        <p className="font-bold text-md">{t("stars")}</p>
                        {hotelsFilters?.rates.map((item) => {
                          return (
                            <div className="w-full flex items-center justify-start gap-x-1">
                              <input
                                type="checkbox"
                                className="w-5 h-5 rounded-2xl"
                                onChange={() => {
                                  setStar(star ? null : item);
                                }}
                              />

                              <p className="text-sm font-semibold text-black capitalize">
                                {item} {t("stars")}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                      <div className="w-full flex flex-col items-start gap-y-2 mt-3">
                        <p className="font-bold text-md">{t("mealType")}</p>
                        <RadioInput roomType={t("Bed Only")} type={"b.o"} />
                        <RadioInput roomType={t("breakfast included")} type={"b.b"} />
                        <RadioInput roomType={t("half board")} type={"h.b"} />
                        <RadioInput roomType={t("full board")} type={"f.b"} />
                        <RadioInput roomType={t("allInclusive")} type={"all"} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {searchFlag ? (
                <div className="w-full">
                  <div className="  flex flex-col  lg:flex-row  w-full h-full justify-center  items-start gap-x-12   bg-white  md:mt-20 mt-10">
                    <div
                      dir={t("dir")}
                      className={`hidden lg:grid w-full py-5 px-2 border-gray-300 border rounded-xl xl:w-[15%] lg:w-[20%] boxShadow bg-white  ${
                        navState2 ? "sticky top-0 lg:top-32 left-12 transform duration-300 ease-in-out " : ""
                      } `}
                    >
                      <div className="flex items-center justify-between bg-white gap-x-1 w-full p-1   text-lg text-black  font-extrabold border-gray-300 border-b pl-3   ">
                        <p className="font-extrabold text-md">{t("filter")} :</p>
                        <button
                          onClick={() => {
                            dispatch(
                              selectedSearchFilterAction(
                                token,
                                {
                                  ...selectedDataFromForm,
                                  features: JSON.stringify(valuesArray),
                                  min_price: value[0],
                                  max_price: value[1],
                                  rates: star,
                                },
                                t("lan"),
                              ),
                            );
                          }}
                          className="  rounded-md px-2 py-1 bg-[#002244] text-white font-thin text-sm  cursor-pointer  hover:scale-105  active:scale-95 duration-300 transition-all"
                        >
                          {t("Done")}
                        </button>
                      </div>
                      {/* <div className='w-full text-md text-black font-bold  px-1 py-2  '>
                  <p>Meals Basis</p>
                </div> */}

                      <div className="grid gap-y-2 py-2 px-2">
                        {hotelsFilters?.features.map((item) => {
                          return (
                            <div className="w-full flex items-center justify-start gap-x-1">
                              <input
                                type="checkbox"
                                className="w-5 h-5 rounded-2xl"
                                onChange={() => {
                                  handleCheckboxChange(item.id);
                                }}
                              />
                              <p className="text-sm font-semibold text-black capitalize">{item.feature}</p>
                            </div>
                          );
                        })}
                        <hr></hr>
                        <div className="w-full flex flex-col items-start gap-y-1">
                          <p className="font-bold text-md">{t("budget")}</p>
                          <div className="w-full flex items-center justify-start  gap-x-1 " dir={t("dir")}>
                            <input
                              type="number"
                              className="text-sm w-[30%] border-gray-300 border rounded-md pl-1"
                              value={hotelsFilters ? value[0] : 0}
                              onChange={(e) => handleChange(e, [parseInt(e.target.value), value[1]])}
                              onBlur={handleBlur}
                            />
                            <p className="text-sm">{t(cookie.load("currency"))}</p>
                            <input
                              type="number"
                              className="text-sm w-[30%] border-gray-300 border rounded-md pl-1"
                              value={hotelsFilters ? value[1] : 0}
                              onChange={(e) => handleChange(e, [value[0], parseInt(e.target.value)])}
                              onBlur={handleBlur}
                            />
                            <p className="text-sm">-{t(cookie.load("currency"))}+</p>
                          </div>
                          {/* <Statistics /> */}
                          <Slider
                            getAriaLabel={() => "Minimum distance"}
                            value={hotelsFilters ? value : 0}
                            min={hotelsFilters && hotelsFilters.range_prices.min}
                            max={hotelsFilters && hotelsFilters.range_prices.max}
                            onChange={handleChange}
                            valueLabelDisplay="auto"
                            getAriaValueText={valuetext}
                            disableSwap
                            // disabled={!hotelsFilters}
                            className="z-0"
                            style={{ zIndex: 0 }}
                          />
                        </div>
                        <hr></hr>
                        <div className="w-full flex flex-col items-start gap-y-2">
                          <p className="font-bold text-md">{t("stars")}</p>
                          {hotelsFilters?.rates.map((item) => {
                            return (
                              <div className="w-full flex items-center justify-start gap-x-1">
                                <input
                                  type="checkbox"
                                  className="w-5 h-5 rounded-2xl"
                                  onChange={() => {
                                    setStar(star ? null : item);
                                  }}
                                />

                                <p className="text-sm font-semibold text-black capitalize">
                                  {item} {t("stars")}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                        <div className="w-full flex flex-col gap-y-2 mt-3">
                          <p className="font-bold text-md">{t("mealType")}</p>
                          <RadioInput roomType={t("Bed Only")} type={"b.o"} />
                          <RadioInput roomType={t("breakfast included")} type={"b.b"} />
                          <RadioInput roomType={t("half board")} type={"h.b"} />
                          <RadioInput roomType={t("full board")} type={"f.b"} />
                          <RadioInput roomType={t("allInclusive")} type={"all"} />
                        </div>
                      </div>
                    </div>

                    <div className={`flex flex-col justify-center items-center gap-y-5 w-full lg:w-[55%] px-4  `}>
                      <div
                        className="flex items-center justify-between border-gray-300 border-b-2 md:w-full pb-3"
                        dir={t("dir")}
                      >
                        <p className="flex justify-center items-center text-center gap-1 lg:text-xl md:text-md  text-sm text-slate-950 ">
                          {t("Found")}{" "}
                          <span className="text-lion">
                            {new Intl.NumberFormat(localeCode).format(count.length)} {t("Hotels")}
                          </span>{" "}
                          {t("In") + " "}
                          <span className="lg:text-xl md:text-md  text-sm text-slate-950 ">
                            {CityFormHotel.formDesLoading ? (
                              <div class="flex space-x-1 justify-center items-center bg-white  dark:invert mr-2 ml-2 ">
                                <span class="sr-only">Loading...</span>
                                <div class="h-3 w-3 bg-[#002244] rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                                <div class="h-3 w-3 bg-[#002244] rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                                <div class="h-3 w-3 bg-[#002244] rounded-full animate-bounce"></div>
                              </div>
                            ) : (
                              CityFormHotel?.formDesLabel
                            )}
                          </span>
                        </p>
                      </div>

                      {loader === false ? (
                        selectedDataOfHotel
                          .sort((a, b) => b.preferred - a.preferred)
                          .map((selectedHotel, index) => {
                            return <SelectedSearch userinfo={userinfo} selectedHotel={selectedHotel} key={index} />;
                          })
                      ) : errorStatus ? (
                        <div className="flex items-center justify-center bg-gray-100 text-black w-full h-[50px] text-xl ">
                          An Error Occured Please Contact Server Administrator
                        </div>
                      ) : loader === false ? (
                        selectedDataOfHotel
                        .sort((a, b) => b.preferred - a.preferred)
                        .map((selectedHotel, index) => {
                          return <SelectedSearch userinfo={userinfo} selectedHotel={selectedHotel} key={index} />;
                        })
                      ) : (
                        <>
                          <Loading /> <TopBarProgress />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full flex flex-col justify-center items-center mt-10 gap-y-10 z-0">
                  <HotelsImg hotelImages={hotelImages} />
                </div>
              )}
            </div>
            <div className="w-full mt-[100px]">
              <Footer />
            </div>
          </div>
        ) : (
          <div className="w-full flex  items-center justify-center h-screen">
            <NoPermission />
          </div>
        )
      ) : (
        <div className="w-full flex  items-center justify-center h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </>
  );
};

export default Hotels;
