import React, { useState, useEffect } from "react";
import { FaDatabase } from "react-icons/fa6";
import { FaTicketAlt } from "react-icons/fa";
import { CiCalendarDate, CiGlobe } from "react-icons/ci";
import { BiMessageSquareError } from "react-icons/bi";
import { CgDetailsMore } from "react-icons/cg";
import { GoPerson } from "react-icons/go";
import { Link, useSearchParams } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import DeleteTicketModal from "./DeleteTicketModal";
import NoPermission from "../../../NoPermission/NoPermission";
import { useNavigate } from "react-router";
import RestoreDeletedTicketModal from "./RestoreDeletedTicketModal";
import ListFilters from "../../../../../utils/ListFilters";
import Select from "react-select";

function ITTicketListTable({
  softDelete,
  tickets,
  count,
  paginationModel,
  setPaginationModel,
  canViewTickets,
  canDeleteTickets,
  websitesData,
  selectedPriority,
  setSelectedPriority,
  setSelectedWebsites,
  selectedWebsites,
}) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const status = searchParams.get("status") || "open";
  const websitesParams = searchParams.get("websites");
  const priorityParams = searchParams.get("priority");
  console.log(canViewTickets);
  console.log(tickets);
  const handleTableChange = (pagination, size) => {
    setPaginationModel((prev) => ({
      ...prev,
      current: pagination,
      pageSize: size,
    }));
  };

  const websitesArr = websitesData?.map((val) => ({ label: val.name, value: val.id }));

  const priorityOptions = [
    { value: "all", label: "All" },
    { value: "urgent", label: "Urgent" },
    { value: "high", label: "High" },
    { value: "medium", label: "Medium" },
    { value: "low", label: "Low" },
  ];

  const handleWebsiteFilter = (selectedOptions) => {
    if (selectedOptions.value === "all") {
      setSelectedWebsites(undefined);
    } else {
      setSelectedWebsites(selectedOptions);
      setPaginationModel({ current: 1, pageSize: 20 });
    }
  };

  const handlePriorityFilter = (selectedOption) => {
    if (selectedOption.value === "all") {
      setSelectedPriority(undefined);
    } else {
      setSelectedPriority(selectedOption);
      setPaginationModel({ current: 1, pageSize: 20 });
    }
  };

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const formatDuration = (duration) => {
    const parts = duration?.split(", ");
    const formattedParts = {};
    parts?.forEach((part) => {
      if (part.includes("day")) {
        formattedParts.days = part?.replace(" days", "d").replace(" day", "d");
      } else if (part.includes("hour")) {
        formattedParts.hours = part?.replace(" hours", "h").replace(" hour", "h");
      } else if (part.includes("minute")) {
        formattedParts.minutes = part?.replace(" minutes", "m").replace(" minute", "m");
      } else if (part.includes("second")) {
        formattedParts.seconds = part?.replace(" seconds", "s").replace(" second", "s");
      }
    });

    const formattedDuration = [
      formattedParts.days,
      formattedParts.hours,
      formattedParts.minutes,
      formattedParts.seconds,
    ]
      .filter(Boolean)
      .join(", ");

    return formattedDuration;
  };
  console.log(tickets);
  const filteredTickets = tickets?.filter(
    (ticket) =>
      (selectedWebsites ? selectedWebsites?.some((website) => website.value === ticket.website.id) : true) &&
      (selectedPriority ? ticket.priority === selectedPriority.value : true),
  );
  const rows = (filteredTickets?.length > 0 ? filteredTickets : tickets)?.map((ticket) => ({
    id: ticket.id,
    name: ticket.request_name,
    type: ticket.type_of_request.name,
    details: ticket.request_details,
    createdAt: ticket.created_at,
    dateOfCompletion: ticket.date_of_task_complete,
    durationOfCompletion: ticket.duration_of_task_complete,
    status: ticket.status,
    createdBy: ticket.created_by.username,
    website: ticket.website.name,
    priority: ticket.priority,
  }));
  console.log(rows);
  const columns = [
    {
      key: "id",
      dataIndex: "id",
      title: (
        <div className="flex items-center gap-x-1 text-[14px]">
          <FaDatabase className="w-5 h-5 text-yellow-500" />
          ID
        </div>
      ),
      render: (text, params) => (
        <Link to={`/dashboard/tickets-support/tickets/${params.id}`} className="flex items-center gap-x-1 text-[14px]">
          {params?.id}
          {console.log(params)}
        </Link>
      ),
    },
    {
      key: "name",
      title: (
        <div className="flex items-center gap-x-1 text-[14px]">
          <FaTicketAlt className="w-5 h-5 text-yellow-500" />
          Name
        </div>
      ),
      render: (text, params) => (
        <Link
          title={params?.name}
          to={`/dashboard/tickets-support/tickets/${params.id}`}
          className="flex items-center gap-x-1 text-[14px]"
        >
          {params?.name}
        </Link>
      ),
      dataIndex: "name",
    },
    {
      key: "website",
      title: (
        <div className="flex items-center gap-x-1 text-[14px]">
          <CiGlobe className="w-5 h-5 text-yellow-500" />
          Website
        </div>
      ),
      render: (text, params) => (
        <Link
          title={params?.website}
          to={`/dashboard/tickets-support/tickets/${params.id}`}
          className="flex items-center gap-x-1 text-[14px]"
        >
          {params?.website}
        </Link>
      ),
      dataIndex: "website",
    },
    {
      key: "priority",
      title: (
        <div className="flex items-center gap-x-1 text-[14px]">
          <CiGlobe className="w-5 h-5 text-yellow-500" />
          Priority
        </div>
      ),
      render: (text, params) => {
        const priorityStyles = {
          urgent: "bg-red-600 text-white neon-shadow-red rounded-md animate-pulse",
          high: "bg-orange-600 text-white neon-shadow-red rounded-md",
          medium: "bg-yellow-500 text-white neon-shadow-yellow rounded-md",
          low: "bg-green-400 text-white neon-shadow-yellow rounded-md",
        };
        const priority = params?.priority;
        const priorityClass = priorityStyles[priority] || "bg-gray-200 text-black"; // default styling if priority not found

        return (
          <Link
            title={priority}
            to={`/dashboard/tickets-support/tickets/${params?.id}`}
            className={`flex items-center justify-center gap-x-1 text-[14px] capitalize ${priorityClass}`}
          >
            {priority}
            {console.log(params)}
          </Link>
        );
      },
      dataIndex: "priority",
    },
    {
      key: "type",
      title: (
        <div className="flex items-center gap-x-1 text-[14px]">
          <BiMessageSquareError className="w-5 h-5 text-yellow-500" />
          Type
        </div>
      ),
      render: (text, params) => (
        <Link to={`/dashboard/tickets-support/tickets/${params.id}`} className="flex items-center gap-x-1 text-[14px]">
          {params?.type}
        </Link>
      ),
    },
    {
      key: "details",
      title: (
        <div className="flex items-center gap-x-1 text-[14px]">
          <CgDetailsMore className="w-5 h-5 text-yellow-500" />
          Details
        </div>
      ),
      render: (text, params) => {
        const cleanText = stripHtmlTags(params?.details);
        const truncatedText = cleanText.length > 20 ? `${cleanText.substr(0, 20)}...` : cleanText;
        const truncatedTextTitle = cleanText.length > 20 ? `${cleanText}` : cleanText;

        return (
          <Link
            title={truncatedTextTitle}
            to={`/dashboard/tickets-support/tickets/${params.id}`}
            className="flex items-center gap-x-1 text-[14px]"
          >
            {truncatedText}
          </Link>
        );
      },
      dataIndex: "details",
    },
    {
      key: "createdBy",
      cellClassName: "!pl-4",
      title: (
        <div className="flex items-center gap-x-1 text-[14px]">
          <GoPerson className="w-5 h-5 text-yellow-500" />
          Created By
        </div>
      ),
      render: (text, params) => (
        <Link to={`/dashboard/tickets-support/tickets/${params.id}`} className="flex items-center gap-x-1 text-[14px]">
          {params?.createdBy}
        </Link>
      ),
      dataIndex: "createdBy",
    },
    {
      key: "createdAt",
      title: (
        <div className="flex items-center gap-x-1 text-[14px]">
          <CiCalendarDate className="w-5 h-5 text-yellow-500" />
          Created At
        </div>
      ),
      render: (text, params) => {
        const dateStr = params?.createdAt?.slice(0, 10).split("-");
        const formattedDate = dateStr && `${dateStr[2]}-${dateStr[1]}-${dateStr[0]}`;
        const dateStr2 = params?.createdAt?.slice(11, 19).split(":");
        const formattedDate2 = dateStr && `${dateStr2[0]}:${dateStr2[1]}`;

        return (
          <Link
            to={`/dashboard/tickets-support/tickets/${params.id}`}
            className="flex items-center gap-x-1 text-[14px]"
          >
            {formattedDate + ", " + formattedDate2}
          </Link>
        );
      },
      dataIndex: "createdAt",
    },
    {
      key: "dateOfCompletion",
      dataIndex: "dateOfCompletion",
      title: (
        <div className="flex items-center gap-x-1 text-[14px]">
          <CiCalendarDate className="w-5 h-5 text-yellow-500" />
          Completion Date
        </div>
      ),
      render: (text, params) => {
        console.log(params);
        const dateStr = params?.dateOfCompletion?.slice(0, 10)?.split("-");
        console.log(dateStr);
        const formattedDate = dateStr && `${dateStr[2]}-${dateStr[1]}-${dateStr[0]}`;
        console.log(formattedDate);
        return (
          <Link
            to={`/dashboard/tickets-support/tickets/${params.id}`}
            className="flex items-center gap-x-1 text-[14px]"
          >
            {formattedDate}
          </Link>
        );
      },
    },
    {
      key: "durationOfCompletion",
      title: (
        <div className="flex items-center gap-x-1 text-[14px]">
          <CiCalendarDate className="w-5 h-5 text-yellow-500" />
          Duration
        </div>
      ),
      render: (text, params) => {
        const formattedDuration = formatDuration(params?.durationOfCompletion);
        return (
          <Link
            to={`/dashboard/tickets-support/tickets/${params.id}`}
            className="flex items-center gap-x-1 text-[14px]"
          >
            {formattedDuration}
          </Link>
        );
      },
      dataIndex: "durationOfCompletion",
    },
    {
      key: "actions",
      title: (
        <div className="flex items-center gap-x-1 text-[14px]">
          <AiOutlineEye className="w-5 h-5 text-yellow-500" />
          Actions
        </div>
      ),
      render: (text, params) => (
        <Link to={`/dashboard/tickets-support/tickets/${params.id}`} className="flex items-center gap-x-1 text-[14px]">
          <AiOutlineEye className="w-5 h-5 text-yellow-500" />
          View
        </Link>
      ),
    },
  ];

  return (
    <div className="formPage-wrap">
      <div className="flex flex-wrap items-center  gap-2 mt-5">
        <Select
          isMulti
          value={selectedWebsites}
          onChange={handleWebsiteFilter}
          placeholder="Filter By Website"
          options={websitesArr}
          className="w-full sm:w-[300px] "
        />

        <Select
          onChange={handlePriorityFilter}
          placeholder="Filter by priority"
          options={priorityOptions}
          defaultValue={priorityParams ? { label: priorityParams, value: priorityParams } : "Filter by priority"}
          className="sm:w-max w-full capitalize"
        />
      </div>
      {canViewTickets ? (
        <ListFilters
          columns={columns}
          rows={rows}
          title={!softDelete ? "Soft Deleted Tickets List" : `${status.split("_").join(" ")} Tickets List`}
          currentPage={paginationModel.current}
          totalPages={count / paginationModel?.pageSize}
          listPageSize={paginationModel.pageSize}
          handlePagination={handleTableChange}
          rowKey="id"
        />
      ) : (
        <NoPermission />
      )}
    </div>
  );
}

export default ITTicketListTable;
