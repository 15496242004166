import React, { useEffect, useRef, useState } from "react";
import "./DateRange.scss";
import toast from "react-hot-toast";
import moment from "moment";
import $ from "jquery";
import "../StaticFormInput/StaticFromInput.css";

export default function DateRange({ dateRange, setDateRange, defaultStart, defaultEnd }) {
  const [fromDateDisabled, setFromDateDisabled] = useState(true);
  const [minDate, setMinDate] = useState(null);
  const [flag, setFlag] = useState(false);
  const fromDate = useRef();
  const toDate = useRef();

  function fromDateChange(e) {
    const startDate = fromDate.current.value;
    if (fromDateDisabled) {
      setFromDateDisabled(false);
    }
    setMinDate(startDate);

    setDateRange({ ...dateRange, startDate: moment(startDate) });
  }

  function toDateChange(e) {
    const startDate = fromDate.current.value;
    const endDate = toDate.current.value;
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    if (endDateObj <= startDateObj) {
      toast.error("start date must be before end date");
      e.target.value = null;
    } else {
      setDateRange({ ...dateRange, endDate: moment(endDate) });
    }
  }

  useEffect(() => {
    if (dateRange.startDate) {
      setFromDateDisabled(false);
      setMinDate(dateRange.startDate.format("YYYY-MM-DDTHH:mm"));
    }
  }, [dateRange]);

  useEffect(() => {
    // Initialize with the current date
    const currentDate = moment().format("YYYY-MM-DD");

    $(".dateFormat")
      .attr("placeholder", "YYYY-MM-DD")
      .on("change", function () {
        this.setAttribute("data-date", moment(this.value, "YYYY-MM-DD").format(this.getAttribute("data-date-format")));
      })
      .trigger("change");

    // Initial setting of data-date attribute
    if (!defaultStart && !defaultStart) {
      $(".dateFormat").attr("data-date", "YYYY-MM-DD");
    }

    $(".dateFormat").attr("placeholder", "YYYY-MM-DD");
  }, []);

  return (
    <div class="w-full flex max-md:flex-col gap-2 items-start  gap-x-5 border rounded-md p-3">
      <div className="flex items-center w-[200px] gap-x-1">
        <label for="fromDate" className="text-slate-800 font-semibold">
          From:
        </label>{" "}
        <br />
        <input
          // type="datetime-local"
          type="date"
          id="fromDate"
          data-date-format="DD/MM/YYYY"
          name="fromDate"
          ref={fromDate}
          onChange={fromDateChange}
          required
          className=" p-3 border rounded-md  floating-input custom-input w-full dateFormat  "
          defaultValue={defaultStart !== undefined && defaultStart.slice(0, 10)}
          // value={dateRange.startDate ? dateRange.startDate.format("YYYY-MM-DD") : null}
        />
      </div>
      <div className="flex  items-center gap-x-1 w-[200px]">
        <label for="toDate" className="text-slate-800 font-semibold">
          To:
        </label>{" "}
        <br />
        <input
          // type="datetime-local"
          type="date"
          id="toDate"
          name="toDate"
          data-date-format="DD/MM/YYYY"
          ref={toDate}
          disabled={fromDateDisabled}
          onChange={toDateChange}
          required
          className=" p-3 border rounded-md  floating-input custom-input w-full dateFormat  "
          min={minDate}
          defaultValue={defaultEnd !== undefined && defaultEnd.slice(0, 10)}
          // value={dateRange.endDate ? dateRange.endDate.format("YYYY-MM-DD") : null}
        />
      </div>
    </div>
  );
}
