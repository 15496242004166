import React, { useEffect, useState } from "react";
import { RiKeyboardLine } from "react-icons/ri";
import { FaArchive } from "react-icons/fa";
import { BsCardChecklist } from "react-icons/bs";
import { IoMdCreate } from "react-icons/io";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { Link } from "react-router-dom";

export default function CancellationPolicy() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["cancellation.add_cancellationpolicy", "cancellation.view_cancellationpolicy"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        <div className="w-full ">
          <div className="w-full flex justify-center   ">
            <h2 className="header-h2">
              <RiKeyboardLine className="w-5 h-5" />
              Cancellation Policy
            </h2>
          </div>
          <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 text-center sm:grid-cols-1 grid-cols-1 form-wrap">
            {permission.map((item) => {
              if (item.name === "cancellation.add_cancellationpolicy" && item.value === true) {
                return (
                  <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                    <Link
                      to={"./create-cancellation-policy"}
                      // onClick={() => navigate("./create-cancellation-policy")}
                      className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-[#0C4A6E] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                    >
                      <div className="w-full flex justify-center items-center ">
                        <IoMdCreate className="w-12 h-12  " />
                      </div>
                    </Link>
                    <p className="text-lg font-semibold">Create Cancellation Policy</p>
                  </div>
                );
              } else if (item.name === "cancellation.view_cancellationpolicy" && item.value === true) {
                return (
                  <>
                    <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                      <Link
                        to={"./cancellation-policy-list"}
                        // onClick={() => navigate("./cancellation-policy-list")}
                        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-[#5C4A6E] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        <div className="w-full flex justify-center items-center ">
                          <BsCardChecklist className="w-12 h-12  " />
                        </div>
                      </Link>
                      <p className="text-lg font-semibold">Cancellation Policy List</p>
                    </div>
                    <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                      <Link
                        to={"./cancellation-policy-archive"}
                        // onClick={() => navigate("./cancellation-policy-archive")}
                        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-[#5C9A6E] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        <div className="w-full flex justify-center items-center ">
                          <FaArchive className="w-12 h-12  " />
                        </div>
                      </Link>
                      <p className="text-lg font-semibold">Cancellation Policy Archive</p>
                    </div>
                  </>
                );
              }
            })}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
