import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../../redux/actions/loginAction";
import { Link, useNavigate, useParams } from "react-router-dom";
import cookie from "react-cookies";
import { clearBookingOrder, statusCardAction } from "../../../redux/actions/bookingOrderAction";
import { MdDashboard, MdOutlineBedroomParent } from "react-icons/md";
import { getCurrencyAction } from "../../../redux/actions/bookingListAction";
import { clearDataOfRoom, selectedSearchAction } from "../../../redux/actions/SelectedSearchAction";
import { IoLogOutOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import TopBarProgress from "react-topbar-progress-indicator";
import usePermissions from "../../../customHooks/usePermissions";
import { RiMenu5Fill } from "react-icons/ri";
import BookingOrder from "../../pages/BookingOrder/BookingOrder";
import { getPermissionAction } from "../../../redux/actions/permissionAction";

const Navbar = ({ setValue, setValuesArray }) => {
  const [navState, setNavState] = useState(false);

  const dispatch = useDispatch();
  const refreshToken = cookie.load("refresh_token");
  const accessToken = cookie.load("access_token");
  const navigate = useNavigate();
  const params = useParams();
  const token = cookie.load("access_token");
  const [t, i18n] = useTranslation();
  const localeCode = t("localeCode");
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [navabrToggle, setNavabrToggle] = useState(false);
  const currencySearch = cookie.load("currency");
  const [currencySelectImg, setCurrencySelectImg] = useState("/images/united-kingdom-uk-svgrepo-com.svg");
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [langToggle, setLangToggle] = useState(false);
  const [lang, setLang] = useState(cookie.load("language"));
  const { cardTotalAmount } = useSelector((state) => state.cardReducer);
  const { currencyList } = useSelector((state) => state.bookingListReducer);
  const { selectedDataFromForm } = useSelector((state) => state.SelectedSearchReducer);
  const finishResrvation = useSelector((state) => state.finishResrvationReducer.data);

  const flag = [
    "/images/united-kingdom-uk-svgrepo-com.svg",
    "/images/yemen-svgrepo-com.svg",
    "/images/flag-for-saudi-arabia-svgrepo-com.svg",
    "/images/united-arab-emirates-svgrepo-com.svg",
  ];
  const languageArray = ["EN", "AR"];
  useEffect(() => {
    dispatch(getCurrencyAction(token));
  }, []);

  const images = [
    "/images/0929c4be-08f9-41f7-8090-58b15a72f380.webp",
    "/images/photo-1569959220744-ff553533f492.avif",
    "/images/saubeiwtygxkp9oxju1l.webp",
    // Add more image URLs here
  ];

  const toggleDropdownStatus = () => {
    setIsOpenStatus(!isOpenStatus);
  };

  let currencyWithFlag = currencyList
    ? currencyList.map((item, index) => {
        return { value: currencyList[index], img: flag[index] };
      })
    : [];

  ////////// arabic language  for currency

  const logOuthandle = () => {
    setLoad(true);
    dispatch(
      logoutAction(accessToken, refreshToken, async (result) => {
        try {
          if (result.status === 205) {
            setLoad(false);
            // Clear cookies
            cookie.remove("access_token", { path: "/" });
            cookie.remove("refresh_token", { path: "/" });
            cookie.remove("currency", { path: "/" });
            cookie.remove("language", { path: "/" });
            cookie.remove("code", { path: "/" });

            // Navigate to login page
            navigate("/login");
          } else {
            // Handle other statuses or conditions if needed
            setLoad(false);
          }
        } catch (error) {}
      }),
    );
  };

  const [statusOfCard, setstatusOfCard] = useState(false);
  const { cardStatus } = useSelector((state) => state.cardReducer);

  const onNavScroll = () => {
    if (window.scrollY > 30) {
      setNavState(true);
    } else {
      setNavState(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onNavScroll);
    return () => {
      window.removeEventListener("scroll", onNavScroll);
    };
  }, []);
  const language = cookie.load("language");

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const handleResize = () => {
      // Check if the screen width is "md" based on Tailwind CSS classes
      if (window.innerWidth < 800) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };

    // Add a resize event listener to track changes in screen width
    window.addEventListener("resize", handleResize);

    // Initial check when the component is mounted
    handleResize();

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const filteredData = Object.entries(selectedDataFromForm)?.filter((item)=>item !== "city")
  const { max_price, min_price, rates, features, ...filteredData } = selectedDataFromForm;

  // permission
  const [permissions, permissionsFlag] = usePermissions(["permissions_management.main_dashboard"]);

  const [OpenNav, setOpenNav] = useState(false);

  const [permissionFlag, setpermissionFlag] = useState(false);
  const [permissionsError, setpermissionsError] = useState();
  const [permissionswhatsUpemail, setpermissionswhatsUpemail] = useState();
  const permissionData = {
    permission_codes: ["permissions_management.notification_whatsUp_email"],
  };

  const handleCard = () => {
    dispatch(statusCardAction(!statusOfCard));
    if (cardStatus) {
      setstatusOfCard(!statusOfCard);
    } else {
      setstatusOfCard(false);
    }
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermissionswhatsUpemail(result.data);
        } else {
          setpermissionsError(result);
        }
      }),
    );
  };

  return (
    <>
      <div dir={t("dir")} className="relative w-full">
        {/* nav mobile */}
        <nav className={`bg-[#002244] w-full p-3  md:hidden  items-center  relative flex  text-start justify-between `}>
          <RiMenu5Fill className=" text-2xl text-white font-semibold " onClick={() => setOpenNav(!OpenNav)} />

          <Link to={`${cookie.load("code") ? `/${cookie.load("code")}` : "/"}`} className=" w-[18%] h-auto">
            <img src="/images/logo2bg.png" className=" object-cover " />
          </Link>
        </nav>

        {/* nav desktop */}
        <nav
          className={`bg-[#002244] w-full md:h-12 h-[15rem] ${!OpenNav && "hidden"} md:flex  justify-end items-center  md:mt-0       mt-[-1%] `}
        >
          <div className="container flex flex-col md:flex-row justify-between md:items-center items-start">
            <Link to={`${cookie.load("code") ? `/${cookie.load("code")}` : "/"}`}>
              <img src="/images/logo2.png" className="w-0 h-0 md:w-36 md:h-36" />
            </Link>
            <div className="grid  grid-cols-1 justify-around  md:flex items-center gap-x-2 gap-2 ">
              {permissionsFlag ? (
                permissions.some((item) => item.value) && (
                  <Link
                    to={"/dashboard"}
                    class="inline-flex gap-x-1 items-center font-medium justify-center  py-2 text-sm text-gray-100 hover:text-white  cursor-pointer md:mr-5 "
                    onClick={() => {}}
                  >
                    <MdDashboard size={20} />
                    {t("dashboard")}
                  </Link>
                )
              ) : (
                <ButtonLoading />
              )}
              <div className="relative">
                <button
                  type="button"
                  data-dropdown-toggle="language-dropdown-menu"
                  class="inline-flex gap-x-1 items-center font-medium justify-center  py-2 text-sm text-gray-100 hover:text-white  cursor-pointer md:mr-5 "
                  onClick={() => setLangToggle(!langToggle)}
                >
                  {t("lan") === "en" ? (
                    <div className="flex items-center gap-x-1">
                      <img src="/images/icons8-english-48.png" className="w-6 h-6 " />
                      <p>EN</p>
                    </div>
                  ) : (
                    <div className="flex items-center gap-x-1">
                      <img src="/images/BevelledEgypt.svg" className="w-6 h-6 " />
                      <p>العربية</p>
                    </div>
                  )}
                  <svg
                    className="-mr-1  h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                {langToggle && (
                  <div className="origin-top-right absolute  mt-2 w-24 flex flex-col items-center justify-start gap-y-2 font-semibold rounded-sm py-1 shadow-lg bg-white  ring-1 ring-black ring-opacity-5 z-50">
                    <button
                      onClick={() => {
                        changeLanguage("en");
                        cookie.save("language", "en");
                        setLangToggle(!langToggle);
                        // dispatch(clearBookingOrder());
                        dispatch(clearDataOfRoom());
                        // setchangeLang(true);
                        if (Object.keys(filteredData).length !== 0) {
                          dispatch(selectedSearchAction(token, { ...filteredData }, "en"));
                        }
                      }}
                      className="flex items-center gap-x-1 hover:bg-gray-100 w-full justify-center text-sm"
                    >
                      <img src="/images/icons8-english-48.png" className="w-6 h-6 " />
                      <p className="w-[50%] ">English</p>
                    </button>
                    <button
                      onClick={() => {
                        changeLanguage("ar");
                        cookie.save("language", "ar");
                        setLangToggle(!langToggle);
                        // dispatch(clearBookingOrder());
                        dispatch(clearDataOfRoom());
                        // setchangeLang(true);
                        if (Object.keys(filteredData).length !== 0) {
                          dispatch(selectedSearchAction(token, { ...filteredData }, "ar"));
                        }
                      }}
                      className="flex items-center gap-x-1 hover:bg-gray-100 w-full justify-center text-sm"
                    >
                      <img src="/images/BevelledEgypt.svg" className="w-6 h-6 " />
                      <p className="w-[50%] ">العربية</p>
                    </button>
                  </div>
                )}
              </div>
              {finishResrvation && finishResrvation === true ? (
                ""
              ) : (
                <>
                  <div className="relative inline-block text-left ">
                    <div>
                      <button
                        onClick={toggleDropdownStatus}
                        type="button"
                        className="flex justify-center font-bold items-center py-2 rounded-sm  shadow-sm   text-sm  text-gray-300    hover:text-white  active:scale-95 duration-300 transition-all"
                      >
                        {currencySearch ? (
                          <p className="flex items-center gap-x-1 text-md">
                            <img
                              src={
                                currencySearch === "USD"
                                  ? flag[0]
                                  : currencySearch === "EGP"
                                    ? flag[1]
                                    : currencySearch === "SAR"
                                      ? flag[2]
                                      : flag[3]
                              }
                              className="w-5 h-5 rounded-full"
                            />
                            {t(currencySearch)}
                          </p>
                        ) : (
                          <p className="flex items-center gap-x-1 text-md">
                            <img src={flag[0]} className="w-5 h-5 rounded-full" />
                            {t("USD")}
                          </p>
                        )}
                        <svg
                          className=" h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>

                    {isOpenStatus && (
                      <div className="origin-top-right absolute right-0  mt-2 w-24 flex flex-col gap-y-1 items-start  justify-start rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                        <div className="py-1" role="menu" aria-orientation="horizontal" aria-labelledby="options-menu">
                          {currencyWithFlag &&
                            currencyWithFlag.map((item, index) => {
                              return (
                                <button
                                  className="w-full flex items-center justify-around px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  onClick={() => {
                                    setIsOpenStatus(!isOpenStatus);
                                    cookie.save("currency", item.value);
                                    setCurrencySelectImg(item.img);
                                    dispatch(clearBookingOrder());
                                    dispatch(clearDataOfRoom());
                                    setValue([0, 0]);
                                    setValuesArray([]);
                                    if (Object.keys(filteredData).length !== 0) {
                                      dispatch(
                                        selectedSearchAction(
                                          token,
                                          { ...filteredData, currency: item.value },
                                          t("lan"),
                                        ),
                                      );
                                    }
                                  }}
                                  key={index}
                                >
                                  <div className="flex items-center gap-x-1 w-full ">
                                    <img src={item.img} className="w-5 h-5  rounded-full" />
                                    <p className="text-gray-600 font-bold w-[80%]">{t(item.value)}</p>
                                  </div>
                                </button>
                              );
                            })}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}

              <button
                onClick={() => handleCard()}
                className=" py-2  text-xs  md:text-sm  font-semibold flex items-center md:justify-center justify-start gap-x-1 text-gray-300 rounded-md hover:scale-105 hover:text-white duration-300 transition-all active:scale-95"
              >
                <MdOutlineBedroomParent className="w-6 h-6 " />{" "}
                <div className=" flex items-center justify-center top-5  left-1 text-sm w-4 h-4 rounded-full   text-white">
                  {new Intl.NumberFormat(localeCode).format(cardTotalAmount)}
                </div>
              </button>

              <button
                className="md:px-5  py-2  text-xs  md:text-sm  font-semibold flex items-center gap-x-1 text-gray-300 rounded-md hover:scale-105 hover:text-white duration-300 transition-all active:scale-95"
                onClick={() => logOuthandle()}
                disabled={load}
                type="button"
              >
                {load ? (
                  <>
                    <TopBarProgress />
                    <ButtonLoading />
                  </>
                ) : (
                  <IoLogOutOutline className={`w-7 h-7 ${t("dir") === "en" ? "rotate-360" : "rotate-180"} `} />
                )}
              </button>
            </div>
          </div>
        </nav>
      </div>
      <BookingOrder
        permissionFlag={permissionFlag}
        permissionswhatsUpemail={permissionswhatsUpemail}
        permissionsError={permissionsError}
      />
    </>
  );
};

export default Navbar;
