import {
  FcAssistant,
  FcBusinessman,
  FcCollaboration,
  FcDam,
  FcHeadset,
  FcInfo,
  FcKindle,
  FcMoneyTransfer,
  FcOvertime,
  FcPlus,
  FcPodiumWithAudience,
  FcRules,
  FcSalesPerformance,
  FcServices,
  FcSettings,
  FcSms,
  FcSteam,
  FcSupport,
} from "react-icons/fc";
import { IoMdAirplane } from "react-icons/io";
import { IoFastFoodSharp } from "react-icons/io5";

function removeSpaces(sentence) {
  let arr = sentence.toLowerCase().split(" ");
  return arr
    .join("_")
    .replace(/[\(\)-]/g, "")
    .replace(/&/g, "and");
}

const Menus = [
  {
    title: "General Setting",
    src: FcServices,
    submenu: true,
    allSubmenuItems: ["Currency Rate", "Markup Control", "Whitelist IP", "Email Settings", "Permissions", "Banner"],
    submenuItems: [],
  },
  {
    title: "CRM",
    src: FcAssistant,
    submenu: true,
    allSubmenuItems: ["Client Calls", "Agents calls", "Call center calls", "Call center WhatsApp"],
    submenuItems: [],
  },
  {
    title: "Marketing",
    src: FcCollaboration,
    submenu: true,
    allSubmenuItems: ["Whatsapp", "SMS", "Emails"],
    submenuItems: [],
  },
  {
    title: "System Notifications",
    src: FcSms,
    submenu: true,
    allSubmenuItems: [
      "Booking Option Date",
      "Booking Pending Payments ",
      "Booking Pending Hotel Confirmation",
      "Same Day Bookings",
      "Bookings Due Out",
    ],
    submenuItems: [],
  },
  {
    title: "B2B Portal",
    src: FcHeadset,
    submenu: true,
    allSubmenuItems: ["Add Agent", "Agents List", "Agents Accounting", "Agent Reports"],
    submenuItems: [],
  },
  {
    title: "B2C Portal",
    src: FcBusinessman,
    submenu: true,
    allSubmenuItems: ["Add Client", "Client List", "Client Accounting", "Client Reports"],
    submenuItems: [],
  },
  {
    title: "Hotel Settings",
    src: FcDam,
    submenu: true,
    allSubmenuItems: [
      "Add Hotel",
      "Hotel List",
      "Preferred Hotels",
      "Room List",
      "Add Room",
      "Add Hotel Info",
      "Room Packages",
      "Upgrade Tool",
    ],
    submenuItems: [],
  },
  {
    title: "Sales",
    src: FcSalesPerformance,
    submenu: true,
    allSubmenuItems: ["Request List", "Client Follow Up", "Waiting List Clients", "Special Rates Requests"],
    submenuItems: [],
  },
  {
    title: "Contract",
    src: FcRules,
    submenu: true,
    allSubmenuItems: [
      "Add Contract",
      "Import & Edit Contract",
      "Contract List",
      "Contract Approval",
      "Contract Archive",
      "Deleted Contract",
      "Price Test Tool",
      "Inventory",
      "Offers",
      "Cancellation Policy",
    ],
    submenuItems: [],
  },
  {
    title: "Hotel Operations",
    src: FcSteam,
    submenu: true,
    allSubmenuItems: ["Booking List", "Bookings Hub", "Cancelled Bookings", "Booking Archive"],
    submenuItems: [],
  },
  {
    title: "Hotel Info",
    src: FcInfo,
    submenu: true,
    allSubmenuItems: [
      "Hotel Main Info",
      "Hotel Bank Account",
      "Sales Team Contacts",
      "Reservations Contacts",
      "Credit Team Contacts",
      "Front Desk Contacts",
      "Accounting Contacts",
    ],
    submenuItems: [],
  },
  {
    title: "Accounting",
    src: FcMoneyTransfer,
    submenu: true,
    allSubmenuItems: [
      "Accounting Setting",
      "Bank Transfers",
      "Cash Boxes Statment",
      "Credit Hotels",
      // "Income",
      "POS (VISA)",
      "E Wallet",
      // "All Transactions",
      "Refund Requests",
      "Collection Requests",
      "Hotel Payment Orders",
      "Expenses",
      "Other Income",
    ],
    submenuItems: [],
  },
  {
    title: "Statistics & Reports",
    src: FcKindle,
    submenu: true,
    allSubmenuItems: [
      "General Statistics",
      "Contracts Statistics",
      "Bookings Statistics",
      "Hotels Statistics",
      "Agents Profit Report",
      "Accounting Report",
      "Hotels Debits Report",
      "Uploading Team Reports",
      "Create Link Statistics",
      "Cancellation Report",
    ],
    submenuItems: [],
  },
  {
    title: "Extras",
    src: FcPlus,
    submenu: true,
    allSubmenuItems: ["Create Link", "Complain Request"],
    submenuItems: [],
  },
  { title: "Team Food Request", src: IoFastFoodSharp, submenu: true, allSubmenuItems: [], submenuItems: [] },
  {
    title: "IT Department",
    src: FcSupport,
    submenu: true,
    allSubmenuItems: ["Tickets Support"],
    submenuItems: [],
  },
  {
    title: "Assets Management",
    src: FcOvertime,
    submenu: true,
    allSubmenuItems: [""],
    submenuItems: [],
  },
  {
    title: "Airport Services",
    src: FcSettings,
    submenu: true,
    allSubmenuItems: ["Meet & Assist Service"],
    submenuItems: [],
  },
  { title: "Airline Tickets", src: IoMdAirplane, submenu: true, allSubmenuItems: [], submenuItems: [] },
  {
    title: "Staff Management",
    src: FcPodiumWithAudience,
    submenu: true,
    allSubmenuItems: ["Departments Management", "Staff Users", "Team Workforce", "Task Manager"],
    submenuItems: [],
  },
];

export default function generateMenu(allowedMenu) {
  if (allowedMenu === null) {
    return [];
  }
  // console.log(
  //   "doensd",
  //   Menus.filter((menu) => {
  //     return allowedMenu[removeSpaces(menu.title)];
  //   }),
  // );
  // to check if which found in menus matches with allowedMenu
  let firstfilter = Menus?.filter((menu) => {
    return allowedMenu[removeSpaces(menu.title)];
  });

  firstfilter.forEach((menuItem) => {
    menuItem.submenuItems = menuItem.allSubmenuItems.filter((item) => allowedMenu[removeSpaces(item)]);
  });

  return firstfilter;
}
