import React, { useEffect, useState } from "react";
import {
  updatedAreaAction,
  updatedDestinationAction,
  updatedHotelAction,
  updatedNationalityAction,
  updateMarkupAction,
} from "../../../redux/actions/searchformAction";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { clearDataOfRoom, selectedSearchAction } from "../../../redux/actions/SelectedSearchAction";
import { GetDataFromSearchForReseve } from "../../../redux/actions/reservationAction";
import DateRangePickerComponent from "../calendar/calendar";
import Travel from "../../travell";
import { BiSearchAlt2 } from "react-icons/bi";
import { GoSearch } from "react-icons/go";
import { useTranslation } from "react-i18next";
import jwtDecode from "jwt-decode";
import { dataOfWhatsApp } from "../../../redux/actions/dataOfWhatsApp";

const SearchForm = ({ setValue, setValuesArray, setStar, setSearchFlag, setCityFormHotel }) => {
  //Get last params to check if user or guest
  const location = useLocation();
  const currentPath = location.pathname;
  const parts = currentPath.split("/");
  const lastParams = parts[parts.length - 1];

  const { dataFromSearchForReseve } = useSelector((state) => state.reservationReducer);
  const { destinationData } = useSelector((state) => state.SearchFormReducer);
  const { areaData } = useSelector((state) => state.SearchFormReducer);
  const { hotelData } = useSelector((state) => state.SearchFormReducer);
  const { markupCheck } = useSelector((state) => state.SelectedSearchReducer);
  const { defaultAreaSearch } = useSelector((state) => state.SelectedSearchReducer);
  const { defaultHotelSearch } = useSelector((state) => state.SelectedSearchReducer);
  const { roomDetails } = useSelector((state) => state.SelectedSearchReducer);
  const { selectedDataFromForm } = useSelector((state) => state.SelectedSearchReducer);

  const [t, i18n] = useTranslation();
  const params = useParams();
  const decoded = jwtDecode(cookie.load("access_token"));
  /// Default Value oF form came from first page or first form

  let destinationsDefault;
  let destinationsDefaultId;
  if (dataFromSearchForReseve.city) {
    destinationsDefault =
      destinationData && destinationData.filter((item) => item.id === dataFromSearchForReseve.city)[0]?.name;
    destinationsDefaultId =
      destinationData && destinationData.filter((item) => item.id === dataFromSearchForReseve.city)[0]?.id;
  }
  // } else {
  //   destinationsDefault = dataFromSearchForReseve.city;
  // }

  let areaDefault;
  let areaDefaultId;
  let areaDefaultArr;
  if (dataFromSearchForReseve.area) {
    areaDefault =
      areaData &&
      areaData.filter((item) => {
        if (dataFromSearchForReseve.area === JSON.stringify([item.id])) {
          return item;
        }
      });
    // areaDefaultArr = areaDefault.map((item) => {
    //   return { value: item.id, label: item.name };
    // });
    areaDefaultId =
      areaData &&
      areaData.filter((item) => {
        if (dataFromSearchForReseve.area === JSON.stringify([item.id])) {
          return item;
        }
      });
  }

  // } else {
  //   areaDefault = dataFromSearchForReseve.area;
  // }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [flag, setFlag] = useState([]);
  const [toggle, setToggle] = useState(true);
  const token = cookie.load("access_token");
  const currencySearch = cookie.load("currency");

  // store data of form

  const [formDes, setFormDes] = useState(destinationsDefaultId);
  const [formDesLoading, setFormDesLoading] = useState(false);
  const [formDesLabel, setFormDesLabel] = useState(destinationsDefault);
  const [formDesLabelLoading, setFormDesLabelLoading] = useState(false);
  const [formArea, setFormArea] = useState(dataFromSearchForReseve && dataFromSearchForReseve.area);
  const [formAreaLabel, setFormAreaLabel] = useState(dataFromSearchForReseve && dataFromSearchForReseve.areaName);
  const [formApi, setFormApi] = useState(true);
  const [formAreaLabelLoading, setFormAreaLabelLoading] = useState(false);
  const [formHotelId, setFormHotelId] = useState(dataFromSearchForReseve && dataFromSearchForReseve.hotel);
  const [formHotelLabel, setFormHotelLabel] = useState(dataFromSearchForReseve && dataFromSearchForReseve.hotelName);
  //// Nationality ////

  const [formNation, setFormNation] = useState(
    cookie.load("code")
      ? null
      : dataFromSearchForReseve.length === 0
        ? "Saudi Arabia"
        : dataFromSearchForReseve && dataFromSearchForReseve.nationality,
  );
  const [formNationLabel, setFormNationLabel] = useState(
    cookie.load("code")
      ? null
      : dataFromSearchForReseve.length === 0
        ? "Saudi Arabia"
        : dataFromSearchForReseve && dataFromSearchForReseve.nationality,
  );
  const [formNationImg, setFormNationImg] = useState(
    cookie.load("code")
      ? null
      : dataFromSearchForReseve.length === 0
        ? "https://flagcdn.com/w320/sa.png"
        : dataFromSearchForReseve && dataFromSearchForReseve.nationalityImg,
  );
  const [nationFlag, setNationFlag] = useState(false);
  const [formCheckIn, setFormCheckIn] = useState(dataFromSearchForReseve && dataFromSearchForReseve.from_date);
  const [formCheckOut, setFormCheckOut] = useState(dataFromSearchForReseve && dataFromSearchForReseve.to_date);
  const [person, setPerson] = useState();
  const [adult, setAdultt] = useState([]);
  const [formRoom, setFormRooms] = useState(1);
  const [formkids, setFormKids] = useState(0);
  const [nights, setNights] = useState(1);
  const [validation, setValidation] = useState(false);
  const [details, setDetails] = useState();
  const [adultPerson, setAdultPerson] = useState(1);
  const [markup, setMarkp] = useState();
  const [markupId, setMarkupId] = useState(dataFromSearchForReseve && dataFromSearchForReseve.markup);
  const [markupLable, setMarkupLable] = useState(dataFromSearchForReseve && dataFromSearchForReseve.markupName);
  const [markupLoading, setMarkupLoading] = useState(false);
  ///// i use this to know if  lang change or not to make select input is empty
  const { nationalities } = useSelector((state) => state.SearchFormReducer);

  //Validation Form

  const [errors, setErrors] = useState({
    destination: "",
    area: "",
    formCheckIn: "",
    formCheckOut: "",
  });

  const flagURL = `https://restcountries.com/v3.1/all`;

  useEffect(() => {
    if ((nights, person && formCheckOut && formCheckIn)) {
      dispatch(dataOfWhatsApp(person, formCheckOut, formCheckIn, nights));
    }
  }, [person, formCheckOut, formCheckIn]);

  useEffect(() => {
    // setFormDesLabel("");
    // setFormAreaLabel("");
    ////////lang Destination //////////////

    setFormDesLoading(true);
    dispatch(
      updatedDestinationAction(token, t("lan"), cookie.load("code"), (result) => {
        if (result.status === 200) {
          setFormDesLoading(false);
          setFormDesLabel(result.data.filter((item) => item.id === formDes)[0].name);
        }
      }),
    );
    ////////////////// lang Area ////////
    if (formDes) {
      setFormDesLabelLoading(true);
      dispatch(
        updatedAreaAction(token, formDes, t("lan"), cookie.load("code"), (result) => {
          if (result.status === 200) {
            // const newData = [...result.data, { id: "all", name: t("allArea") }];
            //

            if (formArea) {
              if (formArea === "all") {
                setFormDesLabelLoading(false);
                setFormAreaLabel(t("allArea"));
              } else {
                setFormDesLabelLoading(false);
                const FilterArea = result.data.find((item) => item.id === JSON.parse(formArea)[0]).name;

                setFormAreaLabel(FilterArea);
              }
            }
          }
        }),
      );
    }

    /////handle lang of hotel ////
    if (formArea) {
      setFormAreaLabelLoading(true);
      dispatch(
        updatedHotelAction(
          token,
          formArea,
          t("lan"),
          cookie.load("code"),
          (result) => {
            if (result.status === 200) {
              setFormAreaLabelLoading(false);
              setFormHotelLabel(result.data.filter((item) => item.id === formHotelId)[0].name);
            }
          },
          formDes,
        ),
      );
    }

    // handle change lang of nationality
    if (formNation) {
      setNationFlag(true);
      dispatch(
        updatedNationalityAction(token, t("lan"), cookie.load("code"), (result) => {
          if (result.status === 200) {
            setNationFlag(false);
            setFormNationLabel(
              result.data.filter((item) => item.name_en === formNation || item.name_ar === formNation)[0].name,
            );
            // setFormNationImg(
            //   result.data.filter((item) => item.name_en === formNation || item.name_ar === formNation)[0].flag
            // );
          }
        }),
      );
    }

    // handle change lang of markup
    setMarkupLoading(true);
    dispatch(
      updateMarkupAction(
        token,
        cookie.load("code"),
        (result) => {
          if (result.status === 200) {
            setMarkupLoading(false);
            setMarkp(result.data);
            setMarkupLable(result.data.filter((item) => item.id === markupId)[0].name);
          }
        },
        t("lan"),
      ),
    );

    // setFormNation("");
    // getFlag();
    // setFormNation(t("lan") === "en" ? "Saudi Arabia" : "المملكة العربية السعودية");
  }, [cookie.load("language"), t("lan")]);

  useEffect(() => {
    // setFormDesLabel(destinationsDefault);
    // setFormAreaLabel(defaultAreaSearch);
    if (dataFromSearchForReseve.city) {
      dispatch(updatedAreaAction(token, dataFromSearchForReseve.city, t("lan"), cookie.load("code")));
    }
    if (dataFromSearchForReseve.area) {
      dispatch(updatedHotelAction(token, dataFromSearchForReseve.area, t("lan"), cookie.load("code")));
    }
    dispatch(updatedNationalityAction(token, t("lan"), cookie.load("code")));
  }, []);

  let destination = destinationData
    ? destinationData.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];

  //  let area = areaData ? areaData.map((item ,index) =>{
  //   return{value:index , label:item}
  //  }) : []
  let area = areaData
    ? areaData.map((item, index) => {
        return { value: JSON.stringify([item.id]), label: item.name };
      })
    : [];

  let hotel = hotelData
    ? hotelData.map((item) => {
        return { value: item.id, label: item.name };
      })
    : [];

  let markupList = markup
    ? markup.map((item) => {
        return { value: item.id, label: item.name };
      })
    : [];

  //    let hotel = hotelData ? hotelData.map((item) =>{
  //     return ({value:item , label:item})
  //  }):[]

  let detailsArray = [];

  if (details) {
    details.map((item) => {
      detailsArray.push(item.adults);
    });
  }

  // const arrayParam =  detailsArray.join(',');
  const selectedData = {
    city: formDes,
    area: cookie.load("code") ? "all" : formArea ? formArea : dataFromSearchForReseve && dataFromSearchForReseve.area,
    from_date: formCheckIn,
    to_date: formCheckOut,
    nights: nights,
    persons: JSON.stringify(detailsArray),
    country: cookie.load("code") ? nationalities && nationalities[0]?.name_en : formNation,
    currency: currencySearch,
    markup: cookie.load("code") ? markup && markup[0]?.id : markupId,
    hotel: formHotelId,
    unique_code: cookie.load("code"),
    people: JSON.stringify(details),
  };

  const selectedDataForReseve = {
    city: formDes,
    area: cookie.load("code") ? "all" : formArea ? formArea : dataFromSearchForReseve && dataFromSearchForReseve.area,
    from_date: formCheckIn,
    to_date: formCheckOut,
    nights: nights,
    persons: JSON.stringify(detailsArray),
    nationality: formNation,
    details: details,
    formRoom: formRoom,
    markup: cookie.load("code") ? markup && markup[0]?.id : markupId,
    hotel: formHotelId,
    unique_code: cookie.load("code"),
    people: JSON.stringify(details),
  };

  let flagData = nationalities
    ? nationalities.map((item) => {
        return { value: item.name_en, label: item.name, image: item.flag };
      })
    : [];

  let personArr = [];

  console.log(details, "detailsArraydd");

  // useEffect(() => {
  //   const handleResize = () => {
  //     // Check if the screen width is "md" based on Tailwind CSS classes
  //     if (window.innerWidth < 1024) {
  //       setToggle(true);
  //     }
  //   };

  //   // Add a resize event listener to track changes in screen width
  //   window.addEventListener("resize", handleResize);

  //   // Initial check when the component is mounted
  //   handleResize();

  //   // Clean up the event listener when the component is unmounted
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      try {
        // Check if the screen width is "md" based on Tailwind CSS classes
        if (window.innerWidth < 1024) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      } catch (error) {}
    };

    // Add a resize event listener to track changes in screen width
    window.addEventListener("resize", handleResize);

    // Initial check when the component is mounted
    handleResize();

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    //  setCurrencySelect(params.currency)

    setSearchFlag(true);
    setValue([0, 0]);
    setValuesArray([]);
    setStar();
    if (formDes == null && formArea == null && formCheckIn == null && formCheckOut == null) {
      setValidation(true);
    } else {
      dispatch(clearDataOfRoom());
      setValidation(false);
      dispatch(selectedSearchAction(token, selectedData, t("lan")));
      dispatch(GetDataFromSearchForReseve(selectedDataForReseve));
      // navigate('/hotels' , { replace: true } );
    }
    if (open) {
      setToggle(!toggle);
    }
  };

  useEffect(() => {
    if ((destinationData && formDesLoading === false) || selectedDataFromForm) {
      setCityFormHotel({ formDesLabel: formDesLabel, formDesLoading: formDesLoading });
    }
  }, [destinationData, formDesLoading, selectedDataFromForm]);

  return (
    <div className=" z-50 w-full flex flex-col items-center justify-center relative ">
      <button
        onClick={() => setToggle(!toggle)}
        className="flex items-center justify-center text-sm mx-auto gap-x-1 w-[90%] rounded-lg bg-[#002244] text-white p-2 lg:hidden"
      >
        {t("search")} <BiSearchAlt2 className="w-5 h-5" />
      </button>
      {toggle ? (
        <form
          dir={t("dir")}
          class={`font-barlow absolute top-5 bg-white  border    shadow-lg   sm:w-full lg:p-3 lg:px-10  lg:rounded-full 
           lg:grid sm:grid-cols-2 ${cookie.load("code") ? "lg:grid-cols-2" : "lg:grid-cols-4"}    hidden`}
          onSubmit={(e) => handleSubmit(e)}
        >
          <div class="flex flex-col items-center justify-between px-3 py-2  border-solid">
            {/* <input data-toggle = "datepicker" type = "text" class = "placeholder:text-eerie-black placeholder:uppercase text-sm outline-none border-none w-full" placeholder="check in"/> */}
            {/* <span class="cursor-pointer">
                                        <img src = "/images/calendar.svg"/>
                                    </span> */}
            <Select
              className="w-full text-gray-800 "
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: "md:18px ",
                  fontWeight: "bold",
                  color: "red",
                  backgroundColor: "white",
                  fontStyle: "bold",

                  borderBottom: "2px thin gray",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  boxShadow: "none",
                  borderRadius: "0",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "#002244", // Change this to the desired color for the placeholder
                }),
              }}
              name="destination"
              value={formDesLoading ? null : formDesLabel && [{ label: formDesLabel, value: formDes }]}
              options={formDesLoading ? [] : destination}
              onChange={(e) => {
                setFormDesLabelLoading(true);
                setFormDes(e.value);
                setFormDesLabel(e.label);
                setFormAreaLabel("");
                setFormArea("");
                setFormHotelId("");
                setFormApi(true);
                if (defaultAreaSearch) {
                  defaultAreaSearch[0] = null;
                }
                if (defaultHotelSearch) {
                  defaultHotelSearch[0] = null;
                }
                dispatch(
                  updatedAreaAction(token, e.value, t("lan"), cookie.load("code"), (result) => {
                    if (result.status === 200) {
                      setFormDesLabelLoading(false);
                    }
                  }),
                );
              }}
              required
              isSearchable
              placeholder={
                formDesLoading
                  ? t("lan") === "en"
                    ? "Loading..."
                    : "جاري التحميل..."
                  : t("lan") === "en"
                    ? "Destination"
                    : "المدينة"
              }
            />
            {/* <span className='text-lion lg:text-sm'>Destination is Requierd</span> */}
          </div>
          {!cookie.load("code") && (
            <div class="flex flex-col items-center justify-between px-3 py-2  border-solid">
              {/* <input data-toggle = "datepicker" type = "text" class = "placeholder:text-eerie-black placeholder:uppercase text-sm outline-none border-none w-full" placeholder="check in"/> */}
              {/* <span class="cursor-pointer">
                                    <img src = "/images/calendar.svg"/>
                                </span> */}
              <Select
                className="w-full text-gray-800 "
                name="area"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "md:18px ",
                    fontWeight: "bold",
                    color: "red",
                    backgroundColor: "white",
                    fontStyle: "bold",

                    borderBottom: "2px thin gray",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderRadius: "0",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#002244", // Change this to the desired color for the placeholder
                  }),
                }}
                value={formDesLabelLoading ? null : formAreaLabel && [{ label: formAreaLabel, value: formArea }]}
                options={
                  formDesLabelLoading ? [] : formDesLabel ? [{ label: t("allArea"), value: "all" }, ...area] : []
                }
                onChange={(e) => {
                  setFormAreaLabelLoading(true);
                  setFormArea(e.value);
                  setFormAreaLabel(e.label);
                  setFormHotelId("");
                  setFormHotelLabel("");
                  if (defaultHotelSearch) {
                    defaultHotelSearch[0] = null;
                  }
                  dispatch(
                    updatedHotelAction(
                      token,
                      e.value,
                      t("lan"),
                      cookie.load("code"),
                      (result) => {
                        if (result.status === 200) {
                          setFormAreaLabelLoading(false);
                        }
                      },
                      formDes,
                    ),
                  );
                  // else {
                  //     // Handle the case where selectedValues is empty (cleared selection)
                  //     setFormAreaLabelLoading(false);
                  //     setFormHotelId("");
                  //   }
                }}
                required
                isSearchable
                placeholder={
                  formDesLabelLoading
                    ? t("lan") === "en"
                      ? "Loading..."
                      : "جاري التحميل..."
                    : t("lan") === "en"
                      ? "Area"
                      : "المنطقة"
                }
              />

              {/* <span className='text-lion lg:text-sm'>Area is Requierd</span> */}
            </div>
          )}
          {!cookie.load("code") && (
            <div class="flex flex-col items-center justify-between px-3 py-2  border-solid">
              {/* <input data-toggle = "datepicker" type = "text" class = "placeholder:text-eerie-black placeholder:uppercase text-sm outline-none border-none w-full" placeholder="check in"/> */}
              {/* <span class="cursor-pointer">
                                    <img src = "/images/calendar.svg"/>
                                </span> */}
              <Select
                className="w-full"
                name="hotel"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "md:18px ",
                    fontWeight: "bold",
                    color: "red",
                    backgroundColor: "white",
                    fontStyle: "bold",

                    borderBottom: "2px thin gray",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderRadius: "0",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#002244", // Change this to the desired color for the placeholder
                  }),
                }}
                value={formAreaLabelLoading ? null : formHotelLabel && [{ label: formHotelLabel, value: formHotelId }]}
                options={formAreaLabelLoading ? [] : formAreaLabel ? hotel : []}
                onChange={(e) => {
                  if (e) {
                    setFormHotelId(e.value);
                    setFormHotelLabel(e.label);
                  } else {
                    setFormHotelId(null); // Clear the selection if the user clears the input
                    setFormHotelLabel(null);
                  }
                }}
                isClearable
                isSearchable
                placeholder={
                  formAreaLabelLoading
                    ? t("lan") === "en"
                      ? "Loading..."
                      : "جاري التحميل..."
                    : t("lan") === "en"
                      ? "Hotels"
                      : "الفنادق"
                }
              />
            </div>
          )}
          {!cookie.load("code") && (
            <div class="flex flex-col items-center justify-between px-3 py-2  border-solid">
              {/* <input data-toggle = "datepicker" type = "text" class = "placeholder:text-eerie-black placeholder:uppercase text-sm outline-none border-none w-full" placeholder="check in"/> */}
              {/* <span class="cursor-pointer">
                                    <img src = "/images/calendar.svg"/>
                                </span> */}
              <Select
                className="w-full z-50 "
                name="Nationality"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "md:18px ",
                    fontWeight: "bold",
                    color: "red",
                    backgroundColor: "white",
                    fontStyle: "bold",

                    borderBottom: "2px thin gray",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderRadius: "0",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#002244", // Change this to the desired color for the placeholder
                  }),
                }}
                options={flagData}
                value={
                  nationFlag
                    ? null
                    : formNation && [
                        {
                          value: formNation,
                          label: formNationLabel,
                          image: formNationImg,
                        },
                      ]
                }
                onChange={(e) => {
                  setFormNation(e.value);
                  setFormNationLabel(e.label);
                  setFormNationImg(e.image);
                }}
                formatOptionLabel={(flagData) => (
                  <div className="flex justify-start items-center  gap-x-3 country-option ">
                    <img src={flagData.image} alt="country-image" className="w-5 h-3" />
                    <span>{flagData.label}</span>
                  </div>
                )}
                isSearchable
                required
                placeholder={nationFlag ? (t("lan") === "en" ? "Loading..." : "جاري التحميل...") : "Nationality"}
              />
            </div>
          )}
          <div class="flex flex-col items-center justify-between px-3 py-2  border-solid">
            <Travel
              setPerson={setPerson}
              setFormRooms={setFormRooms}
              setDetails={setDetails}
              setFormKids={setFormKids}
              setAdultPerson={setAdultPerson}
            />
          </div>

          <div class="flex items-center justify-start gap-x-5 px-3 lg:items-start  lg:col-span-3 py-2  border-solid ">
            <DateRangePickerComponent
              setFormCheckIn={setFormCheckIn}
              setFormCheckOut={setFormCheckOut}
              setNights={setNights}
            />

            <div
              class={`w-[50%] flex items-center ${
                cookie.load("code") ? " justify-end" : " justify-between"
              } gap-x-5  border-solid `}
            >
              {!cookie.load("code") && (
                <div className="w-[70%]">
                  <Select
                    className="w-full "
                    isClearable
                    options={markup ? markupList : []}
                    value={markupLoading ? null : markupLable && [{ label: markupLable, value: markupId }]}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: "md:18px ",
                        fontWeight: "bold",
                        color: "red",
                        backgroundColor: "white",
                        fontStyle: "bold",

                        borderBottom: "2px thin gray",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        boxShadow: "none",
                        borderRadius: "0",
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#002244", // Change this to the desired color for the placeholder
                      }),
                    }}
                    onChange={(e) => {
                      if (e) {
                        setMarkupId(e.value);
                        setMarkupLable(e.label);
                      } else {
                        // if (markupCheck) {
                        //   markupCheck[0] = null;
                        // }
                        setMarkupId(null);
                        setMarkupLable(null);
                      }
                    }}
                    required
                    isSearchable
                    placeholder={
                      markupLoading
                        ? t("lan") === "en"
                          ? "Loading..."
                          : "جاري التحميل..."
                        : t("lan") === "en"
                          ? "Markup"
                          : "سياسة التسعير"
                    }
                  />
                </div>
              )}

              <button
                type="submit"
                className=" bg-[#002244]  text-sm px-2 py-1 z-0   tracking-widest   rounded-md font-barlow  flex items-center justify-center gap-x-1 uppercase text-white transition duration-300   active:scale-95 hover:bg-yellow-600"
              >
                <GoSearch className="w-5 h-6" /> {t("search")}
              </button>
            </div>
          </div>
        </form>
      ) : (
        <form
          dir={t("dir")}
          class="font-barlow p-5  bg-white shadow-lg  w-full   lg:p-3  lg:px-10  lg:rounded-full  lg:grid grid-cols-2 lg:grid-cols-4  "
          onSubmit={(e) => handleSubmit(e)}
        >
          <div class="lg:hidden  mt-[-6px] rounded-md border-8 border-[#002244] border-solid flex flex-col  items-center justify-between px-3 py-2  ">
            <Select
              className="w-full text-gray-800"
              name="destination"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: "md:18px ",
                  fontWeight: "bold",
                  color: "red",
                  backgroundColor: "white",
                  fontStyle: "bold",

                  borderBottom: "2px thin gray",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  boxShadow: "none",
                  borderRadius: "0",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "#002244", // Change this to the desired color for the placeholder
                }),
              }}
              value={formDesLoading ? null : formDesLabel && [{ label: formDesLabel, value: formDes }]}
              options={formDesLoading ? [] : destination}
              onChange={(e) => {
                setFormDesLabelLoading(true);
                setFormDes(e.value);
                setFormDesLabel(e.label);
                setFormAreaLabel("");
                setFormArea("");
                setFormHotelId("");
                setFormApi(true);
                if (defaultAreaSearch) {
                  defaultAreaSearch[0] = null;
                }
                if (defaultHotelSearch) {
                  defaultHotelSearch[0] = null;
                }
                dispatch(
                  updatedAreaAction(token, e.value, t("lan"), cookie.load("code"), (result) => {
                    if (result.status === 200) {
                      setFormDesLabelLoading(false);
                    }
                  }),
                );
              }}
              required
              isSearchable
              placeholder={
                formDesLoading
                  ? t("lan") === "en"
                    ? "Loading..."
                    : "جاري التحميل..."
                  : t("lan") === "en"
                    ? "Destination"
                    : "المدينة"
              }
            />
            {/* <span className='text-lion lg:text-sm'>Destination is Requierd</span> */}
          </div>
          {!cookie.load("code") && (
            <div class="mt-[-6px] rounded-md border-8 border-[#002244] border-solid flex flex-col items-center justify-between px-3 py-2  ">
              <Select
                className="w-full text-gray-800 "
                name="area"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "md:18px ",
                    fontWeight: "bold",
                    color: "red",
                    backgroundColor: "white",
                    fontStyle: "bold",

                    borderBottom: "2px thin gray",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderRadius: "0",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#002244", // Change this to the desired color for the placeholder
                  }),
                }}
                value={formDesLabelLoading ? null : formAreaLabel && [{ label: formAreaLabel, value: formArea }]}
                options={
                  formDesLabelLoading ? [] : formDesLabel ? [{ label: t("allArea"), value: "all" }, ...area] : []
                }
                onChange={(e) => {
                  setFormAreaLabelLoading(true);
                  setFormArea(e.value);
                  setFormAreaLabel(e.label);
                  setFormHotelId("");
                  setFormHotelLabel("");
                  if (defaultHotelSearch) {
                    defaultHotelSearch[0] = null;
                  }
                  dispatch(
                    updatedHotelAction(
                      token,
                      e.value,
                      t("lan"),
                      cookie.load("code"),
                      (result) => {
                        if (result.status === 200) {
                          setFormAreaLabelLoading(false);
                        }
                      },
                      formDes,
                    ),
                  );
                }}
                required
                isSearchable
                placeholder={
                  formDesLabelLoading
                    ? t("lan") === "en"
                      ? "Loading..."
                      : "جاري التحميل..."
                    : t("lan") === "en"
                      ? "Area"
                      : "المنطقة"
                }
              />
              {/* <span className='text-lion lg:text-sm'>Area is Requierd</span> */}
            </div>
          )}
          {!cookie.load("code") && (
            <div class="mt-[-6px] rounded-md border-8 border-[#002244] border-solid flex flex-col items-center justify-between px-3 py-2  ">
              <Select
                className="w-full"
                name="hotel"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "md:18px ",
                    fontWeight: "bold",
                    color: "red",
                    backgroundColor: "white",
                    fontStyle: "bold",

                    borderBottom: "2px thin gray",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderRadius: "0",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#002244", // Change this to the desired color for the placeholder
                  }),
                }}
                value={formAreaLabelLoading ? null : formHotelLabel && [{ label: formHotelLabel, value: formHotelId }]}
                options={formAreaLabelLoading ? [] : formAreaLabel ? hotel : []}
                onChange={(e) => {
                  if (e) {
                    setFormHotelId(e.value);
                    setFormHotelLabel(e.label);
                  } else {
                    setFormHotelId(null); // Clear the selection if the user clears the input
                    setFormHotelLabel(null);
                  }
                }}
                isClearable
                isSearchable
                placeholder={
                  formAreaLabelLoading
                    ? t("lan") === "en"
                      ? "Loading..."
                      : "جاري التحميل..."
                    : t("lan") === "en"
                      ? "Hotels"
                      : "الفنادق"
                }
              />
            </div>
          )}
          {!cookie.load("code") && (
            <div class="mt-[-6px] rounded-md border-8 border-[#002244] border-solid flex flex-col items-center justify-between px-3 py-2 ">
              {/* <input data-toggle = "datepicker" type = "text" class = "placeholder:text-eerie-black placeholder:uppercase text-sm outline-none border-none w-full" placeholder="check in"/> */}
              {/* <span class="cursor-pointer">
                      <img src = "/images/calendar.svg"/>
                  </span> */}
              <Select
                className="w-full  "
                name="Nationality"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "md:18px ",
                    fontWeight: "bold",
                    color: "red",
                    backgroundColor: "white",
                    fontStyle: "bold",

                    borderBottom: "2px thin gray",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderRadius: "0",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#002244", // Change this to the desired color for the placeholder
                  }),
                }}
                value={
                  nationFlag
                    ? null
                    : formNation && [
                        {
                          value: formNation,
                          label: formNation,
                          image: formNationImg,
                        },
                      ]
                }
                options={flagData}
                onChange={(e) => {
                  setFormNation(e.value);
                  setFormNationLabel(e.label);
                  setFormNationImg(e.image);
                }}
                formatOptionLabel={(flagData) => (
                  <div className="flex justify-start items-center  gap-x-3 country-option  ">
                    <img src={flagData.image} alt="country-image" className="w-5 h-3" />
                    <span>{flagData.label}</span>
                  </div>
                )}
                required
                isSearchable
                placeholder={nationFlag ? (t("lan") === "en" ? "Loading..." : "جاري التحميل...") : "Nationality"}
              />
            </div>
          )}

          <div class="mt-[-6px] rounded-md border-8 border-[#002244] border-solid flex flex-col items-center justify-between px-3 py-2   ">
            <Travel
              setPerson={setPerson}
              setFormRooms={setFormRooms}
              setDetails={setDetails}
              setFormKids={setFormKids}
              setAdultPerson={setAdultPerson}
            />
          </div>

          <div class="mt-[-6px] rounded-md border-8 border-[#002244] border-solid flex flex-col items-center justify-between px-3 lg:items-start lg:col-span-2 py-2  z-[60]">
            <DateRangePickerComponent
              setFormCheckIn={setFormCheckIn}
              setFormCheckOut={setFormCheckOut}
              setNights={setNights}
              formCheckIn={formCheckIn}
              formCheckOut={formCheckOut}
            />
          </div>
          {!cookie.load("code") && (
            <div class="mt-[-6px] rounded-md border-8 border-[#002244] border-solid flex flex-col items-center justify-between px-3 lg:items-start lg:col-span-2 py-2  z-50">
              <Select
                className="w-full "
                options={markup ? markupList : []}
                value={markupLoading ? null : markupLable && [{ label: markupLable, value: markupId }]}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "md:18px ",
                    fontWeight: "bold",
                    color: "red",
                    backgroundColor: "white",
                    fontStyle: "bold",

                    borderBottom: "2px thin gray",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderRadius: "0",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#002244", // Change this to the desired color for the placeholder
                  }),
                }}
                onChange={(e) => {
                  if (e) {
                    setMarkupId(e.value);
                    setMarkupLable(e.label);
                  } else {
                    // if (markupCheck) {
                    //   markupCheck[0] = null;
                    // }
                    setMarkupId(null);
                    setMarkupLable(null);
                  }
                }}
                required
                isSearchable
                placeholder={
                  markupLoading
                    ? t("lan") === "en"
                      ? "Loading..."
                      : "جاري التحميل..."
                    : t("lan") === "en"
                      ? "Markup"
                      : "سياسة التسعير"
                }
              />
            </div>
          )}

          <div className="flex flex-col items-center justify-between px-3 py-2    md:col-span-2 lg:col-span-1">
            <button
              type="submit"
              className=" bg-[#002244]  px-2 py-1 xl:p-[3px]  2xl:p-2  z-0 text-sm   tracking-widest   rounded-md font-barlow  flex items-center justify-center gap-x-1 uppercase text-white transition duration-300 ease-in-out  active:scale-95 hover:bg-yellow-600"
            >
              <GoSearch className="w-5 h-6" /> {t("search")}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default SearchForm;
